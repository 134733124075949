<script setup>
import { computed, ref } from 'vue'
import _ from 'lodash'
import StreamService from '@/services/StreamService'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import TabGroup from '../../Atoms/TabGroup.vue'
import TabItem from '../../Atoms/TabItem.vue'
import Badge from '../../Badge.vue'
import FormButton from '../../Atoms/FormButton.vue'
import IconBase from '../../icon/IconBase.vue'
import IconHelp from '../../icon/IconHelp.vue'
import StreamRegion from '../StreamRegion.vue'
import GetRtmpSrtButtons from './GetRtmpSrtButtons.vue'
import TabLinked from './TabLinked.vue'
import TabWebcam from './TabWebcam.vue'
import TabPublish from './TabPublish.vue'
import TabPull from './TabPull.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  mediaPulse: {
    type: Object,
    default() { return {}; }
  },
  selectedPreviewTab: {
    type: String
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  failoverIngestToggleState: {
    type: Boolean,
    default: false,
  },
  transcoderView: {
    type: Boolean
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  selectedLinkedSource: {
    type: Object,
    default: null
  },
  streamKeySrt: {
    type: Object,
    default: null
  },
  sourceSet: {
    type: Boolean,
    default: false
  },
  failoverIgestActive: {
    type: Boolean,
    default: false
  },
  isPasswordEnabled: {
    type: Boolean,
    default: false
  },
  isAnyBackupStreamLive: {
    type: Boolean,
    default: false
  },
  srtRegionIngestDestination: {
    type: String,
    default: null
  },
  backupStreamAlive: {
		type: Boolean,
		default() { return false; }
	},
	primaryStreamAlive: {
		type: Boolean,
		default() { return false; }
	},
})

const { notify } = useNotification()
const store = useStore()

const hasFailoverIngestFeature = computed(() => store.getters['User/hasFailoverIngestFeature'])

const emit = defineEmits({
  'stream-updated': (region) => { return { region } },
  'request-url': (url) => { return url },
  'update-password-enabled': (value) => {return value},
})


const switchRegionProcessing = ref(false)
const failoverIngestToggleProcessing = ref(false)

const switchStreamRegion = async (region) => {
  if (!region) {
    return;
  }

  switchRegionProcessing.value = true;
  try {
    await StreamService.switchStreamRegion(props.stream._id, region._id, props.transcoderView);
    emit('stream-updated', region);

    notify({
      group: 'success',
      text: `region changed to ${region.name}`
    });
  } catch (e) {
    notify({
      group: 'error',
      text: (e && e.message) || 'could not switch stream region'
    });
  }
  if (region.hostname === 'live.castr.io') {
    failoverIngestToggleProcessing.value = true;
  } else failoverIngestToggleProcessing.value = false;
  switchRegionProcessing.value = false;
}

const selectSourceTab = (sourceTab) => {
  emit('select-source-tab', sourceTab)
}

const classes = (isActive) => `flex h-8 items-center text-sm rounded capitalize justify-center px-2 w-full hover:bg-surface-4 ${isActive ? 'bg-surface-4' : 'bg-surface-3'}`

const setLinkedSource = () => emit('set-linked-source')
const initSelectedSource = (source) => emit('init-selected-source', source);
const selectedSource = (source) => emit('selected-source', source);

const failoverPrimaryIngest = () => {
  let ingest = _.get(props.stream, 'uiRegion.config.failover.primaryIngest');
  if (!ingest) {
    // ingest = this.stream.uiRegion.hostname.replace('.castr.io', '-primary.castr.io');
    ingest = props.stream.uiRegion.hostname
  }
  return ingest;
}

const failoverBackupIngest = () => {
  if (!hasFailoverIngestFeature.value) {
    return 'xxxxxxxxxxxxxxxxx';
  }
  let ingest = _.get(props.stream, 'uiRegion.config.failover.backupIngest');
  if (!ingest) {
    // ingest = this.stream.uiRegion.hostname.replace('.castr.io', '-backup.castr.io');
    ingest = props.stream.uiRegion.hostname
  }
  return ingest;
}

const getStreamPushUrl = (backupIngest) => {
  const stream = _.cloneDeep(props.stream)
  const { uiRegion: region } = stream
  let hostname = region.hostname;
  if (props.transcoderView && !region.rtmpPort) {
    region.rtmpPort = 1935
  }

  if (props.failoverIngestToggleState && !backupIngest && hasFailoverIngestFeature.value) {
    hostname = failoverPrimaryIngest()
  }

  if (props.failoverIngestToggleState && backupIngest) {
    hostname = failoverBackupIngest()
  }
  let pushUrl = `rtmp://${hostname}`;
  if (region.rtmpPort !== 1935) {
    pushUrl += ':' + region.rtmpPort;
  }

  // @todo
  let prefixPath = _.get(region, 'config.prefixPath');
  prefixPath = prefixPath || '/static';
  prefixPath = _.replace(prefixPath, /^\/\//gi, '/');

  pushUrl += prefixPath;


  if (!hasFailoverIngestFeature.value && backupIngest) {
    pushUrl = 'xxxxxxxxxxxxxx';
  }

  if (props.stream.user === '62c4b3398b2e840b11244bfd') {
    pushUrl = 'rtmp://fifa.castr.io/static'
  }

  return pushUrl;
}

const getStreamPullUrl = () => {
  let server = getStreamPushUrl();
  // override hostname with region stream is being received
  let hostId = props.mediaPulse?.hostId;
  if (props.stream.user === '62c4b3398b2e840b11244bfd') {
    hostId = 'fifa'
  }

  if (hostId) {
    const mappedHostname = `${hostId}.castr.io`;
    const defaultHostname = props.stream.uiRegion.hostname;
    server = server.replace(defaultHostname, mappedHostname);
    if (props.failoverIngestToggleState && props.hasFailoverIngestFeature) {
      server = `rtmp://${mappedHostname}/static/`
    }
  }

  if (!/\/$/gi.test(server)) {
    server += '/';
  }

  const pullUrl = server + props.stream.key;
  return pullUrl;
}

const requestSRTPullUrl = () => {
  // try copy to clipboard
  let server = props.streamKeySrt
  let hostId = props.mediaPulse?.hostId;

  if (props.stream.user === '62c4b3398b2e840b11244bfd') {
    hostId = 'fifa'
  }

  if (hostId) {
    const mappedHostname = `${hostId}.castr.io`;
    server = 'srt://' + mappedHostname + ':9999'
    server += `?streamid=#!::r=${props.stream.key},m=request`;
  }

  try {
    clipboardCopy(server);
  } catch (e) { }
}

const getStreamUrlAndKey = computed(() => {
  let server = getStreamPushUrl();
  let key = streamKey.value;
  const UrlAndKey = server + '/' + key;

  return UrlAndKey;
})

const streamKey = computed(() => {
  const prefix = props.failoverIgestActive && hasFailoverIngestFeature.value ? '_primary' : ''
  let pushKey = props.stream.key + prefix;
  if (props.stream?.config?.password && props.isPasswordEnabled) {
    pushKey += '?password=' + props.stream.config.password;
  }
  return pushKey;
})

const navigateBackupPage = () => {
  router.push({
    name: 'StreamsManageSettingsBackup',
    params: { streamId: props.stream._id }
  });
}
const onUpdateStream = (pullUrl) => emit('stream-updated', pullUrl);

const requestUrl = (url) => {
  if (url === 'RTMP') clipboardCopy(getStreamPullUrl())
  if (url === 'SRT') requestSRTPullUrl()
}

const clipboardCopy = async (value) => {
  console.log(value);
  await navigator.clipboard.writeText(value);
  notify({ group: 'info', text: 'Copied to clipboard' });
}

const updatePasswordEnabled = (value) => {
  console.log(value);
  emit('update-password-enabled', value)
}

</script>
<template>
  <section v-if="failoverIngestToggleState" class="p-3 bg-surface-2">
    <div class="flex items-center justify-between">
      <h4 class="text-base font-medium">Advanced Encoder Flow Enabled</h4>
      <FormButton @click="navigateBackupPage" type="secondary" size="sm">
        Go to Backup
      </FormButton>
    </div>
    <div class="mt-3 flex align-center justify-between">
      <div class="flex justify-between items-center w-full mt-2">
        <h4 class="text-sm text-surface-9">Primary Ingest</h4>
        <Badge v-if="primaryStreamAlive" type="online">
          <span class="rounded-full size-2 bg-white mr-1 self-center"></span>
          Live
        </Badge>
        <Badge v-else>
          <span class="rounded-full size-2 bg-surface-8 mr-1 self-center"></span>
          Offline
        </Badge>
      </div>
    </div>
    <div class="mt-3 flex align-center justify-between">
      <h4 class="flex justify-between align-center w-full">
        <span class="text-sm text-surface-9">Backup Ingest</span>
        <Badge v-if="backupStreamAlive" type="online">
          <span class="rounded-full size-2 bg-white mr-1 self-center"></span>
          Live
        </Badge>
        <Badge v-else>
          <span class="rounded-full size-2 bg-surface-8 mr-1 self-center"></span>
          Offline
        </Badge>
      </h4>
    </div>
    <hr class="mt-2">
    <div class="flex flex-wrap">
      <div class="w-full">
        <GetRtmpSrtButtons v-if="!transcoderView" @request-url="requestUrl" :stream="stream" :stream-alive="streamAlive"
          :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
          :stream-source-type="streamSourceType" :source-types="sourceTypes" />
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="flex items-center justify-center mt-3">
          <a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders" target="_blank"
            class="inline-flex items-center justify-center text-surface-7 px-0 gap-x-2 text-sm">
            <icon-base>
              <icon-help />
            </icon-base>
            <span>Recommended settings</span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section v-else class="p-3 bg-surface-2 relative">
    <StreamRegion :stream="stream" @switch-stream-region="switchStreamRegion" fullWidth show-alert />

    <div class="flex flex-wrap mt-3">
      <div class="w-full" v-if="!transcoderView">
        <TabGroup v-if="stream.type !== 'subsecond'" class="bg-surface-3 w-full gap-x-1 grid"
          :class="stream.type === 'live' || stream.type === 'restream' ? 'grid-cols-4' : 'grid-cols-3'">
          <TabItem :customClasses="classes(streamSourceType === sourceTypes.Publish)"
            :isActive="streamSourceType === sourceTypes.Publish" :label="sourceTypes.Publish"
            @click="selectSourceTab(sourceTypes.Publish)" />
          <TabItem :customClasses="classes(streamSourceType === sourceTypes.Pull)"
            :isActive="streamSourceType === sourceTypes.Pull" :label="sourceTypes.Pull"
            @click="selectSourceTab(sourceTypes.Pull)" />
          <TabItem :customClasses="classes(streamSourceType === sourceTypes.Webcam)"
            :isActive="streamSourceType === sourceTypes.Webcam" :label="sourceTypes.Webcam"
            @click="selectSourceTab(sourceTypes.Webcam)" />
          <TabItem :customClasses="classes(streamSourceType === sourceTypes.Linked)"
            :isActive="streamSourceType === sourceTypes.Linked" v-if="stream.type === 'live' || stream.type === 'restream'"
            :label="sourceTypes.Linked" @click="selectSourceTab(sourceTypes.Linked)" />
        </TabGroup>
      </div>
      <TabLinked v-show="streamSourceType === sourceTypes.Linked" @request-url="requestUrl"
        @set-linked-source="setLinkedSource" @init-selected-source="initSelectedSource"
        @selected-source="selectedSource" :stream="stream" :stream-alive="streamAlive"
        :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
        :transcoder-view="transcoderView" :stream-source-type="streamSourceType" :source-types="sourceTypes"
        :selected-linked-source="selectedLinkedSource" :source-set="sourceSet" />
      <TabWebcam v-show="streamSourceType === sourceTypes.Webcam" @request-url="requestUrl"
        @set-linked-source="setLinkedSource" @init-selected-source="initSelectedSource"
        @selected-source="selectedSource" :stream="stream" :stream-alive="streamAlive"
        :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
        :transcoder-view="transcoderView" :stream-source-type="streamSourceType" :source-types="sourceTypes"
        :selected-linked-source="selectedLinkedSource" :source-set="sourceSet" />
      <TabPublish v-show="streamSourceType === sourceTypes.Publish" @request-url="requestUrl"
        @set-linked-source="setLinkedSource" @init-selected-source="initSelectedSource"
        @selected-source="selectedSource" :stream="stream" :stream-alive="streamAlive"
        :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
        :transcoder-view="transcoderView" :stream-source-type="streamSourceType" :source-types="sourceTypes"
        :selected-linked-source="selectedLinkedSource" :source-set="sourceSet" :get-stream-push-url="getStreamPushUrl()"
        :get-stream-url-and-key="getStreamUrlAndKey" :srt-region-ingest-destination="srtRegionIngestDestination"
        :is-password-enabled="isPasswordEnabled" :stream-key="streamKey"
        :is-any-backup-stream-live="isAnyBackupStreamLive" :stream-key-srt="streamKeySrt" @update-password-enabled="updatePasswordEnabled" />
      <TabPull v-show="streamSourceType === sourceTypes.Pull" @request-url="requestUrl"
        @set-linked-source="setLinkedSource" @init-selected-source="initSelectedSource"
        @selected-source="selectedSource" :stream="stream" :stream-alive="streamAlive" @stream-updated="onUpdateStream"
        :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
        :transcoder-view="transcoderView" :stream-source-type="streamSourceType" :source-types="sourceTypes"
        :selected-linked-source="selectedLinkedSource" :source-set="sourceSet" :get-stream-push-url="getStreamPushUrl()"
        :get-stream-url-and-key="getStreamUrlAndKey" :srt-region-ingest-destination="srtRegionIngestDestination"
        :is-password-enabled="isPasswordEnabled" :stream-key="streamKey"
        :is-any-backup-stream-live="isAnyBackupStreamLive" :stream-key-srt="streamKeySrt" />
    </div>
  </section>

</template>