<template>
	<div class="container page-title-area">
		<section class="mt-5">
			<div class="title d-flex justify-content-between align-items-center mb-4">
				<h1 class="page-title mb-0">Livestreams</h1>
				<FormButton v-if="!loadingStatus" size="lg" @click="showCreateStreamSidebar" :disabled="memberPermission && !memberPermission.liveStreamManage">
					<icon-base class="mr-2" fill="none">
						<icon-camera-plus />
					</icon-base>
					Create New
				</FormButton>
			</div>
		</section>
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
			spinner-size="24px" />
		<div v-else-if="!this.activeTeam || hasAccessToLivePage">
			<section class="service-tab">
				<div class="toolbar-container tab-settings">
					<div class="d-flex w-100 flex-wrap flex-md-nowrap align-items-center my-3">
						<div class="mr-0 mr-md-2 mb-2 mb-md-0 filter-wrapper order-1 order-md-0">
							<b-dropdown class="w-100" variant="dark-3" no-caret
								toggle-class="d-flex align-items-center justify-content-between font-weight-normal"
								menu-class="dropdown-menu_md w-100">
								<template #button-content>
									<div class="d-flex align-items-center w-100">
										<icon-base width-view-box="20" icon-name="Sort By">
											<icon-sort />
										</icon-base>
										<!-- <span class="text-dark-5">Sort By:</span>  -->
										<span class="ml-1">{{ sortBy }}</span>
									</div> <i class="ml-2 fa fa-chevron-down" />
								</template>
								<b-dropdown-item :active="sortBy === 'Sort by Oldest'" @click="changeSortBy('Sort by Oldest')">
									<div class="ml-2">Sort by Oldest</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'Sort by Newest'" @click="changeSortBy('Sort by Newest')">
									<div class="ml-2">Sort by Newest</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'A to Z'" @click="changeSortBy('A to Z')">
									<div class="ml-2">A to Z</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'Z to A'" @click="changeSortBy('Z to A')">
									<div class="ml-2">Z to A</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>

						<b-input-group size="md" :class="{ active: searchWidgetActive }"
							class="search-form order-0 order-md-1 mb-3 mb-md-0">
							<template #prepend>
								<b-input-group-text class="search-form__toggle color-light" @click="toggleSearchWidget">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z"
											stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</b-input-group-text>
							</template>
							<b-form-input ref="searchWidgetInputElement" v-model="searchWidgetInput"
								:placeholder="searchWidgetActive ? 'Search for streams ..' : ''" class="search-form__field"
								@focus="onSearchWidgetFocus" @blur="onSearchWidgetBlur"></b-form-input>
						</b-input-group>
						<b-button-group class="order-2 ml-auto">
							<b-button variant="dark-3" :class="{ active: !shouldShowCompactModeStreams }" class="view-mode no-focus"
								@click="unsetCompactMode">
								<icon-base class="">
									<icon-grid />
								</icon-base>
							</b-button>
							<b-button variant="dark-3" :class="{ active: shouldShowCompactModeStreams }" class="view-mode no-focus"
								@click="setCompactMode">
								<icon-base class="">
									<icon-list />
								</icon-base>
							</b-button>
						</b-button-group>
					</div>
				</div>
				<b-row v-if="showBanner">
					<b-col>
						<div class="overusage-warning d-flex align-items-start justify-content-start">
							<img src="@/assets/images/info-small.svg" alt="Menu close" class="mr-2">
							<div class="overusage-text">
								<h5 class="text-dark-8 text-s-m text-500">You're using {{ this.liveCounter }} streams, but your plan only
									allows
									{{ getStreamnumber(this.currentCategory, true) }}.</h5>
								<p class="mb-0 text-dark-7 text-s-s font-normal">Please remove extra streams within {{ this.warningDays }}
									days,
									otherwise we'll need to disable them. To use {{ this.liveCounter }} streams, consider <a
										href="/app/subscribe">upgrading</a> your plan. <br>Thanks for your cooperation!</p>
							</div>
						</div>
					</b-col>
				</b-row>
				<div :class="{ 'list-view': shouldShowCompactModeStreams }" class="castr-tabs mt-4">
					<div v-for="(categoryName, index) in streamCategoryList" :key="categoryName">
						<FormButton :aria-controls="categoryName + '-streams'"
							:class="categoryCollapseHash[categoryName] ? null : 'collapsed'" variant="link"
							:aria-expanded="categoryCollapseHash[categoryName] ? 'true' : 'false'"
							customClasses="text-reset pl-4 font-weight-normal btn-category"
							@click="categoryCollapseHash[categoryName] = !categoryCollapseHash[categoryName]">
							<img :src="getCategoryIcon(categoryName)" :alt="categoryName + 'streams'" class="mr-2">
							<span class="mr-2">{{ categoryNameMaps[categoryName] || categoryName }}</span>
							<b-badge variant="dark-3" :id="'stream_count_' + categoryName" class="stream-count" pill>{{
								streamFilter(categoryName).length
							}}{{ streamsCount(categoryName) }}
							</b-badge>
							<b-tooltip :target="'stream_count_' + categoryName" placement="top" v-if="streamsCount(categoryName)">
								<span class="text-dark-8 text-small">
									You have created {{ streamFilter(categoryName).length }}{{ streamsCount(categoryName) }} streams.
									Need more? <a class="text-underline text-white" target="_blank" href="/app/subscribe">Upgrade here</a>
								</span>
							</b-tooltip>
						</FormButton>
						<b-collapse class="mt-3" v-model="categoryCollapseHash[categoryName]" :id="categoryName + 'streams'">
							<b-row>
								<b-col cols="12" md="6" :lg="shouldShowCompactModeStreams ? '12' : '4'"
									v-for="stream in streamFilter(categoryName)" :key="stream._id" class="grid-item">
									<!-- {{stream}} -->
									<stream-card-view :stream="stream"
										:notAllowed="checkEnabled(categoryName).length >= (getStreamnumber(categoryName, true))"
										:compact-mode="shouldShowCompactModeStreams"
										:deleteBlock="deleteProcessing" @delete-stream="onStreamDeleteRequest"
										@pulse-update="updateStreamPulse" @check-multistream="checkMultistream"
										:hasEnabledLinkedSources="hasEnabledLinkedSources(stream)"
										:tooltipLinkedSourceText="getTooltipLinkedSourceText(stream)" />
								</b-col>
							</b-row>
						</b-collapse>
						<hr class="my-6" :class="{ 'd-none': shouldShowCompactModeStreams }"
							v-show="index != (streamCategoryList.length - 1)">
					</div>
				</div>

				<b-row
					v-if="streamCategoryList.length === 0 || streams.length === (getVODList('vod').length)">
					<b-col class="text-center">
						<div class="">
							<img class="mb-6 mx-auto" src="@/assets/images/no-stream-02.svg" alt="No Stream">
						</div>
						<h3>Get Started Now!</h3>
						<p>
							Let's create a stream and start publishing
						</p>
						<div class="d-flex flex-column mw-200 mx-auto">
							<FormButton type="secondary" size="lg" v-b-modal.modal-demo-video class="text-center justify-center mt-6 mb-2">
								<icon-base class="mr-2" fill="none">
									<icon-camera />
								</icon-base>
								Livestreams Demo
							</FormButton>
							<FormButton size="lg" @click="showCreateStreamSidebar" class="text-center justify-center" :disabled="memberPermission && !memberPermission.liveStreamManage">
								<icon-base class="mr-2" fill="none">
									<icon-camera-plus />
								</icon-base>
								Create New
							</FormButton>
						</div>
					</b-col>
				</b-row>

				<!-- <add-stream-modal :typeFromBilling="this.action" @new-channel="onNewStream" /> -->
				<demo-video-modal videoid="nlAJJ_mROavGyHPRs8ZZw" />
				<confirm-modal message="Please update your subscription to get more concurrent stream."
					modal-id="increase-limit" modal-type="danger" ok-text="Yes" cancel-text="Cancel"
					@modal-confirm="gotoUpgrade" />
				<confirm-modal message="Would you like to delete this stream and all of its content?" modal-id="delete-stream"
					modal-type="danger" @modal-confirm="onStreamDeleteConfirm" />
				<confirm-modal message="Would you like to delete this stream and all of its content?"
					modal-id="delete-stream-showcased"
					body="This stream is included in the showcase. Removing it will remove the stream from the showcase as well."
					modal-type="danger" ok-text="Delete" cancel-text="Cancel" @modal-confirm="onStreamDeleteConfirmShowcased" />
				<confirm-modal
					message="Attention: Multistream has been discontinued. Set up a new All-In-One Stream today for uninterrupted streaming with enhanced features, at no extra cost."
					modal-id="create-aio" ok-text="Create All in One" cancel-text="Cancel" @modal-confirm="openCreatestream" />
				<confirm-modal :message="'You are invited to the &quot;' + this.teamownerName + '&quot; team'"
					body="Join your team and start streaming incredible content together." modal-id="join-team" modal-size="md"
					ok-text="Join Team" cancel-text="Cancel" @modal-confirm="joinTeam" />
				<div v-if="deleteProcessing" class="vue-notification-group" style="width: 300px; top: 10px; right: 10px;">
					<div data-id="2" class="vue-notification-wrapper" style="transition: all 300ms ease 0s;">
						<div class="vue-notification-template vue-notification info">
							<div class="notification-content">Deleting... Don't refresh the page</div>
						</div>
					</div>
				</div>
				<SidebarAddStream ref="sidebarAddStream" />
			</section>
		</div>
		<div class="no-access w-100 mt-0 pl-3" v-else>
			<div class="text-center pt-5 mt-5">
				<h4 class="text-dark-9 text-s-xl text-500 mb-1">You don't have permission to access this.</h4>
				<p class="text-dark-6 text-s-m">Contact the team owner at <a  class="text-dark-6 text-s-m text-underline" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</p>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import UserService from "@/services/UserService";
import Spinner from '@/components/ui/Spinner.vue'
import IconBase from '@/components/icon/IconBase.vue';
import IconSort from '@/components/icon/IconSort.vue';
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';
import Utils from '@/utils';
import StreamCardView from '@/components/ui/StreamCardView.vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import AlertModal from "@/components/modals/AlertModal.vue";
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import moment from 'moment';
import IconCameraPlus from '../components/icon/IconCameraPlus.vue';
import IconCamera from '../components/icon/IconCamera.vue';
import IconGrid from '../components/icon/IconGrid.vue';
import IconList from '../components/icon/IconList.vue';
import FormButton from '@/components/Atoms/FormButton.vue';
import SidebarAddStream from '../components/modals/SidebarAddStream.vue';

export default {
	name: 'ChannelList',
	components: {
		Spinner,
		StreamCardView,
		DemoVideoModal,
		ConfirmModal,
		IconBase,
		IconSort,
		IconGrid,
		IconList,
		Multiselect,
		AlertModal,
		IconCameraPlus,
		IconCamera,
		FormButton,
		SidebarAddStream
	},
	data() {
		return {
			showBanner: false,
			liveCounter: 0,
			downgradeDatedifference: 0,
			warningDays: 3,
			currentCategory: 'live',
			withinLast3Days: false,
			startDisabling: false,
			searchWidgetActive: false,
			searchWidgetFocused: false,
			searchWidgetInput: '',
			visible: true,
			deleteModalConfiguredStream: null,
			chosenStream: null,
			deleteProcessing: false,
			deleteIndex: null,
			loading: true,
			streamCreateDropdownActive: false,
			userSubscription: null,
			rawStreams: [],
			categoryNameMaps: {
				live: 'All In One Stream',
				restream: 'Multistream',
				ipcam: 'IP Camera',
				vod: 'Video Hosting',
			},
			categoryCollapseHash: {
				restream: true,
				live: true,
				vod: true,
				ipcam: true,
			},
			sortBy: 'Sort by Oldest',
			filterBy: [],
			action: null,
			masteraccStreams: [],
			emailFilteredStreams: [],
			team_id: null,
			teamownerName: null,
			showcase: null,
			showcasedStream: null
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions',
			streams: 'Streams/streams',
			activeTeam: 'User/activeTeam'
		}),
		hasAccessToLivePage() {
			return (this.memberPermission && (this.memberPermission.liveStreamView || this.memberPermission.liveStreamManage || this.memberPermission.liveStreamToggle || this.memberPermission.liveStreamDelete))
		},
		getVODList() {
			return (categoryName) => {
				const filteredStreams = _.cloneDeep(this.streams).filter((s) => {
					let bool = s.type === categoryName;
					if (bool && this.searchWidgetInput) {
						const searchStr = this.searchWidgetInput.toLowerCase();
						bool = s.name.toLowerCase().includes(searchStr);
					}
					return bool;
				});
				return filteredStreams;
			};
		},
		shouldShowCompactModeStreams() {
			return this.$store.state.Ui.shouldShowCompactModeStreams;
		},
		streamCategoryList() {
			const cats = {};
			let streamList = _.cloneDeep(this.streams)
			streamList.forEach(s => {
				if (s.type != 'vod' && s.type != 'scheduled' && s.type != 'tvplayout' && s.type != 'subsecond')
					cats[s.type] = s.sortOrder;
			});

			let categoryList = Object.keys(cats).sort(
				(cat1, cat2) => cats[cat1] > cats[cat2]
			);
			categoryList = _.sortBy(categoryList, cat => cats[cat]);

			return categoryList;
		},
		activeStreamsCount() {
			const activeStreams = _.cloneDeep(this.streams).filter(s => {
				return s.pulse && s.pulse.alive === true;
			});
			return activeStreams.length;
		},
		inactiveStreamsCount() {
			const inactiveStreams = _.cloneDeep(this.streams).filter(s => {
				return !s.pulse || s.pulse.alive !== true;
			});
			return inactiveStreams.length;
		},
		streamFilter() {
			let streamList = _.cloneDeep(this.streams)
			return categoryName => {
				const filteredStreams = streamList.filter(s => {
					let bool = s.type === categoryName;
					if (bool && this.searchWidgetInput) {
						const searchStr = this.searchWidgetInput.toLowerCase();
						bool = s.name.toLowerCase().includes(searchStr);
					}
					return bool;
				});
				return filteredStreams;
			};
		},
		checkEnabled(categoryName) {
			let streamList = _.cloneDeep(this.streams)
			return categoryName => {
				const filteredStreams = streamList.filter(s => {
					if (s.enabled) {
						let bool = s.type === categoryName;
						if (bool && this.searchWidgetInput) {
							const searchStr = this.searchWidgetInput.toLowerCase();
							bool = s.name.toLowerCase().includes(searchStr);
						}
						return bool;
					}
				});

				return filteredStreams;
			};
		},
		userEmail() {
			return this.$store.state.User.email;
		},
		memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
	},
	watch: {
		async activeTeam() {
			// await this.$store.dispatch('User/getinfoOwner', null, { root: true });
			this.loadStreams();
		}
	},
	async mounted() {
		this.$store.dispatch('Ui/setLoadingStatus', true);
		// await this.$store.dispatch('User/getinfoOwner', null, { root: true });
		this.loadStreams();
		const streamsCount = _.size(this.streams);
		let showcase = await UserService.getShowcase();
		this.showcase = showcase;

		// event tracking
		window.trackEvent('Dashboard', {
			totalStreams: streamsCount,
			enabledStreams: _.size(_.filter(this.streams, { enabled: true }))
		});

		if (window.Intercom) {
			window.Intercom('update', {
				totalStreams: streamsCount,
				totalLiveStreams: this.streams.filter(({ type }) => type === 'live').length
			});
		}

		// update window title
		this.$emit('updateTitle', 'Dashboard - Castr Streams');

		this.onViewLoaded();
		this.action = this.$route.query && this.$route.query.action;
		if (this.action === 'restream' || this.action === 'live') {
			this.$bvModal.show('modal-add-stream');
		}

		this.$root.$on('openAIO', data => {
			this.$bvModal.show('modal-add-stream');
		});
		this.$root.$on('teamchanging', data => {
			// this.loading = true;
			this.$store.dispatch('Ui/setLoadingStatus', true);
		});
		this.$root.$on('teamchanged', data => {
			this.loadStreams();
		});
		this.$root.$on('togglefinished', data => {
			this.loadStreams();
		});
		this.$root.$on('toggledone', data => {
			this.checkAllowed('live');
			this.checkAllowed('restream');
		});
		let teamID = this.$route.query && this.$route.query.teamID;
		let sourcePage = this.$route.query && this.$route.query.from;
		if (teamID) {
			this.team_id = teamID;
			try {
				const owner = await UserService.getTeamOwner(teamID, true);
				this.teamownerName = owner.teamName;
				if (sourcePage && sourcePage == 'signup') {
					this.joinTeam();
				} else {
					setTimeout(() => {
						this.$bvModal.show("join-team");
					}, 2000);
				}
			} catch (err) {
				console.log(err);
				this.$notify({
					group: "error",
					text: err.message || "Something went wrong. Please try again later.",
				});
			}

		}
	},
	methods: {
		async checkAllowed(categoryName) {
			// 1. If have excess stream enabled this function will count excess 
			// and disable those automatically.
			//2. If livestream count is greater than the allowed stream then we will show
			// a warning banner for 3 days.
			//3. After 3 days we will disable the livstream too.
			let list = this.streamFilter(categoryName);
			if (list.length > 0) {
				let check = list.filter(s => {
					return s.enabled;
				});
				let enabledStreamlength = check.length;
				let liveCollection = [];
				if (!this.startDisabling && (enabledStreamlength > this.getStreamnumber(categoryName, true))) {
					let live = await Promise.all(list.map(async s => {
						if (s.enabled) {
							let pulse = await this.checkPulse(s.key);
							if (pulse.alive) {
								if (!liveCollection.includes(s._id)) {
									liveCollection.push(s._id);
								}
								let objWithIdIndex = check.findIndex((obj) => obj._id === s._id);
								if (objWithIdIndex > -1) {
									check.splice(objWithIdIndex, 1);
								}
								this.liveCounter = liveCollection.length;
							}
						}
					}));


					if (this.liveCounter > this.getStreamnumber(categoryName, true)) {
						// const oneDay = 5 * 60 * 1000; 
						const oneDay = 24 * 60 * 60 * 1000;
						let d = new Date();
						let utc = d.getTime();
						let current = utc;
						let start = new Date('Jul 12 2023 10:00:00 GMT+0000').getTime();
						let dayspassed = Math.floor((current - start) / oneDay);
						if (this.withinLast3Days || ((dayspassed < 3) && (dayspassed >= 0))) {
							this.currentCategory = categoryName;
							if (this.withinLast3Days) {
								this.warningDays = 3 - this.downgradeDatedifference;
							}
							if (dayspassed < 3) {
								this.warningDays = 3 - dayspassed;
							}
							this.showBanner = true;
						} else {
							this.warningDays = 0;
							this.startDisabling = true;
							this.showBanner = false;
							this.checkAllowed(categoryName);
						}
					} else {
						this.showBanner = false;
					}
				} else {
					this.showBanner = false;
				}
				check.sort((a, b) => a.creationTime - b.creationTime);
				if (enabledStreamlength > this.getStreamnumber(categoryName, true)) {
					let toReduce = (enabledStreamlength - this.getStreamnumber(categoryName, true));
					if ((toReduce > 0) && (check.length > 0)) {
						await this.onStreamToggleConfirm(null, false, check[0]);
						// console.log(check[0]);
					}
				}
				if (enabledStreamlength >= this.getStreamnumber(categoryName, true)) {
					localStorage.setItem('notAllowed', true);
					localStorage.setItem('notAllowed' + categoryName, true);
				} else {
					localStorage.setItem('notAllowed', true);
					localStorage.setItem('notAllowed' + categoryName, false);
				}
			}
		},
		async checkPulse(streamKey) {
			try {
				const pulseObject = await StreamStatsService.getStreamMediaPulse(
					streamKey
				);
				return pulseObject;
			} catch (e) { }
		},
		getDowngradedate() {
			if (this.userSubscription) {
				let date = [];
				if (this.userSubscription.subscription && this.userSubscription.subscription.enabled && this.userSubscription.subscription.package.category == 'live') {
					let starttime = this.userSubscription.subscription;
					date.push(new Date(starttime).getTime());
				}
				const typeFilteredaddonsub = _.map(this.userSubscription && this.userSubscription.addonSubscriptions, function (single) {
					date.push(new Date(single.cstart).getTime());

				});
				const lastActivateddate = Math.max.apply(null, date);
				if (lastActivateddate) {
					// const millisecondsPerDay = 5 * 60 * 1000;
					const millisecondsPerDay = 24 * 60 * 60 * 1000;

					const currentDate = new Date();
					const givenDate = new Date(lastActivateddate);
					const timeDifference = currentDate.getTime() - givenDate.getTime();

					const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
					this.withinLast3Days = (daysDifference < 3);
					this.downgradeDatedifference = daysDifference;
				}
			}
		},
		async loadStreams() {
			try {
				await this.$store.dispatch('Streams/getStreams')

				this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions };
				setTimeout(() => {
					this.$store.dispatch('Ui/setLoadingStatus', false);

				}, 200);
				setTimeout(() => {
					this.checkAllowed('live');
					this.checkAllowed('restream');
					this.getDowngradedate();
				}, 1000);
			} catch (err) {
				this.$store.dispatch('Ui/setLoadingStatus', false);
				return;
			}
			this.getStreamnumber();
		},
		streamsCount(type) {
			if (type === 'restream') return '/' + (this.baseSubscription?.definitionOverride?.streams ?? this.baseSubscription?.package?.definition?.streams ?? 0)
			if (type === 'live') return '/' + (this.addonSubscriptions?.find(subscription => subscription.category === 'live')?.definitionOverride?.streams ?? this.addonSubscriptions?.find(subscription => subscription.category === 'live')?.package?.definition?.streams ?? 0)
			return '/' + (0)
		},
		getStreamnumber(type, number = false) {
			if (this.userSubscription) {
				let streamCountarray = [];
				if (this.userSubscription?.subscription?.enabled && this.userSubscription?.subscription?.package?.category == type) {

					let streamCountoverride = _.get(this.userSubscription.subscription, ['definitionOverride', 'streams']);
					if (streamCountoverride) {
						streamCountarray.push(streamCountoverride);
					} else {
						streamCountarray.push(_.get(this.userSubscription.subscription, ['package', 'definition', 'streams']));
					}
				}
				if (streamCountarray.length) {
					if (number) {
						return (streamCountarray.length > 1 ? Math.max.apply(Math, streamCountarray) : streamCountarray[0]);
					} else {
						return '/' + (streamCountarray.length > 1 ? Math.max.apply(Math, streamCountarray) : streamCountarray[0]);
					}
				}
			}
		},
		changeSortBy(val) {
			this.sortBy = val;
			this.$store.dispatch('Streams/setSortOption', this.sortBy)

		},
		setCompactMode() {
			this.$store.dispatch('Ui/setCompactModeStreams', true);
		},
		unsetCompactMode() {
			this.$store.dispatch('Ui/setCompactModeStreams', false);
		},
		getCategoryIcon(categoryName) {
			if (categoryName === 'subsecond') {
				return
			}
			return require(`@/assets/icons/stream-type-${categoryName}-ico.svg`); // eslint-disable-line no-undef
		},
		updateStreamPulse(streamId, pulseData) {
			const sindex = _.findIndex(this.streams, s => s._id === streamId);
			if (sindex > -1) {
				const updatedStream = { ...this.streams[sindex], pulse: pulseData };
				const updatedStreams = Utils.updateArrayItem(
					_.cloneDeep(this.streams),
					updatedStream,
					sindex
				)
				this.$store.dispatch('Streams/updateStreams', updatedStreams)
			}
		},
		checkSlot(categoryName) {
			const allowed = getStreamnumber(categoryName, true);
		},
		checkMultistream(stream) {
			if (this.userSubscription.subscription && this.userSubscription.subscription.enabled && this.userSubscription.subscription.package.category == 'restream') {
				this.$root.$emit('bv::show::modal', 'modalToggleStream' + stream._id, '#btnShow')
			} else {
				this.$bvModal.show('create-aio')
			}
		},
		toggleSearchWidget() {
			this.searchWidgetActive = !this.searchWidgetActive;
			this.$refs.searchWidgetInputElement.focus();
			if (!this.searchWidgetActive) {
				this.searchWidgetInput = '';
			}
		},
		onSearchWidgetFocus() {
			this.searchWidgetFocused = true;
		},
		onSearchWidgetBlur() {
			this.searchWidgetFocused = false;
			setTimeout(() => {
				const blankSearchVal =
					!this.searchWidgetInput || !this.searchWidgetInput.length;
				if (
					this.searchWidgetActive &&
					blankSearchVal &&
					!this.searchWidgetFocused
				) {
					this.toggleSearchWidget();
				}
			}, 500);
		},
		onViewLoaded() {
			const qs = Utils.parseQueryString(window.location.search) || {};
			if (qs.action === 'newstream') {
				let streamCreationType = qs.streamtype || '';
				streamCreationType =
					streamCreationType === 'restream' ? '' : streamCreationType;
				if (streamCreationType) streamCreationType += '-';

				const streamCreationModalId = `modal-add-${streamCreationType}channel`;

				this.$root.$emit('bv::show::modal', streamCreationModalId);
			}
		},
		openCamModal() {
			this.$root.$emit('bv::show::modal', 'modal-add-cam-channel');
		},
		openScheduledStreamModal() {
			this.$root.$emit('bv::show::modal', 'modal-add-scheduled-channel');
		},
		onStreamCreateToggle(state) {
			this.streamCreateDropdownActive = state;
		},
		onNewStream(stream, regionDetails) {
			this.streams = [...this.streams, stream];
			this.$notify({ group: 'success', text: 'Stream deployed successfully' });

			// setTimeout(() => {
			let redirectPath = '/multistreams/';
			if (stream.type === 'live') {
				redirectPath = '/livestreams/';
			} else if (stream.type === 'ipcam') {
				redirectPath = '/ipcams/';
			} else if (stream.type === 'scheduled') {
				redirectPath = '/scheduled/';
			} else if (stream.type === 'vod') {
				redirectPath = '/vods/';
			}

			redirectPath += stream._id;
			this.$router.push({ path: redirectPath });
			// }, 3000)

			// track event
			window.trackEvent(`Deployed new stream ${stream.name}${regionDetails ? ` in ${regionDetails.name}` : ''}`, stream);
		},
		async onStreamDeleteRequest(stream) {
			this.deleteModalConfiguredStream = stream;
			this.deleteIndex = this.streams.indexOf(stream);
			let modalId = "delete-stream";
			// this.$root.$emit('bv::show::modal', 'modal-confirm');
			if (this.showcase && this.showcase.streams.length > 0) {
				let hasShowcased = this.showcase.streams.filter((sw) => {
					return sw._id == stream._id;
				});
				if (hasShowcased.length > 0) {
					this.showcasedStream = hasShowcased[0];
					modalId = "delete-stream-showcased";
				}
			}
			this.$bvModal.show(modalId);
		},
		async onStreamDeleteConfirmShowcased() {
			const filteredShowcase = [];
			this.showcase.streams.map(sw => {
				if (sw._id != this.showcasedStream._id) {
					filteredShowcase.push(sw._id);
				}
			});
			const filteredVod = [];
			this.showcase.vods.map(sw => {
				let vid = (sw.refId ? sw.refId : sw.id);
				filteredVod.push(vid);
			});
			const streamOrder = this.showcase.streamOrder.filter(sw => {
				return sw != this.showcasedStream._id;
			});
			this.showcase.streams = filteredShowcase;
			let updateShowcase = await UserService.addShowcase(filteredShowcase, filteredVod, streamOrder, this.showcase._id);
			this.onStreamDeleteConfirm();
		},
		gotoUpgrade() {
			window.location.href = `/app/subscribe?category=live`;
		},
		openCreatestream() {
			this.$bvModal.show('modal-add-stream');
		},
		async onStreamDeleteConfirm() {
			// pop stream out of the list
			this.deleteProcessing = true;
	
			// Try to delete its dvr
			let vodEpisodes
			let permanentDvrArchives
			try {
				vodEpisodes = await StreamService.getStreamDvrArchives(this.deleteModalConfiguredStream._id);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not retreive saved vod(s)'
				});
			}

			vodEpisodes = _.map(vodEpisodes, vodEpisode => {
				const ep = _.assign({}, vodEpisode, {
					startTimeDate: new Date(vodEpisode.startTime),
					endTimeDate: new Date(vodEpisode.endTime)
				});

				ep.fileName = `${moment(new Date(String(ep.startTimeDate))).fromNow()}`
				ep.fileName = ep.fileName.replace('a few seconds ago', 'a seconds ago');
				ep.creationTime = ep.startTimeDate;

				ep.live = false;
				ep.expanded = false;
				ep.streamType = this.deleteModalConfiguredStream.type;
				ep.blocking = false;
				ep.size = ep.size ? this.$filters.bytes(ep.size, false, 2) : '--';

				ep.exportProcessing = false;
				ep.exportStatusTimer = null;

				return ep;
			});

			permanentDvrArchives = _.filter(vodEpisodes, { exportStatus: 'EXPORTED' }) || []
			permanentDvrArchives.forEach(async (video) => {
				const q = {
					streamId: this.deleteModalConfiguredStream._id,
					from: video.from,
					duration: video.duration,
					abr: video.abr
				};
				if (!video.pseudoVod) {
					await StreamService.deleteStreamDvrArchive(q);
				}
			});

			try {
				await StreamService.deleteStream(this.deleteModalConfiguredStream._id);
				await this.$store.dispatch('Streams/getStreams')
				// track event
				const removedStream = this.deleteModalConfiguredStream;
				window.trackEvent(
					`Deleted stream ${removedStream.name}`,
					removedStream
				);
			} catch (err) {
				this.$notify({ group: 'error', title: err.error, text: err.message });
				// push stream back to list
				this.onNewStream(this.deleteModalConfiguredStream);
			}

			this.deleteModalConfiguredStream = null;
			this.deleteIndex = null;
			this.deleteProcessing = false;
			this.$notify({
				group: 'success',
				text: 'Deleted successfully'
			});
		},
		async onStreamToggleConfirm(ev, newStatus, s = null) {
			let stream = this.stream;
			if (s) {
				stream = s;
			}
			try {
				await StreamService.toggleStream(stream._id);
				this.loadStreams();
				// window.location.reload();
			} catch (err) {
				this.$notify({
					group: 'error',
					title: "Couldn't toggle stream status",
					text: err.message
				});

				if (err.message && err.message.indexOf('upgrade') > -1) {
					this.$root.$emit('bv::show::modal', 'billing-prompt');
				}
			}
		},
		async joinTeam() {
			// this.loading = true;
			this.$store.dispatch('Ui/setLoadingStatus', true);
			try {
				const res = await UserService.joinTeam(this.team_id);
				if (res.success) {
					const select = await UserService.selectTeam(this.team_id);
					const loadActiveteam = await UserService.getMyTeam();
					this.$notify({
						group: "success",
						text: `You're successfully logged in into ${loadActiveteam.name} team`,
					});
					setTimeout(() => {
						this.$router.push({ path: '/' });
						window.location.reload();
					}, 1000);
				}
			} catch (err) {
				// this.loading = false;
				this.$store.dispatch('Ui/setLoadingStatus', false);
				this.$notify({ group: 'error', title: err.error, text: err.message });
				return;
			}
		},
		// async setdefaultTeam(){
		// 	const res = await UserService.selectTeam(this.$store.state.User._id);
		// 	const unsetOwner = await UserService.setOwnerSession(null);
		// },
		redirect() {
			setTimeout(() => {
				this.$router.push({ path: '/' });
				window.location.reload();
			}, 1000);
		},
		showCreateStreamSidebar(){
			this.$refs.sidebarAddStream.toggleSidebar()
		},
		hasEnabledLinkedSources(stream) {
			const result = stream?.platforms?.some(platform => platform.enabled && platform.linkedSource) || false;
			return stream?.platforms?.some(platform => platform.enabled && platform.linkedSource) || false;
		},
		getTooltipLinkedSourceText(stream) {
			const enabledLinkedSource = stream?.platforms?.find(platform => platform.enabled && platform.linkedSource);
			if (!enabledLinkedSource || !enabledLinkedSource?.streamObject) return ''
			const linkedStreamName = enabledLinkedSource.streamObject.name
			return `This livestream cannot be deleted because it is being used as a source in ${linkedStreamName} livestream. To delete this livestream, first remove it as a source from ${linkedStreamName} livestream.`
		},
	},
	beforeDestroy() {
		this.$store.dispatch('Ui/setLoadingStatus', true);
	},

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .btn-category::after {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	width: 100%;
	border-top: 1px solid var(--c-dark-5);
	flex: 1 1 100%;
	right: -100%;
} */
.stream-acc li {
	display: inline-block;
	padding: 4px 15px;
	margin: 3px;
	cursor: pointer;
	border-radius: 4px;
}

.stream-acc li.active {
	background: var(--c-dark-5);
	font-weight: 500;
}

.stream-acc ul {
	background: var(--c-dark-3);
	border-radius: 6px;
}

.filter-wrapper>div {
	min-width: 282px;
}

.active .search-form__toggle {
	z-index: 9;
}

.search-form__field {
	border: none;
	width: 0;
	flex: 0 0 0;
	transition: all .2s ease-out;
	padding-left: 0;
	padding-right: 0;
	caret-color: var(--c-main-light);
}

.search-form__field::placeholder {
	color: var(--c-dark-6);
}

.input-group>.search-form__field {
	border-radius: 6px;
}

.active .search-form__field {
	width: 100%;
	max-width: 620px;
	flex: 1 1 auto;
	transition: all .3s ease-out;
	padding-left: 40px;
	padding-right: 16px;
	border: 1.5px solid var(--c-main-light);
	background: var(--c-dark-2);
}

.search-form__close {
	visibility: hidden;
}

.active .search-form__close {
	visibility: visible;
}

.view-mode.active {
	color: var(--c-text-1);
}

.castr-card.compact {
	display: flex;
	background: transparent;
	align-items: center;
}

.badge-pill {
	line-height: 13px;
	display: flex;
	align-items: center;
	font-weight: 500;
	padding: 0px;
	width: 24px;
	height: 24px;
	justify-content: center;
}

.btn-category:focus {
	text-decoration: none;
}

.btn-category:focus-visible {
	outline: none;
}

.no-stream {
	min-height: 140px;
}

.stream-count {
	padding: 0px 10px;
	width: auto;
	height: 20px;
}

.overusage-warning {
	background: rgba(167, 181, 226, 0.08);
	border-radius: 6px;
	padding: 8px 12px;
}

@media (max-width: 767px) {
	.filter-wrapper {
		flex: 1 1 auto;
	}

	.search-form__field {
		padding-left: 40px;
		padding-right: 16px;
		flex: 1 1 100%;
	}

	.color-light svg {
		z-index: 1;
	}

	.filter-wrapper {
		max-width: 100%;
	}

	.stream-acc {
		width: 100%;
	}
}
</style>
