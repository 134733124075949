<template>
	<div v-if="uploadFiles.length > 0" class="upload-widget bg-white">
		<b-button size="lg" class="d-flex justify-content-between align-items-center px-3 border-0"
			variant="sidebar-collapse" v-b-toggle.setup>
			<span class="text-s-s text-dark-1">Uploading {{ uploadFiles.length }} files</span>
			<svg class="ml-auto mr-0 text-dark-7" width="12" height="7" viewBox="0 0 12 7" fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M10.9242 0.575638C11.1585 0.809953 11.1585 1.18985 10.9242 1.42417L6.42417 5.92417C6.18985 6.15848 5.80995 6.15848 5.57564 5.92417L1.07564 1.42417C0.841324 1.18985 0.841324 0.809953 1.07564 0.575638C1.30995 0.341324 1.68985 0.341324 1.92417 0.575638L5.9999 4.65137L10.0756 0.575638C10.31 0.341324 10.6899 0.341324 10.9242 0.575638Z"
					fill="currentColor" fill-opacity="0.5" />
			</svg>
		</b-button>
		<b-collapse :visible="true" class="p-3" id="setup">
			<div v-for="media in uploadFiles" :key="media.name" class="video-item">
				<b-row class="flex-nowrap" no-gutters v-if="!media.mediaInfo">
					<b-col>
						<span :title="media.fileNameOriginal"
							class="video-item__name text-dark-1 text-s-s d-flex align-items-center">
							{{ media.fileName }}
						</span>
					</b-col>
					<b-col cols>
						<div class="text-center">
							<i class="fas fa-spinner fa-spin" />
						</div>
					</b-col>
				</b-row>
				<div class="list-unstyled mb-0" v-else>
					<b-row class="flex-nowrap" align-v="center" no-gutters>
						<b-col v-if="isMediaCompatible(media.mediaInfo)">
							<span :title="media.fileNameOriginal"
								class="video-item__name text-dark-1 text-500 text-s-s d-inline-flex align-items-center">
								{{ media.fileName }}
							</span>
						</b-col>
						<b-col class="" v-else>
							<span :title="media.fileNameOriginal"
								class="video-item__name text-dark-1 text-500 text-s-s d-inline-flex align-items-cente">{{ media.fileName
								}}</span>
							<span class="color-danger text-s-xs d-flex">
								<svg class="mr-1" width="16" height="15" viewBox="0 0 16 15" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M5.36957 3.79623C6.53108 1.67817 7.11184 0.619141 8 0.619141C8.88817 0.619141 9.46892 1.67817 10.6304 3.79623L14.0638 10.0571C15.15 12.0377 15.693 13.028 15.2574 13.7638C14.8217 14.4996 13.6923 14.4996 11.4334 14.4996H4.56665C2.30773 14.4996 1.17826 14.4996 0.742615 13.7638C0.306966 13.028 0.850048 12.0377 1.93621 10.0571L5.36957 3.79623ZM7.99996 4.74955C8.41417 4.74955 8.74996 5.08534 8.74996 5.49955V7.99955C8.74996 8.41377 8.41417 8.74955 7.99996 8.74955C7.58575 8.74955 7.24996 8.41377 7.24996 7.99955V5.49955C7.24996 5.08534 7.58575 4.74955 7.99996 4.74955ZM8 11.9996C8.55228 11.9996 9 11.5518 9 10.9996C9 10.4473 8.55228 9.99956 8 9.99956C7.44771 9.99956 7 10.4473 7 10.9996C7 11.5518 7.44771 11.9996 8 11.9996Z"
										fill="currentColor" />
								</svg>
								<span>{{ getErrorMessage(media.mediaInfo) }}</span>
							</span>
						</b-col>
						<b-col class="text-right">
							<div v-show="!media.uploading" class="h-100 vod-upload-action justify-content-end align-items-center">

								<!-- upload button -->
								<b-button title="Click to upload this file" variant="icon-6" size="sm" class="px-1 ml-auto"
									@click="uploadVideoFile(media)" :disabled="media.durationExceeded">
									<span class="d-flex align-items-center" v-if="!media.statusProcessing">
										<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd"
												d="M3.60823 5.74275C3.74035 3.70905 5.43246 2.09999 7.4999 2.09999C9.22469 2.09999 10.6894 3.22005 11.2035 4.77413C11.2697 4.97406 11.4358 5.12468 11.6412 5.171C12.9344 5.46264 13.8999 6.61928 13.8999 7.99999C13.8999 8.7284 13.6321 9.39277 13.1888 9.90233C12.9713 10.1523 12.9977 10.5313 13.2477 10.7488C13.4977 10.9663 13.8767 10.94 14.0942 10.6899C14.7202 9.97032 15.0999 9.02875 15.0999 7.99999C15.0999 6.16334 13.8928 4.60989 12.229 4.0876C11.4727 2.21911 9.64106 0.899994 7.4999 0.899994C4.9146 0.899994 2.77896 2.82334 2.44522 5.31758C1.52199 5.85378 0.899902 6.85369 0.899902 7.99999C0.899902 9.27672 1.67161 10.3719 2.77158 10.847C3.07579 10.9783 3.42892 10.8383 3.56031 10.534C3.6917 10.2298 3.55161 9.87671 3.2474 9.74532C2.57123 9.45327 2.0999 8.781 2.0999 7.99999C2.0999 7.21899 2.57123 6.54672 3.2474 6.25467C3.45426 6.16532 3.59362 5.9676 3.60823 5.74275ZM8.42419 6.07572C8.18988 5.84141 7.80998 5.84141 7.57566 6.07572L5.07566 8.57572C4.84135 8.81004 4.84135 9.18994 5.07566 9.42425C5.30998 9.65857 5.68988 9.65857 5.92419 9.42425L7.39993 7.94852V14C7.39993 14.3314 7.66856 14.6 7.99993 14.6C8.3313 14.6 8.59993 14.3314 8.59993 14V7.94852L10.0757 9.42425C10.31 9.65857 10.6899 9.65857 10.9242 9.42425C11.1585 9.18994 11.1585 8.81004 10.9242 8.57572L8.42419 6.07572Z"
												fill="#68759C" />
										</svg>
									</span>
									<i v-else class="fa fa-spinner fa-spin" />
								</b-button>

								<!-- remove button -->
								<b-button :key="media.fileName" class="px-1 mr-n2" v-show="!media.statusProcessing" variant="icon-6"
									size="sm" @click="removeVideoFile(media)">
									<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M1.07613 1.07613C1.31044 0.841812 1.69034 0.841812 1.92465 1.07613L6.00039 5.15186L10.0761 1.07613C10.3104 0.841812 10.6903 0.841812 10.9247 1.07613C11.159 1.31044 11.159 1.69034 10.9247 1.92465L6.84892 6.00039L10.9247 10.0761C11.159 10.3104 11.159 10.6903 10.9247 10.9247C10.6903 11.159 10.3104 11.159 10.0761 10.9247L6.00039 6.84892L1.92465 10.9247C1.69034 11.159 1.31044 11.159 1.07613 10.9247C0.841812 10.6903 0.841812 10.3104 1.07613 10.0761L5.15186 6.00039L1.07613 1.92465C0.841812 1.69034 0.841812 1.31044 1.07613 1.07613Z"
											fill="currentColor" />
									</svg>
								</b-button>
							</div>
							<div class="inline-flex items-center" v-show="media.uploading">
								<span class="text-s-xs text-dark-6">{{ media.uploadProgress.toFixed(0) }}%</span>
								<b-button v-if="media.uploadProgress.toFixed(0) < 100" variant="icon" class="px-1 mr-n2" size="sm"
									@click="cancelVideoUpload(media)">
									<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M1.07613 1.07613C1.31044 0.841812 1.69034 0.841812 1.92465 1.07613L6.00039 5.15186L10.0761 1.07613C10.3104 0.841812 10.6903 0.841812 10.9247 1.07613C11.159 1.31044 11.159 1.69034 10.9247 1.92465L6.84892 6.00039L10.9247 10.0761C11.159 10.3104 11.159 10.6903 10.9247 10.9247C10.6903 11.159 10.3104 11.159 10.0761 10.9247L6.00039 6.84892L1.92465 10.9247C1.69034 11.159 1.31044 11.159 1.07613 10.9247C0.841812 10.6903 0.841812 10.3104 1.07613 10.0761L5.15186 6.00039L1.07613 1.92465C0.841812 1.69034 0.841812 1.31044 1.07613 1.07613Z"
											fill="currentColor" />
									</svg>
								</b-button>
								<svg v-else-if="media.uploadProgress.toFixed(0) == 100" class="ml-1" width="13" height="10"
									viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M11.9243 1.42426C11.9243 1.42427 11.9243 1.42428 11.9243 1.42429L11.9243 1.42432L11.9239 1.42471L11.922 1.42658L11.914 1.43462L11.8813 1.4676C11.8523 1.49697 11.809 1.54096 11.7529 1.59857C11.6405 1.71381 11.4766 1.88354 11.2717 2.09996C10.862 2.53291 10.2892 3.15206 9.63906 3.89512C8.33538 5.38502 6.73413 7.35741 5.5087 9.31798C5.40807 9.47898 5.23717 9.58285 5.04792 9.59804C4.85867 9.61323 4.67339 9.53795 4.54836 9.39507L1.04836 5.39507C0.830155 5.14568 0.855426 4.76663 1.10481 4.54842C1.35419 4.33021 1.73325 4.35548 1.95146 4.60486L4.92726 8.00578C6.12685 6.2 7.5609 4.44783 8.73597 3.10491C9.3983 2.34798 9.98182 1.71714 10.4002 1.27509C10.6094 1.05401 10.7775 0.879985 10.8937 0.760851C10.9518 0.70128 10.9969 0.655419 11.0277 0.624248L11.063 0.588625L11.0722 0.579302L11.0747 0.576803L11.0754 0.576099L11.0756 0.575884C11.0757 0.575814 11.0758 0.57576 11.5 1.00002L11.0758 0.57576C11.3101 0.341446 11.69 0.341446 11.9243 0.57576C12.1586 0.810064 12.1586 1.18994 11.9243 1.42426Z"
										fill="#65C374" />
								</svg>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<div v-show="media.uploading" class="h-100 vod-upload-action justify-content-end align-items-center">
								<b-progress :value="media.uploadProgress" :max="100" variant="primary"
									class="progressbar-wrapper w-100">
									<b-progress-bar :value="media.uploadProgress">
									</b-progress-bar>
								</b-progress>
							</div>
						</b-col>
					</b-row>
					<b-row v-if="media.durationExceeded">
						<b-col class="text-s-xs text-danger">Your video exceed 10 mins for trial, please upload shorter
							video.</b-col>
					</b-row>
				</div>
			</div>
		</b-collapse>
		<alert-modal :message="uploadErrorMessage || 'You are exceeding your storage limit. Please upgrade to continue'"
			modal-id="alert-storage-exceeding" ok-text="Got it, thanks" button-alignment="text-right" />
		<alert-modal modal-id="alert-video-max-size"
			:message="maxFileSizeErrorMessage || 'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'"
			ok-text="Got it, thanks" button-alignment="text-right" />
		<alert-modal modal-id="alert-video-bad-codec"
			message="Only MP4 videos encoded with the H.264 codec are supported. Please ensure your video is in the correct format and encoding before uploading."
			ok-text="Got it, thanks" button-alignment="text-right" @modal-confirm="removeFileFromUpload" />
		<upload-alert-bad-format-modal modal-id="alert-video-bad-format" :message="errorMessage" :body="errorBody"
			@modal-confirm="continueUpload" ok-text="Upload Anyways" button-alignment="text-right" />
	</div>
</template>
<script>
import axios from 'axios'
import StreamService from '@/services/StreamService'
import { mapGetters } from 'vuex'
import AlertModal from '@/components/modals/AlertModal.vue'
import UploadAlertBadFormatModal from '@/components/modals/UploadAlertBadFormatModal.vue';
import UserService from "@/services/UserService"
import MetricsService from '../../services/MetricsService.js'

export default {
	name: 'UploadFiles',
	components: {
		AlertModal,
		UploadAlertBadFormatModal
	},
	props: {

	},
	data() {
		return {
			availableEncodingPresets: [
				{ id: '360p', text: '360p', minHeight: 300 },
				{ id: '480p', text: '480p', minHeight: 440 },
				{ id: '720p', text: '720p', minHeight: 700 },
				{ id: '1080p', text: '1080p', minHeight: 1020 }
			],
			uploadErrorMessage: null,
			maxFileSizeErrorMessage: null,
			maxFileNumberErrorMessage: null,
			errorMessage: null,
			errorBody: null,
			canUpload: true,
			tempoFile: null
		}
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			uploadFiles: "Ui/uploadFiles",
			streamToUpload: "Ui/streamToUpload",
			canEncodeVideos: "User/canEncodeVideos",
			vodStreams: 'Streams/vodStreams',
		}),
		shouldCheckMediaQuality() {
			return this.$route.name === 'ScheduledStreamManageDashboard'
		},
	},
	watch: {
		uploadFiles: {
			handler(newValue, oldValue) {
				newValue.map(file => {

					setTimeout(() => {
						if (file.durationExceeded) {
							return
						}
						const isAllowedCodec = !!file.mediaInfo.codecs.find(element => element.codec === 'AVC')

						if (!isAllowedCodec) {
							this.canUpload = false
							this.tempoFile = file
							this.$root.$emit('bv::show::modal', 'alert-video-bad-codec');
						} else if (file.uploadable && !file.uploading && newValue !== oldValue && file.mediaInfo.isVbr) {
							this.canUpload = false
							this.$root.$emit('bv::show::modal', 'alert-video-bad-format');
						} else if ((this.$route.name === "VODStreamManage" || this.$route.name === "VODBucketVideos") && file.uploadable && !file.uploading && newValue !== oldValue) {
							this.uploadVideoFile(file)
						} else if (file.uploadable && !file.uploading && newValue !== oldValue && !file.mediaInfo.isVbr) {
							this.uploadVideoFile(file)
						}
					}, "500");
				})
			},
			deep: true
		},
		canUpload() {
			if (this.canUpload) {
				this.uploadFiles.map(file => {
					if (file.uploadable && !file.uploading) {
						this.uploadVideoFile(file)
					}
				})
			}
		}
	},
	async mounted() {
		this.$store.dispatch('Ui/dropFilesToUpload');
		this.$store.dispatch('Ui/dropStreamToUpload');
	},
	beforeDestroy() {
		this.setupAWSLibs();
		this.$store.dispatch('Ui/dropFilesToUpload');
		this.$store.dispatch('Ui/dropStreamToUpload');
	},
	methods: {
		isMediaCompatible(mediaInfo) {
			if (!this.shouldCheckMediaQuality) return true

			if (mediaInfo.hasAudioTrack && !mediaInfo.isVbr) return true
			return false
		},
		async uploadVideoFile(uploadItem) {
			if (this.streamToUpload.type === 'vod' && this.canEncodeVideos) {
				// auto video encoding if has special subscription plan
				const maxHeight = uploadItem.mediaInfo.height
				const encodingPresets = _.map(_.filter(this.availableEncodingPresets, i => maxHeight >= i.minHeight), 'id')
				this.$store.dispatch('Ui/addFileData', { uploadItem, encodingPresets: encodingPresets });
				// console.log('encoding video to=', JSON.stringify(uploadItem.encodingPresets))
			}
			this.$store.dispatch('Ui/addFileData', { uploadItem, statusProcessing: true });
			// uploadItem.statusProcessing = true;
			const isReplacingExistingVod = uploadItem.isReplacingExistingFile;
			let mediaUploadSpec;
			let replaceValidateFailed = false;
			try {
				if (isReplacingExistingVod) {
					const res = await this.validateReplaceStorage(uploadItem);
					if (!res) {
						replaceValidateFailed = true;
						return;
					}
					mediaUploadSpec = await this.requestSignedUploadUrlForReplacingVideo(uploadItem);
				} else {
					mediaUploadSpec = await this.requestSignedUploadUrl(uploadItem);
				}
			} catch (e) {
				console.log(e);
				this.uploadErrorMessage = 'We are unable to upload your file, please try again. If the issue persist then please contact live support.';
				this.$root.$emit('bv::show::modal', 'alert-storage-exceeding');
				return;
			} finally {
				// uploadItem.statusProcessing = false;
				this.$store.dispatch('Ui/dropVideoStatus', uploadItem);
				if (replaceValidateFailed) {
					this.$store.dispatch('Ui/dropStreamToUpload');
					this.$store.dispatch('Ui/dropFilesToUpload');
				}
			}

			const uploadUrl = mediaUploadSpec.uploadUrl;
			this.$store.dispatch('Ui/addFileData', { uploadItem, uploading: true });
			// uploadItem.uploading = true;
			// const onBytes = uploaded => uploadItem.uploadProgress = uploaded;
			const onBytes = uploaded => this.$store.dispatch('Ui/addFileData', { uploadItem, uploaded });

			const cancelSource = axios.CancelToken.source();
			// uploadItem.cancelSource = cancelSource;
			this.$store.dispatch('Ui/addFileData', { uploadItem, cancelSource });

			let success = false;
			try {
				if (mediaUploadSpec.uploadType === 'direct') {
					await StreamService.uploadVodMediaToBucket(
						uploadUrl,
						uploadItem.file,
						onBytes,
						cancelSource.token
					);
					success = true;
				} else if (mediaUploadSpec.uploadType === 'multipart') {
					success = await this.startMultipartBucketUpload(mediaUploadSpec.uploadId,
						uploadItem, mediaUploadSpec.uploadCredentials, onBytes)
				}

			} catch (e) {
				console.log(e);
				this.$store.dispatch('Ui/addFileData', { uploadItem, uploadProgress: 0, uploadError: true, cancelSource: null, uploading: false })
				// uploadItem.uploadProgress = 0;
				// uploadItem.uploadError = true;
				// uploadItem.cancelSource = null;
				// uploadItem.uploading = false;
				return;
			}

			let uploadResult;
			if (success) {
				try {
					if (this.$route.name === 'StreamsManageAdvertising' || this.$route.name === 'StreamsManageVodAdvertising') {
						this.$store.dispatch('Ui/addFileData', { uploadItem, advMedia: true })
						// uploadItem.mediaInfo.advMedia = true;
					}
					if (isReplacingExistingVod) {
						uploadResult = await StreamService.onCompleteVodReplacement(
							uploadItem.currFile.id,
							mediaUploadSpec.uploadId,
							this.streamToUpload._id,
							this.streamToUpload.key,
							uploadItem
						);
						// this will clear captions, chapeters, schedule, and posters.
						await StreamService.clearVodMetadata(this.streamToUpload._id, uploadItem.currFile.id, uploadItem.currFile._id);
					} else {
						uploadResult = await StreamService.updateVodMediaUploadState(
							mediaUploadSpec.uploadId,
							this.streamToUpload._id,
							this.streamToUpload.key,
							uploadItem
						);
					}
				} catch (e) {
					this.$store.dispatch('Ui/addFileData', { uploadItem, uploadProgress: 0, uploadError: true })
					// uploadItem.uploadProgress = 0;
					// uploadItem.uploadError = true;
				}
			}
			this.$store.dispatch('Ui/addFileData', { uploadItem, uploading: false })
			// uploadItem.uploading = false;
			if (isReplacingExistingVod) {
				this.onVideoReplaced(this.streamToUpload._id, uploadItem.currFile.id);
			} else if (uploadResult) {
				this.onVideoUploaded(uploadItem, uploadResult);
			}
		},
		async requestSignedUploadUrl(uploadItem) {
			const fileObj = uploadItem.file;
			const uploadPayload = {
				encodingPresets: uploadItem.encodingPresets,
				metadata: {
					size: fileObj.size,
					type: fileObj.type,
					originalFilename: fileObj.name,
					precedence: uploadItem.precedence
				},
				mediaInfo: uploadItem.mediaInfo,
				streamType: this.streamToUpload.type,
				multipartSupport: fileObj.size / (1000 ** 3) > 1 // GB
			};

			const uploadUrl = await StreamService.requestVodMediaUploadUrl(this.streamToUpload.type === 'scheduled' ? this.streamToUpload._id : this.streamToUpload.key, uploadPayload);
			return uploadUrl;
		},
		async requestSignedUploadUrlForReplacingVideo(uploadItem) {
			const fileObj = uploadItem.file;
			const uploadPayload = {
				encodingPresets: uploadItem.encodingPresets,
				metadata: {
					size: fileObj.size,
					type: fileObj.type,
					originalFilename: fileObj.name,
					precedence: uploadItem.precedence
				},
				mediaInfo: uploadItem.mediaInfo,
				streamType: this.streamToUpload.type,
				multipartSupport: fileObj.size / (1000 ** 3) > 1 // GB
			};
			const currFile = uploadItem.currFile;
			const uploadUrl = await StreamService.requestVodMediaReplaceUploadUrl(this.streamToUpload.key, currFile.id, uploadPayload);
			return uploadUrl;
		},
		async validateReplaceStorage(uploadItem) {
			const assignedSpace = parseFloat(this.$store.state.User.maxVodStorage);
			const existingFileStorage = uploadItem.currFile.bytes;
			const newFileStorage = uploadItem.bytes;
			let freeSpaceAvailable = 0;

			let totalVodBytes = 0;
			let usedDvrStorage = { value: 0 };
			if (assignedSpace) {
				const resp = await MetricsService.getUserDvrStorage();
				usedDvrStorage = resp.bytes || 0;
				const streams = this.vodStreams;

				streams.forEach((item) => {
					if (item.type === 'vod' && !item.liveParent) {
						totalVodBytes += item.totalBytes
					}
				})

				if (totalVodBytes > 0) {
					totalVodBytes = totalVodBytes / (1000 ** 3)
				}

				const dvrHumanSize = usedDvrStorage / 1000 ** 3;
				freeSpaceAvailable = (assignedSpace - (totalVodBytes + dvrHumanSize)) * (1000 ** 3);
			}
			if (freeSpaceAvailable + existingFileStorage - newFileStorage < 0) {
				this.$root.$emit('bv::show::modal', 'max-size-vod-storage-reached');
				return false;
			}
			return true;
		},
		async onVideoReplaced(streamId, videoId) {
			await this.$store.dispatch('Streams/getStreams');
			await this.$store.dispatch('Streams/getFilesInVODFolder')
			this.$notify({
				group: 'success',
				text: 'media replaced successfully'
			});
			this.showOptions = false;
			this.$store.dispatch('Ui/dropStreamToUpload')
			this.$store.dispatch('Ui/dropFilesToUpload')
			setTimeout(() => window.location.reload(), 300);
		},
		async onVideoUploaded(uploadItem, uploadResult) {
			if (!uploadResult || !uploadResult.fileId) return;
			this.$store.dispatch('Ui/addFileData', { uploadItem, uploadResult })
			const allUploaded = this.uploadFiles.every(item => item.uploadProgress === 100)
			await this.$store.dispatch('Streams/getStreams');
			await this.$store.dispatch('Streams/getFilesInVODFolder')
			this.$notify({
				group: 'success',
				text: 'media uploaded successfully'
			});
			this.$emit('video-added', uploadItem);
			setTimeout(() => {
				this.$notify({ group: 'info', text: 'Video added to media list' });
			}, 2000);
			setTimeout(async () => {
				// if(this.$store?.state?.User?.activeTeam) {
				await StreamService.updateStreamPlaylistVideoMetaData(this.$route.params.streamId, uploadItem.id, {
					author: this.$store?.state?.User?.activeTeam ? this.$store?.state?.User?.teamMemberUser?.email : this.$store?.state?.User?.email,
				});
				// }
			})
			this.showOptions = false;
			if (allUploaded) {
				this.$store.dispatch('Ui/dropStreamToUpload')
				this.$store.dispatch('Ui/dropFilesToUpload')
				if (this.$route.name === 'ScheduledStreamManageDashboard' || this.$route.name === 'StreamsManageAdvertising' || this.$route.name === 'StreamsManageVodAdvertising') {
					window.location.reload();
				}
			}
		},
		startMultipartBucketUpload(uploadId, uploadItem, uploadCredentials, onBytesUploaded) {
			return new Promise((resolve) => {
				uploadItem.uploading = true

				let AWS = window.AWS
				AWS.config.update({
					region: uploadCredentials.region,
					useAccelerateEndpoint: uploadCredentials.useAccelerateEndpoint
				})

				// Initialize the Amazon Cognito credentials provider
				let identityConf = {
					IdentityPoolId: uploadCredentials.poolId,
					LoginId: UserService.getUser(this.activeTeam).email
				}

				AWS.config.credentials = new AWS.CognitoIdentityCredentials(identityConf)

				// Upload the File
				const uploadBucket = new AWS.S3({ params: { Bucket: uploadCredentials.bucketName } })
				const uploadOpts = {
					Key: uploadCredentials.keyPrefix + uploadId,
					ContentType: uploadItem.file.type,
					Body: uploadItem.file,
					queueSize: uploadCredentials.concurrency || 2, // default 2 parts in parallel
					partSize: uploadCredentials.chunkSize || (2 * 1000 * 1000), // default 2MB
					UseAccelerateEndpoint: true
				}

				const onBytes = (ev) => {
					let percent = (ev.loaded * 100) / ev.total
					onBytesUploaded(percent)
				}

				let multipartUploadHandle = uploadBucket.upload(uploadOpts)
				multipartUploadHandle.on('httpUploadProgress', onBytes)

				uploadItem.cancelSource = {
					cancel: () => void multipartUploadHandle.abort()
				}

				multipartUploadHandle.send((err, data) => {
					if (err) {
						uploadItem.uploadProgress = 0
						uploadItem.uploadError = true
						uploadItem.cancelSource = null
						uploadItem.uploading = false
					}

					resolve(!err)
				})
			})
		},
		cancelVideoUpload(uploadItem) {
			if (!uploadItem) return;

			if (uploadItem.cancelSource) {
				uploadItem.cancelSource.cancel();
			}
			this.$store.dispatch('Ui/cancelVideoUpload', uploadItem);
			// media.uploading = false;
		},
		removeVideoFile(uploadItem) {
			this.$store.dispatch('Ui/removeFileFromUpload', uploadItem)
		},
		removeAll() {
			this.$store.dispatch('Ui/dropFilesToUpload');
		},
		continueUpload() {
			this.canUpload = true
			console.log('continue');
		},
		getErrorMessage(mediaInfo) {
			let message = ''

			if (mediaInfo.isVbr) {
				message = 'File should be CBR-encoded'
			}

			if (!mediaInfo.hasAudioTrack) {
				if (mediaInfo.isVbr) message += ' and have sound'
				else message = 'File should have sound'
			}

			message += '.'

			return message
		},
		removeFileFromUpload() {
			this.$store.dispatch('Ui/removeFileFromUpload', this.tempoFile);
			this.tempoFile = null
		}
	}
}
</script>

<style scoped>
.upload-widget {
	position: fixed;
	bottom: 0;
	right: 2rem;
	width: 24rem;
	border-radius: 8px 8px 0 0;
	box-shadow: 0px 32px 40px 0px #0000001F;
	box-shadow: 0px 8px 12px 0px #00000014;
	z-index: 99;
}

:deep(.upload-widget .btn-sidebar-collapse) {
	border-radius: 8px 8px 0 0;
}

.progressbar-wrapper {
	height: 3px;
	border-radius: 3px;
	background-color: var(--c-neutral-n3);
}

.progress-label {
	position: absolute;
	top: 0;
	right: 1.75rem;
	color: var(--c-dark-6);
}

@media (max-width: 767px) {
	.upload-widget {
		right: auto;
		width: calc(100% - 30px);
		margin: 0 15px;
	}
}

@media (min-width: 992px) {
	.upload-widget {
		right: 6rem;
	}
}
</style>