<script setup>
import { computed, ref, onBeforeMount, onMounted, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import _ from 'lodash';
import StreamService from '@/services/StreamService';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import PaywallService from '@/services/PaywallService'
import FormButton from '../../components/Atoms/FormButton.vue';
import FormUpload from '../../components/Atoms/FormUpload.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import Alert from '../../components/Atoms/Alert.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	streamAlive: {
		type: Boolean
	},
})

const emit = defineEmits({
	'set-live-stream-countdown': (value) => { return value },
	'live-stream-abr-changed': (value) => { return value }
})

const themeColorDropdown = ref(null)
const watermarkPositionDropdown = ref(null)
const streamMeta = ref(null)
const embedThemeColorSelection = ref(null)
const maxPosterUploadMB = ref(2)
const features = ref({
	embedPoster: {
		error: false,
		enabled: false,
		value: null,
		valueType: 'string'
	},
	embedWatermark: {
		error: false,
		enabled: false,
		value: null,
		valueType: 'string'
	},
	embedWatermarkPosition: {
		error: false,
		enabled: true,
		value: 'tr',
		title: 'Top Right',
		valueType: 'string'
	},
	embedLiveViewers: {
		enabled: false,
		valueType: 'bool'
	},
})

const featureProcessing = ref({
	embedPoster: false,
	embedWatermark: false,
	embedWatermarkPosition: false,
	embedLiveViewers: false,
	theme: false,
})
const embedPosterTemp = ref(null)
const embedWatermarkTemp = ref(null)

const prevStreamCountdowndDateTime = ref(null)
const streamCountdowndDateTime = ref(null)
const minStreamCountdowndDateTime = ref(null)

const streamMetadataLoaded = ref(false)
const streamAbrFps = ref(30)

const streamCountdownSaveBtn = computed(() => {
	return streamCountdowndDateTime.value && prevStreamCountdowndDateTime.value !== streamCountdowndDateTime.value
})

const posterUrl = computed(() => {
	if (embedPosterTemp.value) {
		return embedPosterTemp.value
	}
	const imageId = features.value.embedPoster.value;
	// return `https://static.castr.io/embedPosters/${imageId}`;
	return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
})
const watermarkUrl = computed(() => {
	if (embedWatermarkTemp.value) return embedWatermarkTemp.value;
	const imageId = features.value.embedWatermark.value;
	return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
})

onBeforeMount(async () => {
	streamMeta.value = await StreamService.getStreamMetadata(props.stream._id);

	_.forIn(streamMeta.value, (value, key) => {
		if (value !== undefined && value !== null && key in features.value) {
			let enabled = value !== false;
			if (Array.isArray(enabled)) {
				enabled = enabled.length;
			}
			features.value[key].enabled = enabled;
			if (value.constructor !== Boolean) {
				features.value[key].value = value;
			}
		}
	})

	if (streamMeta.value.countdownDate) {
		streamCountdowndDateTime.value = new Date(streamMeta.value.countdownDate).toISOString()
		prevStreamCountdowndDateTime.value = streamCountdowndDateTime.value
	}

	embedThemeColorSelection.value = streamMeta.value.options.embedThemeColors.find(({ color }) => {
		return color === streamMeta.value.embedThemeColor
	});

	if (streamMeta.value.abrFps) {
		streamAbrFps.value = streamMeta.value.abrFps
	}
	streamMetadataLoaded.value = true
})

onMounted(async () => {
	if (checkSettingVisibility('embedWatermark')) {
		onWatermarkPositionChange()
	}
	// event tracking
	window.trackEvent(
		props.stream.name + ' - Stream Settings Page',
		props.stream
	)
})

const setMinminScheduleDateTime = () => {
	minStreamCountdowndDateTime.value = new Date(
		Date.now() + 180 * 1000
	).toISOString();
}

const saveStreamCountdown = async () => {
	if (!streamCountdowndDateTime.value) {
		return;
	}
	featureProcessing.value.streamCountdown = true;
	const utcDate = new Date(streamCountdowndDateTime.value).toUTCString();
	await saveSetting('countdownDate', utcDate)
	featureProcessing.value.streamCountdown = false;
	prevStreamCountdowndDateTime.value = streamCountdowndDateTime.value;
	emit('set-live-stream-countdown', streamCountdowndDateTime.value);
}

const clearStreamCountdown = async () => {
	await saveSetting('countdownDate', null)
	streamCountdowndDateTime.value = null;
	emit('set-live-stream-countdown', null);
}

const checkSettingVisibility = (settingPropname) => {
	switch (settingPropname) {
		case 'embedPoster':
		case 'embedWatermark':
		case 'embedWatermarkPosition':
			return ['live', 'ipcam'].indexOf(props.stream.type) > -1;
	}
	return false;
}

const onWatermarkPositionChange = () => {
	const pos = features.value.embedWatermarkPosition.value;
	for (const el of document.querySelectorAll('.poster-thumb-wrapper.watermark')) {
		el.setAttribute('class', `poster-thumb-wrapper watermark ${pos}`);
	}
}

const selectEmbedThemeColor = (event, color) => {
	event.preventDefault();
	embedThemeColorSelection.value = color
	themeColorDropdown.value.toggleDropdown()
}
const setWatermarkPosition = (value, title) => {
	features.value.embedWatermarkPosition.value = value
	features.value.embedWatermarkPosition.title = title
	watermarkPositionDropdown.value.toggleDropdown()
}
const saveEmbedThemeColor = async () => {
	if (!streamMeta.value) return;

	const colorNode = embedThemeColorSelection.value;
	try {
		featureProcessing.value.theme = true
		const color = colorNode.color;
		await saveSetting('embedThemeColor', color);
		streamMeta.value.embedThemeColor = color;
	} catch (e) {
		notify({
			group: 'error',
			text: 'could not update player color'
		});
	} finally {
		featureProcessing.value.theme = false
	}
}

const canSaveEmbedThemeColor = () => {
	if (!embedThemeColorSelection.value) return;
	return (
		embedThemeColorSelection.value.color !==
		streamMeta.value.embedThemeColor
	);
}

const navigateToBilling = () => {
	if ((props.stream.type === 'live') || props.stream.type === 'vod') {
		window.location.href = '/app/subscribe?category=live';
	} else {
		window.location.href = '/app/subscribe';
	}
}

const toggleFeature = async (featureName, newFeature = false) => {
	if (featureProcessing.value[featureName]) return;
	const feature = features.value[featureName];
	if (!feature) return;
	let nstate = feature.enabled;
	feature.enabled = nstate;
	if (feature.valueType === 'bool' || !nstate) {
		if (feature.valueType === 'array' && !nstate) {
			nstate = [];
		}
		await saveSetting(featureName, nstate);
	}
}

const canSaveWatermarkPosition = () => {
	return (
		features.value.embedWatermark.value &&
		features.value.embedWatermarkPosition.value !==
		streamMeta.value.embedWatermarkPosition
	);
}

const saveWatermarkPosition = async () => {
	const newpos = features.value.embedWatermarkPosition.value;
	await saveSetting('embedWatermarkPosition', newpos);
	streamMeta.value.embedWatermarkPosition = newpos;
}

const hitUpload = (type) => {
	const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	document.querySelector(selector).click();
}

const onEmbedPosterPreview = (file, name) => {
	console.log(file);
	console.log(name);
	if (!file) return;
	const selector = name === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	const imageInput = document.querySelector(selector);
	if (file.type.indexOf('image/') === -1) {
		root.$emit('bv::show::modal', 'embed-upload-format');
		imageInput.value = null;
		return;
	}
	let maxSize = name === 'watermark' ? 1 : 2;
	maxPosterUploadMB.value = maxSize;
	maxSize *= 1024 * 1024;
	if (file.size > maxSize) {
		root.$emit('bv::show::modal', 'embed-image-size-exceed');
		imageInput.value = null;
		return;
	}
	const validImageTypes = name === 'watermark' ? ['png', 'jpeg', 'gif', 'avif'] : null;
	const uploadedImageFileType = file.type.replace('image/', '');
	// if (validImageType && imageFile.type.indexOf(validImageType) === -1) {
	if (
		validImageTypes &&
		validImageTypes.indexOf(uploadedImageFileType) === -1
	) {
		root.$emit('bv::show::modal', 'embed-image-mime-type');
		imageInput.value = null;
		return;
	}
	imageReader(file, base64 => {
		if (name === 'watermark') {
			embedWatermarkTemp.value = base64;
		} else {
			embedPosterTemp.value = base64;
		}
	});
}

const saveEmbedPoster = async (type) => {
	const featureProp = type === 'watermark' ? 'embedWatermark' : 'embedPoster';
	features.value[featureProp].error = false;
	const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	const imageInput = document.querySelector(selector);
	const imageFile = imageInput.files[0];
	if (!imageInput.value || !imageFile) {
		features.value[featureProp].error = 'please pick an image file';
		return;
	}
	// this.featureProcessing.embedPoster = true;
	featureProcessing.value[featureProp] = true;
	// -- upload reuqest --
	const fdata = new FormData();
	fdata.append('file', imageFile);
	const res = await StreamService.uploadStreamPoster(
		props.stream._id,
		fdata,
		type === 'watermark'
	);
	if (res.success) {
		if (type === 'watermark') {
			await saveWatermarkPosition();
			embedWatermarkTemp.value = null;
		} else {
			const poster = `https://assets.castr.io/embedImages/${res.uploadId}`;
			PaywallService.updateStreamPaywall(props.stream._id, { wallpaper_url: poster }).catch(err => {
				console.error('update paywall stream poster failed: ', err)
			});
			embedPosterTemp.value = null;
		}
		features.value[featureProp].value = res.uploadId;
		notify({
			group: 'success',
			text: `${type === 'watermark' ? 'Watermark' : 'Poster'} uploaded with success`
		});
	} else {
		features.value[featureProp].error = 'could not handle image upload. Please try again later';
	}

	featureProcessing.value[featureProp] = false;
}

const cancelUpload = async (type) => {
	const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	const previewFlag = type === 'watermark' ? embedWatermarkTemp.value : embedPosterTemp.value;
	if (previewFlag) {
		if (type === 'watermark') {
			embedWatermarkTemp.value = null;
		} else {
			embedPosterTemp.value = null;
			features.value.embedPoster.error = false;
		}
		document.querySelector(selector).value = null;
		return;
	}
	removePoster(type);
}

const removePoster = async (type) => {
	try {
		const settingsProp =
			type === 'watermark' ? 'embedWatermark' : 'embedPoster';
		await saveSetting(settingsProp, null);
		features.value[settingsProp].value = null;
	} catch (e) {
		console.log(e);
	}
}

const saveSetting = async (key, value, successToast = false) => {
	featureProcessing.value[key] = true;
	try {
		await StreamService.saveStreamMetadata(props.stream._id, key, value);
		// clear stream meta cache in embed player
		StreamService.clearStreamMetadataCache(props.stream._id);
		if (successToast) {
			notify({ group: 'success', text: 'Setting saved' })
		}
	} catch (e) {
		notify({ group: 'error', text: 'could not save changes' });
	}
	featureProcessing.value[key] = false;
}

function imageReader(file, cb) {
	if (!file) {
		return;
	}
	var reader = new FileReader();
	reader.onload = function () {
		if (cb) {
			cb(reader.result);
		}
	};
	reader.readAsDataURL(file);
}
</script>

<template>
	<div class="divide-y divide-surface-2">
		<section class="flex justify-between !py-3">
			<h4 class="text-base">Player Theme Color</h4>
			<div class="flex justify-end items-center gap-x-2">
				<DropDownMenu v-if="embedThemeColorSelection" type="secondary" ref="themeColorDropdown">
					<template #toggle-button>
						<div :style="{ 'background-color': embedThemeColorSelection.color }"
							class="size-3 border border-white rounded-full !mr-2" />
						<span class="capitalize">{{ embedThemeColorSelection.label }}</span>
						<icon-base class="!ml-1">
							<icon-chevron-up-down />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem class="uppercase" v-for="(color, index) in streamMeta.options.embedThemeColors"
							:key="index" @click="selectEmbedThemeColor($event, color)">
							<div :style="{ 'background-color': color.color }" class="size-3 border border-white rounded-full !mr-2" />
							<span class="text-capitalize">{{ color.label }}</span>
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<FormButton v-show="canSaveEmbedThemeColor()" :disabled="featureProcessing.theme" @click="saveEmbedThemeColor">
					{{featureProcessing.theme ? 'Saving': 'Save'}}
				</FormButton>
			</div>
		</section>
		<section v-if="stream.type === 'live'" class="flex justify-between items-center !py-3">
			<h4 class="text-base">Display Event Countdown</h4>
			<div class="flex justify-end gap-x-2">
				<VueDatePicker placeholder="Select date time" v-model="streamCountdowndDateTime" utc
					:min-date="minStreamCountdowndDateTime" @focus="setMinminScheduleDateTime"
					inputClassName="border-none bg-surface-3 h-8 font-normal text-tiny font-[Inter] rounded-md"></VueDatePicker>
				<FormButton v-if="streamCountdownSaveBtn" :disabled="featureProcessing.streamCountdown" class=""
					@click="saveStreamCountdown">
					<span>{{ featureProcessing.streamCountdown ? 'Saving' : 'Save' }}</span>
				</FormButton>
				<FormButton v-if="streamCountdowndDateTime" :disabled="featureProcessing.embedPoster" type="secondary"
					@click="clearStreamCountdown">
					Clear
				</FormButton>
			</div>
		</section>
		<section v-if="stream.type === 'live'" class="flex justify-between items-center !py-3">
			<div>
				<h4 class="text-base">Display Number of Viewers</h4>
				<p class="text-tiny text-surface-7">The number of viewers appear in your livestream player</p>
			</div>
			<FormSwitch @change="toggleFeature('embedLiveViewers')" v-model="features.embedLiveViewers.enabled">
			</FormSwitch>
		</section>
		<section v-if="checkSettingVisibility('embedPoster')" class="flex justify-between items-center !py-3">
			<div>
				<h4 class="text-base">Custom Embed Poster</h4>
				<img v-if="posterUrl" width="100px" :src="posterUrl" class="w-[100px]" />
				<p v-else class="text-tiny text-surface-6">No file choosen</p>
			</div>
			<div class="flex justify-end items-center gap-x-2">
				<FormUpload name="poster" id="embed-poster-input" placeholder="No file chosen" type="secondary"
					:label="embedPosterTemp ? 'Change' : 'Select Poster'" :on-file-change="onEmbedPosterPreview" />
				<FormButton v-if="embedPosterTemp" :disabled="featureProcessing.embedPoster" @click="saveEmbedPoster">
					{{ featureProcessing.embedPoster ? 'Saving' : 'Save Poster' }}
				</FormButton>
				<FormButton v-if="embedPosterTemp || features.embedPoster.value" :disabled="featureProcessing.embedPoster"
					type="secondary" @click="cancelUpload">
					<span v-if="embedPosterTemp && features.embedPoster.value">Restore</span>
					<span v-else-if="embedPosterTemp">Cancel</span>
					<span v-else>Remove</span>
				</FormButton>
			</div>
			<Alert v-show="features.embedPoster.error" :text="features.embedPoster.error" />
		</section>
		<section v-if="checkSettingVisibility('embedWatermark')" class="flex justify-between items-center !py-3">
			<div>
				<h4 class="text-base">Embed Watermark</h4>
				<img v-if="watermarkUrl" width="100px" :src="watermarkUrl" class="w-[100px]" />
				<p v-else class="text-tiny text-surface-6">No file choosen</p>
			</div>
			<div class="flex justify-end items-center gap-x-2">
				<span class="text-tiny text-surface-8">Position</span>
				<DropDownMenu type="secondary" ref="watermarkPositionDropdown">
					<template #toggle-button>
						{{ features.embedWatermarkPosition.title }}
						<icon-base class="!ml-1">
							<icon-chevron-up-down />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('tr', 'Top Right')">
							Top Right
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('tl', 'Top Left')">
							Top Left
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('cc', 'Center')">
							Center
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('bl', 'Bottom Left')">
							Bottom Left
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('br', 'Bottom Right')">
							Bottom Right
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<FormUpload name="watermark" id="embed-watermark-input" placeholder="No file chosen" type="secondary"
					:label="embedPosterTemp ? 'Change' : 'Select Watermark'" :on-file-change="onEmbedPosterPreview" />
				<FormButton v-show="canSaveWatermarkPosition()" :disabled="featureProcessing.embedWatermarkPosition"
					@click="saveWatermarkPosition">
					{{ featureProcessing.embedWatermarkPosition ? 'Saving' : 'Save' }}
				</FormButton>
				<FormButton v-if="embedWatermarkTemp" :disabled="featureProcessing.embedWatermark"
					@click="saveEmbedPoster('watermark')">
					{{ featureProcessing.embedWatermark ? 'Saving' : 'Save' }}
				</FormButton>
				<FormButton v-if="embedWatermarkTemp || features.embedWatermark.value"
					:disabled="featureProcessing.embedWatermark" type="secondary" @click="cancelUpload('watermark')">
					<span v-if="embedWatermarkTemp && features.embedWatermark.value">Restore</span>
					<span v-else-if="embedWatermarkTemp">Cancel</span>
					<span v-else>Remove</span>
				</FormButton>
			</div>
			<Alert v-show="features.embedWatermark.error" class="alert alert-danger">
				{{ features.embedWatermark.error }}
			</Alert>
		</section>
	</div>
	<alert-modal modal-id="embed-upload-format" message="Only valid image files are allowed. Please pick an image file" />

	<alert-modal :message="'Uploaded image too large. Image must not exceed ' + maxPosterUploadMB + 'MB'"
		modal-id="embed-image-size-exceed" />

	<alert-modal modal-id="embed-image-mime-type" message="Only PNG/JPG/GIF/AVIF image files are allowed" />
	<confirm-modal modal-id="feature-upgrade" message="Please upgrade your subscription plan to access this feature"
		ok-text="Upgrade Now" cancel-text="Later" @modal-confirm="navigateToBilling()" />
</template>