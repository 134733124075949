<template>
	<div class="!mt-1 md:!mt-6 media-info-wrapper">
		<span v-if="mediaPulse?.alive" class="media-info__line media-info__line_left"></span>
		<span v-if="mediaPulse?.alive" class="media-info__line media-info__line_right"></span>
		<span v-if="mediaPulse?.alive" class="media-info__line media-info__line_top"></span>
		<span v-if="mediaPulse?.alive" class="media-info__line media-info__line_bottom"></span>
		<h2 class="text-xs text-surface-8 text-center font-normal leading-none">Ultra Low Latency Stream</h2>
		<div class="media-info flex flex-wrap">
			<!-- analytics data -->
			<div class="w-1/3 md:w-1/5">
				<!-- viewers stats -->
				<div class="media-info__item flex items-start">
					<h6 class="media-info__title mb-auto order-last md:order-first flex items-center justify-center">
						<icon-base class="text-blue mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
							height="16">
							<icon-eye />
						</icon-base>
						<span class="media-info__text media-info__text_blue">Viewers</span>
					</h6>
					<div class="info mt-auto order-first md:order-last">
						<div class="media-info__value">
							<div v-if="streamAlive">
								<div v-if="clientsCount !== -1">
									<span class="">
										{{ clientsCount | number }}
									</span>
								</div>
								<div v-else>
									<span class="">
										<i class="fas fa-spinner fa-spin loader" />
									</span>
								</div>
							</div>
							<div v-else>
								..
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- stream bitrate -->
			<div class="w-1/3 md:w-1/5">
				<Tooltip :active="stream.type === 'live' || stream.type === 'restream'" content="Open Input Health Charts">
					<div class="media-info__item flex items-start bitrate-button"
						:class="{ 'input-health-navigation': (stream.type === 'live' || stream.type === 'restream') }"
						@click="onClickBitrate">
						<h6 class="media-info__title mb-auto order-last md:order-first flex items-center justify-center">
							<icon-base class="text-pink mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
								height="16">
								<icon-bitrate />
							</icon-base>
							<span class="media-info__text media-info__text_pink">Bitrate</span>
						</h6>
						<div class="info mt-auto order-first md:order-last">
							<div class="media-info__value ml-1 mt-1">
								<div v-if="streamAlive">
									<span v-if="mediaPulse.bitrate">
										<span class="">
											{{ mediaPulse.bitrate }}
											<span>kbps</span>
											<span class="ml-2" v-if="stream.type === 'live' || stream.type === 'restream'">
												<icon-base class="text-surface-8" width-view-box="16" height-view-box="16" iconColor="none"
													width="16" height="16">
													<icon-diagram />
												</icon-base>
											</span>
										</span>
									</span>
									<span v-else class="fas fa-spinner fa-spin loader" />
								</div>
								<div v-else>
									..
								</div>
							</div>
						</div>
					</div>
				</Tooltip>
			</div>
			<!-- media resolution & quality -->
			<div class="w-1/3 md:w-1/5">
				<div class="media-info__item flex items-start">
					<h6 class="media-info__title mb-auto order-last md:order-first flex items-center justify-center">
						<icon-base class="text-gold mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
							height="16">
							<icon-resolution />
						</icon-base>
						<span class="media-info__text media-info__text_gold">Resolution</span>
					</h6>
					<div class="info mt-auto order-first md:order-last">
						<div class="media-info__value">
							<div v-if="streamAlive && streamResolution">
								<div>
									<span class="">
										{{ streamResolution }}
									</span>
									<span class="text-uppercase">{{ streamQuality }}</span>
								</div>
							</div>
							<div v-else class="">
								..
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- media FPS, codecs -->
			<div class="w-1/2 md:w-1/5" :class="{ 'stream-live': streamAlive }">
				<div :class="{ empty: !streamAlive }" class="media-info__item item flex items-start">
					<h6 class="media-info__title mb-auto order-last md:order-first flex items-center justify-center">
						<icon-base class="text-purple mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
							height="16">
							<icon-codec />
						</icon-base>
						<span class="media-info__text media-info__text_purple">Codecs</span>
					</h6>
					<div class="info mt-auto order-first md:order-last">
						<div class="media-info__value">
							<div v-if="streamAlive" class="flex md:block items-center justify-center flex-wrap text-nowrap">
								<span v-if="mediaPulse.fps" class="fps mr-1 order-1">
									<span class="">
										{{ mediaPulse.fps | normalizedFPS }}
										<span class="text-uppercase">fps</span>
									</span>
								</span>
								<span v-if="streamAlive" class="order-0">
									<Badge v-for="(track, index) in mediaPulse.codecs" :key="index"
										class="mr-1 badge_xs text-uppercase font-medium" :variant="track.type">
										{{ track.codec }}
									</Badge>
								</span>
								<span v-else>
									..
								</span>
							</div>
							<div v-else>
								..
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- stream bitrate -->
			<div class="w-1/2 md:w-1/5">
				<div class="media-info__item flex items-start bitrate-button">
					<h6 class="media-info__title mb-auto order-last md:order-first text-nowrap flex items-center justify-center">
						<icon-base class="text-pink mr-1" iconColor="none">
							<icon-speaker />
						</icon-base>
						<span class="media-info__text media-info__text_pink text-nowrap">Audio Bitrate</span>
					</h6>
					<div class="info mt-auto order-first md:order-last">
						<div class="media-info__value ml-1 mt-1">
							<div v-if="streamAlive">
								<span v-if="mediaPulse.audioBitrate">
									<!-- <span>{{mediaPulse.bitrate | number}}</span> -->
									<span class="inline-flex gap-x-1 items-center">
										{{ mediaPulse.audioBitrate }}
										<span>kbps</span>
										<icon-base iconColor="none">
											<icon-diagram-up />
										</icon-base>
									</span>
								</span>
								<Spinner v-else />
							</div>
							<div v-else>
								..
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService';
import { bytes } from '@/filtersNew';
import IconBase from '../icon/IconBase.vue';
import IconEye from '../icon/IconEye.vue';
import IconBitrate from '../icon/IconBitrate.vue';
import IconResolution from '../icon/IconResolution.vue';
import IconCodec from '../icon/IconCodec.vue';
import IconSpeaker from '../icon/IconSpeaker.vue';
import IconDiagramUp from '../icon/IconDiagramUp.vue';
import Badge from '../Badge.vue';
import Spinner from '../ui/Spinner.vue';

export default {
	name: 'SubSecondStats',
	components: {
		IconBase,
		IconEye,
		IconBitrate,
		IconResolution,
		IconCodec,
		IconSpeaker,
		IconDiagramUp,
		Spinner
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		videoFiles: {
			type: Array,
			default() { return []; }
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		}
	},
	data() {
		return {
			scopeAlive: true,
			processing: true,
			processingMessage: null,
			windowHeight: 0,
			clientsCount: -1,
			totalEncodingStorageTime: 0,
			bandwidthStats: {
				value: 0,
				unit: 'bytes',
				loaded: false
			},
			encodingStats: {
				value: 0,
				loaded: false
			},
			storageStats: {
				value: 0,
				unit: 'bytes',
				loaded: false
			},
			columns: [
				{ id: 'stream_status', streamTypes: ['restream', 'scheduled'] },
				{ id: 'analytics', streamTypes: ['live', 'ipcam', 'vod'] },
				{
					id: 'stream_bitrate',
					streamTypes: ['restream', 'live', 'ipcam', 'scheduled']
				},
				{ id: 'media_quality', streamTypes: ['restream', 'live', 'ipcam'] },
				{ id: 'media_codecs', streamTypes: ['restream', 'live', 'ipcam'] },
				{ id: 'videos_count', streamTypes: ['scheduled', 'vod'] },
				{ id: 'bytes_storage', streamTypes: ['scheduled', 'vod'] }
			]
		};
	},
	computed: {
		subscription() {
			let subs = this.$store.state.User.subscriptions.find(sub => sub.category && sub.category.includes('live'));
			return subs;
		},
		streamAlive() {
			return this.stream.enabled && this.mediaPulse && this.mediaPulse.alive;
		},
		streamResolution() {
			return (this.streamAlive && this.mediaPulse.width) ? `${this.mediaPulse.width} x ${this.mediaPulse.height}` : null;
		},
		streamQuality() {
			const sizes = [480, 720, 1080, 1440, 2160];
			const sizesFmt = ['sd', 'hd', 'fhd', 'qhd', 'uhd'];

			const height = this.mediaPulse.height || 0;

			let quality = 'sd';
			for (let i = 0; i < sizes.length; i++) {
				const nsize = sizes[i + 1];
				if (height >= sizes[i]) {
					if (!nsize || height < nsize) {
						quality = sizesFmt[i];
						break;
					}
				}
			}

			return quality;
		}
	},
	mounted() {
		this.setupBandwidthCounter();
		this.setupViewershipCounter();
	},
	destroyed() {
		this.scopeAlive = false;
	},
	methods: {
		onClickBitrate() {
			if (this.stream.type === 'live' || this.stream.type === 'restream') this.$router.push({ name: 'StreamsManageAnalyticsInputHealthCharts' })
		},
		setupViewershipCounter() {
			(async function updateCounter() {
				let timeoutInterval = 5000;
				let shouldUpdateValue = this.stream.enabled;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						const data = await StreamService.getSubSecondStreamViewers(
							this.stream.key
						);
						this.clientsCount = data.viewers || 0;
					} catch (e) { }
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		setupBandwidthCounter() {
			(async function updateCounter() {
				let timeoutInterval = 10000;
				let shouldUpdateValue =
					this.stream.enabled || !this.bandwidthStats.loaded;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						let startTime = this.subscription.cstart;
						if (_.isString(startTime)) {
							startTime = new Date(startTime).getTime();
							if (this.subscription.enabled === false) {
								startTime = moment(startTime).utc().startOf('day').valueOf()
							}
						}
						const bandwidthRes = await MetricsService.getStreamBandwidth(
							this.stream._id,
							startTime
						);
						// }
						const downloadedBytes = _.get(bandwidthRes, 'bytes') || 0;
						const bytesInfo = bytes(
							downloadedBytes >= 100000000 ? downloadedBytes : 0, // only show bandwidth > 100MB
							true,
							2,
							true
						);
						this.bandwidthStats = _.assign(
							{},
							this.bandwidthStats,
							bytesInfo
						);
						this.bandwidthStats.loaded = true;
					} catch (e) {
						console.log(e);
					}
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		setupStorageCounter() {
			(async function updateCounter() {
				let timeoutInterval = 10000;
				let shouldUpdateValue = true;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						const productId = this.subscription.package._id;
						const storageRes = await MetricsService.getSubscriptionStorage(
							undefined,
							productId
						);

						const bytesConsumed = _.get(storageRes, 'bytes') || 0;
						const bytesInfo = this.$options.filters.bytes(
							bytesConsumed,
							true,
							2,
							true
						);

						this.storageStats = _.assign(
							{},
							this.storageStats,
							bytesInfo
						);
						this.storageStats.loaded = true;
					} catch (e) { }
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		setupEncodingCounter() {
			(async function updateCounter() {
				let timeoutInterval = 30000;
				// let shouldUpdateValue = !this.encodingStats.loaded
				let shouldUpdateValue = true;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						const productId = this.subscription.package._id;
						const encodingUsageInfo = await MetricsService.getSubscriptionEncodingUnits(null, productId);
						const minsEncoded = _.get(encodingUsageInfo, 'units') || 0;
						this.encodingStats = {
							value: minsEncoded > 0 ? Math.round(minsEncoded) : minsEncoded,
							loaded: true
						};
					} catch (e) {
						console.log('e', e);
					}
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		getStorageused() {
			let totalBytes = this.videoFiles.reduce((tf, item) => tf + item.bytes, 0);
			const k = 1000;
			const decimals = 2;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const i = Math.floor(Math.log(totalBytes) / Math.log(k));
			totalBytes = parseFloat((totalBytes / Math.pow(k, i)).toFixed(dm));
			return {
				totalBytes: totalBytes,
				unit: sizes[i]
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.media-info {
	display: flex;
	align-items: stretch;
}

.media-info__item {
	height: 100%;
	width: 100%;
	background-color: var(--c-dark-2);
	padding: 8px 8px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.media-info__item {
	text-align: center;
}

.media-info__item .info {
	width: 100%;
}

:deep(.row .col:first-child .media-info__item) {
	border-radius: 0 0 0 4px;
}

:deep(.row .col:last-child .media-info__item) {
	border-radius: 0 0 4px 0;
}

.media-info__title {
	font-size: var(--font-s-s);
	font-weight: 400;
	color: var(--c-dark-7);
	flex: 0 0 100%;
}

.media-info__value {
	font-size: var(--font-s-m);
}

.badge {
	color: #fff;
}

.badge-audio {
	background-color: var(--c-aac);
	position: relative;
	top: -2px;
}

.badge-video {
	background-color: var(--c-h264);
	position: relative;
	top: -2px;
}

@media (min-width: 1280px) {
	.media-info {
		flex-wrap: nowrap;
	}
}

.input-health-navigation:hover {
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.2) !important;
}

@media (max-width: 1280px) {

	.row_5>.col {
		flex: 0 0 33.3333333%;
		max-width: 33.333332%
	}

	.row_5>.col:nth-child(4),
	.row_5>.col:nth-child(5) {
		flex: 0 0 50%;
		max-width: 50%
	}

	:deep(.row_5 .col:nth-child(1) .media-info__item) {
		border-radius: 4px 0 0 0;
	}

	:deep(.row_5 .col:nth-child(2) .media-info__item) {
		border-radius: 0;
	}

	:deep(.row_5 .col:nth-child(3) .media-info__item) {
		border-radius: 0 4px 0 0;
	}

	:deep(.row_5 .col:nth-child(4) .media-info__item) {
		margin-top: 2px;
		border-radius: 0 0 0 4px;
	}

	:deep(.row_5 .col:nth-child(5) .media-info__item) {
		margin-top: 2px;
		border-radius: 0 0 4px 0;
	}
}

@media (min-width: 1280px) {
	.text-xl-nowrap {
		white-space: nowrap;
	}
}

@media (max-width: 1440px) {
	.media-info__value {
		font-size: var(--font-s-xs);
	}
}

.stream-live .badge_xs {
	font-size: 10px;
}

.media-info-wrapper {
	@apply px-0.5 pb-0.5 pt-3 rounded-lg relative overflow-hidden
}

.media-info-wrapper:after {
	content: '';
	display: block;
	position: absolute;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	border: 2px solid rgba(255, 255, 255, 0.1);
	border-radius: 6px;
	left: 2px;
	top: 2px;
}

.media-info__line {
	position: absolute;
	animation: 5s 3s linear infinite;
}

.media-info__line_left {
	animation-name: to-top;
	background: linear-gradient(to bottom, var(--c-main-1), transparent);
	left: 2px;
	bottom: -100%;
	width: 2px;
	height: 100%;
	z-index: 11;
}

.media-info__line_right {
	animation-name: to-bottom;
	background: linear-gradient(to bottom, transparent, var(--c-main-1));
	right: 2px;
	top: -100%;
	width: 2px;
	height: 100%;
	z-index: 11;
}

.media-info__line_top {
	animation-delay: 4.7s;
	animation-name: to-right;
	background: linear-gradient(to right, transparent, var(--c-main-1));
	width: 100%;
	height: 2px;
	top: 2px;
	left: -100%;
	z-index: 11;
}

.media-info__line_bottom {
	animation-delay: 4.7s;
	animation-name: to-left;
	background: linear-gradient(to right, var(--c-main-1), transparent);
	bottom: 2px;
	right: -100%;
	width: 100%;
	height: 2px;
	z-index: 11;
}

@keyframes to-top {
	to {
		bottom: 200%;
	}
}

@keyframes to-bottom {
	to {
		top: 200%;
	}
}

@keyframes to-left {
	to {
		right: 200%;
	}
}

@keyframes to-right {
	to {
		left: 200%;
	}
}
</style>
