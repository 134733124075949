<script setup>
import { computed, ref, watch } from 'vue'
import _ from 'lodash'
import StreamService from '@/services/StreamService'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import TabGroup from '../../Atoms/TabGroup.vue'
import TabItem from '../../Atoms/TabItem.vue'
import Badge from '../../Badge.vue'
import FormButton from '../../Atoms/FormButton.vue'
import FormInput from '../../Atoms/FormInput.vue'
import FormRadio from '../../Atoms/FormRadio.vue'
import Tooltip from '../../Atoms/Tooltip.vue'
import IconBase from '../../icon/IconBase.vue'
import IconCopy from '../../icon/IconCopy.vue'
import IconLinkExternal from '../../icon/IconLinkExternal.vue'

import Alert from '../../Atoms/Alert.vue'
import FormSwitch from '../../Atoms/FormSwitch.vue'
import StreamSidebarPlaybackPlaceholder from './StreamSidebarPlaybackPlaceholder.vue'

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  selectedPreviewTab: {
    type: String
  },
  streamSourceType: {
    type: String
  },
  hlsUrl: {
    type: String
  },
  llHls: {
    type: Boolean,
    default: false
  },
  sourceTypes: {
    type: Array
  },
  failoverIngestToggleState: {
    type: Boolean,
    default: false,
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  selectedLinkedSource: {
    type: Object,
    default: null
  },
  streamKeySrt: {
    type: Object,
    default: null
  },
  sourceSet: {
    type: Boolean,
    default: false
  },
  failoverIgestActive: {
    type: Boolean,
    default: false
  },
  isPasswordEnabled: {
    type: Boolean,
    default: false
  },
  isAnyBackupStreamLive: {
    type: Boolean,
    default: false
  },
  srtRegionIngestDestination: {
    type: String,
    default: null
  },
})

const { notify } = useNotification()
const store = useStore()

const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])
const maxSubSecondStreamViewers = computed(() => store.getters['User/maxSubSecondStreamViewers'])

const selectedPlaybackTab = ref('full')
const iframe = ref('responsive')
const llHlsRef = ref(null)

watch(()=> props.llHls, () => {
  llHlsRef.value = props.llHls
}, {
  immediate: true
})

const selectPlaybackTab = (tabName) => selectedPlaybackTab.value = tabName

const streamIframeCode = computed(() => {
  const embedUrl = streamEmbedUrl.value;
  let htmlCode = "";
  if (iframe.value === "responsive") {
    htmlCode = `<iframe src="${embedUrl}" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
  } else {
    htmlCode = `<iframe src="${embedUrl}" width="590" height="431" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
  }
  if (selectedPlaybackTab.value === 'audio') {
    htmlCode = `<iframe src="${embedUrl}" width="100%" style="height: 50px;" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
  }

  return htmlCode;
})

const streamEmbedUrl = computed(() => {
  let embedUrl = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${props.stream.key}`;

  if (selectedPlaybackTab.value === 'audio') {
    // if stream source is webcam for only audio we need point player to 
    // pick correct track audio number(#2) from audio source
    let webcamSrc = '';
    if (props.stream.type === 'live' && props.streamSourceType === 'webcam') {
      webcamSrc = '&webcam=true';
    }
    let onlyAudio = `?onlyAudio=true${webcamSrc}`;
    if (embedUrl.includes('?')) {
      onlyAudio = `&onlyAudio=true${webcamSrc}`;
    }

    embedUrl += onlyAudio;
  }
  if (selectedPlaybackTab.value === 'll-hls') {
    let ll = `?ll=true`;
    if (embedUrl.includes('?')) {
      ll = `&ll=true`;
    }

    embedUrl += ll;
  }
  if (selectedPlaybackTab.value === 'ull') {
    embedUrl += '/realtime';
  }
  return embedUrl;
})

const clipboardCopy = async (value) => {
  await navigator.clipboard.writeText(value);
  notify({ group: 'info', text: 'Copied to clipboard' });
}

const baseCharge = computed(() => {
  const subscription = addonSubscriptions.value.find(sub => sub.category === 'live')
  return subscription?.package?.baseCharge 
})

const allowHls = computed(() => {
  const subscription = addonSubscriptions.value.find(sub => sub.category === 'live')
  const hlsEnabled = _.get(subscription, 'definitionOverride.hlsEnabled', _.get(subscription, 'package.definition.hlsEnabled', false));
  if (hlsEnabled) return true;
  if ((baseCharge.value > 750 && subscription?.package?.cycleAge >= 365) || (baseCharge.value > 100 && subscription?.package?.cycleAge < 365)) {
    return true
  } else return false
})

const linkedCpCode = computed(() => {
  const subscription = addonSubscriptions.value.find(sub => sub.category === 'live')
  const cpcode = _.get(subscription, 'definitionOverride.cdnDeploymentId');
  return cpcode;
})

const labelHls = computed(() => {
  if (props.hlsUrl && allowHls.value && linkedCpCode.value) return `HLS Url`

  if (allowHls.value && !linkedCpCode.value) return `HLS URL is available. <router-link class="text-surface-8 underline" onclick="Intercom('show')">Contact us</router-link> to activate.`

  return `HLS URL. <router-link class="text-surface-8 underline" to="/subscribe">Upgrade</router-link> to access this feature.`
})

const shouldShowInputs = computed(() => {
  if (selectedPlaybackTab.value === 'll-hls' && llHlsRef.value) return true
  if (selectedPlaybackTab.value === 'audio') return true
  if (selectedPlaybackTab.value === 'full') return true
  return false
})

const hasLLHls = computed(() => {
  // TODO(remove): hardcode access to LL hls feature for user with premuin sub
  // LL hls should be blocked for current sub for all other clients
  if (props.stream.user === '653686e34d8f1722c0b3c9a4') {
    return true
  }
  if (props.stream.user === '66e83ba5bb1df9597da804a9') {
    return true
  }
  return store.state.User.aggregatedDefinition.live.llHls ?? false
})

const saveLLHls = async () => {
  try {
    await StreamService.saveStreamMetadata(props.stream._id, 'llHls', llHlsRef.value);
    notify({ group: 'success', text: 'Setting saved' })
  } catch (e) {
    llHlsRef.value = !llHlsRef.value;
    notify({ group: 'error', text: 'could not save changes' });
  }
}

const shouldShowHlsUrl = computed(() => selectedPlaybackTab.value === 'full' && (props.stream.type !== 'subsecond'))
</script>

<template>
  <section class="p-3 bg-surface-2" v-if="stream.type != 'restream'">
    <Alert v-if="stream.paywallPopupAuth" show type="warning" class="flex p-2 mb-1">
      This player is now behind a paywall.
    </Alert>
    <Alert v-if="!isPasswordEnabled && stream.type === 'live'" class="inline-flex items-center w-full" show
      type="warning2">
      Ingest is not secure.
    </Alert>
    <TabGroup type="lines" class="w-full">
      <TabItem type="line" :isActive="selectedPlaybackTab === 'full'" label="Normal"
        @click="selectPlaybackTab('full')" />
      <TabItem v-if="stream.type !== 'subsecond'" type="line" :isActive="selectedPlaybackTab === 'll-hls'" label="LL"
        @click="selectPlaybackTab('ll-hls')" />
      <TabItem v-if="stream.type !== 'subsecond'" type="line" :isActive="selectedPlaybackTab === 'ull'" label="ULL" @click="selectPlaybackTab('ull')" />
      <TabItem type="line" :isActive="selectedPlaybackTab === 'audio'" label="Audio"
        @click="selectPlaybackTab('audio')" />
    </TabGroup>

    <div v-if="selectedPlaybackTab === 'ull'" class="mt-3">
      <p class="text-surface-9 text-sm mb-3">Ultra Low Latency is now Sub Second Streaming.</p>
      <FormButton size="md" to="/sub-second" class="mb-3">
        Create Sub Second Streaming
      </FormButton>
    </div>
    <Alert v-if="selectedPlaybackTab === 'll-hls'" hide-icon class="items-start mt-3">
      <Badge class="mr-2" title="Beta" />
      Low Latency Embed will give you less than 15 seconds of latency.
    </Alert>
    <Alert v-if="selectedPlaybackTab === 'audio'" class="mt-3">
      The below players will have only audio from your Livestream.
    </Alert>
    <div class="mt-3" v-if="selectedPlaybackTab === 'll-hls'">
      <Tooltip :active="!hasLLHls">
        <FormSwitch class="w-full justify-between" labelPosition="left" label="Enable Low Latency" v-if="baseCharge > 0" :disabled="!hasLLHls" @change="saveLLHls" v-model="llHlsRef" />
          <template #content>
            Your plan is not supported. <a target="_blank" class="underline" href="/app/subscribe">Upgrade</a> to access this feature
          </template>
      </Tooltip>
    </div>
    <div v-if="shouldShowInputs" class="mt-3">
      <div class="flex gap-x-4 mb-2">
        <FormRadio class="m-0" label="Responsive" v-model="iframe" inputValue="responsive" />
        <FormRadio class="m-0" label="Fixed size" v-model="iframe" inputValue="fixed" />
      </div>
      <div class="flex relative w-full mb-3">
        <FormInput customClasses="!pr-8" :modelValue="streamIframeCode" @click="clipboardCopy(streamIframeCode)"
          readonly />
        <FormButton class="absolute right-0 top-px z-10" isLink isIcon type="link" size="sm"
          @click="clipboardCopy(streamIframeCode)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
      <div class="flex relative w-full">
        <FormInput customClasses="!pr-16" label="Direct Player Link" :modelValue="streamEmbedUrl"
          @click="clipboardCopy(streamEmbedUrl)" readonly />
        <FormButton class="absolute right-6 bottom-px z-10" isLink isIcon type="link" size="sm"
          @click="clipboardCopy(streamEmbedUrl)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
        <FormButton class="absolute right-0 bottom-px z-10" isLink isIcon type="link" size="sm" :href="streamEmbedUrl">
          <icon-base class="" fill="none">
            <icon-link-external />
          </icon-base>
        </FormButton>
      </div>
    </div>
    <div v-if="shouldShowHlsUrl">
      <label class="mt-3" v-html="labelHls" />
      <div class="flex relative w-full">
        <FormInput customClasses="!pr-8" :modelValue="allowHls && linkedCpCode ? hlsUrl : 'xxxxxxxx'"
          :disabled="!allowHls || !linkedCpCode" @click="clipboardCopy(allowHls && linkedCpCode ? hlsUrl : 'xxxxxxxx')"
          readonly />
        <FormButton v-if="allowHls && linkedCpCode" class="absolute right-0 top-px z-10" isLink isIcon type="link"
          size="sm" @click="clipboardCopy(hlsUrl)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
    </div>
    <p v-if="stream.type === 'subsecond'" class="text-surface-7 text-sm mt-3">Maximum {{ maxSubSecondStreamViewers }} concurrent {{ maxSubSecondStreamViewers === 1 ? 'viewer' : 'viewers' }}. Need more? <FormButton type="link" customClasses="underline" onclick="Intercom('showNewMessage', 'I want to talk about low latency solution')">Contact us</FormButton></p>
  </section>
  <StreamSidebarPlaybackPlaceholder v-else />
</template>