<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, getCurrentInstance, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import IconBase from '../icon/IconBase.vue'
import IconReplace from '../icon/IconReplace.vue'
import IconCopy from '../icon/IconCopy.vue'
import IconDownload from '../icon/IconDownload.vue'
import IconCamera from '../icon/IconCamera.vue'
import IconMoveTo from '../icon/IconMoveTo.vue'
import IconBin from '../icon/IconBin.vue'
import IconGear from '../icon/IconGear.vue'
import IconPlus from '../icon/IconPlus.vue'
import IconTag from '../icon/IconTag.vue'
import FormButton from '../Atoms/FormButton.vue'
import { bytes } from '../../filtersNew'
import VODTags from '../ui/VODTags.vue'
import ReplaceVodModal from '@/components/modals/ReplaceVodModal.vue'
import AddPreRecordStreamModal from "@/components/modals/AddPreRecordStreamModal.vue"
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import MoveVod from '../../components/VOD/MoveVod.vue'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import MetricsService from '../../services/MetricsService'
import UserService from "@/services/UserService"
import StreamService from '@/services/StreamService'
import PlaylistService from "@/services/Playlistservice";
import utils from '@/utils';
import UppyUploaderReplace from '../UppyUploaderReplace.vue'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const vodTags = ref(null)

const selectedVodFile = ref(null)
const aioStorageLimit = ref(null)
const uploadInputDrop = ref(null)
const maxTotalFileSize = ref(null)
const showMoveDialog = ref(false)
const selectedVideoId = ref(null)
const isDownloading = ref(false)
const isVodBeingMoved = ref(false)
const isVodRemovable = ref(false)
const vodLinkedPlayout = ref(null)
const mediaInfoLib = ref(null);
const maxVideoFileSize = ref(null);
const storageRestricitonsLoaded = ref(false);

const availableEncodingPresets = [
    { id: '360p', text: '360p', minHeight: 300 },
    { id: '480p', text: '480p', minHeight: 440 },
    { id: '720p', text: '720p', minHeight: 700 },
    { id: '1080p', text: '1080p', minHeight: 1020 }
  ]

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  queryString: {
    type: String,
  },
  playerUrl: {
    type: String
  }
})

const vodStreams = computed(() => store.getters['Streams/vodStreams'])
const vodFiles = computed(() => store.getters['Streams/vodFiles'])
const canDownloadSource = computed(() => store.getters['User/canDownloadSource'])

const activeTeam = computed(() => store.state.User.activatedTeam);
const memberPermission = computed(() => store.state.User.memberPermission);

const canDeleteVideoFile = computed(() => !activeTeam.value || (activeTeam.value && memberPermission.value && memberPermission.value.hostingDelete))

const ownerEmail = computed(() => store.state.User.email)

const noDeletePermissionTooltip = computed(() => {
        return `<span class="text-dark-8 text-small">
					You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
					:href="'mailto:${ownerEmail.value}'">${ownerEmail.value}</a> to gain access.
				</span>`
})

const isLinkedPlayoutTooltip = computed(() => {
  let playoutRef = ''
  vodLinkedPlayout.value.forEach((item, idx) => {
    const isLast = idx === vodLinkedPlayout.value.length - 1
    playoutRef += `<span class="text-dark-8 text-small" style="font-weight: 700" :href="/app/tv-playout/${item._id}">${item.name}${isLast ? ' ' : ', '}</span>`
  })
  return `<span class="text-dark-8 text-small">
    This file is currently linked to ${playoutRef} TV Playouts. You can safely delete it once it has been removed from all associated TV Playouts.
  </span>`
})

const canEncodeVideos = computed(() => {
  const encodingUnits = _.get(
    store.state.User.baseSub,
    "package.definition.encodingUnits"
  );
  return encodingUnits > 0;
})

const hasAccessToVodReplace = () => {
  const subs = store.state.User.subscriptions || [];
  const active = subs.filter(x => x.enabled && ['vod', 'live'].includes(x.category));
  return active.some((sub) => {
    if (sub.definitionOverride && !_.isNil(sub.definitionOverride.vodReplace)) {
      return sub.definitionOverride.vodReplace;
    } else if (sub.package && !_.isNil(sub.package.definition.vodReplace)) {
      return sub.package.definition.vodReplace;
    } else {
      return false;
    }
  });
}

const emit = defineEmits({
  'route-change': (id) => { return id },
  'video-update': (value) => { return value },
  'video-removed': (value) => { return value }
})

const checkVodMoveStatus = async () => {
  const vodFile = props.vodFile;
  const folderKey = props.vodFolder?.key;
  if(!vodFile) setTimeout(checkVodMoveStatus, 5000);

  try {
    const status = await PlaylistService.vodMoveStatus(folderKey, vodFile.id)
    if(!status) {
      setTimeout(checkVodMoveStatus, 15000);
      isVodBeingMoved.value = true;
    } else {
      isVodBeingMoved.value = false;
    }
  } catch {
    setTimeout(checkVodMoveStatus, 15000);
  }
}

const checkVodRemovable = async () => {
  const vodFile = props.vodFile;
  if(!vodFile) setTimeout(checkVodRemovable, 5000);

  try {
    const playoutList = await StreamService.getLinkedTvPlayout(props.vodFolder._id, vodFile._id)
    if(!playoutList || _.isEmpty(playoutList)) {
      setTimeout(checkVodRemovable, 15000);
      isVodRemovable.value = true;
    } else {
      isVodRemovable.value = false;
      vodLinkedPlayout.value = playoutList;
    }
  } catch {
    setTimeout(checkVodRemovable, 15000);
  }
}


onMounted(async () => {
  setupMediaInfoLib();
  checkVodMoveStatus();
  checkVodRemovable()
})

const onCreatePrerecord = () => {
  selectedVodFile.value = _.cloneDeep(props.vodFile)
  selectedVodFile.value.name = selectedVodFile.value.fileName
  selectedVodFile.value.size = bytes(selectedVodFile.value.bytes, false, 2)
  selectedVodFile.value.sizeRaw = selectedVodFile.value.bytes
  selectedVodFile.value.duration = selectedVodFile.value.mediaInfo.duration

  const srcHls = selectedVodFile.value.srcHls
  if (srcHls.includes('/videos/')) selectedVodFile.value.vodId = srcHls.substring(srcHls.indexOf('/videos') + 1, srcHls.indexOf('/index.m3u8'))
  if (srcHls.includes('/episodes/')) selectedVodFile.value.vodId = srcHls.substring(srcHls.indexOf('/episodes') + 1, srcHls.indexOf('/index.m3u8'))
  setTimeout(() => {
    root.$emit('bv::show::modal', 'modal-add-pre-record' + selectedVodFile.value.name);
  }, 100);
}

const clearSelectedVodFile = () => selectedVodFile.value = null

const clipboardCopy = async (value) => {
  await navigator.clipboard.writeText(value);
  notify({ group: 'info', text: 'Copied to clipboard' });
}
const onClickReplaceVod = () => {
  root.$emit('bv::show::modal', 'confirm-replace-vod')
}
const replaceVideo = async (type) => {
  if (type === 'raw') {
    uploadInputDrop.value.click();
  } else {
    await Promise.all([
      calculateAioStorageLimit(),
      calculateAvailableStorage()
    ])
    storageRestricitonsLoaded.value = true;
  }
}

const calculateAioStorageLimit = async () => {
  const subscriptions = store.state.User.subscriptions;
  const activeAIOSub = _.find(subscriptions, (sub) => sub.category === 'live' && sub.enabled === true)
  if (activeAIOSub) {
    const res = await MetricsService.getSubscriptionStorage(UserService.getUserId(), activeAIOSub.package._id)
    aioStorageLimit.value = res ? res : {}
    if(props.vodFolder.type === 'vod' && res) {
      maxVideoFileSize.value = 100;
    }
  }
}

const calculateAvailableStorage = async () => {
  const assignedSpace = parseFloat(store.state.User.maxVodStorage);
  const existingFileStorage = props.vodFile.bytes;
  let freeSpaceAvailable = 0;

  let totalVodBytes = 0;
  let usedDvrStorage = 0;
  if (assignedSpace) {
    const resp = await MetricsService.getUserDvrStorage();
    usedDvrStorage = resp.bytes || 0;

    if (aioStorageLimit.value) {
      aioStorageLimit.value.bytes += usedDvrStorage
      aioStorageLimit.value.unitsLeft -= usedDvrStorage
    }

    vodStreams.value.forEach((item) => {
      if (!item.liveParent) {
        totalVodBytes += item.totalBytes
      }
    })

    if (totalVodBytes > 0) {
      totalVodBytes = totalVodBytes / (1000 ** 3)
    }

    const dvrHumanSize = usedDvrStorage / 1000 ** 3;
    freeSpaceAvailable = (assignedSpace - (totalVodBytes + dvrHumanSize)) * (1000 ** 3);
  }

  maxTotalFileSize.value = freeSpaceAvailable + existingFileStorage;
}

const showPopover = () => {
  showMoveDialog.value = true
  selectedVideoId.value = props.vodFile?._id || props.vodFile?.refId;
}
const closePopover = () => {
  showMoveDialog.value = false
  selectedVideoId.value = null
}
const onClickMove = async (newFolder) => {
  try {
    const result = await PlaylistService.moveVideoToAnotherFolder(selectedVideoId.value, newFolder, Boolean(props.vodFolder.liveParent));
    if (!result.success) {
      throw new Error("Failed to move the file");
    }
    notify({ group: "success", text: "Moving process initiated..." });
    await store.dispatch('Streams/getVodFilesByVodId', props.vodFolder._id);
    setTimeout(() => {
      const video = vodFiles.value.find(x => x.id)
      if (video?.id) router.push({ name: 'VODStreamManage', params: { streamId: props.vodFolder?._id, videoId: video?.id, } })
      else {
        router.push({ name: 'VODFolderManage', params: { streamId: props.vodFolder?._id } })
      }
    }, 300);
  } catch (err) {
    console.log(err);
    notify({ group: "error", text: "Failed to move the file!" });
  } finally {
    closePopover();
  }
}
const downloadDvrArchive = (url, customFileName = '') => {
  if (url?.includes('archives.liveapi.com')) {
    url = url.replace('archives.liveapi.com', 'recordings.castr.com');
  }
  if (!customFileName) {
    window.location.href = url
  } else {
    if (!customFileName?.includes('.mp4')) {
      customFileName = customFileName + '.mp4';
    }
    url = url + '?filename=' + customFileName;
    isDownloading.value = true;
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.target = '_blank';
    link.download = customFileName;
    link.click();
    document.body.removeChild(link);
    isDownloading.value = false;
  }
}

const requestVideoRemoval = () => {
  if(!canDeleteVideoFile) return;
  root.$emit('bv::show::modal', 'confirm-video-removal')
}

const onFileSelected = async () => {
  const el = uploadInputDrop.value;
    if (!el) {
      console.log('"el" not found')
      setTimeout(() => onFileSelected(), 100);
      return;
    }
    const file = el.files[0];
    
    const originalFileName = props.vodFile.fileName ?? file.name;
    let trimmedName = originalFileName;
    const nameChunks = trimmedName.split('.');
    const extname = nameChunks.length > 1 ? nameChunks.pop() : '';
    let basename = nameChunks.join('.');
    if (basename.length > 20) {
      basename = `${basename.substr(0, 14)} ... ${basename.substr(basename.length - 6)}`;
    }
    trimmedName = basename + '.' + extname;

    const newVideo = {
      id: `unnamed_${Math.random().toString().slice(2)}`,
      fileName: trimmedName,
      fileNameOriginal: originalFileName,
      bytes: file.size,
      mediaInfo: null,
      precedence: 0,
      enabled: true,
      removing: false,
      statusProcessing: false,
      isReplacingExistingFile: true,
      currFile: props.vodFile,
      file,
      uploadable: true,
      uploading: false,
      uploadProgress: 0,
      encodingPresets: [],
      encodingUnitsRequired: 0,
      presetListCollapse: true,
      durationExceeded: false
    };
    store.dispatch('Ui/addStreamToUpload', props.vodFolder);
    store.dispatch('Ui/addFileToUpload', newVideo);
    
    utils.inspectMediaFile(mediaInfoLib.value, file, info => {
      const durationSec = info.duration;
      const durationMins = Math.ceil(
        (durationSec < 60 ? 60 : durationSec) / 60
      );

      const mappedInfo = { ...info, durationMins };
      // uploadItem.mediaInfo = mappedInfo;
      const file = newVideo
      store.dispatch('Ui/addFileData', {uploadItem: file, mappedInfo});
      // if (this.isTrialSub && info.duration > 10 * 60) {
      //   // uploadItem.durationExceeded = true;
      //   store.dispatch('Ui/addFileData', {uploadItem: file, durationExceeded: true})
      // }
    });
    root.$emit('bv::hide::modal', 'confirm-replace-vod')
}

const setupMediaInfoLib = () => {
  const scriptId = 'mediainfo-js';

  
  // async load mediainfo js
  const scriptEl = document.createElement('script');
  if ('WebAssembly' in window) {
    scriptEl.src = 'https://castr.com/libs/mediainfojs2/MediaInfoWasm.js';
  } else {
    scriptEl.src = 'https://castr.com/libs/mediainfojs2/MediaInfo.js';
  }

  scriptEl.setAttribute('id', scriptId);
  scriptEl.onload = function() {
    initClass();
  };
  document.head.appendChild(scriptEl);
  function initClass() {
    // eslint-disable-next-line no-undef
    mediaInfoLib.value = window.MediaInfoLib({
      postRun() {
        if (typeof Promise !== 'undefined' && mediaInfoLib.value instanceof Promise) {
          mediaInfoLib.value.then((module)  => {
            mediaInfoLib.value = module
          });
        } else {
        }
      }
    });
  }
}

const onFinishedUploadingVideoThroughCloud = async (video) => {
  console.log('onFinishedUploadingVideoThroughCloud', video);
  
    const streamId = props.vodFolder._id;
    const streamKey = props.vodFolder.key;
    const newVideoId = video.videoId || video.id;
    video.file = {
      name: props.vodFile.fileName,
      size: video.bytes,
    };
    video.precedence = props.vodFile.precedence;
    const uploadResult = await StreamService.onCompleteVodReplacement(
      props.vodFile.id,
      newVideoId,
      streamId,
      streamKey,
      video
    );
    // this will clear captions, chapeters, schedule, and posters.
    await StreamService.clearVodMetadata(streamId, props.vodFile.id, props.vodFile._id);
    notify({
      group: 'success',
      text: 'media replaced successfully'
    });

    setTimeout(() => window.location.reload(), 300);
  }

const removeVideoFile = async () => {
  const video = props.vodFile;
  const videoId = video.id;
  emit('video-update', videoId, { removing: true });

  try {
    // if (video.encodingRequired) {
    const videoIndex = vodFiles.value.findIndex(element => element.id === videoId)
    if (props.vodFolder.liveParent) {
      const q = {
        streamId: props.vodFolder.liveParent,
        from: video.archive.from,
        duration: video.archive.duration,
        abr: video.archive.abr,
        namedHls: video.archive.namedHls
      };
      await StreamService.deleteStreamDvrArchive(q);
      await store.dispatch('Streams/getStreams');
      notify({
        group: 'success',
        title: "The file was successfully deleted",
      });
    } else {
      await StreamService.deleteVodMedia(videoId, props.vodFolder);
      await store.dispatch('Streams/getStreams');
      notify({
        group: 'success',
        title: "The file name was successfully deleted",
      });
    }
    await store.dispatch('Streams/getFilesInVODFolder');

    if (vodFiles.value?.length > 0) {
      router.push({
        params: { videoId: vodFiles.value[videoIndex - 1].id },
      });
    } else {
      router.push({
        name: 'VODBucketVideos'
      });
    }
  } catch (e) {
    console.log(e);
    emit('video-update', videoId, { removing: false });
  }
  emit('video-removed', videoId);
}

const showGeneralTagsModal = () => vodTags.value.showManageTagsModal()

const showAddGeneralTagModal = () => {
  root.$emit('bv::show::modal', '1-modal')
}
</script>

<template>
  <section>
    <ul class="grid grid-cols-2 md:flex flex-col gap-2 mb-2 md:mb-0">
      <li>
        <FormButton type="secondary" fullWidth @click="onCreatePrerecord()">
          <icon-base class="mr-2 shrink-0">
            <icon-camera />
          </icon-base>
          Stream as Live
        </FormButton>
      </li>
      <li>
        <FormButton @click="clipboardCopy(playerUrl)" type="secondary" fullWidth>
          <icon-base class="mr-2 shrink-0">
            <icon-copy />
          </icon-base>
          Playback URL
        </FormButton>
      </li>
      <li id="tooltip-replace-feat">
        <ejs-tooltip v-if="vodFolder.liveParent" content="Replace feature is not available for Live-to-VOD files">
          <FormButton type="secondary" fullWidth disabled>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-replace />
            </icon-base>
            Replace
          </FormButton>
        </ejs-tooltip>
        <ejs-tooltip v-else-if="!hasAccessToVodReplace"
          content="Replace feature is not available for your subscription.">
          <FormButton type="secondary" fullWidth disabled>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-replace />
            </icon-base>
            Replace
          </FormButton>
        </ejs-tooltip>
        <FormButton v-else type="secondary" fullWidth @click="onClickReplaceVod"
          :disabled="vodFolder.liveParent || !hasAccessToVodReplace">
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-replace />
          </icon-base>
          Replace
        </FormButton>
      </li>
      <li class="popup-parent relative" id="tooltip-move-to" v-click-outside="closePopover"
        v-if="!vodFolder.liveParent">
        <ejs-tooltip v-if="isVodBeingMoved" content="Moving is still in progress. Once complete, new files can be transferred.">
          <FormButton type="secondary" fullWidth disabled>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-move-to />
            </icon-base>
            Move to
          </FormButton>
        </ejs-tooltip>
        <FormButton v-else @click="showPopover" type="secondary" fullWidth>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-move-to />
          </icon-base>
          Move to
        </FormButton>
        <b-tooltip v-if="vodFolder.liveParent" target="tooltip-move-to" placement="top">Move feature is not available
          for
          Live-to-VOD files</b-tooltip>
        <div class="popup-container" v-if="showMoveDialog">
          <move-vod :currentFolder="vodFolder._id" :vodStreams="vodStreams" @confirm="onClickMove"
            @cancel="closePopover" />
        </div>
      </li>
      <li v-if="canDownloadSource && vodFolder.liveParent" id="disabled-downloading-1">
        <FormButton :disabled="isDownloading" @click="downloadDvrArchive(vodFile.downloadUrl, vodFile.fileName)"
          type="secondary" fullWidth>
          <Spinner v-if="isDownloading" text="" spinner-color="var(--c-dark-8)" spinner-size="16px" />
          <icon-base v-else class="mr-2 shrink-0" fill="transparent">
            <icon-download />
          </icon-base>
          {{ isDownloading ? 'Downloading' : 'Download' }}
        </FormButton>
        <b-tooltip v-if="isDownloading" target="disabled-downloading-1" placement="top">
          Video is being downloaded!
        </b-tooltip>
      </li>
      <li v-else-if="!canDownloadSource && vodFolder.liveParent">
        <span id="disabled-wrapper1" class="d-inline-block w-100" tabindex="0">
          <FormButton disabled type="secondary" fullWidth>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-download />
            </icon-base>
            Download
          </FormButton>
          <b-tooltip target="disabled-wrapper1" placement="top">
            Upgrade to use the download feature. Check details on <a target="_blank" class="text-reset text-underline"
              href="https://castr.io/pricing">pricing page</a>.
          </b-tooltip>
        </span>
      </li>
      <li v-else-if="!canDownloadSource && !vodFolder.liveParent">
        <span id="disabled-wrapper" class="d-inline-block w-100" tabindex="0">
          <FormButton disabled type="secondary" fullWidth>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-download />
            </icon-base>
            Download
          </FormButton>
        </span>
        <b-tooltip target="disabled-wrapper" placement="top">
          Upgrade to use the download feature. Check details on <a target="_blank" class="text-reset text-underline"
            href="https://castr.io/pricing">pricing page</a>.
        </b-tooltip>
      </li>
      <li v-else-if="canDownloadSource && !vodFolder.liveParent && vodFile" id="disabled-downloading-2">
        <FormButton :disabled="isDownloading"
          @click="downloadDvrArchive('https://video-assets.castr.com/' + vodFolder.key + '/' + vodFile.id, vodFile.fileName)"
          type="secondary" fullWidth>
          <Spinner v-if="isDownloading" text="" spinner-color="var(--c-dark-8)" spinner-size="16px" />
          <icon-base v-else class="mr-2 shrink-0" fill="transparent">
            <icon-download />
          </icon-base>
          {{ isDownloading ? 'Downloading' : 'Download' }}
        </FormButton>
        <b-tooltip v-if="isDownloading" target="disabled-downloading-2" placement="top">
          Video is being downloaded!
        </b-tooltip>
      </li>
      <li>
        <FormButton @click="requestVideoRemoval" type="secondary" fullWidth v-if="canDeleteVideoFile && !vodLinkedPlayout">
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-bin />
          </icon-base>
          Delete
        </FormButton>
        <ejs-tooltip v-else-if="vodLinkedPlayout" :content="isLinkedPlayoutTooltip">
          <FormButton type="secondary" fullWidth disabled>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-move-to />
            </icon-base>
           Delete
          </FormButton>
        </ejs-tooltip>
        <ejs-tooltip v-else :content="noDeletePermissionTooltip">
          <FormButton type="secondary" fullWidth disabled>
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-move-to />
            </icon-base>
           Delete
          </FormButton>
        </ejs-tooltip>
      </li>
      <li class="self-start w-full">
        <div class="flex items-center w-full md:!mt-2">
          <icon-base class="shrink-0 text-surface-8 !mr-1" fill="transparent">
            <icon-tag />
          </icon-base>
          <h4 class="text-sm text-surface-8">Tags</h4>
          <FormButton class="ml-auto" @click="showAddGeneralTagModal" type="link" isIcon>
            <icon-base class="shrink-0" fill="transparent">
              <icon-plus />
            </icon-base>
          </FormButton>
          <FormButton class="!ml-1" @click="showGeneralTagsModal" type="link" isIcon>
            <icon-base class="shrink-0" fill="transparent">
              <icon-gear />
            </icon-base>
          </FormButton>
        </div>
        <VODTags ref="vodTags" v-if="vodFile" :videoId="vodFile?.id" :tagIds="vodFile.tags"
          :liveParent="vodFile.archive" :key="vodFile?.id" :streamParent="vodFolder" />
      </li>
    </ul>
    <add-pre-record-stream-modal v-if="selectedVodFile" :video="selectedVodFile" :stream="vodFolder"
      @modal-dismiss="clearSelectedVodFile" @modal-confirm="clearSelectedVodFile" />
    <ReplaceVodModal
      body="You are about to replace the video. All changes made in Chapters, Captions, Clips, Schedule, Meta and Thumbnail will be reset except for Embed URL. Are you sure?"
      message="You are going to replace the video" modal-id="confirm-replace-vod" ok-text="Upload New Video"
      cancel-text="Cancel" @modal-confirm="replaceVideo" />
    <b-modal hide-header hide-footer centered id="cloud-import-replace2" class="cloud-import-box">
			<UppyUploaderReplace v-if="storageRestricitonsLoaded"
				:stream="vodFolder" 
				:max-files="1" 
				:max-total-file-size="maxTotalFileSize"
				:maxFileSize="maxVideoFileSize ? (maxVideoFileSize * (1000 **3)) : maxVideoFileSize"
				:storageLimit="aioStorageLimit"
				:oldFileSize="props.vodFile.bytes"
				:replaceVideoId="props.vodFile.id"
				@upload-success="onFinishedUploadingVideoThroughCloud"
				:encodingPresets="canEncodeVideos ? availableEncodingPresets : []"
			/>
		</b-modal>
    <input ref="uploadInputDrop" type="file" :multiple="false" style="display: none;" accept="video/mp4"
      name="fields[assetsFieldHandle][]" id="assetsFieldHandleDrop" @change="onFileSelected">
    <confirm-modal :message='`You are about to delete video named ${vodFile?.fileName}`' modal-type="danger"
      modal-id="confirm-video-removal" ok-text="Delete" cancel-text="Keep" @modal-confirm="removeVideoFile()" />
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
