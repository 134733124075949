<script setup>
import { getCurrentInstance } from 'vue'
import _ from 'lodash'
import TabGroup from '../../Atoms/TabGroup.vue'
import TabItem from '../../Atoms/TabItem.vue'

import FormButton from '../../Atoms/FormButton.vue'
import FormInput from '../../Atoms/FormInput.vue'
import FormRadio from '../../Atoms/FormRadio.vue'

import IconBase from '../../icon/IconBase.vue'
import IconCopy from '../../icon/IconCopy.vue'
import IconLinkExternal from '../../icon/IconLinkExternal.vue'

const instance = getCurrentInstance();
const root = instance.proxy.$root;

const showModal = () => root.$emit('bv::show::modal', 'modal-up');
</script>

<template>
  <section class="p-3 bg-surface-2 relative">
    <TabGroup type="lines" class="w-full">
      <TabItem type="line" isActive label="Normal" />
      <TabItem type="line" label="LL" />
      <TabItem type="line" label="ULL" />
      <TabItem type="line" label="Audio" />
    </TabGroup>
    <div class="mt-3">
      <div class="flex gap-x-4 mb-2">
        <FormRadio class="m-0" label="Responsive" inputValue="responsive" />
        <FormRadio class="m-0" label="Fixed size" inputValue="fixed" />
      </div>
      <div class="flex relative w-full mb-3">
        <FormInput customClasses="!pr-8" readonly />
        <FormButton class="absolute right-0 top-px z-10" isLink isIcon type="link" size="sm">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
      <div class="flex relative w-full">
        <FormInput customClasses="!pr-16" label="Direct Player Link" readonly />
        <FormButton class="absolute right-6 bottom-px z-10" isLink isIcon type="link" size="sm">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
        <FormButton class="absolute right-0 bottom-px z-10" isLink isIcon type="link" size="sm">
          <icon-base class="" fill="none">
            <icon-link-external />
          </icon-base>
        </FormButton>
      </div>
    </div>
    <div class="absolute size-full backdrop-blur z-10 top-0 left-0 bg-surface-2/20 flex items-center justify-center flex-col p-3">
      <p class="text-base mb-2 text-center">Get your own embeddable player.</p>
      <p class="text-sm mb-3 text-surface-8 text-center">You can do so much more with your content, let us
        show you or upgrade to access instantly.</p>
      <p class="flex justify-center gap-x-2 mb-2"><FormButton target="_blank"
          href="https://castr.com/blog/introducing-all-in-one-streaming-solution/">Show me</FormButton> <FormButton
          type="secondary" target="_blank"
          href="https://castr.com/app/subscribe?category=live">Upgrade</FormButton></p>
      <FormButton customClasses="underline text-tiny text-surface-8" type="link" size="sm" @click="showModal">
        Already Upgraded?
      </FormButton>
    </div>
  </section>
</template>