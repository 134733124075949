<script setup>
import DynamicService from '@/services/DynamicService'
import { computed, ref, getCurrentInstance, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import _ from 'lodash';
import FormSwitch from '@/components/Atoms/FormSwitch.vue';
import FormButton from '@/components/Atoms/FormButton.vue';
import FormInput from '@/components/Atoms/FormInput.vue';
import Tootip from '@/components/Atoms/Tooltip.vue'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()

// Refs
const features = ref({
	ga: {
		enabled: false,
		value: null,
		valueType: 'string'
	},
	embedAutoplay: {
		error: false,
		enabled: true,
		value: null,
		valueType: 'bool'
	},
})

const featureProcessing = ref({
	ga: false,
	embedAutoplay: false,
})

// Computed

const player = computed(() => store.getters['DynamicPlayers/dynamicPlayer'])
const dynamicPlayerFeatures = computed(() => store.getters['User/dynamicPlayerFeatures'])

// Hooks
watch(player, () => {
	if (player.value?.metadata?.ga) {
		features.value.ga.enabled = true
		features.value.ga.value = player.value?.metadata?.ga
	}
	if (player.value?.metadata?.embedAutoplay != undefined) {
		features.value.embedAutoplay.enabled = player.value?.metadata?.embedAutoplay
	}
}, {
	immediate: true
})

// Methods
const toggleFeature = async (featureName, newFeature = false) => {
	if (featureProcessing.value[featureName]) return;
	const feature = features.value[featureName];
	if (!feature) return;

	let nstate = feature.enabled;
	feature.enabled = nstate;
	if (feature.valueType === 'bool' || !nstate) {
		if (feature.valueType === 'array' && !nstate) {
			nstate = [];
		}
		await saveSetting(featureName, nstate);
	}
}

const savePlayerGAId = async () => {
	const gaId = features.value.ga.value;
	if (!gaId) return;
	await saveSetting('ga', gaId);
}

const saveSetting = async (key, value, successToast = false) => {
	featureProcessing.value[key] = true;
	try {
		await DynamicService.savePlayerMetadata(player.value._id, key, value)
		// clear stream meta cache in embed player
		DynamicService.clearPlayerMetadataCache(player.value._id)
		await store.dispatch('DynamicPlayers/getPlayer', route.params?.playerId);
		if (successToast) {
			notify({ group: 'success', text: 'Setting saved' })
		}
	} catch (e) {
		console.log(e);
		notify({ group: 'error', text: 'could not save changes' })
	}
	featureProcessing.value[key] = false;
}

</script>

<template>
	<div class="divide-y divide-surface-2">
		<section class="flex justify-between !py-3">
			<h4 class="text-sm">Autoplay Embed Player</h4>
			<FormSwitch @change="toggleFeature('embedAutoplay')" v-model="features.embedAutoplay.enabled" />
		</section>
		<section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
			<h4 class="text-sm">Enable Google Analytics</h4>
			<FormSwitch class="justify-self-end" @change="toggleFeature('ga')" v-model="features.ga.enabled" />
			<div class="col-span-2 flex items-center gap-x-2 !mt-1" v-if="features.ga.enabled">
				<label class="m-0">GA ID</label>
				<FormInput class="!w-1/3" v-model="features.ga.value" placeholder="UA-00000 or G-xxxxx" />
				<FormButton :disabled="!features.ga.value || featureProcessing.ga" @click="savePlayerGAId">
					{{ featureProcessing.ga ? 'Saving' : 'Save' }}
				</FormButton>
			</div>
		</section>
	</div>
</template>