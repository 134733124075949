<script setup>
import { computed, ref, onMounted } from 'vue'
import IconBase from '../../icon/IconBase.vue';
import IconCamera from '../../icon/IconCamera.vue';
import IconChevronUpDown from '../../icon/IconChevronUpDown.vue';
import IconMic from '../../icon/IconMic.vue';
import TabGroup from '../../Atoms/TabGroup.vue';
import TabItem from '../../Atoms/TabItem.vue';
import DropDownMenu from '../../Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../Atoms/DropDownMenuItem.vue';
import IconCheckmark from '../../icon/IconCheckmark.vue';

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  transcoderView: {
    type: Boolean,
    default: false
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  selectedLinkedSource: {
    type: Object,
    default: null
  },
  sourceSet: {
    type: Boolean,
    default: false
  }
})

const windowWidth = ref(window.innerWidth)
const webcamDevices = ref(null)
const audioDevice = ref(null)
const videoDevice = ref(null)
const audioDeviceDropdown = ref(null)
const cameraDeviceDropdown = ref(null)

const emit = defineEmits({
  'request-url': (url) => { return url },
  'set-linked-source': (val) => { return val },
  'init-selected-source': (source) => { return source },
  'selected-source': (source) => { return source },
})

onMounted(async () => {
  window.onresize = () => {
    windowWidth.value = window.innerWidth
  }
  webcamDevices.value = await navigator.mediaDevices?.enumerateDevices();
})

const isMobile = computed(() => {
  if (windowWidth.value > 767) {
    return false
  } else {
    return !props.transcoderView
  }
})

const audioDevices = computed(() => webcamDevices.value?.filter((device) => device.kind === 'audioinput'))
const videoDevices = computed(() => webcamDevices.value?.filter((device) => device.kind === 'videoinput'))
const defaultAudioDevice = computed(() => audioDevice.value || audioDevices.value.find((device) => device.deviceId === 'default') || audioDevices.value[0])
const defaultVideoDevice = computed(() => videoDevice.value || videoDevices.value[0])

const setDefaultAudioDevice = (device) => {
  audioDevice.value = device
  audioDeviceDropdown.value.toggleDropdown()
  console.log(device);
}
const setDefaultVideoDevice = (device) => {
  videoDevice.value = device
  cameraDeviceDropdown.value.toggleDropdown()
}

const activeTab = ref('sound')

const setActivetab = (tabName) => activeTab.value = tabName

</script>
<template>
  <!-- Webcam source tabs -->
  <div class="flex flex-wrap w-full mt-3 mb-2" v-if="!isMobile">
    <TabGroup type="lines" class="w-full">
      <TabItem type="line" label="Sound" :isActive="activeTab === 'sound'" @click="setActivetab('sound')" />
      <TabItem type="line" label="Camera" :isActive="activeTab === 'camera'" @click="setActivetab('camera')" />
    </TabGroup>
    <div class="w-full" v-if="audioDevices?.length && (!hasPullSource)">
      <section v-if="activeTab === 'sound'">
        <h6 class="text-sm text-surface-8 mt-2 flex items-center gap-x-2 mb-1">
          <icon-base width="16px" height="16px" fill="transparent">
            <icon-mic />
          </icon-base>
          Input device
        </h6>
        <DropDownMenu type="secondary" :disabled="streamAlive" class="md:mb-6" ref="audioDeviceDropdown" full-width wrapperClass="min-w-[240px]"
          maxHeight="64">
          <template #toggle-button>
            <span class="truncate">{{ defaultAudioDevice.label }}</span>
            <icon-base class="ml-auto">
              <icon-chevron-up-down />
            </icon-base>
          </template>
          <template #menu-items>
            <DropDownMenuItem v-for="(device, index) in audioDevices" :key="index"
              @click="setDefaultAudioDevice(device)" :disabled="streamAlive">
              <icon-base v-if="defaultAudioDevice.label === device.label" class="text-success">
                <icon-checkmark />
              </icon-base>
              <span>{{ device.label }}</span>
            </DropDownMenuItem>
          </template>
        </DropDownMenu>
      </section>
      <section v-if="activeTab === 'camera'">
        <h6 class="text-sm text-surface-8 mt-2 flex items-center gap-x-2 mb-1">
          <icon-base>
            <icon-camera />
          </icon-base>
          Camera
        </h6>
        <DropDownMenu type="secondary" :disabled="streamAlive" class="md:mb-6" ref="cameraDeviceDropdown" full-width wrapperClass="min-w-[240px]"
          maxHeight="64">
          <template #toggle-button>
            <span class="truncate">{{ defaultVideoDevice.label }}</span>
            <icon-base class="ml-auto">
              <icon-chevron-up-down />
            </icon-base>
          </template>
          <template #menu-items>
            <DropDownMenuItem v-for="(device, index) in videoDevices" :key="index"
              @click="setDefaultVideoDevice(device)" :disabled="streamAlive">
              <icon-base v-if="defaultVideoDevice.label === device.label" class="text-success">
                <icon-checkmark />
              </icon-base>
              <span>{{ device.label }}</span>
            </DropDownMenuItem>
          </template>
        </DropDownMenu>
      </section>
    </div>
  </div>
  <div class="flex flex-wrap" v-else>
    <div class="w-full">
      <div class="text-center py-2">
        <img class="d-inline-block mx-auto mb-2" src="@/assets/images/no-access.svg" alt="No Access">
        <p class="text-s-ss text-dark-8 text-400">Webcam access feature <br>is not available on mobile.</p>
      </div>
    </div>
  </div>
</template>