<script setup>
import { computed, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import TabGroup from '../../Atoms/TabGroup.vue';
import TabItem from '../../Atoms/TabItem.vue';
import CirclePing from '../../Atoms/CirclePing.vue';

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  selectedPreviewTab: {
    type: String
  },
  playbackClicked: {
    type: Boolean,
    default: false,
  },
  transcoderView: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['show-stream-toggle-modal'])

const onSelectTab = (tabName) => emit('select-option-tab', tabName)

const tabItemClass = (isActive) => `text-base font-medium h-10 py-2 border-b-2 hover:border-white hover:text-white text-surface-9 hover:no-underline text-nowrap leading-none flex items-center w-full capitalize mx-auto -mb-px justify-center relative ${isActive ? 'text-white border-white' : 'border-transparent'}`

</script>
<template>
  <TabGroup type="lines" class="w-full bg-surface-4 !rounded-none">
    <TabItem class="w-1/3" :customClasses="tabItemClass(selectedPreviewTab === 'source-setup')"
      @click="onSelectTab('source-setup')" :isActive="selectedPreviewTab === 'source-setup'">Source Setup</TabItem>
    <TabItem class="w-1/3" v-if="!transcoderView" :customClasses="tabItemClass(selectedPreviewTab === 'playback')"
      @click="onSelectTab('playback')" :isActive="selectedPreviewTab === 'playback'">
      Playback
      <CirclePing class="!absolute right-3"
        v-if="streamAlive && stream.enabled && (selectedPreviewTab != 'playback') && !playbackClicked" />
    </TabItem>
    <TabItem class="w-1/3" v-if="!transcoderView" :customClasses="tabItemClass(selectedPreviewTab === 'monitoring')"
      @click="onSelectTab('monitoring')" :isActive="selectedPreviewTab === 'monitoring'">Monitoring</TabItem>
  </TabGroup>
</template>