<script setup>
import { computed, ref } from 'vue'
import IconBase from '../icon/IconBase.vue';
import IconInfo from '../icon/IconInfo.vue';
import IconAlertTriangle from '../icon/IconAlertTriangle.vue';
import IconError from '../icon/IconError.vue';

const isVisible = ref(true)

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  hideIcon: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'normal'
  },
  text: {
    type: String,
    default: '',
  }
})

const toggleView = () => {
  isVisible.value = !isVisible.value
}
const computedClass = computed(() => {
  switch (props.type) { 
    case 'normal': 
      return 'bg-surface-3 text-surface-8'
    case 'error':
      return 'bg-error text-white'
    case 'warning':
      return 'bg-warning text-white'
    case 'warning2':
      return 'alert-warning'
    default: return ''
  }
})
const computedIcon = computed(() => {
  switch (props.type) { 
    case 'normal': 
      return IconInfo
    case 'error':
      return IconError
    case 'warning':
    case 'warning2':
      return IconAlertTriangle
    default: return 
  }
})
defineExpose({ toggleView, isVisible })
</script>
<template>
  <Transition name="collapse">
    <section class="collapse-wrapper flex text-tiny !p-2 rounded-md leading-4" :class="computedClass" v-if="isVisible">
      <icon-base v-if="!hideIcon" class="shrink-0 text-white !mr-2">
        <component :is="computedIcon" />
      </icon-base>
      {{ text }}
      <slot />
    </section>
  </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.alert-warning {
  background-color: var(--c-warning-bg);
  border-width: 0 0 0 6px;
  border-color: var(--c-warning);
  border-radius: 6px;
  color: #fff;
}
</style>