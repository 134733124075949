<script setup>
import { ref, computed } from 'vue'
import moment from "moment";

import { useStore } from 'vuex'

import { date, duration } from '@/filtersNew'

import FormButton from '../Atoms/FormButton.vue';
import FormInputDuration from '../Atoms/FormInputDuration.vue';
import IconBase from '../icon/IconBase.vue';
import IconCameraFilled from '../icon/IconCameraFilled.vue';
import IconFilm from "../icon/IconFilm.vue";
import IconBreak from "../icon/IconBreak.vue";
import IconHandleVertical from "../icon/IconHandleVertical.vue";
import IconCross from "../icon/IconCross.vue";
import IconEdit from '../icon/IconEdit.vue';
import EditDurationModal from '../modals/EditDurationModal.vue';

const store = useStore()

const props = defineProps({
  item: {
    type: Object,
  },
})

const isVisible = ref(false)

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])

const isDurationShow = ref(true)

const deleteItem = async (item) => {
  await store.dispatch('Playout/deleteItem', item)
}

const durationChanged = (value) => {
  store.dispatch('Playout/changeItemDuration', { item: props.item, value })
}

const isPlaying = computed(() => {
  return props.item.startTime < moment().valueOf() && moment().valueOf() < props.item.endTime
})
const sourceIconComputed = (item) => {
  if (item.sourceType === 'live') return IconCameraFilled
  if (item.sourceType === 'vod') return IconFilm
  if (item.sourceType === 'break') return IconBreak
}
const minTimeComputed = computed(() => {
  if (props.item.sourceType === 'break') return { hours: 0, minutes: 2, seconds: 0 }
  return { hours: 0, minutes: 10, seconds: 0 }
})

const showEditDurationModal = () => isVisible.value = true

</script>

<template>
  <div class="flex h-12 items-center text-surface-9 relative"
    :class="{ 'border-l-2 border-success bg-gradient-to-r from-[#74DE851F] to-[#74DE8500] ': isPlaying }">
    <div class="truncate flex items-center" :class="playoutConfig?.mode === 'schedule' ? 'basis-5/12' : 'basis-9/12'">
      <span v-if="isPlaying" class="text-success text-[8px] absolute top-px left-2">Playing</span>
      <icon-base class="mx-2 shrink-0 text-surface-6 cursor-grab !hidden md:!block">
        <icon-handle-vertical />
      </icon-base>
      <icon-base class="mr-1 shrink-0">
        <component v-if="item.sourceType !== 'break'" :is="sourceIconComputed(item)" />
      </icon-base>
      <div class="truncate" :title="item.sourceName">{{ item.sourceName }}</div>
    </div>
    <div v-if="playoutConfig?.mode === 'schedule'" class="basis-3/12">
      {{ date(item.startTime, 'M/DD/YYYY, HH:mm:ss', true) }}
    </div>
    <div class="basis-3/12" v-if="item.sourceType === 'vod'">{{ duration(item.endTime - item.startTime) }}</div>
    <div v-else-if="item.sourceType === 'live'" class="basis-3/12">
      <FormInputDuration :minTime="minTimeComputed" :minHours="24" :show="isDurationShow" :id="'duration' + item._id"
        :modelValue="duration(item.endTime - item.startTime)" @update:modelValue="durationChanged" />
    </div>
    <div class="basis-3/12 group flex items-center leading-none" v-else>
      {{ duration(item.endTime - item.startTime) }}
      <FormButton isIcon size="xs" class="opacity-30 group-hover:opacity-100 !text-surface-7 hover:!text-white -mt-1" type="link" @click="showEditDurationModal">
        <icon-base>
          <icon-edit />
        </icon-base>
      </FormButton>
    </div>
    <div class="basis-1/12 flex justify-end">
      <FormButton class="" isIcon type="link" @click="deleteItem(item)">
        <icon-base>
          <icon-cross />
        </icon-base>
      </FormButton>
    </div>
    <EditDurationModal v-model:is-visible="isVisible" :item="item" />
  </div>
</template>
<style scoped></style>
