<script setup>
import { computed, ref, useTemplateRef, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from "lodash";
import Spinner from "@/components/ui/Spinner.vue";
import IconBase from '@/components/icon/IconBase.vue'
import IconPlus from '@/components/icon/IconPlus.vue'
import IconLightning from '@/components/icon/IconLightning.vue'
import IconChevronDown from '@/components/icon/IconChevronDown.vue'
import IconSearch from '@/components/icon/IconSearch.vue'
import AddSubSecondStreamModal from '@/components/modals/AddSubSecondStreamModal.vue'
import StreamCardView from '@/components/ui/StreamCardView.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import Tooltip from '../../components/Atoms/Tooltip.vue';
import StreamService from '@/services/StreamService'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue'
import FormButton from '../../components/Atoms/FormButton.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import ViewModeSwitcher from '../../components/ui/ViewModeSwitcher.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import CollapseContainer from '../../components/Atoms/CollapseContainer.vue';
import Badge from '../../components/Badge.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const searchWidgetActive = ref(false)
const searchWidgetFocused = ref(false)
const searchWidgetInput = ref('')
const isShown = ref(true)
const deleteModalConfiguredStream = ref(null)
const deleteProcessing = ref(false)
const viewMode = ref('grid')

const searchWidgetInputElement = ref(null)
const sssList = ref(null)

const loadingStatus = computed(() => store.getters["Ui/loadingStatus"]);
const subSecondStreams = computed(() => store.getters["Streams/subSecondStreams"]);
const sortBy = computed(() => store.getters["Streams/sortBy"]);
const hasLiveSubscription = computed(() => store.getters["User/hasLiveSubscription"]);
const maxSubSecondStreams = computed(() => store.getters["User/maxSubSecondStreams"]);

const shouldShowCompactModeStreams = computed(() => viewMode.value != 'grid')

const subSecondStreamsFiltered = computed(() => {
	const searchStr = searchWidgetInput.value.toLowerCase();
	const result = subSecondStreams.value.filter(stream => stream.name.toLowerCase().includes(searchStr))
	return result
})

watch(
	() => hasLiveSubscription,
	async () => {
		store.dispatch('Ui/setLoadingStatus', true)
		await store.dispatch('Streams/getStreams')
		store.dispatch('Ui/setLoadingStatus', false)
	}, {
	immediate: true
}
)

const toggleCollapse = () => {
	isShown.value = !isShown.value
	sssList.value.toggleView()
}

const toggleSearchWidget = () => {
	searchWidgetActive.value = !searchWidgetActive.value;
	searchWidgetInputElement.value.toggleFocus();
	if (!searchWidgetActive.value) {
		searchWidgetInput.value = '';
	}
}

const onSearchWidgetFocus = () => {
	searchWidgetFocused.value = true;
}

const onSearchWidgetBlur = () => {
	searchWidgetFocused.value = false;
	setTimeout(() => {
		const blankSearchVal =
			!searchWidgetInput.value || !searchWidgetInput.value.length;
		if (
			searchWidgetActive.value &&
			blankSearchVal &&
			!searchWidgetFocused.value
		) {
			toggleSearchWidget();
		}
	}, 500);
}

const changeSortBy = (val) => {
	store.dispatch('Streams/setSortOption', val)
}


const toggleView = (view) => {
	viewMode.value = view
}

const onNewStream = (substream) => {
	router.push({ path: '/sub-second/' + substream._id + '/dashboard' })
}

const onStreamDeleteRequest = (stream) => {
	deleteModalConfiguredStream.value = stream;
	root.$emit('bv::show::modal', 'delete-stream');
}

const onStreamDeleteConfirm = async () => {
	// pop stream out of the list
	deleteProcessing.value = true;
	try {
		await StreamService.deleteStream(deleteModalConfiguredStream.value._id);
		await store.dispatch('Streams/getStreams')
		// track event
		const removedStream = deleteModalConfiguredStream.value
		window.trackEvent(
			`Deleted stream ${removedStream.name}`,
			removedStream
		);
	} catch (err) {
		notify({ group: 'error', title: err.error, text: err.message });
		// push stream back to list
		onNewStream(deleteModalConfiguredStream.value);
	}

	deleteModalConfiguredStream.value = null
	deleteProcessing.value = false
	notify({
		group: 'success',
		text: 'Deleted successfully'
	})
}

const showCreateSubSecondStreamModal = () => root.$emit('bv::show::modal', 'modal-add-sub-second-stream')
const showTutorialModal = () => root.$emit('bv::show::modal', 'modal_tutorial');
</script>

<template>
	<div class="container page-title-area">
		<header class="mt-5">
			<div class="title flex justify-between items-center mb-4">
				<h1 class="page-title mb-0">Sub Second Streaming</h1>
				<Tooltip v-if="subSecondStreamsFiltered.length >= maxSubSecondStreams" :hide="1000">
					<FormButton size="lg" :disabled="memberPermission && !memberPermission.liveStreamManage">
						<icon-base icon-name="plus" classIcon="mr-2"><icon-plus /></icon-base>
						New Sub Second Stream
					</FormButton>
					<template #content>
						<span class="text-surface-8 text-sm">
							Want more streams? Contact <a class="text-reset text-underline" href="#"
								onclick="Intercom('show')">sales</a>.</span>
					</template>
				</Tooltip>
				<FormButton v-else-if="!loadingStatus && hasLiveSubscription.enabled && !hasLiveSubscription.trial" size="lg"
					@click="showCreateSubSecondStreamModal" :disabled="memberPermission && !memberPermission.liveStreamManage">
					<icon-base icon-name="plus" classIcon="mr-2"><icon-plus /></icon-base>
					New Sub Second Stream
				</FormButton>
			</div>
		</header>
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-surface-8 mh-60"
			spinner-color="var(--c-surface-8)" spinner-size="15px" />
		<div v-else-if="subSecondStreams && subSecondStreams.length === 0"
			class="mt-5 text-center flex flex-col col-3 mx-auto items-center">
			<img class="mb-4" width="100" src="@/assets/empty-playout-placeholder.png" alt="Playout Line Up">
			<div v-if="hasLiveSubscription.enabled && maxSubSecondStreams > 0">
				<h3 class="text-s-m text-surface-9">There are no sub second streams here!</h3>
				<p class="mb-4 text-s-s text-surface-7">Fortunately, it’s very easy to create one</p>
				<FormButton type="secondary" @click="showTutorialModal" class="mb-3 w-full flex items-center justify-center gap-x-2">
					<div class="rounded-full !border !border-surface-8 size-4 flex items-center justify-center">
						<icon-base iconColor="none"
							class="text-dark-8">
							<icon-lightning />
						</icon-base>
					</div>
					Sub second stream demo
				</FormButton>
				<FormButton :disabled="subSecondStreamsFiltered.length >= maxSubSecondStreams"
					@click="showCreateSubSecondStreamModal" class="w-full flex items-center justify-center gap-x-2">
					<div class="rounded-full !border !border-surface-8 size-4 flex items-center justify-center">
						<icon-base iconColor="none"
							class="text-dark-8">
							<icon-lightning />
						</icon-base>
					</div>
					Create sub second stream
				</FormButton>
			</div>
			<div v-else>
				<h3 class="text-base text-surface-9 mb-3">The Sub-Second Streams feature is not included in your current subscription
					plan</h3>
				<FormButton to="/subscribe?category=live" class="mb-3" size="lg">
					Upgrade
				</FormButton>
			</div>
		</div>
		<div v-else>
			<section class="mt-5">
				<div class="toolbar-container tab-settings">
					<div class="flex w-full !flex-wrap md:!flex-nowrap items-center my-3">
						<div class="mr-0 mr-md-2 mb-2 mb-md-0 filter-wrapper !order-1 md:!order-0">
							<drop-down-menu ref="sortDropdown" class="" type="secondary"
								customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize text-nowrap">
								<template #toggle-button>
									{{ sortBy }}
									<icon-base class="ml-2">
										<icon-chevron-down />
									</icon-base>
								</template>
								<template #menu-items>
									<drop-down-menu-item @click="changeSortBy('Sort by Oldest')">
										Sort by Oldest
									</drop-down-menu-item>
									<drop-down-menu-item @click="changeSortBy('Sort by Newest')">
										Sort by Newest
									</drop-down-menu-item>
									<drop-down-menu-item @click="changeSortBy('A to Z')">
										A to Z
									</drop-down-menu-item>
									<drop-down-menu-item @click="changeSortBy('Z to A')">
										Z to A
									</drop-down-menu-item>
								</template>
							</drop-down-menu>
						</div>
						<div class="search-form flex !order-0 md:!order-1 !mb-3 md:!mb-0 w-full">
							<FormButton isIcon type="link" @click="toggleSearchWidget">
								<icon-base class="ml-2">
									<icon-search />
								</icon-base>
							</FormButton>
							<FormInput ref="searchWidgetInputElement" :class="searchWidgetActive ? 'opacity-100 active': 'opacity-0'"  v-model="searchWidgetInput"  :placeholder="searchWidgetActive ? 'Search for streams ..' : ''"
								class="search-form__field"></FormInput>
						</div>
						<ViewModeSwitcher class="ml-auto order-3" @toggle-view="toggleView" />
					</div>
				</div>
			</section>
			<section>
				<FormButton class="gap-x-2" type="link" @click="toggleCollapse">
					<icon-base class="transition-all" :class="isShown ? 'rotate-0' : '-rotate-90'">
						<icon-chevron-down />
					</icon-base>
					<icon-base iconColor="none" class="text-primary" icon-name="Sub Second">
						<icon-lightning />
					</icon-base>
					<span class="text-lg">Sub Second Streams</span>
					<Badge id="stream_count_-sub-stream" pill>
						{{ subSecondStreamsFiltered.length
						}}/{{ maxSubSecondStreams }}
					</Badge>
				</FormButton>
				<CollapseContainer class="mt-3" ref="sssList" :is-open="isShown">
					<div class="grid gap-3" :class="shouldShowCompactModeStreams ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-3'">
						<div
							v-for="stream in subSecondStreamsFiltered" :key="stream._id" class="grid-item">
							<stream-card-view :stream="stream" :compact-mode="shouldShowCompactModeStreams"
								@delete-stream="onStreamDeleteRequest" />
						</div>
					</div>
				</CollapseContainer>
			</section>
		</div>
		<add-sub-second-stream-modal @new-channel="onNewStream" />
		<demo-video-modal modalId="modal_tutorial" videoid="48ofjE3WZ064-SuIElpIr" />
		<confirm-modal message="Would you like to delete this stream and all of its content?" modal-id="delete-stream"
			modal-type="danger" @modal-confirm="onStreamDeleteConfirm" />
	</div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-form__toggle:hover {
	cursor: pointer;
}

.active .search-form__toggle {
	z-index: 9;
}

.search-form__field {
	border: none;
	width: 0;
	flex: 0 0 0;
	transition: all .2s ease-out;
	padding-left: 0;
	padding-right: 0;
	caret-color: var(--c-main-light);
}

.search-form__field::placeholder {
	color: var(--c-dark-6);
}

.input-group>.search-form__field {
	border-radius: 6px;
}

.active.search-form__field {
	width: 100%;
	max-width: 620px;
	flex: 1 1 auto;
	transition: all .3s ease-out;
	@apply !rounded-md ring-1 ring-main
}

.search-form__close {
	visibility: hidden;
}

.active .search-form__close {
	visibility: visible;
}
</style>
