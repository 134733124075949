<script setup>
import { computed, ref, onMounted, onUnmounted, getCurrentInstance, watch } from 'vue'
import _ from 'lodash'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import UserService from '@/services/UserService';
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconChevronLeft from '../icon/IconChevronLeft.vue';
import MainSidebarLogo from './MainSidebarLogo.vue';
import IconChevronUpDown from '../icon/IconChevronUpDown.vue';
import IconHandleVertical from '../icon/IconHandleVertical.vue'
import IconCross from '../icon/IconCross.vue'
import MainSidebarNavList from './MainSidebarNavList.vue'
import DropDownMenu from '../Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../Atoms/DropDownMenuItem.vue';
import AlertModal from "@/components/modals/AlertModal.vue";
import Sidebar from '../modals/Sidebar.vue'

const props = defineProps({
  mediaPulse: {
    type: Object,
  },
})

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()

const memberDetails = computed(() => store.getters['User/memberDetails'])
const userLoaded = computed(() => store.getters['User/userLoaded'])
const activeTeam = computed(() => store.getters['User/activeTeam'])
const userId = computed(() => store.getters['User/userId'])
const subscriptions = computed(() => store.getters['User/subscriptions'])
const expanded = computed(() => store.getters['Ui/sidebarExpanded'])

const selectedTeamComputed = computed(() => {
  return (expanded.value && !isMobile.value) ? selectedTeam.value : selectedTeam.value?.substring(0, 1)
})

const dropdownClassComputed = computed(() => {
  return expanded.value
    ? `!w-full inline-flex items-center !rounded-md leading-none text-nowrap box-border font-medium hover:no-underline disabled:bg-surface-5 disabled:ring-surface-5 disabled:text-surface-7 max-w-full truncate grow-0 py-2 !px-3 h-8 text-tiny text-surface-text-1 hover:text-white disabled:text-surface-6 hover:ring-1 hover:ring-surface-2`
    : `size-8 inline-flex items-center justify-center !rounded-full bg-surface-3 hover:ring-1 hover:ring-surface-5 leading-none text-nowrap box-border font-medium hover:no-underline disabled:bg-surface-5 disabled:ring-surface-5 disabled:text-surface-7 max-w-full truncate grow-0 !p-1 text-tiny text-surface-text-1 hover:text-white disabled:text-surface-6`

})
const teamDropdown = ref(null)
const selectedTeam = ref(null)
const isBillingAccess = ref(false)
const userInformation = ref(null)
const user = ref(null)

const mobileSidebar = ref(null)

const toggleSidebar = () => {
  if (isMobile.value) {
    store.dispatch('Ui/expandSidebar');
    mobileSidebar.value.toggleSidebar()
  } else {
    store.dispatch('Ui/toggleSidebarExpanded');
  }
}

const windowWidth = ref(window.innerWidth);

const updateWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(async () => {
  window.addEventListener('resize', updateWidth);
  await store.dispatch('User/getinfoMember', null, { root: true });
  user.value = await UserService.getUser(activeTeam.value)
  checkAccess()
  if (!activeTeam.value) {
    selectedTeam.value = 'Your Dashboard';
  }
})

// Remove event listener on unmount
onUnmounted(() => {
  window.removeEventListener('resize', updateWidth);
});

const isMobile = computed(() => windowWidth.value < 768)

watch(() => [activeTeam.value, userLoaded.value], async () => {
  if (userLoaded.value) {
    await setUser()
    await setTeam()
  }
})

watch(() => store.state.User?.memberDeleted, async () => {
  setDefaultTeam();
  root.$emit("bv::show::modal", "deleted-member");
})

watch(() => store.state.User?.memberDisabled, async () => {
  setDefaultTeam();
  root.$emit("bv::show::modal", "disabled-paused");
})

watch(() => subscriptions.value, async () => {
  checkSubscriptions()
}, { deep: true })

watch(() => route.path, async () => {
  mobileSidebar.value?.closeSidebar()
})
const checkAccess = async () => {
  if (activeTeam.value && store.state.User.memberDeleted) {
    setDefaultTeam();
    root.$emit("bv::show::modal", "deleted-member");
  }

  if (activeTeam.value && store.state.User.memberDisabled && !store.state.User.memberDeleted) {
    setDefaultTeam();
    root.$emit("bv::show::modal", "disable-paused");
  }
  checkSubscriptions()
}
const checkSubscriptions = () => {
  if (activeTeam.value && (subscriptions.value?.length > 0) && !store.state.User.memberDisabled && !store.state.User.memberDeleted) {
    const ownerSub = subscriptions.value.filter((s) => {
      return s.enabled;
    });
    let numofTeamallowed = checkOwnerTeamNum();
    if (ownerSub.length == 0 || !numofTeamallowed || numofTeamallowed == 0) {
      setDefaultTeam();
      console.log('show modal here');
      root.$emit("bv::show::modal", "expired-owner");
    }
  }
}

const checkOwnerTeamNum = () => {
  let numofTeam = []
  _.map(subscriptions.value, function (single) {
    if (single.enabled) {
      let streamCountoverride = _.get(single, ['definitionOverride', 'numOfTeamMembers']);
      if (streamCountoverride) {
        numofTeam.push(streamCountoverride);
      } else {
        numofTeam.push(_.get(single, ['package', 'definition', 'numOfTeamMembers']));
      }
    }
  })

  numofTeam = numofTeam.filter(function (el) {
    if (el && el != 0) {
      return el == +el;
    }
  });

  if (numofTeam.length) {
    return (numofTeam.length > 1 ? Math.max.apply(Math, numofTeam) : numofTeam[0])
  } else {
    return null;
  }
}

const changeTeam = async (name, id) => {
  root.$emit('teamchanging', true);
  await UserService.selectTeam(id);
  await UserService.getUserInfo();
  await store.dispatch('User/setdefaultUserstate', null, { root: true });
  await store.dispatch('User/getinfoOwner', null, { root: true });
  await store.dispatch('User/getinfoMember', null, { root: true });
  await store.dispatch('User/loadUser', null, { root: true });
  await UserService.setOwnerSession(store.state.User.ownermember);
  root.$emit('teamchanged', true);
  teamDropdown.value.toggleDropdown()
  if ((window.location.href.indexOf("/app/paywall") > -1) || window.location.href.indexOf("/billing") > -1 || window.location.href.indexOf("/subscribe") > -1) {
    window.location.reload();
  }
  const redirect = checkRedirect();
  if (redirect) {
    router.push({ path: '/' });
  }
}
const checkRedirect = () => {
  const parts = window.location.pathname.split('/');
  let redirect = false;
  parts.map(function (p) {
    if ((p == 'multistreams') || (p == 'scheduled') || (p == 'videos') || (p == 'livestreams')) {
      redirect = true;
    }
  });
  return redirect;
}

const setUser = async () => {
  const userInfo = store.state.User;
  isBillingAccess.value = _.isBoolean(userInfo.isBillingAccess) ? userInfo.isBillingAccess : true;
  if (!userInfo.activeTeam) {
    userInformation.value = userInfo
  } else {
    const ownerUserInfo = await UserService.getTeamOwner(userInfo.activeTeam)
    ownerUserInfo.owner._id = userInfo._id;
    ownerUserInfo.owner.activeTeam = userInfo.activeTeam;
    userInformation.value = ownerUserInfo.owner;
  }
  // this.checkMenu(userInfo.activeTeam);
}

const setTeam = async () => {
  user.value = await UserService.getUser(activeTeam.value?.id);
  if (!activeTeam.value) {
    selectedTeam.value = 'Your Dashboard';
  } else {
    const loadActiveteam = await UserService.getMyTeam();
    selectedTeam.value = loadActiveteam.name;
  }
  // setTimeout(() => {
  //   this.processing = true;
  // }, 2000);
}

const setDefaultTeam = async () => {
  const me = store.state.User;
  await UserService.selectTeam(me._id);
  await UserService.setOwnerSession(null);
}

const redirect = async () => {
  setTimeout(() => {
    router.push({ path: '/' });
    window.location.reload();
  }, 1000);
}

</script>

<template>
  <aside
    class="h-svh !border-r !border-surface-2 shrink-0 sticky z-20 top-0 !py-4 md:!pt-11 relative transition-all ease-in-out bg-surface-2 shadow md:bg-transparent md:shadow-none"
    :class="expanded ? 'w-full md:w-[260px] basis-full md:basis-[260px] !px-4' : 'w-16 basis-16'">
    <FormButton isIcon type="link" class="absolute top-4 transition-all opacity-50 hover:opacity-100 !hidden md:!block"
      size="sm" :class="expanded ? 'rotate-0 -right-2' : 'rotate-180 right-1'" @click="toggleSidebar">
      <icon-base class="" fill="none">
        <icon-chevron-left />
      </icon-base>
    </FormButton>
    <div class="flex !flex-row items-center w-full md:!flex-col md:!items-start">
      <MainSidebarLogo class="mx-auto md:mb-6 order-2 md:order-first" :expanded="expanded" />
      <DropDownMenu v-if="memberDetails.length > 0" class="md:mb-6" :class="{'mx-auto': !expanded}" ref="teamDropdown" full-width :customClasses="dropdownClassComputed"
        wrapperClass="min-w-[240px]" maxHeight="64">
        <template #toggle-button>
          <span class="truncate">{{ selectedTeamComputed }}</span>
          <icon-base v-if="expanded" class="ml-auto">
            <icon-chevron-up-down />
          </icon-base>
        </template>
        <template #menu-items>
          <DropDownMenuItem :active="selectedTeam === 'Your Dashboard'" @click="changeTeam('Your Dashboard', userId)">
            <span>Your Dashboard</span>
          </DropDownMenuItem>
          <DropDownMenuItem v-for="team in memberDetails" :key="team.id" @click="changeTeam(team.name, team.id)"
            :active="selectedTeam === team">
            <span>{{ team.name }}</span>
          </DropDownMenuItem>
        </template>
      </DropDownMenu>
      <FormButton isIcon type="link" class="transition-all !block md:!hidden order-3" size="sm" @click="toggleSidebar">
        <icon-base class="" fill="none">
          <icon-handle-vertical />
        </icon-base>
      </FormButton>
    </div>

    <Sidebar v-if="isMobile" ref="mobileSidebar" :stage="1" stageOneCusstomClass="!mt-6 h-svh" hide-header>
      <template v-slot:stage-one>
        <div class="flex items-center justify-center w-full bg-surface-3 shadow h-[72px]">
          <MainSidebarLogo class="relative top-0 left-4" :expanded="expanded" />
          <form-button class="absolute right-5 top-5" type="link" isIcon @click="toggleSidebar">
            <icon-base iconColor="none" class="">
              <icon-cross />
            </icon-base>
          </form-button>
        </div>
        <div class="my-6">
          <MainSidebarNavList :expanded="expanded" />
        </div>
      </template>
    </Sidebar>
    <MainSidebarNavList v-else :expanded="expanded" />

    <alert-modal description="Access required" modal-id="deleted-member"
      message="Access required! You no longer have access permission for this team. Keep on streaming in your own dashboard!"
      ok-text="Got It" @modal-confirm="redirect" />
    <alert-modal description="Access required" modal-id="disabled-paused"
      :message="'Access required! You don’t have access to the &quot;' + ((store.state.User?.guestData?.teamName) ? store.state.User.guestData.teamName : '') + '&quot;. Contact the team’s organizer (' + ((store.state.User?.guestData?.ownersEmail) ? store.state.User?.guestData?.ownersEmail : '') + ') for details.'"
      ok-text="Got It" @modal-confirm="redirect" />
    <alert-modal description="Team's account is expired" modal-id="expired-owner"
      :message="'You cannot access &quot;' + ((store.state.User?.guestData?.teamName) ? store.state.User?.guestData?.teamName : '') + '&quot; because it has been expired. Contact the team owner at (' + ((store.state.User?.guestData?.ownersEmail) ? store.state.User?.guestData?.ownersEmail : '') + ') to reactivate account.'"
      ok-text="Ok, go to my dashboard instead" @modal-confirm="redirect" />
    <alert-modal description="Access required" modal-id="downgraded-owner"
      :message="'You cannot access &quot;' + ((store.state.User?.guestData?.teamName) ? store.state.User?.guestData?.teamName : '') + '&quot; because it no longer have access permission. Contact the team owner at (' + ((store.state.User?.guestData?.ownersEmail) ? store.state.User?.guestData?.ownersEmail : '') + ') for details.'"
      ok-text="Ok, go to my dashboard instead" @modal-confirm="redirect" />
  </aside>
</template>

<style scoped></style>
