<script setup>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'
import _ from 'lodash'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconSearch from '../icon/IconSearch.vue';
import IconCross from '../icon/IconCross.vue';
import IconPlaylist from '../icon/IconPlaylist.vue';
import IconPlus from '../icon/IconPlus.vue';
import FormInput from '../Atoms/FormInput.vue';

const store = useStore()

const emit = defineEmits({
	'add-playlist': (value) => { return value }
})

const playlists = computed(() => store.getters['Playlist/playlist'])

const search = ref('')

const playlistsFiltered = computed(() => {
	const playlistList = _.cloneDeep(playlists.value.filter(playlist => playlist.name.toLowerCase().includes(search.value.toLowerCase())))
	return playlistList.sort(function (a, b) {
		return new Date(b.creationTime) - new Date(a.creationTime)
	})
})

const clearSearch = () => search.value = ''

const addFile = (file) => {
	emit('add-playlist', file)
}

</script>

<template>
	<section v-if="playlistsFiltered.length === 0" class="min-h-28">
		<h3 class="text-sm text-surface-8 font-medium text-center mt-5">You have no playlists yet</h3>
		<p class="text-center my-3">
			<FormButton to="/playlists">
				Open Playtlists
			</FormButton>
		</p>
	</section>
	<section v-else>
		<div class="flex items-center mb-3 relative">
			<icon-base class="absolute z-10 left-2 text-surface-7">
				<icon-search />
			</icon-base>
			<FormInput customClasses="!px-8" v-model="search" />
			<FormButton class="absolute z-10 right-0" isIcon type="link" v-if="search" @click="clearSearch">
				<icon-base>
					<icon-cross />
				</icon-base>
			</FormButton>
		</div>
		<slot />
		<ul class="flex flex-col divide-y divide-surface-3 max-h-[400px] overflow-y-auto overflow-x-hidden">
			<li v-for="playlistList in playlistsFiltered" :key="playlistList._id" :title="playlistList.name"
				class="hover:bg-surface-3 flex items-center py-1 gap-x-2 px-2">
					<icon-base class="shrink-0" :icon-name="playlistList.name">
						<icon-playlist />
					</icon-base>
					<span class="text-sm grow-0 truncate">{{ playlistList.name }}</span>
					<FormButton size="sm" isIcon class="shrink-0 ml-auto" @click="addFile(playlistList)">
						<icon-base>
							<icon-plus />
						</icon-base>
					</FormButton>
			</li>
		</ul>
	</section>
</template>

<style scoped></style>