<template>
  <Spinner
    v-if="loaderShow"
    text="Retrieving data..."
    classes="text-dark-8 mt-6"
    spinner-color="var(--c-dark-8)"
    spinner-size="15px"
  />
  <div class="pt-4 flex justify-center ml-5" v-else-if="!viewsData.length">
    <div>
      <svg
        width="95"
        height="95"
        viewBox="0 0 95 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="47.3023" cy="47.5" rx="47.3023" ry="47.5" fill="#232D4D" />
        <path
          d="M95 47.5C95 61.3946 88.9711 73.8005 79.3841 82.3358H2.58981C1.60147 82.3358 0.810791 81.5418 0.810791 80.5494V16.0385C0.810791 15.0461 1.60147 14.2521 2.58981 14.2521H80.8667C89.5641 22.4896 95 34.3993 95 47.5Z"
          fill="#232D4D"
        />
        <path
          d="M79.2852 82.2367H79.384C80.6688 81.0457 81.8548 79.8547 83.0409 78.5645C81.9537 79.954 80.6688 81.145 79.2852 82.2367Z"
          fill="#232D4D"
        />
        <path
          d="M34.5132 54.2488H3.67686C3.18269 54.2488 2.78735 53.8519 2.78735 53.3556V31.1242C2.78735 30.6279 3.18269 30.231 3.67686 30.231H34.5132C35.0074 30.231 35.4027 30.6279 35.4027 31.1242V53.3556C35.4027 53.7526 35.0074 54.2488 34.5132 54.2488Z"
          fill="#404B72"
        />
        <path
          d="M90.8488 28.3453H3.67686C3.18269 28.3453 2.78735 27.9483 2.78735 27.4521V16.9319C2.78735 16.4356 3.18269 16.0386 3.67686 16.0386H82.7444C83.9304 17.3289 85.0176 18.7183 86.1048 20.1078C87.1919 21.5965 88.2791 23.2837 89.1686 24.8717C89.7616 26.0626 90.3546 27.1544 90.8488 28.3453Z"
          fill="#404B72"
        />
        <path
          d="M89.1687 24.9709H75.5296C75.2331 24.9709 75.0354 24.7724 75.0354 24.4747V20.7033C75.0354 20.4055 75.2331 20.207 75.5296 20.207H86.006C87.192 21.6957 88.1804 23.2837 89.1687 24.9709Z"
          fill="#4F5C87"
        />
        <path
          d="M86.7968 23.0851H78.4947C78.1982 23.0851 78.0005 22.8866 78.0005 22.5889C78.0005 22.2911 78.1982 22.0927 78.4947 22.0927H86.7968C87.0933 22.0927 87.2909 22.2911 87.2909 22.5889C87.2909 22.7874 87.0933 23.0851 86.7968 23.0851Z"
          fill="#404B72"
        />
        <path
          d="M68.71 54.2488H37.8736C37.3795 54.2488 36.9841 53.8519 36.9841 53.3556V31.1242C36.9841 30.6279 37.3795 30.231 37.8736 30.231H68.71C69.2042 30.231 69.5995 30.6279 69.5995 31.1242V53.3556C69.6983 53.7526 69.303 54.2488 68.71 54.2488Z"
          fill="#404B72"
        />
        <path
          d="M94.407 40.255C93.814 36.6821 92.9245 33.3077 91.6397 30.1318H72.1693C71.6751 30.1318 71.2798 30.5288 71.2798 31.025V53.2564C71.2798 53.7527 71.6751 54.1497 72.1693 54.1497H94.5059C94.8024 51.9662 95 49.6835 95 47.5001C95 45.5151 94.9012 43.5302 94.6047 41.5452L94.407 40.255Z"
          fill="#404B72"
        />
        <path
          d="M34.5132 80.0532H3.67686C3.18269 80.0532 2.78735 79.6562 2.78735 79.16V56.9286C2.78735 56.4323 3.18269 56.0353 3.67686 56.0353H34.5132C35.0074 56.0353 35.4027 56.4323 35.4027 56.9286V79.16C35.4027 79.6562 35.0074 80.0532 34.5132 80.0532Z"
          fill="#404B72"
        />
        <path
          d="M68.71 80.0532H37.8736C37.3795 80.0532 36.9841 79.6562 36.9841 79.16V56.9286C36.9841 56.4323 37.3795 56.0353 37.8736 56.0353H68.71C69.2042 56.0353 69.5995 56.4323 69.5995 56.9286V79.16C69.6983 79.6562 69.303 80.0532 68.71 80.0532Z"
          fill="#404B72"
        />
        <path
          d="M94.2093 56.0353C94.0116 57.2263 93.7151 58.318 93.4186 59.4098C93.3198 59.8067 93.2209 60.1045 93.1221 60.5015C92.8256 61.5932 92.4303 62.6849 92.0349 63.7766C91.7384 64.5706 91.3431 65.4639 91.0466 66.2578C90.7501 66.8533 90.4536 67.4488 90.1571 68.0443C90.0582 68.342 89.8606 68.6398 89.6629 68.9375C89.0699 70.0292 88.4769 71.121 87.785 72.2127C87.5874 72.5104 87.3897 72.9074 87.0932 73.2052C86.4014 74.1976 85.7095 75.1901 85.0177 76.1826C84.7212 76.5796 84.4247 76.8773 84.1282 77.2743C83.3375 78.2668 82.448 79.2592 81.5585 80.1525H71.9715C71.4774 80.1525 71.082 79.7555 71.082 79.2592V56.9286C71.082 56.4323 71.4774 56.0353 71.9715 56.0353H94.2093Z"
          fill="#404B72"
        />
        <path
          d="M23.0486 69.2352C23.0486 69.3344 23.0486 69.4337 22.9497 69.5329C22.8509 69.7314 22.8509 69.9299 22.8509 70.1284C22.752 70.2276 22.7521 70.3269 22.7521 70.4261C22.6532 70.6246 22.6532 70.8231 22.5544 70.9224V71.0216C22.4555 71.1209 22.4555 71.2201 22.4555 71.2201C22.3567 71.3194 22.3567 71.4186 22.2579 71.4186L24.0369 73.0066C24.1357 72.9073 24.1357 72.8081 24.2346 72.7088C24.4322 72.4111 24.6299 72.1133 24.7287 71.8156C24.8276 71.7163 24.8276 71.6171 24.8276 71.5178C24.9264 71.2201 25.0252 70.9224 25.2229 70.6246C25.2229 70.5254 25.3217 70.4261 25.3217 70.4261C25.4206 70.2276 25.4206 70.0291 25.4206 69.9299C25.4206 69.8306 25.5194 69.7314 25.5194 69.7314C25.5194 69.6321 25.5194 69.5329 25.6182 69.5329V69.4337C25.6182 69.3344 25.6182 69.2352 25.6182 69.2352H23.0486ZM18.008 73.7013C15.2406 73.7013 12.8686 71.4186 12.8686 68.6397C12.8686 65.96 14.8453 63.8758 17.3162 63.578V61.1961C13.5604 61.4939 10.4966 64.769 10.4966 68.7389C10.4966 72.9073 13.857 76.2817 18.008 76.2817C20.0835 76.2817 21.9614 75.4877 23.2462 74.0983L21.4672 72.4111C20.5777 73.3043 19.2928 73.7013 18.008 73.7013ZM18.601 61.0969V63.4788C20.973 63.7765 22.752 65.563 23.0486 67.8457H25.5194C25.2229 64.2728 22.2579 61.3946 18.601 61.0969Z"
          fill="#343E61"
        />
        <path
          d="M25.5195 67.9451H23.1475C22.851 65.5631 20.9731 63.7767 18.7 63.5782V61.1962C22.258 61.3947 25.223 64.2729 25.5195 67.9451Z"
          fill="#4F5C87"
        />
        <path
          d="M25.5193 69.2352C25.5193 69.3345 25.5193 69.4337 25.5193 69.4337V69.533C25.5193 69.6322 25.5193 69.7315 25.4205 69.7315C25.4205 69.8307 25.4205 69.93 25.3217 69.93C25.2228 70.1285 25.2228 70.3269 25.2228 70.4262C25.2228 70.5254 25.124 70.6247 25.124 70.6247C25.0252 70.9224 24.9263 71.2202 24.7287 71.5179C24.6298 71.6172 24.6298 71.7164 24.6298 71.8157C24.531 72.1134 24.3333 72.4111 24.1357 72.7089C24.0368 72.8081 24.0368 72.9074 23.938 73.0066L22.2578 71.4187C22.3566 71.3194 22.3566 71.2202 22.4555 71.2202C22.5543 71.1209 22.5543 71.0217 22.5543 71.0217V70.9224C22.6531 70.7239 22.752 70.5254 22.752 70.4262C22.8508 70.3269 22.8508 70.2277 22.8508 70.1285C22.9497 69.93 22.9497 69.7315 22.9497 69.533C22.9497 69.4337 23.0485 69.3345 23.0485 69.2352H25.5193Z"
          fill="#4F5C87"
        />
        <path
          d="M94.6045 41.5452C94.6045 41.1482 94.5056 40.7512 94.4068 40.255L89.1686 45.5151C88.9709 45.4158 88.6744 45.3166 88.4767 45.3166C88.2791 45.3166 87.9826 45.4158 87.7849 45.5151L83.0408 40.7512C83.1397 40.5527 83.1397 40.4534 83.1397 40.1557C83.1397 39.2625 82.4478 38.667 81.6572 38.667C80.8665 38.667 80.1747 39.3617 80.1747 40.1557C80.1747 40.3542 80.1747 40.5527 80.2735 40.7512L76.2213 44.6218C76.0236 44.5226 75.8259 44.5226 75.6283 44.5226C74.7388 44.5226 74.1458 45.2173 74.1458 46.0113C74.1458 46.8053 74.8376 47.5 75.6283 47.5C76.4189 47.5 77.1108 46.8053 77.1108 46.0113C77.1108 45.8128 77.012 45.6143 76.9131 45.4158L80.7677 41.4459C81.0642 41.5452 81.163 41.6444 81.3607 41.6444C81.5583 41.6444 81.8548 41.5452 81.9537 41.4459L86.6977 46.2098C86.5989 46.4083 86.5989 46.5075 86.5989 46.706C86.5989 47.5993 87.2907 48.1947 88.0814 48.1947C88.9709 48.1947 89.5639 47.5 89.5639 46.706C89.5639 46.5075 89.5639 46.4083 89.4651 46.2098L94.6045 41.5452ZM75.7271 46.9045C75.3318 46.9045 75.0353 46.6068 75.0353 46.2098C75.0353 45.8128 75.3318 45.5151 75.7271 45.5151C76.1224 45.5151 76.4189 45.8128 76.4189 46.2098C76.4189 46.5075 76.1224 46.9045 75.7271 46.9045ZM81.6572 40.8504C81.2618 40.8504 80.9653 40.5527 80.9653 40.1557C80.9653 39.7587 81.2618 39.461 81.6572 39.461C82.0525 39.461 82.349 39.7587 82.349 40.1557C82.349 40.5527 82.1513 40.8504 81.6572 40.8504ZM88.5756 47.5993C88.1802 47.5993 87.8837 47.3015 87.8837 46.9045C87.8837 46.5075 88.1802 46.2098 88.5756 46.2098C88.9709 46.2098 89.2674 46.5075 89.2674 46.9045C89.3663 47.3015 89.0698 47.5993 88.5756 47.5993Z"
          fill="#68759C"
        />
        <path
          d="M42.9141 46.3091H41.5304C41.4316 46.3091 41.2339 46.1106 41.2339 46.0114V38.4686C41.2339 38.2701 41.3327 38.1708 41.5304 38.1708H42.9141C43.0129 38.1708 43.2106 38.3693 43.2106 38.4686V46.0114C43.1117 46.1106 43.0129 46.3091 42.9141 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M43.7048 46.3091H42.1235C42.0246 46.3091 41.9258 46.2098 41.9258 46.1106V42.6369C41.9258 42.4384 42.0246 42.4384 42.1235 42.4384H43.7048C43.8036 42.4384 43.9025 42.5377 43.9025 42.6369V46.1106C43.9025 46.2098 43.8036 46.3091 43.7048 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M46.4722 46.309H45.0885C44.9897 46.309 44.792 46.1105 44.792 46.0113V40.7512C44.792 40.5527 44.8908 40.4534 45.0885 40.4534H46.4722C46.571 40.4534 46.7687 40.6519 46.7687 40.7512V46.0113C46.7687 46.1105 46.571 46.309 46.4722 46.309Z"
          fill="#4F5C87"
        />
        <path
          d="M50.03 46.3091H48.6464C48.5475 46.3091 48.3499 46.1106 48.3499 46.0113V42.6369C48.3499 42.4384 48.4487 42.3392 48.6464 42.3392H50.03C50.1289 42.3392 50.3265 42.5377 50.3265 42.6369V45.9121C50.3265 46.1106 50.1289 46.3091 50.03 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M53.5884 46.3091H52.2047C52.1059 46.3091 51.9082 46.1106 51.9082 46.0113V39.7587C51.9082 39.5602 52.007 39.461 52.2047 39.461H53.5884C53.6872 39.461 53.8849 39.6595 53.8849 39.7587V46.0113C53.8849 46.1106 53.7861 46.3091 53.5884 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M57.2451 46.3091H55.8614C55.7626 46.3091 55.5649 46.1106 55.5649 46.0113V42.2399C55.5649 42.0414 55.6638 41.9422 55.8614 41.9422H57.2451C57.344 41.9422 57.5416 42.1407 57.5416 42.2399V46.0113C57.5416 46.1106 57.4428 46.3091 57.2451 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M60.8032 46.309H59.4195C59.3207 46.309 59.123 46.1105 59.123 46.0113V40.7512C59.123 40.5527 59.2219 40.4534 59.4195 40.4534H60.8032C60.9021 40.4534 61.0997 40.6519 61.0997 40.7512V46.0113C61.0997 46.1105 61.0009 46.309 60.8032 46.309Z"
          fill="#4F5C87"
        />
        <path
          d="M64.46 46.3091H63.0763C62.9775 46.3091 62.7798 46.1106 62.7798 46.0113V42.6369C62.7798 42.4384 62.8786 42.3392 63.0763 42.3392H64.46C64.5588 42.3392 64.7565 42.5377 64.7565 42.6369V45.9121C64.6576 46.1106 64.5588 46.3091 64.46 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M47.2629 46.3091H45.5827C45.4839 46.3091 45.4839 46.2098 45.4839 46.1106V44.0264C45.4839 43.9271 45.5827 43.8279 45.5827 43.8279H47.2629C47.3617 43.8279 47.3617 43.9271 47.3617 44.0264V46.1106C47.4606 46.2098 47.3617 46.3091 47.2629 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M50.9196 46.3091H49.2395C49.1406 46.3091 49.1406 46.2098 49.1406 46.1106V45.1181C49.1406 45.0189 49.2395 44.9196 49.2395 44.9196H50.9196C51.0185 44.9196 51.0185 45.0189 51.0185 45.1181V46.1106C51.0185 46.2098 51.0185 46.3091 50.9196 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M54.4777 46.309H52.7976C52.6987 46.309 52.6987 46.2098 52.6987 46.1105V44.6218C52.6987 44.5226 52.7976 44.4233 52.7976 44.4233H54.4777C54.5766 44.4233 54.5766 44.5226 54.5766 44.6218V46.1105C54.6754 46.2098 54.6754 46.309 54.4777 46.309Z"
          fill="#4F5C87"
        />
        <path
          d="M58.1345 46.309H56.5532C56.4543 46.309 56.4543 46.2097 56.4543 46.1105V43.4308C56.4543 43.3315 56.5532 43.2323 56.5532 43.2323H58.1345C58.2334 43.2323 58.2334 43.3315 58.2334 43.4308V46.1105C58.3322 46.2097 58.2334 46.309 58.1345 46.309Z"
          fill="#4F5C87"
        />
        <path
          d="M61.8903 46.3091H60.1113C60.0124 46.3091 60.0125 46.3091 60.0125 46.2099V45.7136C60.0125 45.6144 60.0124 45.6144 60.1113 45.6144H61.8903C61.9891 45.6144 61.9891 45.6144 61.9891 45.7136V46.1106C61.9891 46.2099 61.9891 46.3091 61.8903 46.3091Z"
          fill="#4F5C87"
        />
        <path
          d="M65.4484 46.309H63.6694C63.6694 46.309 63.5706 46.309 63.5706 46.2098V46.0113C63.5706 46.0113 63.5706 45.912 63.6694 45.912H65.4484C65.4484 45.912 65.5472 45.9121 65.5472 46.0113L65.4484 46.309C65.5472 46.2098 65.4484 46.309 65.4484 46.309Z"
          fill="#4F5C87"
        />
        <path
          d="M67.03 59.2112H39.6528V62.8834H67.03V59.2112Z"
          fill="#232D4D"
        />
        <path
          d="M55.5651 60.4022H48.0537V61.4939H55.5651V60.4022Z"
          fill="#4F5C87"
        />
        <path
          d="M65.0533 60.4022H62.3848V61.4939H65.0533V60.4022Z"
          fill="#4F5C87"
        />
        <path
          d="M67.03 66.1586H39.6528V69.8307H67.03V66.1586Z"
          fill="#232D4D"
        />
        <path
          d="M53.2919 67.548H48.0537V68.6397H53.2919V67.548Z"
          fill="#4F5C87"
        />
        <path
          d="M65.0532 67.548H60.408V68.6397H65.0532V67.548Z"
          fill="#4F5C87"
        />
        <path
          d="M67.03 73.2051H39.6528V76.8773H67.03V73.2051Z"
          fill="#232D4D"
        />
        <path
          d="M56.7511 74.4953H48.0537V75.587H56.7511V74.4953Z"
          fill="#4F5C87"
        />
        <path
          d="M65.0531 74.4953H63.5706V75.587H65.0531V74.4953Z"
          fill="#4F5C87"
        />
        <path
          d="M10.101 20.9017H5.94998C5.65348 20.9017 5.45581 20.6039 5.45581 20.4054V19.9092C5.45581 19.6115 5.75231 19.413 5.94998 19.413H10.101C10.3975 19.413 10.5952 19.7107 10.5952 19.9092V20.4054C10.5952 20.6039 10.3975 20.9017 10.101 20.9017Z"
          fill="#4F5C87"
        />
        <path
          d="M38.6643 20.9017H14.1534C13.8568 20.9017 13.6592 20.6039 13.6592 20.4054V19.9092C13.6592 19.6115 13.9557 19.413 14.1534 19.413H38.6643C38.9608 19.413 39.1585 19.7107 39.1585 19.9092V20.4054C39.1585 20.6039 38.9608 20.9017 38.6643 20.9017Z"
          fill="#343E61"
        />
        <path
          d="M10.101 24.9709H5.94998C5.65348 24.9709 5.45581 24.6731 5.45581 24.4747V23.9784C5.45581 23.6807 5.75231 23.4822 5.94998 23.4822H10.101C10.3975 23.4822 10.5952 23.7799 10.5952 23.9784V24.4747C10.5952 24.7724 10.3975 24.9709 10.101 24.9709Z"
          fill="#4F5C87"
        />
        <path
          d="M27.2983 24.4747C27.2983 24.7724 27.0018 24.9709 26.8042 24.9709H14.1534C13.8568 24.9709 13.6592 24.6731 13.6592 24.4747V23.9784C13.6592 23.6807 13.9557 23.4822 14.1534 23.4822H26.8042C27.1007 23.4822 27.2983 23.7799 27.2983 23.9784V24.4747Z"
          fill="#343E61"
        />
        <path
          d="M31.9434 51.3707H5.45581V34.7963C6.83949 34.8956 8.22317 34.9948 9.50802 35.3918C10.101 35.5903 10.4964 35.7888 10.8917 36.1858C11.0894 36.3843 11.0894 36.4835 11.0894 36.5828C11.287 37.079 11.6824 37.3768 12.3742 37.476L12.9672 37.5753C14.1532 37.6745 15.0427 38.3692 15.1416 39.1632L15.3393 40.5527C16.6241 40.8504 16.7229 40.8504 17.9089 41.1482C18.1066 41.2474 18.1066 41.1482 18.2055 41.0489C18.3043 40.9497 18.4031 40.9497 18.502 40.9497C18.6996 40.9497 18.6996 40.9497 18.8973 40.8504C19.095 40.7512 19.2926 40.7512 19.3915 40.9497C19.8856 41.6444 20.874 42.0414 21.467 42.6369C22.1588 43.3316 23.3448 43.2324 24.1355 43.2324C24.3332 43.2324 24.6297 43.3316 24.8274 43.4309C25.8157 44.1256 27.3971 46.4083 28.6819 46.4083C29.1761 46.4083 29.5714 46.6068 29.8679 46.8053C30.9551 48.3932 31.6469 49.6834 31.9434 51.3707Z"
          fill="#232D4D"
        />
        <path
          d="M31.2517 51.47C30.9552 49.9813 30.2633 48.7903 29.1762 47.5001C29.0773 47.4008 28.7808 47.3016 28.4843 47.3016C27.2983 47.3016 26.31 46.2099 25.3216 45.2174C24.9263 44.8204 24.5309 44.4234 24.2344 44.2249C24.1356 44.1257 23.9379 44.1257 23.9379 44.1257C23.7403 44.1257 23.6414 44.1257 23.4438 44.1257C22.6531 44.1257 21.5659 44.0264 20.7752 43.3317C20.5776 43.034 20.2811 42.8355 19.8857 42.637C19.4904 42.3392 19.095 42.1407 18.7985 41.7437C18.6997 41.7437 18.502 41.7437 18.4032 41.7437C18.3044 41.843 17.909 42.0415 17.4149 41.843L16.8219 41.6445C16.2288 41.5452 15.9323 41.446 14.944 41.2475H14.6475L14.4498 39.3618C14.4498 38.8656 13.758 38.4686 12.9673 38.3693L12.3743 38.2701C11.4848 38.1708 10.6941 37.6746 10.4965 36.8806V36.7814C10.1999 36.4836 9.90344 36.2851 9.50811 36.1859C8.32209 35.7889 6.93841 35.6896 5.65356 35.5904L5.7524 34.1017C7.13608 34.2009 8.6186 34.3002 10.0023 34.7964C10.6941 34.9949 11.2871 35.3919 11.6825 35.8881C11.7813 35.9874 11.979 36.2851 12.0778 36.6821C12.1766 36.8806 12.3743 36.9799 12.6708 36.9799L13.2638 37.0791C14.8452 37.1784 15.9323 38.1708 16.13 39.3618L16.2288 40.1558C16.8219 40.255 17.1184 40.3543 17.6125 40.4535L18.2055 40.5528C18.4032 40.4535 18.6009 40.3543 18.7985 40.3543C18.8974 40.3543 18.8974 40.3543 18.9962 40.3543C19.4904 40.1558 20.0834 40.3543 20.3799 40.7513C20.5776 40.9498 20.9729 41.2475 21.2694 41.446C21.6647 41.7437 22.0601 41.9422 22.3566 42.3392C22.6531 42.637 23.3449 42.637 24.0368 42.7362C24.2344 42.7362 24.3333 42.7362 24.5309 42.7362C24.9263 42.7362 25.2228 42.9347 25.5193 43.034L25.6181 43.1332C26.0135 43.4309 26.5076 43.8279 26.903 44.3242C27.496 45.0189 28.4843 45.9121 28.9785 45.9121C29.5715 45.9121 30.1645 46.1106 30.6587 46.5076C32.0424 47.9963 32.833 49.485 33.1295 51.2715L31.2517 51.47Z"
          fill="#4F5C87"
        />
        <path
          d="M30.8564 41.2475H19.194C18.6998 41.2475 18.3044 40.8505 18.3044 40.3542V36.1858C18.3044 35.6896 18.6998 35.2926 19.194 35.2926H30.8564C31.3506 35.2926 31.7459 35.6896 31.7459 36.1858V40.3542C31.7459 40.8505 31.3506 41.2475 30.8564 41.2475Z"
          fill="#4F5C87"
        />
        <path
          d="M29.3739 36.9798H20.7753C20.5776 36.9798 20.4788 37.1783 20.4788 37.2775C20.4788 37.3768 20.5776 37.6745 20.6764 37.6745H29.275C29.4727 37.6745 29.5715 37.476 29.5715 37.3768C29.5715 37.2775 29.4727 36.9798 29.3739 36.9798Z"
          fill="#404B72"
        />
        <path
          d="M25.3218 38.9647H20.6766C20.479 38.9647 20.3801 39.1632 20.3801 39.2625C20.3801 39.461 20.5778 39.5602 20.6766 39.5602H25.3218C25.5195 39.5602 25.6184 39.3617 25.6184 39.2625C25.6184 39.1632 25.4207 38.9647 25.3218 38.9647Z"
          fill="#404B72"
        />
        <path
          d="M82.7444 67.9451H74.9364V64.6699H82.7444V67.9451ZM75.0353 68.9375H82.8432V72.2127H75.0353V68.9375ZM75.6283 60.5015H82.8432V63.8759H75.0353V61.1962C75.0353 60.7 75.3318 60.5015 75.6283 60.5015ZM75.0353 75.5871V73.2052H82.8432V76.1826H75.6283C75.3318 76.1826 75.0353 75.8849 75.0353 75.5871ZM93.122 60.5015C93.2208 60.1045 93.3196 59.8068 93.4185 59.4098H75.6283C74.7388 59.4098 74.1458 60.2038 74.1458 61.097V75.4879C74.1458 76.3811 74.8376 77.1751 75.6283 77.1751H84.3257C84.6222 76.7781 84.9187 76.4803 85.2152 76.0834H83.8315V73.1059H87.2907C87.4884 72.8082 87.6861 72.4112 87.9826 72.1135H83.8315V68.8383H89.7616C89.9593 68.5406 90.0581 68.2428 90.2558 67.9451H83.8315V64.6699H91.1453V66.0594C91.5406 65.2654 91.8371 64.3722 92.1336 63.5782H92.0348V60.5015H93.122ZM91.0464 63.8759H83.8315V60.5015H91.1453V63.8759H91.0464Z"
          fill="#343E61"
        />
        <path
          d="M80.8666 14.0537C80.1747 13.3589 79.3841 12.6642 78.5934 12.0687V13.9544C78.9887 14.3514 79.4829 14.7484 79.8782 15.1454C88.2792 23.2837 93.6162 34.7964 93.6162 47.5001C93.6162 60.7992 87.8838 72.8082 78.6922 81.0457V82.9314C78.8899 82.7329 79.1864 82.5344 79.3841 82.3359C88.971 73.8007 94.9999 61.3947 94.9999 47.5001C94.9999 34.3994 89.564 22.4897 80.8666 14.0537Z"
          fill="#232D4D"
        />
      </svg>
      <span class="no-data-text">No data to show</span>
    </div>
  </div>
  <div v-show="!loaderShow && viewsData.length" class="pt-4">
    <div class="text-left">
      <h3 class="data-header">Data collected over the last 30 days</h3>
      <span class="date-range">{{ fromStr }} - Today</span>
    </div>
    <div class="chart-card mt-2">
      <div id="views-chart-container"></div>
    </div>

    <div class="chart-card mt-5">
      <div id="peak-viewer-chart-container"></div>
    </div>

    <div class="detail-cards">
      <div class="card">
        <span>Avg time watched</span>
        <h3>{{ avgTimeWatched }}</h3>
      </div>
      <!-- <div class="card">
            <span>Completion rate</span>
            <h3>89%</h3>
        </div> -->
      <div class="card">
        <span>Total time watched</span>
        <h3>{{ totalTimeWatched }}</h3>
      </div>
    </div>
  </div>
</template>

<script setup>
import Highcharts from "highcharts";
import { onMounted, ref } from "vue";
import MetricsService from "../../services/MetricsService";
import Spinner from "@/components/ui/Spinner.vue";
import { useRoute } from "vue-router";
import moment from "moment";
import { Duration } from "luxon";

Highcharts.setOptions({
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
});

const props = defineProps({
    currentFile: {
    type: Object,
    required: true,
  },
});

const route = useRoute();

const viewsData = ref([
//   [1715232300000, 0.006242],
//   [1715232360000, 0.006242],
//   [1715232420000, 0.006188],
//   [1715232480000, 0.0059830000000000005],
//   [1715232540000, 0.005565],
]);
const peakViewerData = ref([
//   [1715232300000, 0.006242],
//   [1715232360000, 0.006242],
//   [1715232420000, 0.006188],
//   [1715232480000, 0.0059830000000000005],
//   [1715232540000, 0.005565],
]);

const totalTimeWatched = ref("");
const avgTimeWatched = ref("");

const fromStr = ref("");
const loaderShow = ref(true);

onMounted(async () => {
  try {
    
    const vodId = props.currentFile?.refId ?? route.params.videoId?.replace(".mp4", "")
    loaderShow.value = true;

    const startTime = Date.now() - 30 * 1440 * 60 * 1000;
    const endTime = Date.now();

    fromStr.value = moment(startTime).format("MMM Do, YYYY");

    let [viewsRes, peakViewersRes] = await Promise.all([
      MetricsService.getBitmovinAnalytics({
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
        type: "uniqueUsers",
        interval: "DAY",
        selectedStreams: [vodId],
      }),
      MetricsService.getBitmovinAnalytics({
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
        type: "concurrentViewers",
        interval: "DAY",
        selectedStreams: [vodId],
      }),
    ]);

    viewsData.value = viewsRes ?? [];
    peakViewerData.value = peakViewersRes ?? [];

    await getWatchTimes(startTime, endTime, vodId);

    const viewChart = new Highcharts.Chart("views-chart-container", {
      chart: {
        type: "areaspline",
        // backgroundColor: "rgba(167, 181, 226, 0.08)",
        backgroundColor: null,
        zooming: {
          type: "x",
        },
      },
      credits: {
        enabled: false,
      },
      colors: [
        "rgba(32, 118, 255, 1)",
        "#FF530D",
        "#E82C0C",
        "#FF0000",
        "#E80C7A",
        "#E80C7A",
      ],
      title: false,
      title: {
        text: "Views",
        align: "left",
        style: {
          color: "#AEBAD6",
          fontSize: 13,
          fontFamily: "Inter",
        },
      },
      legend: {
        enabled: false,
      },
      colorAxis: {
        gridLineColor: "red",
      },
      xAxis: {
        lineColor: "#fff",
        tickColor: "#fff",
        labels: {
          style: {
            color: "rgba(135, 148, 181, 1)",
            fontSize: 12,
            fontFamily: "Inter",
          },
        },
      },

      yAxis: {
        gridLineColor: "rgba(167, 181, 226, 0.08)",
        title: false,
        labels: {
          style: {
            color: "rgba(135, 148, 181, 1)",
            fontSize: 12,
            fontFamily: "Inter",
          },
        },
      },
      // tooltip: {
      //   shared: true,
      //   formatter: function () {
      //     return this.points.reduce(function (s, point) {
      //       return isResolutionOption()
      //         ? formatter(point.y)
      //         : s + "<br/>" + point.series.name + ": " + formatter(point.y);
      //     }, "<b>" + formatXAxis(this.x) + "</b>");
      //   },
      // },
      plotOptions: {
        areaspline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, "rgba(32, 118, 255, 1)"],
              [1, "rgba(0, 98, 255, 0)"],
            ],
          },
        },
      },

      series: [
        {
          name: "Views",
          data: [],
        },
      ],
    });
    viewChart.xAxis[0].update(
      {
        type: "datetime",
        categories: null,
        min: Math.min(viewsData.value[0]?.[0]),
        max: viewsData.value[viewsData.value.length - 1]?.[0],
        minTickInterval: 60 * 1000,
      },
      true
    );
    viewChart.addSeries({
      type: "areaspline",
      data: viewsData.value,
      color: "#2076FF",
      name: "Views", //this.optionMap[option] || option,
    });
    viewChart.redraw();

    const peakViewerChart = new Highcharts.Chart(
      "peak-viewer-chart-container",
      {
        chart: {
          type: "areaspline",
          // backgroundColor: "rgba(167, 181, 226, 0.08)",
          backgroundColor: null,
          zooming: {
            type: "x",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "rgba(32, 118, 255, 1)",
          "#FF530D",
          "#E82C0C",
          "#FF0000",
          "#E80C7A",
          "#E80C7A",
        ],
        //   title: false,
        title: {
          text: "Peak viewer",
          align: "left",
          style: {
            color: "#AEBAD6",
            fontSize: 13,
            fontFamily: "Inter",
          },
        },
        legend: {
          enabled: false,
        },
        colorAxis: {
          gridLineColor: "red",
        },
        xAxis: {
          lineColor: "#fff",
          tickColor: "#fff",
          labels: {
            style: {
              color: "rgba(135, 148, 181, 1)",
              fontSize: 12,
              fontFamily: "Inter",
            },
          },
        },

        yAxis: {
          gridLineColor: "rgba(167, 181, 226, 0.08)",
          title: false,
          labels: {
            style: {
              color: "rgba(135, 148, 181, 1)",
              fontSize: 12,
              fontFamily: "Inter",
            },
          },
        },
        // tooltip: {
        //   shared: true,
        //   formatter: function () {
        //     return this.points.reduce(function (s, point) {
        //       return isResolutionOption()
        //         ? formatter(point.y)
        //         : s + "<br/>" + point.series.name + ": " + formatter(point.y);
        //     }, "<b>" + formatXAxis(this.x) + "</b>");
        //   },
        // },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false,
            },
          },
          series: {
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, "rgba(32, 118, 255, 1)"],
                [1, "rgba(0, 98, 255, 0)"],
              ],
            },
          },
        },

        series: [
          {
            name: "Peak Viewers",
            data: [],
          },
        ],
      }
    );
    peakViewerChart.xAxis[0].update(
      {
        type: "datetime",
        categories: null,
        min: Math.min(viewsData.value[0]?.[0]),
        max: viewsData.value[viewsData.value.length - 1]?.[0],
        minTickInterval: 60 * 1000,
      },
      true
    );
    peakViewerChart.addSeries({
      type: "areaspline",
      data: peakViewerData.value,
      color: "#2076FF",
      name: "Peak Viewers", //this.optionMap[option] || option,
    });
    peakViewerChart.redraw();

    loaderShow.value = false;
  } catch (e) {
    console.log(e);
  }
});

const getWatchTimes = async (startTime, endTime, vodId) => {
  const [totalHoursWatched, avgViewTimes] = await Promise.all([
    MetricsService.getBitmovinAnalytics({
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      type: "totalHoursWatched",
      interval: "DAY",
      selectedStreams: [vodId],
    }),
    MetricsService.getBitmovinAnalytics({
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      type: "viewTime",
      interval: "DAY",
      selectedStreams: [vodId],
    }),
  ]);

  if (totalHoursWatched.length)
    totalTimeWatched.value = formatTime(
      totalHoursWatched[totalHoursWatched.length - 1][1]
    );
  if (avgViewTimes.length)
    avgTimeWatched.value = formatTime(avgViewTimes[avgViewTimes.length - 1][1]);
};

const formatTime = (ms) => {
  const durationSeconds = ms / 1000;
  if (!durationSeconds) return "-";
  let formatStr = "s's'";
  if (durationSeconds >= 60) formatStr = "m'm' s's'";
  if (durationSeconds >= 3600) formatStr = "h'h' m'm'";
  if (durationSeconds >= 1440 * 60) formatStr = "d'd' h'h'";
  return Duration.fromObject({ seconds: durationSeconds }).toFormat(formatStr);

  return Number(ms).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};
</script>

<style scoped>
.no-data-text {
  font-weight: 400;
  font-size: var(--font-s-xs);
  color: #8794b5;
}

.chart-card {
  padding: 16px;
  background-color: var(--c-dark-2);
  border-radius: 8px;
}

.data-header {
  font-size: var(--font-s-m);
  font-weight: 500;
  color: #ffffff;
}

.date-range {
  color: var(--c-dark-9);
  font-size: var(--font-s-ss);
  font-weight: 400;
}

.detail-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.card {
  padding: 8px 16px;

  border-radius: 8px;
  border-color: var(--c-dark-3);
  border-width: 2px;

  background-color: transparent;
  min-width: 190px;
}

.card span {
  color: var(--c-dark-8);
  font-size: var(--font-s-ss);
  font-weight: 400;
}

.card h3 {
  color: #fff;
  font-size: var(--font-s-xxl);
  font-weight: 500;
}
</style>