<template>
  <path
    d="M14 5C14 3.11438 14 3.17157 13.4142 2.58579C12.8284 2 11.8856 2 10 2H6C4.11438 2 3.17157 2 2.58579 2.58579C2 3.17157 2 4.11438 2 6V9.33333C2 10.5904 2 11.219 2.39052 11.6095C2.78105 12 3.40959 12 4.66667 12H7"
    stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M1.33337 14H8.00004" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" />
  <path d="M7 10H4" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" />
  <path
    d="M9.33337 10C9.33337 8.74292 9.33337 8.11438 9.7239 7.72386C10.1144 7.33334 10.743 7.33334 12 7.33334C13.2571 7.33334 13.8857 7.33334 14.2762 7.72386C14.6667 8.11438 14.6667 8.74292 14.6667 10V12C14.6667 13.2571 14.6667 13.8856 14.2762 14.2761C13.8857 14.6667 13.2571 14.6667 12 14.6667C10.743 14.6667 10.1144 14.6667 9.7239 14.2761C9.33337 13.8856 9.33337 13.2571 9.33337 12V10Z"
    stroke="currentColor" stroke-width="1.2" />
  <path d="M12.6667 13.3333H11.3334" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" />
</template>
