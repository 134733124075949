<script setup>
import { computed, ref, getCurrentInstance, onMounted, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import _ from 'lodash';
import StreamService from '@/services/StreamService';
import PlayoutService from '@/services/PlayoutService';
import IntegrationService from '@/services/IntegrationService';
import TranscoderService from '@/services/TranscoderService';
import PromptModal from '@/components/modals/PromptModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconBin from '../icon/IconBin.vue';
import MonitoringChart from '../../views/StreamsDash/MonitoringChart.vue';
import StreamSidebarPreview from '../ui/StreamSidebar/StreamSidebarPreview.vue'
import StreamSidebarOptionTabs from './StreamSidebar/StreamSidebarOptionTabs.vue';
import StreamSidebarSource from './StreamSidebar/StreamSidebarSource.vue';
import StreamSidebarPlayback from './StreamSidebar/StreamSidebarPlayback.vue';
import Tooltip from '@/components/Atoms/Tooltip.vue';

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	mediaPulse: {
		type: Object,
		default() { return {}; }
	},
	streamAlive: {
		type: Boolean
	},
	backupStreamAlive: {
		type: Boolean,
		default() { return false; }
	},
	isRtmpPullEnabled: {
		type: Boolean,
		default() { return true; }
	},
	transcoderView: {
		type: Boolean,
		default: false
	},
	primaryStreamAlive: {
		type: Boolean,
		default() { return false; }
	},
})

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const notAllowed = ref(false)
const SourceTypes = ref({
	Pull: 'pull',
	Publish: 'publish',
	Webcam: 'webcam',
	Linked: 'linked'
})
const processing = ref(true)
const rmptPullUrlProcessing = ref(false)
const hlsUrl = ref(null)
const streamSourceType = ref(null)
const streamSourceTypeModel = ref(null)
const streamPreviewSpecs = ref({})
const streamPullUrl = ref(null)
const webcamPushReady = ref(false)
const streamSourceTypeProcessing = ref(null)
const streamId = ref(null)
const playbackClicked = ref(false)
const windowWidth = ref(window.innerWidth)
const webcamRouteLeaveNavigationCallback = ref(null)
const failoverIngestToggleState = ref(false)
const failoverIngestToggleProcessing = ref(false)
const lowLatencyPlaybackStage = ref(false)
const autoDetectDisabled = ref(false)
const autoDetectDisabledClass = ref({ 'auto-detect-disabled': false })
const componentLoaded = ref(false)
const streamAbrToggleState = ref(null)
const llHls = ref(false)
const isPasswordEnabled = ref(true)
const webcamDevices = ref([])
const selectedPreviewTab = ref('source-setup')
const backTransition = ref(true)
const isTransitioning = ref(false)
const linkedSource = ref(null)
const selectedLinkedSource = ref(null)
const sourceSet = ref(false)

const isMonitoringAllowed = computed(() => {
	const liveSub = _.find(subscription.value, { category: 'live' }) || {};
	return liveSub.enabled;
})

const isAnyBackupStreamLive = computed(() => props.streamAlive || props.backupStreamAlive)

const mobile = computed(() => {
	if (windowWidth.value > 767) {
		return false
	} else {
		return !props.transcoderView
	}
})

const failoverIgestActive = computed(() => failoverIngestToggleState.value === true && streamSourceTypeModel.value === 'publish' && props.stream.type === 'live')

const srtRegionIngestDestination = computed(() => {
	if (props.stream.user === '62c4b3398b2e840b11244bfd') {
		return 'fifa'
	}
	// hack for studio@jackshoot.com
	if (props.stream.user === '5da088ba2564bd064957e474' && props.stream.uiRegion._id === '62e2a0e108cb9dc2bcfb17d8') {
		return 'kr'
	}
	if (props.stream.user === '5ba39c5e688503562c8097b4' && props.stream.uiRegion._id === '63f3624d8ae252ca28ed79d2') {
		return 'ae'
	}

	const liveRegionRel = {
		'us-east-1': 'cg',
		'us-east-3': 'ny',
		'us-east-2': 'mi',
		'us-west-2': 'se',
		'us-west-1': 'la',
		'us-central-1': 'da',
		'na-east-1': 'qc',
		'sa-east-1': 'br',
		'eu-west-1': 'uk',
		'af-south-1': 'southafrica',
		'eu-central-1': 'fr',
		'ru-west-1': 'ru',
		'ap-southeast-1': 'sg',
		'ap-south-1': 'in',
		'ap-southeast-2': 'au',
		'madrid': 'madrid',
		'paris': 'paris',
		'milan': 'milan',
		'stockholm': 'stockholm',
		'mexico': 'mexico',
		'hongkong': 'hongkong',
		'colombia': 'bogota',
		'istanbul': 'istanbul',
		'telaviv': 'telaviv',
		'dubai': 'dubai',
		'tokyo': 'tokyo',
		'copenhagen': 'copenhagen',
		'santiago': 'santiago',
		'lagos': 'lagos-1',
		'oslo': 'oslo',
		'athens': 'athens',
	}

	const multistreamRegionRel = {
		'us-east-1': 'cg',
		'us-east-3': 'ny',
		'us-east-2': 'mi',
		'us-west-2': 'se',
		'us-west-1': 'la',
		'us-central-1': 'da',
		'na-east-1': 'qc',
		'sa-east-1': 'br',
		'eu-west-1': 'uk',
		'af-south-1': 'southafrica',
		'eu-central-1': 'fr',
		'ru-west-1': 'ru',
		'ap-southeast-1': 'sg',
		'ap-south-1': 'in',
		'ap-southeast-2': 'au',
		'madrid': 'madrid',
		'paris': 'paris',
		'milan': 'milan',
		'stockholm': 'stockholm',
		'mexico': 'mexico',
		'hongkong': 'hongkong',
		'colombia': 'bogota',
		'istanbul': 'istanbul',
		'telaviv': 'telaviv',
		'dubai': 'dubai',
		'tokyo': 'tokyo',
		'copenhagen': 'copenhagen',
		'santiago': 'santiago',
		'lagos': 'lagos-1',
		'oslo': 'oslo',
		'athens': 'athens',
	}

	if (props.stream.type === 'live') {
		return liveRegionRel[props.stream.uiRegion?.code] ?
			liveRegionRel[props.stream.uiRegion?.code] : 'srt-ingest'
	}

	if (props.stream.type === 'restream') {
		return multistreamRegionRel[props.stream.uiRegion?.code] ?
			multistreamRegionRel[props.stream.uiRegion?.code] : 'srt-ingest'
	}

	return 'srt-ingest'
})

const streamKeySRT = computed(() => {
	let streamURL = `srt://${srtRegionIngestDestination.value}.castr.io:9998?pkt_size=1316&streamid=#!::r=${props.stream.key},m=publish`;
	if (props.stream?.config?.password && isPasswordEnabled.value) {
		streamURL = `srt://${srtRegionIngestDestination.value}.castr.io:9998?pkt_size=1316&streamid=#!::r=${props.stream.key},password=${props.stream.config.password},m=publish`
	}
	return streamURL;
})

const activeTeam = computed(() => store.state.User.activatedTeam)

const memberPermission = computed(() => activeTeam.value && store.state.User.memberPermission)

const cannotToggleStream = computed(() => {
	if (!activeTeam.value) return false;
	let permission = memberPermission.value && (
		((props.stream.type === 'live') && !memberPermission.value.liveStreamToggle) ||
		((props.stream.type === 'restream') && !memberPermission.value.recordedStreamToggle))
	return permission;
})

const subscription = computed(() => store.state.User.subscriptions)

const baseSubscription = computed(() => {
	const baseSub = _.find(subscription.value, { category: props.stream.type }) || {};
	return baseSub;
})

const hasFailoverIngestFeature = computed(() => {
	const baseSub = baseSubscription.value;
	return !!_.get(baseSub, 'package.definition.failoverIngest');
})

watch(() => streamAbrToggleState, async () => {
	await setStreamHlsUrl()
})

watch(() => props.mediaPulse, async () => {
	await onMediaPulseChanged()
})

watch(streamSourceTypeModel, async () => {
	if (streamSourceType.value === SourceTypes.value.Pull && props.stream.uiRegion.hostname === 'live.castr.io') {
		streamSourceType.value = SourceTypes.value.Publish;
		streamSourceTypeModel.value = SourceTypes.value.Publish;
		root.$emit('bv::show::modal', 'alert-disable-pull');
	}
})

watch(() => props.stream.failoverIngest, () => {
	failoverIngestToggleState.value = props.stream.failoverIngest
})
const emit = defineEmits({
	'stream-updated': (value) => { return value },
	'toggle-transcoder': (stream) => { return stream },
	'delete-transcoder': (stream) => { return stream },
	'show-stream-toggle-modal': (event) => { return event }
})

onMounted(async () => {
	if (localStorage.getItem('notAllowed' + props.stream.type)) {
		notAllowed.value = (localStorage.getItem('notAllowed' + props.stream.type) == 'true');
	}
	await store.dispatch('User/getinfoOwner', null, { root: true });
	failoverIngestToggleState.value = props.stream.failoverIngest;
	lowLatencyPlaybackStage.value = props.stream.lowLatencyPlayback;
	await setupStream();
	await setStreamHlsUrl();
	isPasswordEnabled.value = !Boolean(props.stream?.config?.isPasswordDisabled);
	webcamDevices.value = await navigator.mediaDevices?.enumerateDevices();

	if (props.stream.uiRegion.hostname === 'live.castr.io') {
		failoverIngestToggleProcessing.value = true;
	} else failoverIngestToggleProcessing.value = false;
	componentLoaded.value = true;
	//TODO delete after move to component
	window.onresize = () => {
		windowWidth.value = window.innerWidth
	}
	if (props.stream.type === 'live' && !props.transcoderView) {
		const meta = await StreamService.getStreamMetadata(props.stream._id);
		llHls.value = meta.llHls || false
	}
})

const onSelectTab = (id) => {
	if (selectedPreviewTab.value === 'playback') playbackClicked.value = true;
	if (selectedPreviewTab.value === 'source-setup') backTransition.value = true;
	else if (selectedPreviewTab.value === 'monitoring') backTransition.value = false;
	else if (selectedPreviewTab.value === 'playback' && id === 'source-setup') backTransition.value = false;
	else backTransition.value = true;

	isTransitioning.value = false;
	selectedPreviewTab.value = id;
}

const togglePasswordCheckProced = () => {
	if (mobile.value) {
		showTogglePasswordDialog();
	} else {
		togglePassword()
	}
}

const confirmDisablePasswordMobile = () => {
	isPasswordEnabled.value = !isPasswordEnabled.value
	togglePassword();
}

const showTogglePasswordDialog = () => {
	// undo the change done by v-model
	isPasswordEnabled.value = !isPasswordEnabled.value;
	root.$emit('bv::show::modal', 'modal-disable-password-mobile-dialog');
}

const togglePassword = async () => {
	// optimistic update
	try {
		const res = await StreamService.updatePasswordToggle(props.stream._id, !isPasswordEnabled.value);
		emit('stream-updated', {
			config: {
				isPasswordDisabled: !isPasswordEnabled.value
			}
		});
		if (!res.success) {
			onFailedToTogglePassword();
		}
	} catch {
		onFailedToTogglePassword();
	}
}

const onFailedToTogglePassword = () => {
	isPasswordEnabled.value = !isPasswordEnabled.value
	notify({ group: 'error', text: 'Could not toggle the password.' });
}

const onBackupModalToggleCancel = async () => {
	failoverIngestToggleState.value = !failoverIngestToggleState.value
}

const onBackupModalToggleConfirm = async () => {
	if (!hasFailoverIngestFeature.value) {
		failoverIngestToggleState.value = false;
		return
	}

	failoverIngestToggleProcessing.value = true;
	try {
		await StreamService.toggleStreamFailoverIngest(props.stream._id, failoverIngestToggleState.value);
	} catch (e) {
		failoverIngestToggleState.value = !failoverIngestToggleState.value;
		notify({
			group: 'error',
			text: (e && e.message) || 'could not toggle backup stream ingest'
		});
	}

	// TODO: remove here mutation of prop and emit event to parent component
	// backupMediaPulse must stop processing when backup ingest disabled
	props.stream.failoverIngest = failoverIngestToggleState.value;
	autoDetectDisabled.value = failoverIngestToggleState.value
	autoDetectDisabledClass.value = { 'auto-detect-disabled': true }

	failoverIngestToggleProcessing.value = false;
	if (failoverIngestToggleState.value) window.Intercom('trackEvent', 'toggle-backup-on')
}

const setupStream = async () => {
	const hasPullUrl = props.stream.pullUrl;
	if (!linkedSource.value) streamSourceTypeModel.value = hasPullUrl
		? SourceTypes.value.Pull
		: SourceTypes.value.Publish;

	onSourceTypeChange(streamSourceTypeModel.value)
	if (props.stream.type === 'ipcam') {
		streamSourceType.value = SourceTypes.value.Pull;
	}

	if (hasPullUrl) {
		streamPullUrl.value = props.stream.pullUrl;
	}
	if (props.stream.failoverIngest === true) {
		autoDetectDisabled.value = true;
		autoDetectDisabledClass.value = { 'auto-detect-disabled': true }
	}
}

const setStreamHlsUrl = async () => {
	try {
		const hlsUrlRes = await StreamService.getStreamHlsUrl(props.stream._id);
		if (!hlsUrlRes || !hlsUrlRes.hlsUrl) {
			throw new Error();
		}
		hlsUrl.value = hlsUrlRes.hlsUrl;
	} catch (e) {
		// do nothing
	}
}

const onMediaPulseChanged = async () => {
	if (props.mediaPulse?.isWowza === true) {
		streamPreviewSpecs.value.type = 'hls';
		streamPreviewSpecs.value.url = `https://wowza-stage.castr.io/static/${props.stream.key}/playlist_sfm4s.m3u8`;
	}

	if (_.isEmpty(streamPreviewSpecs.value)) {
		streamPreviewSpecs.value.type = 'mse';
		streamPreviewSpecs.value.url = `wss://wowza-stage.castr.io/${props.stream.key}/mse_ld?tracks=v1a1`;
	}
}

const navigatePaymentsPage = () => {
	const category = props.stream.type;
	window.location.href = `/app/subscribe?category=${category}`;
}

const isMixerPullAuthorized = () => {
	const exlcudedRegions = ['br'];
	const curRegion = props.stream.region.identifier;

	let bypassed = true;
	for (let i = 0; i < exlcudedRegions.length; i++) {
		if (curRegion === exlcudedRegions[i]) {
			bypassed = false;
			break;
		}
	}

	return bypassed;
}

const onWebcamAuthorized = () => {
	// check if streaming pulling mode is active
	if (props.stream.pullUrl) {
		root.$emit(
			'bv::show::modal',
			'modal-set-publish-mode-webcam'
		);
	}
}

const onWebcamStopped = () => {
	webcamPushReady.value = false;
}

const onWebcamStarted = () => {
	webcamPushReady.value = true;
}

const confirmWebcamLeave = () => {
	if (webcamRouteLeaveNavigationCallback.value) {
		webcamRouteLeaveNavigationCallback()
	}
}

//TODO this should be refactored as a watcher for streamSourceType !!!
const onSourceTypeChange = async (selectedTab) => {
	if (selectedTab) streamSourceTypeModel.value = selectedTab
	if (streamSourceType.value === SourceTypes.value.Webcam && webcamPushReady.value) {
		setTimeout(() => {
			streamSourceTypeModel.value = streamSourceType.value;
		}, 100);

		root.$emit(
			'bv::show::modal',
			'modal-webcam-leave-navigation'
		);
		webcamRouteLeaveNavigationCallback = () => {
			streamSourceType.value = streamSourceTypeModel.value;
		};
		return;
	}


	if (selectedTab && linkedSource.value && selectedTab !== SourceTypes.value.Linked) {
		root.$emit('bv::show::modal', 'modal-unset-linked-source');
		return
	}

	if (selectedTab === SourceTypes.value.Linked) {
		// check if operational mode is `pull`
		const hadPullUrl = props.stream.pullUrl;
		if (hadPullUrl) root.$emit('bv::show::modal', 'modal-set-linked-mode');
	}

	streamSourceType.value = streamSourceTypeModel.value;

	// check if new mode is `publish`
	if (selectedTab === SourceTypes.value.Publish) {
		// check if operational mode is `pull`
		const hadPullUrl = props.stream.pullUrl;
		// if (hadPullUrl) this.unsetStreamPullUrl();
		if (hadPullUrl) requestPublishPrompt();
	}

	if (selectedTab === SourceTypes.value.Webcam && _.isEmpty(webcamDevices.value)) {
		await navigator.mediaDevices?.getUserMedia({ video: true, audio: true });
		webcamDevices.value = await navigator.mediaDevices?.enumerateDevices();
	}
}

const requestPublishPrompt = (preventSourceRestore) => {
	root.$emit('bv::show::modal', 'modal-set-publish-mode');

	if (preventSourceRestore) return;
	setTimeout(() => {
		streamSourceType.value = SourceTypes.value.Pull;
		streamSourceTypeModel.value = SourceTypes.value.Pull;
	});
}

const onMixerUsername = async (mixerUsername, ackCB) => {
	const res = await IntegrationService.getMixerFTLUrl(mixerUsername);
	ackCB(!res.mixerPullURL);

	const { mixerPullURL } = res;
	streamPullUrl.value = mixerPullURL;
}

const unsetStreamPullUrl = async (preventSourceRestore) => {
	streamSourceTypeProcessing.value = true;

	try {
		await StreamService.unsetStreamPullUrl(props.stream._id);
		// this.stream.pullUrl = null;

		emit('stream-updated', { pullUrl: null });

		notify({ group: 'success', text: 'Publish mode activated' });

		if (!preventSourceRestore) {
			// change tab to publish
			streamSourceType.value = SourceTypes.value.Publish;
			streamSourceTypeModel.value = SourceTypes.value.Publish;
		}
	} catch (e) {
		if (!preventSourceRestore) {
			streamSourceType.value = SourceTypes.value.Pull;
			streamSourceTypeModel.value = SourceTypes.value.Pull;
		}

		notify({
			group: 'error',
			text: 'could not switch to Publish mode'
		});
	}

	streamSourceTypeProcessing.value = false;
}

const requestRTMPPullUrl = async () => {
	// check if RTMP pull is allowed
	if (!props.isRtmpPullEnabled) return

	// try copy to clipboard
	const rtmpPullUrl = getStreamPullUrl();
	try {
		this.$copyText(rtmpPullUrl);
		onStreamKeyCopied();
	} catch (e) { }
}

const requestSRTPullUrl = async () => {
	// try copy to clipboard
	let server = streamKeySRT.value
	let hostId = props.mediaPulse?.hostId;

	if (props.stream.user === '62c4b3398b2e840b11244bfd') {
		hostId = 'fifa'
	}

	if (hostId) {
		const mappedHostname = `${hostId}.castr.io`;
		server = 'srt://' + mappedHostname + ':9999'
		server += `?streamid=#!::r=${props.stream.key},m=request`;
	}

	try {
		this.$copyText(server);
		onStreamKeyCopied();
	} catch (e) { }
}

const onStreamKeyCopied = () => {
	notify({ group: 'info', text: 'Copied to clipboard' });
	// track event
	window.trackEvent(`Copied RTMP pull for stream ${props.stream.name}`);
}

const changePasstoggle = () => {
	isPasswordEnabled.value = !isPasswordEnabled.value
}

const resetStreamPassword = () => {
	root.$emit('bv::show::modal', 'modal-reset-stream-password');
}

const confirmResetStreamPassword = async () => {
	try {
		await StreamService.resetStreamPassword(props.stream._id)
		const stream = await StreamService.getStream(props.stream._id)
		props.stream.config.password = stream.config.password
		notify({ group: 'success', text: 'Password changed!' });
	} catch (error) {

		notify({ group: 'error', text: 'Change password error!' });
	}
}

const initSelectedSource = (source) => {
	linkedSource.value = source
	selectedSource(source)
	streamSourceTypeModel.value = SourceTypes.value.Linked
	onSourceTypeChange()
}

const selectedSource = (source) => {
	selectedLinkedSource.value = source
	if (linkedSource.value?._id === source?._id) {
		sourceSet.value = true
	} else sourceSet.value = false
	props.stream.removedSource = false
}

const removeLinkedSource = async () => {
	if (linkedSource.value?.type === 'tvplayout') {
		// Unlink old playout config
		let oldConfig = await PlayoutService.getPlayoutConfig(linkedSource.value?._id)
		oldConfig.destination = ""
		oldConfig.settings.destinationEnabled = false
		await PlayoutService.savePlayoutConfig(linkedSource.value?._id, oldConfig)
	} else if (linkedSource.value?.key.startsWith('coder_')) {
		// Unlink old transcoder config
		let oldConfig = await TranscoderService.getTranscoder(linkedSource.value?._id)
		if (oldConfig) await TranscoderService.updateTranscoder(linkedSource.value?._id, { destination: null })
	}
}

const setLinkedSource = async () => {
	if (!selectedLinkedSource.value) return

	// Remove AIO linked source
	if (linkedSource.value?.type === 'live') {
		try {
			await StreamService.deleteStreamLinkedSourcePlatform(linkedSource.value._id)
		} catch (error) {
			console.error('Failed to delete linked source platform:', error)
		}
	}

	// Case TV Playout
	if (selectedLinkedSource.value.type === 'tvplayout') {
		const playoutConfig = await PlayoutService.getPlayoutConfig(selectedLinkedSource.value._id)
		playoutConfig.destination = props.stream._id
		playoutConfig.settings.destinationEnabled = true
		await PlayoutService.savePlayoutConfig(selectedLinkedSource.value._id, playoutConfig)
	}

	// Case Transcoder
	if (selectedLinkedSource.value.key.startsWith('coder_')) {
		// await TranscoderService.getTranscoder(selectedLinkedSource.value._id)
		await TranscoderService.updateTranscoder(selectedLinkedSource.value._id, { destination: props.stream._id })
	}

	// Case AIO
	if (selectedLinkedSource.value.key.startsWith('live_')) {
		const streamObj = selectedLinkedSource.value
		
		const payload = {
			template: 'custom',
			server: `rtmp://${props.stream.uiRegion.hostname}/static`,
			key: props.stream.key,
			region: props.stream.region.name,
			enabled: true,
			castrName: props.stream.name,
			castrStream: true,
			streamObject: props.stream._id,
			linkedSource: true
		}
		await StreamService.addStreamPlatform(streamObj._id, payload)
	}

	if (linkedSource.value && linkedSource.value._id !== selectedLinkedSource.value._id) {
		await removeLinkedSource()
	}

	linkedSource.value = selectedLinkedSource.value
	selectedLinkedSource.value = null
	sourceSet.value = true
	notify({ group: 'info', text: 'Set linked source successfully' });
}

const unsetLinkedSource = async () => {
	await removeLinkedSource()
	linkedSource.value = null
	selectedLinkedSource.value = null
	sourceSet.value = false
	props.stream.removedSource = true
	streamSourceType.value = streamSourceTypeModel.value
	onSourceTypeChange(streamSourceTypeModel.value)
	notify({ group: 'info', text: 'Removed Linked Source successfully' });
}

const onLinkedSourceDismiss = () => {
	streamSourceType.value = 'linked'
	streamSourceTypeModel.value = 'linked'
	onSourceTypeChange()
}

const updateStream = (value) => {
	if (value.area) {
		emit('stream-updated', { uiRegion: value });
		return
	}
	emit('stream-updated', value)
}

const requestUrl = (url) => {
	if (url === 'RTMP') requestRTMPPullUrl()
	if (url === 'SRT') requestSRTPullUrl()
	return
}

const updatePasswordEnabled = (value) => {
	isPasswordEnabled.value = value
	root.$emit(
		'bv::show::modal',
		'modal-pass-toggle-warning'
	);
}

const showStreamToggleModal = () => { 
	emit('show-stream-toggle-modal') 
}

const hasDestination = computed(() => {
	return !!props.stream?.destination
})

const deleteTranscoderTooltip = computed(() => {
	const linkedStreamName = props.stream?.destination?.name
	return `This transcoder cannot be deleted because it is being used as a source in ${linkedStreamName} livestream. To delete this transcoder, first remove it as a source from ${linkedStreamName} livestream.`
})

</script>

<template>
	<div class="mt-2">
		<StreamSidebarPreview :stream="stream" :media-pulse="mediaPulse" :stream-source-type="streamSourceType"
			:source-types="SourceTypes" :stream-alive="streamAlive" :stream-preview-specs="streamPreviewSpecs"
			:cannot-toggle-stream="cannotToggleStream" :transcoder-view="transcoderView" :countdown-date="countdownDate"
			@show-stream-toggle-modal="showStreamToggleModal" />

		<StreamSidebarOptionTabs @select-option-tab="onSelectTab" :stream="stream" :media-pulse="mediaPulse"
			:stream-alive="streamAlive" :selected-preview-tab="selectedPreviewTab" :playback-clicked="playbackClicked"
			:transcoder-view="transcoderView" />

		<StreamSidebarSource v-show="selectedPreviewTab === 'source-setup'" @stream-updated="updateStream"
			@select-source-tab="onSourceTypeChange" @request-url="requestUrl" @set-linked-source="setLinkedSource"
			@init-selected-source="initSelectedSource" @selected-source="selectedSource" :stream="stream"
			:stream-alive="streamAlive" :transcoder-view="transcoderView" :selected-preview-tab="selectedPreviewTab"
			:stream-source-type="streamSourceType" :source-types="SourceTypes"
			:failover-ingest-toggle-state="failoverIngestToggleState" :failover-igest-active="failoverIgestActive"
			:is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
			:selected-linked-source="selectedLinkedSource" :srt-region-ingest-destination="srtRegionIngestDestination"
			:source-set="sourceSet" :is-password-enabled="isPasswordEnabled" :backup-stream-alive="backupStreamAlive"
			:primary-stream-alive="primaryStreamAlive" :is-any-backup-stream-live="isAnyBackupStreamLive"
			:stream-Key-Srt="streamKeySRT" :media-pulse="mediaPulse" @update-password-enabled="updatePasswordEnabled" />

		<StreamSidebarPlayback v-if="!transcoderView" v-show="selectedPreviewTab === 'playback'"
			@stream-updated="updateStream" @select-source-tab="onSourceTypeChange" @request-url="requestUrl"
			@init-selected-source="initSelectedSource" @selected-source="selectedSource" :stream="stream"
			:stream-alive="streamAlive" :selected-preview-tab="selectedPreviewTab" :stream-source-type="streamSourceType"
			:source-types="SourceTypes" :failover-ingest-toggle-state="failoverIngestToggleState"
			:failover-igest-active="failoverIgestActive" :is-rtmp-pull-enabled="isRtmpPullEnabled"
			:rmpt-pull-url-processing="rmptPullUrlProcessing" :selected-linked-source="selectedLinkedSource"
			:srt-region-ingest-destination="srtRegionIngestDestination" :source-set="sourceSet"
			:is-password-enabled="isPasswordEnabled" :is-any-backup-stream-live="isAnyBackupStreamLive"
			:stream-Key-Srt="streamKeySRT" :hls-url="hlsUrl" :ll-hls="llHls" />

		<div v-if="!transcoderView && selectedPreviewTab === 'monitoring'" class="p-3 bg-dark-2"
			v-show='selectedPreviewTab === "monitoring" && !isTransitioning' id="monitoring">
			<MonitoringChart :streamKey="stream.key" :streamAlive="streamAlive" :isAllowed="isMonitoringAllowed" />
		</div>

		<div v-if="transcoderView" class="d-none d-md-flex justify-content-end mt-3"
			:class="{ 'pe-none': false, 'transcoder-action': transcoderView }">
			<div class="flex">
				<FormButton type="secondary" :label="stream.enabled ? 'Disable' : 'Enable'" @click="$emit('toggle-transcoder', stream)" />
				<div class="relative group ml-2">
					<Tooltip v-if="hasDestination" :content="deleteTranscoderTooltip">
						<FormButton type="danger" isIcon @click="$emit('delete-transcoder', stream)" :disabled="hasDestination">
							<icon-base class="" fill="none">
								<icon-bin />
							</icon-base>
						</FormButton>
					</Tooltip>
					<FormButton type="danger" isIcon @click="$emit('delete-transcoder', stream)" v-else>
						<icon-base class="" fill="none">
							<icon-bin />
						</icon-base>
					</FormButton>
				</div>
			</div>
		</div>

		<b-modal size="sm" id="modal-up" centered hide-header hide-footer>
			<p class="mb-4 text-s-m text-center text-dark-9">Please go to the dashboard and create a new all-in-one stream to
				get
				started.</p>
			<div class="d-flex justify-content-end">
				<FormButton size="md" @click="$bvModal.hide('modal-up')">
					Got it
				</FormButton>
			</div>
		</b-modal>
		<confirm-modal modal-id="modal-unset-linked-source" message="Are you sure you want to switch to a different source?"
			ok-text="Yes" cancel-text="No" @modal-confirm="unsetLinkedSource" @modal-dismiss="onLinkedSourceDismiss"
			:dismiss-after-confirm="false" />

		<confirm-modal modal-id="modal-set-publish-mode" message="Switching to Publish mode will disable your pulled stream"
			ok-text="Enable Publish Mode" cancel-text="Cancel" @modal-confirm="unsetStreamPullUrl" />

		<confirm-modal modal-id="modal-set-linked-mode"
			message="Switching to Linked Source mode will disable your pulled stream" ok-text="Enable Linked Source Mode"
			cancel-text="Cancel" @modal-confirm="unsetStreamPullUrl" />

		<confirm-modal modal-id="modal-set-publish-mode-webcam"
			message="Switching to Webcam mode will disable your pulled stream" ok-text="Disable Pulled Stream"
			cancel-text="Cancel" @modal-confirm="unsetStreamPullUrl(true)" />

		<confirm-modal modal-id="modal-webcam-leave-navigation" message="Webcam will stop streaming if you navigate"
			ok-text="Leave Anyway" cancel-text="Keep Webcam" @modal-confirm="confirmWebcamLeave" />

		<confirm-modal modal-id="modal-pull-hls-premium"
			message="http pull feature is only available in our paid subscriptions" ok-text="Upgrade now"
			cancel-text="No thanks" @modal-confirm="navigatePaymentsPage" />
		<confirm-modal modal-id="modal-failoverIngest-subscription"
			message="Backup ingest feature not available in your subscription" ok-text="Upgrade now" cancel-text="No thanks"
			@modal-confirm="navigatePaymentsPage" />

		<confirm-modal modal-id="modal-disable-password-mobile-dialog"
			message="If your encoder doesn't support passing query parameters, the password option can be disabled here."
			ok-text="Confirm" cancel-text="Cancel" @modal-confirm="confirmDisablePasswordMobile" />

		<alert-modal modal-id="alert-mixer-pull"
			message="Mixer pull is not available in this region. Please use any regions in the US and it will not impact the quality of the stream"
			ok-text="Got it" />

		<alert-modal modal-id="alert-disable-pull" message="Choose a specific region to use this mode." ok-text="Got it" />

		<prompt-modal modal-id="modal-mixer-username" message="Enter your Mixer username"
			message2="Note: If you are pulling, please disable push to mixer" ok-text="Grab Mixer Pull Url"
			cancel-text="Cancel" error-message="No FTL broadcasts found" @modal-prompt="onMixerUsername" />

		<confirm-modal modal-id="modal-backup-toggle-warning" message=""
			body="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast"
			ok-text="Ok, I will  update" cancel-text="No, don't change it" :confirmCancelEvent="false"
			@modal-confirm="onBackupModalToggleConfirm" @modal-cancel="onBackupModalToggleCancel" />

		<confirm-modal modal-id="modal-pass-toggle-warning" message=""
			body="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast"
			ok-text="Ok, I will  update" cancel-text="No, don't change it" :confirmCancelEvent="false"
			@modal-confirm="togglePasswordCheckProced" @modal-cancel="changePasstoggle" />

		<confirm-modal modal-id="modal-reset-stream-password"
			message="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast. If you have linked other products with this stream, please update them as well."
			ok-text="Ok, I will update" cancel-text="Cancel" @modal-confirm="confirmResetStreamPassword" />

	</div>
</template>

<style>
.password-toggle .custom-control-label::after,
.password-toggle .switcher[type="checkbox"]::after {
	background-image: url('~@/assets/icons/unlocked.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.password-toggle .custom-control-input:checked~.custom-control-label::after,
.password-toggle .switcher[type="checkbox"]:checked::after {
	background-image: url('~@/assets/icons/lock.svg');
	background-repeat: no-repeat;
	background-position: center;
}
</style>

<style scoped>
.video-thumb,
.video-wrapper {
	width: 100%;
	min-height: 236px;
	background-color: #000;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px 8px 0 0;
}

.player-overlay {
	border-radius: 0 0 6px 6px;
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

:deep(.player-overlay .position-absolute) {
	width: 100%;
	text-align: center;
}

:deep(.player-overlay .bg-blured) {
	background-color: rgba(30, 39, 69, 0.95);
	margin: -1rem;
	width: calc(100% + 2rem);
}

.b-overlay {
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

@supports not (backdrop-filter: blur(2px)) {
	:deep(* .blur-fix) {
		filter: blur(2px)
	}
}

@media (max-width: 767px) {
	.btn-sidebar-collapse {
		margin-bottom: 1px;
	}
}
</style>
