<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from 'lodash'
import UserService from '@/services/UserService'
import TabGroup from '../../components/Atoms/TabGroup.vue'
import TabItem from '../../components/Atoms/TabItem.vue'

// Props
const props = defineProps({
  subscriptions: Array
})

// Emit
const emit = defineEmits(['updateTitle'])

// Store and route
const store = useStore()
const route = useRoute()
const router = useRouter()

// State
const menu = ref([
  { name: 'account', href: '/manage/account' },
  { name: 'change password', href: '/manage/change-password' },
  { name: 'developers', href: '/manage/developers' },
  { name: 'API', href: '/manage/api' },
])

const userSubscription = ref(null)
const paidSub = ref(false)
const menuProcess = ref(false)
const userInformation = ref(null)
const isTeamOwner = ref(false)

// Computed properties
const baseSubscription = computed(() => store.getters['User/baseSubscription'])
const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])
const activeTeam = computed(() => store.state.User.activatedTeam)

// Methods
const checkBilingaccess = async (team) => {
  if (!team) {
    const hasCP = menu.value.some(element => element.name === 'change password')
    if (!menuProcess.value && !hasCP) {
      menu.value.splice(3, 0, { name: 'change password', href: '/manage/change-password' })
    }
  } else {
    const permission = await UserService.getMemberpermission(team, userInformation.value._id)
    let role = permission.role || null

    if (role !== 'admin') {
      menu.value = menu.value.filter(item =>
        item.name !== 'billing' && item.name !== 'change password'
      )
    }

    if (role === 'admin') {
      const hasCP = menu.value.some(element => element.name === 'change password')
      if (!menuProcess.value && !hasCP) {
        menu.value.splice(3, 0, { name: 'change password', href: '/manage/change-password' })
      }
    }
  }
}

const setUser = async () => {
  let userInfo = store.state.User
  if (!userInfo.activeTeam) {
    userInformation.value = userInfo
    isTeamOwner.value = true
  } else {
    let ownerUserInfo = await UserService.getTeamOwner(userInfo.activeTeam)
    isTeamOwner.value = ownerUserInfo.owner._id === userInfo._id
    ownerUserInfo.owner._id = userInfo._id
    ownerUserInfo.owner.activeTeam = userInfo.activeTeam
    userInformation.value = ownerUserInfo.owner
  }
  await checkBilingaccess(userInformation.value.activeTeam)
}

// Watchers
watch(activeTeam, () => {
  setUser()
}, {
  immediate: true
})

const isCurrentRoute = (item) => route.path === item.href

</script>

<template>
  <div class="container">
    <h1 class="text-2xl mt-5">{{ route.meta.title }}</h1>
    <TabGroup type="lines" class="w-full !my-4" v-if="route.name != 'SubscribeSubAccount' && route.name != 'Team' && route.name != 'Billing'">
      <TabItem type="line" v-for="(menuItem, index) in menu" :key="index" :to="menuItem.href"
        :isActive="isCurrentRoute(menuItem)">{{ menuItem.name }}</TabItem>
    </TabGroup>

    <router-view :user-subscription="userSubscription"></router-view>
  </div>
</template>


<style scoped>
/* Keep existing styles */
</style>