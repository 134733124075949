<script setup>
import { ref, computed, onMounted, onUnmounted, getCurrentInstance, watch } from 'vue';
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useNotification } from "@kyvg/vue3-notification";
import _ from 'lodash'
import DynamicService from '@/services/DynamicService'
import StreamStatsService from '@/services/StreamStatsService'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import Spinner from "@/components/ui/Spinner.vue"
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import DynamicNav from '@/components/ui/DynamicNav.vue'
import StreamPlayerDynamic from '@/components/DynamicPlayer/StreamPlayerDynamic.vue'
import StreamPlayerHlsDynamic from '@/components/DynamicPlayer/StreamPlayerHlsDynamic.vue'
import DynamicPlayerInterface from '@/components/DynamicPlayer/DynamicPlayerInterface.vue'
import DynamicPlayerFunctions from '@/components/DynamicPlayer/DynamicPlayerFunctions.vue'
import DynamicPlayerSecurity from '@/components/DynamicPlayer/DynamicPlayerSecurity.vue'
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import IconCopy from '../../components/icon/IconCopy.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import IconPlayer from '../../components/icon/IconPlayer.vue';
import IconBookOpen from '../../components/icon/IconBookOpen.vue';
import Title from '../../components/ui/Title.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconPlus from '../../components/icon/IconPlus.vue';
import IconElipsis from '../../components/icon/IconElipsis.vue';
import IconBin from '../../components/icon/IconBin.vue';
import Alert from '../../components/Atoms/Alert.vue';
import IconError from '../../components/icon/IconError.vue';
import IconCustomArrow from '../../components/icon/IconCustomArrow.vue';
import StreamSourceDropdown from '../../components/ui/StreamSourceDropdown.vue';
import IconCheckmark from '../../components/icon/IconCheckmark.vue';
import IconLinkExternal from '../../components/icon/IconLinkExternal.vue';
import TabGroup from '../../components/Atoms/TabGroup.vue';
import TabItem from '../../components/Atoms/TabItem.vue';

const store = useStore()
const router = useRouter()
const route = useRoute()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const { notify } = useNotification()

const dynamicPlayerList = ref(null)
const dynamicPlayerDropdown = ref(null)
const iframeDropdown = ref(null)

const mediaPulse = ref(null)
const scopeAlive = ref(true)
const iframe = ref('Responsive')
const streamPreviewSpecs = ref(null)
const streamPreviewSpecsUrl = ref(null)
const streamPreviewSpecsType = ref(null)
const mediaPulseTimeoutCtrl = ref(null)
const allowEditTitle = ref(false)
const dividerPosition = ref(25)

// Computed Getters
const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const hasVodSubscription = computed(() => store.getters['User/hasVodSubscription'])
const player = computed(() => store.getters['DynamicPlayers/dynamicPlayer'])
const players = computed(() => store.getters['DynamicPlayers/dynamicPlayers'])
const playerLoaded = computed(() => store.getters['DynamicPlayers/dynamicPlayerLoaded'])
const allInOneStreams = computed(() => store.getters['Streams/allInOneStreams'])

// Computed 

const playerUrl = computed(() => {
	if (player.value?.source?.liveParent) {
		const range = `${player.value.subSource?.data?.from}-${player.value.subSource?.data?.duration}`
		return `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${player.value.source.key}?range=${range}&preview=true&abr=${player.value.subSource.data.abr ? 'true' : 'false'}`
			+ (player.value.subSource.data.namedHls ? '&namedHls=true' : '');
	} else if (player.value?.subSource) {
		const playerUri = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/${player.value.subSource.data.id}?preview=true`
		return playerUri
	} else return null
})

const sourceLiveStream = computed(() => {
	if (player.value?.source && !player.value?.subSource) {
		return allInOneStreams.value.find(stream => stream._id === player.value.source._id)
	} else return null
})

const dynamicPlayerUrl = computed(() => {
	const playerUrl = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${player.value.key}`
	return playerUrl
})

const iFrameCode = computed(() => {
	if (iframe.value === 'Responsive' && dynamicPlayerUrl.value) {
		return `<iframe src="${dynamicPlayerUrl.value}" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
	} else if (iframe.value === 'Fixed Size' && dynamicPlayerUrl.value) {
		return `<iframe src="${dynamicPlayerUrl.value}" width="590" height="431" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
	} else return
})

const playerSourceType = computed(() => {
	if (player.value?.source && !player.value?.subSource) {
		return 'LiveStream'
	} else if (player.value?.subSource && player.value?.source?.liveParent) {
		return 'LiveToVod'
	} else if (player.value?.subSource && !player.value?.source?.liveParent) {
		return 'Vod'
	} else return null
})

const playerSource = computed(() => {
	if (playerSourceType.value === 'LiveStream') return sourceLiveStream.value
	if (playerSourceType.value === 'LiveToVod') return player.value.subSource.data
	if (playerSourceType.value === 'Vod') return player.value.subSource.data
	if (player.value?.subSource && !player.value?.source?.liveParent) return 'Vod'
	return null
}
)

// Watchers
watch(sourceLiveStream, async () => {
	if (sourceLiveStream.value) {
		unsubscribeMediaPulse()
		subscribeMediaPulse()
	}
})

watch(mediaPulse, async () => {
	await onMediaPulseChanged()
})

// Hooks
onMounted(async () => {
	await store.dispatch('DynamicPlayers/getPlayers');
	await store.dispatch('DynamicPlayers/getPlayer', route?.params?.playerId);
	await store.dispatch('Streams/getStreams')
})

onUnmounted(() => {
	scopeAlive.value = false
	unsubscribeMediaPulse()
})

// Methods
const onClickAnalytics = () => {
	let id = '';
	if (playerSourceType.value === 'Vod') {
		id = player.value.subSource.data.id;
	} else {
		id = player.value.source?.key;
	}
	if (id) {
		const route = router.resolve({ name: 'AnalyticsDashboardWithRoute', params: { videoId: id } });
		window.open(route.href, '_blank');
	}
}

const onDeletePlayer = async () => {
	const playerName = player.value.name
	try {
		await DynamicService.deletePlayer(
			player.value._id
		)
		await store.dispatch('DynamicPlayers/getPlayers');
		notify({
			group: 'success',
			text: `Dynamic Player ${playerName} is completely deleted from your account`,
		})
		router.push({
			name: "DynamicDashboard",
		})
	} catch (err) {
		console.log(err)
		notify({
			group: 'error',
			title: "Couldn't delete dynamic player",
			text: err.message
		});
	}
}

const setElement = async (item) => {
	try {
		if (item?.file && item?.folder) {
			await DynamicService.setVodPlayer(
				player.value._id,
				player.value.name,
				item.folder._id,
				item.file._id
			)
			notify({
				group: 'success',
				text: `Source set`,
			})
		} else if (item?.type === 'live') {
			await DynamicService.setLiveStreamPlayer(
				player.value._id,
				player.value.name,
				item._id
			)
			notify({
				group: 'success',
				text: `Source set`,
			})
		} else {
			await DynamicService.setLiveStreamPlayer(
				player.value._id,
				player.value.name,
				null
			)
			notify({
				group: 'success',
				text: `Source removed`,
			})
		}
		await store.dispatch('DynamicPlayers/getPlayers');
		await store.dispatch('DynamicPlayers/getPlayer', route?.params?.playerId);
	} catch (err) {
		console.log(err)
	}
}

const togglePlayer = async () => {
	await DynamicService.togglePlayer(
		player.value._id
	)
	dynamicPlayerDropdown.value.toggleDropdown()
	await store.dispatch('DynamicPlayers/getPlayer', route.params?.playerId)
	notify({
		group: 'success',
		text: `Status changed`,
	})
}

const subscribeMediaPulse = () => {
	const streamKey = sourceLiveStream.value.key
	const streamEnabled = sourceLiveStream.value.enabled
	console.log(streamEnabled);
		(async function loop() {
			if (!scopeAlive.value) return

			let timeoutInterval = 5000
			let shouldUpdatePulse = streamEnabled
			if (document.hidden) {
				shouldUpdatePulse = false
				timeoutInterval = 6000
			}

			if (shouldUpdatePulse) {
				if (!document.hasFocus()) {
					timeoutInterval = 14000
				}
			}
			if (shouldUpdatePulse) {
				try {
					const pulseObject = await StreamStatsService.getStreamMediaPulse(
						streamKey
					)
					onMediaPulse(pulseObject);

					// console.log('mediPulse stream', self.stream)
					if (player.value.failoverIngest) {
						const backupPulseObject = await StreamStatsService.getStreamMediaPulse(
							`${streamKey}_backup`
						)
						onBackupMediaPulse(backupPulseObject)
					}
				} catch (e) { }
			} else mediaPulse.value = null
			// reschedule
			mediaPulseTimeoutCtrl.value = setTimeout(loop, timeoutInterval);
		})();
}

const onMediaPulse = (pulseObject = {}) => {
	if (mediaPulse.value?.withoutPushStats) {
		const oldPushStats = mediaPulse.value.push_stats;
		pulseObject.push_stats = oldPushStats;
	}
	mediaPulse.value = pulseObject;
	streamAlive.value =
		player.value.enabled && mediaPulse.value?.alive;
}

const onMediaPulseChanged = async () => {
	if (mediaPulse.value?.isWowza === true) {
		streamPreviewSpecsType.value = 'hls';
		streamPreviewSpecsUrl.value = `https://wowza-stage.castr.io/static/${sourceLiveStream.value.key}/playlist_sfm4s.m3u8`;
	}

	if (_.isEmpty(streamPreviewSpecs.value) && sourceLiveStream.value?.key) {
		streamPreviewSpecsType.value = 'mse';
		streamPreviewSpecsUrl.value = `wss://wowza-stage.castr.io/${sourceLiveStream.value.key}/mse_ld?tracks=v1a1`;
	}
}

const unsubscribeMediaPulse = () => {
	clearTimeout(mediaPulseTimeoutCtrl.value);
}

const changeIframe = (type) => {
	iframe.value = type
	iframeDropdown.value.toggleDropdown()
}

const clipboardCopy = async (value) => {
	try {
		await navigator.clipboard.writeText(value);
		notify({ group: 'info', text: 'Copied to clipboard' });
	} catch (e) { }
}

const changeRouteQuery = (item) => {
	router.replace({ query: { ...route.query, tab: item } })
}

const saveNewPlayerName = async (newName) => {
	if (newName != player.value.name) {
		await DynamicService.renamePlayer(
			player.value._id,
			newName,
		)
		allowEditTitle.value = false
		notify({ group: 'info', text: 'Your player was renamed' })
		await store.dispatch('DynamicPlayers/getPlayer', route.params?.playerId)
		await store.dispatch('DynamicPlayers/getPlayers');
	}
}

const showDeleteModal = () => {
	root.$emit("bv::show::modal", 'delete-player');
	dynamicPlayerDropdown.value.toggleDropdown()
}

const goToPlayer = async (id) => {
	if (id != route.params.playerId) {
		await store.dispatch('DynamicPlayers/setCurrentPlayer', id);
		await store.dispatch('DynamicPlayers/getPlayer', id);
		router.push({
			name: "DynamicManage",
			params: { playerId: id },
		});
	} else return
}

const showAddPlayerModal = () => {
	root.$emit("bv::show::modal", 'modal-add-dynamic-player');
}

const handleDragging = (e) => {
	e.preventDefault();
	const position = e.pageX
	const wrapper = document.getElementById('resizible-wrapper')
	const windowWidth = window.innerWidth
	const percentage = ((position - 260) / wrapper.offsetWidth) * 100
	if (percentage >= 20 && percentage <= 30) {
		dividerPosition.value = percentage.toFixed(2)
	}
	if (position - 260 <= 0) {
		document.removeEventListener('mousemove', handleDragging(event))
	}
}

const startDragging = () => {
	document.addEventListener('mousemove', handleDragging(event))
}

const endDragging = () => {
	document.removeEventListener('mousemove', handleDragging(event))
}

</script>

<template>
	<div class="w-full px-6" @mouseup="endDragging()">
		<div id="resizible-wrapper" tag="main" class="relative flex flex-wrap md:flex-nowrap ">
			<DynamicNav class="pt-2 pt-md-5 aside-secondary"
				:style="{ width: `${dividerPosition}%`, 'flex-basis': `${dividerPosition}%` }" />
			<div class="aside-divider" :style="{
				left: `${dividerPosition}%`
			}" @mousedown="startDragging()">
			</div>
			<div v-if="loadingStatus" class="max-w-[1360]">
				<Spinner text="Retrieving data..." classes="text-surface-8 mt-24" spinner-color="var(--c-surface-8)"
					spinner-size="15px" />
			</div>
			<div v-else class="max-w-[1360] md:px-6 max-sm:!w-full max-sm:basis-full"
				:style="{ width: `${100 - dividerPosition}%`, 'flex-basis': `${100 - dividerPosition}%` }">
				<div class="flex mb-4 mt-12">
					<Title :editable="true" :title="player?.name" @changed="saveNewPlayerName" />
					<DropDownMenu class="block md:hidden" ref="dynamicPlayerList" type="link" maxHeight="64">
						<template #toggle-button>
							<icon-base class="ml-auto">
								<icon-chevron-up-down />
							</icon-base>
						</template>
						<template #menu-items>
							<DropDownMenuItem v-for="(player, index) in players" :key="index" @click="goToPlayer(player._id)">
								{{ player.name }}
							</DropDownMenuItem>
						</template>
					</DropDownMenu>
					<div class="!hidden md:!flex flex-col items-start ml-auto mr-2 ">
						<a class="text-tiny text-surface-6 hover:text-white hover:no-underline flex items-center gap-x-1"
							target="_blank" href="https://docs.castr.com/en/articles/7785953-how-to-create-a-dynamic-player">
							<icon-base>
								<IconBookOpen />
							</icon-base>
							How Dynamic Players works</a>
						<FormButton type="link"
							customClasses="text-tiny !text-surface-6 hover:!text-white !p-0 gap-x-1 flex items-center"
							v-b-modal.modal-demo-video>
							<icon-base>
								<IconPlayer />
							</icon-base>
							Dynamic Players Demo
						</FormButton>
					</div>
					<FormButton @click="togglePlayer" type="secondary" size="md" class="ml-2 mr-2 !hidden md:!block">
						<span v-if="player.enabled">Disable</span>
						<span v-else>Enable</span>
					</FormButton>
					<FormButton @click="showDeleteModal" isIcon type="danger" size="md" class="!hidden md:!block">
						<icon-base class="mx-auto">
							<IconBin />
						</icon-base>
					</FormButton>
					<FormButton @click="showAddPlayerModal" size="sm" class="p-2 lg:hidden mt-1 ml-auto ml-md-0 shrink-0" type="secondary">
						<icon-base>
							<IconPlus />
						</icon-base>
					</FormButton>
					<DropDownMenu class="block md:hidden" position="right" ref="dynamicPlayerDropdown" type="link" maxHeight="64">
						<template #toggle-button>
							<icon-base>
								<IconElipsis />
							</icon-base>
						</template>
						<template #menu-items>
							<DropDownMenuItem @click="togglePlayer">
								<span v-if="player.enabled">Disable</span>
								<span v-else>Enable</span>
							</DropDownMenuItem>
							<DropDownMenuItem class="text-danger" @click="showDeleteModal">
								<span>Delete</span>
							</DropDownMenuItem>
						</template>
					</DropDownMenu>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 gap-6">
					<div class="md:col-span-2">
						<Alert
							v-if="hasLiveSubscription && !hasLiveSubscription.enabled || !hasLiveSubscription && hasVodSubscription && !hasVodSubscription.enabled"
							class="mb-2" type="error" hideIcon>
							<icon-base class="!mr-2">
								<IconError />
							</icon-base>
							<span>Your plan has ended. Please <a class="underline" target="_blank" href="/app/subscribe">upgrade</a>
								to
								continue.</span>
						</Alert>
						<div v-if="!playerLoaded"
							class="video-placeholder test-base font-medium text-surface-4 flex items-center justify-center flex-wrap bg-surface-darker">
							<Spinner text="Retrieving data..." classes="text-surface-8" spinner-color="var(--c-surface-8)"
								spinner-size="15px" />
						</div>
						<div v-else-if="player && !player.enabled"
							class="video-placeholder test-base font-medium text-surface-4 flex items-center justify-center flex-wrap bg-surface-darker">
							<p class="text-surface-8 text-base w-full text-center mb-2 mt-auto">
								Disabled Stream
							</p>
							<p class="text-surface-6 text-sm w-full text-center mb-auto">Please enable</p>
						</div>
						<div v-else>
							<div v-if="player && !player.source"
								class="video-placeholder test-base font-medium text-surface-4 flex items-center justify-center flex-wrap bg-surface-darker relative">
								<div class="flex text-sureface-6 text-sm absolute right-0 top-14 gap-x-2">
									Select source first
									<icon-base class="-mt-3" width="48" height="22" viewBox="0 0 48 22">
										<IconCustomArrow />
									</icon-base>
								</div>
								Nothing to display
							</div>
							<iframe v-else-if="playerUrl" :src="playerUrl" class="embed video-frame" frameborder="0" width="100%"
								scrolling="no" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen
								msallowfullscreen />
							<div v-if="sourceLiveStream && !mediaPulse || sourceLiveStream && !mediaPulse.alive"
								class="video-placeholder test-base font-medium text-surface-4 flex items-center justify-center flex-wrap bg-surface-darker">
								Stream Offline
							</div>
							<stream-player-dynamic
								v-else-if="sourceLiveStream && mediaPulse && streamPreviewSpecsUrl && streamPreviewSpecsType === 'mse'"
								:stream="sourceLiveStream" :stream-url="streamPreviewSpecsUrl" :media-pulse="mediaPulse"
								class="video-wrapper bg-surfacedarker flex justify-center items-center" />

							<stream-player-hls-dynamic
								v-else-if="sourceLiveStream && mediaPulse && streamPreviewSpecsUrl && streamPreviewSpecsType === 'hls'"
								:stream="sourceLiveStream" :stream-url="streamPreviewSpecsUrl" :autoplay="true" :frameless="true"
								class="video-wrapper" />
						</div>
					</div>
					<div>
						<div class="flex justify-between items-center text-surface-6 mb-2 mt-4 md:!mt-0">
							<h6 class="text-surface-8 text-sm font-medium m-0">Source</h6>
							<b-button v-if="player && player.source" variant="icon" size="xs" class="p-0 text-s-s text-reset border-0"
								@click="setElement(null)">Remove</b-button>
						</div>
						<stream-source-dropdown :disabled="hasLiveSubscription && !hasLiveSubscription.enabled"
							@on-show="onSourceDropdownShow" @on-hide="onSourceDropdownHide" @on-source-selected="setElement"
							:dropRight="false" :selectedStream="playerSource" :showPlaylist="false" source="dynamic" />

						<hr class="mb-2 mt-4">
						<div class="flex justify-between items-center">
							<h6 class="text-surface-8 text-sm font-medium m-0 text-nowrap">iFrame Widget</h6>
							<DropDownMenu type="link" ref="iframeDropdown" maxHeight="64" size="sm">
								<template #toggle-button>
									{{ iframe }}
									<icon-base class="!ml-1 shrink-0 -mr-2">
										<icon-chevron-up-down />
									</icon-base>
								</template>
								<template #menu-items>
									<DropDownMenuItem @click="changeIframe('Responsive')">
										<icon-base class="!mr-2"
											:classIcon="iframe === 'Responsive' ? 'text-surface-8' : 'text-transparent'">
											<IconCheckmark />
										</icon-base>
										<span>Responsive</span>
									</DropDownMenuItem>
									<DropDownMenuItem @click="changeIframe('Fixed Size')">
										<icon-base class="!mr-2"
											:classIcon="iframe === 'Fixed Size' ? 'text-surface-8' : 'text-transparent'">
											<IconCheckmark />
										</icon-base>
										<span>Fixed Size</span>
									</DropDownMenuItem>
								</template>
							</DropDownMenu>
						</div>
						<div class="flex relative w-full mb-3">
							<FormInput customClasses="!pr-8" readonly :disabled="!dynamicPlayerUrl" :modelValue="iFrameCode"
								@click="clipboardCopy(iFrameCode)" />
							<FormButton :disabled="!dynamicPlayerUrl" class="absolute right-0 top-px z-10" isLink isIcon type="link"
								size="sm" @click="clipboardCopy(iFrameCode)">
								<icon-base class="" fill="none">
									<icon-copy />
								</icon-base>
							</FormButton>
						</div>
						<h6 class="text-surface-8 text-sm font-medium mt-4 text-nowrap">Embed URL</h6>
						<div class="flex relative w-full mb-3">
							<FormInput customClasses="!pr-14" readonly :disabled="!dynamicPlayerUrl" :modelValue="dynamicPlayerUrl"
								@click="clipboardCopy(dynamicPlayerUrl)" />
							<FormButton :disabled="!dynamicPlayerUrl" class="absolute right-6 top-px z-10" isLink isIcon type="link"
								size="sm" @click="clipboardCopy(dynamicPlayerUrl)">
								<icon-base class="" fill="none">
									<icon-copy />
								</icon-base>
							</FormButton>
							<FormButton :disabled="!dynamicPlayerUrl" class="absolute right-0 top-px z-10" isLink isIcon type="link"
								size="sm" :href="dynamicPlayerUrl">
								<icon-base class="" fill="none">
									<IconLinkExternal />
								</icon-base>
							</FormButton>
						</div>
					</div>
				</div>
				<section class="mt-6">
					<TabGroup type="lines" class="w-full">
						<TabItem type="line" label="Player interface" :isActive="!route.query.tab || route.query?.tab === 'interface'"
							@click="changeRouteQuery('interface')" />
						<TabItem type="line" label="Player functions" :isActive="route.query.tab === 'functions'"
							@click="changeRouteQuery('functions')" />
						<TabItem type="line" label="Security" :isActive="route.query.tab === 'security'"
							@click="changeRouteQuery('security')" />
						<TabItem type="line" label="Analytics" :isActive="route.query.tab === 'analytics'"
							@click="changeRouteQuery('analytics')" />
					</TabGroup>
					<DynamicPlayerInterface v-if="!route.query.tab || route.query?.tab === 'interface' " />
					<DynamicPlayerFunctions v-if="route.query?.tab === 'functions'" />
					<DynamicPlayerSecurity v-if="route.query?.tab === 'security'" />
					<div v-if="route.query?.tab === 'analytics'" class="analytics-tab-container">
						<h3 class="text-sm font-medium mt-4 mb-2">Checkout Analytics</h3>
						<p v-if="player.subSource || player.source" class="text-surface-8 text-sm">Clicking on the button will
							open Analytics in a new window by filtering the source.</p>
						<p v-else class="text-surface-8 text-sm">Please select a source to open analytics.</p>

						<FormButton class="mt-6" :disabled="!player.subSource && !player.source" @click="onClickAnalytics">Checkout
							analytics of your video</FormButton>
					</div>
				</section>
			</div>
		</div>
		<b-modal modal-class="modal_tutorial" id="modal_tutorial" centered>
			<iframe src="https://www.youtube.com/embed/ouEzsml7whU" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen></iframe>
		</b-modal>
		<demo-video-modal videoid="9UJ1aZUoaRqiJ86YfFYKG" />
		<confirm-modal modalId="delete-player" message="Are you sure you want to delete your Dynamic Player?"
			modal-type="danger" @modal-confirm="onDeletePlayer" />
	</div>
</template>

<style scoped></style>
