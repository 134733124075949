<script>
export default {
	compatConfig: {
		MODE: 3,
		COMPONENT_V_MODEL: false,
	}
}
</script>
<script setup>
import { computed, watch } from 'vue'
import _ from 'lodash';
import Overlay from './Overlay.vue';
import IconBase from '@/components/icon/IconBase.vue';
import IconCross from '@/components/icon/IconCross.vue';
import FormButton from '../Atoms/FormButton.vue';

const props = defineProps({
	title: {
		type: String
	},
	placement: {
		type: String,
		default: 'center'
	},
	bgColor: {
		type: String,
		default: 'bg-surface-2'
	},
	size: {
		type: String,
		default: 'md'
	},
	modelValue: {
		type: Boolean,
		default: false,
	},
	hideClose: {
		type: Boolean,
		default: false,
	},
	zIndex: {
		type: String,
		default: 'z-50'
	},
	wrapperClasses: {
		type: String,
		default: ''
	}
})

const emit = defineEmits({
	'update:modelValue': (value) => { return value },
	'close': (value) => { return value },
	'open': (value) => { return value },
})

const value = computed({
	get: () => props.modelValue,
	set: value => emit('update:modelValue', value),
})

const overlayClick = () => {
	confirmCancel('cancel')
}

const confirmCancel = (mode) => {
	value.value = false
	emit(mode)
}

const confirm = () => confirmCancel('confirm')

const close = () => confirmCancel('close')

const computedClassStageOne = computed(() => {
	const colorClass = props.bgColor
	const sizeClass = (() => {
		switch (props.size) {
			case 'sm':
				return 'w-full md:w-[480px]';
			case 'md':
				return 'w-full md:w-[640px] md:h-[480px] md:max-h-[70vh] overflow-y-auto';
			case 'lg':
				return 'w-full md:w-[1024px]';
			default:
				return 'w-full md:w-[640px] md:h-[480px]';
		}
	})();
	const baseClass = 'z-30 modal-block m-auto'
	const statusCLass = value ? 'modal-open' : 'modal-closed'
	const placement = props.placement === 'right' ? '' : ''
	const result = []
	result.push(baseClass, colorClass, sizeClass, statusCLass, placement)
	return result.join(' ')
})


watch(value, async () => {
	if (value.value) {
		document.body.classList.add('modalbox-open')
	} else {
		document.body.classList.remove('modalbox-open')
	}
}, { immediate: true })

</script>

<template>
	<Teleport to="body">
		<Transition enter-active-class="transition-opacity  ease-out " enter-from-class="opacity-0" enter-to-class=""
			leave-active-class="transition-opacity animate-fadeOut" leave-to-class="opacity-0 ">
			<overlay v-if="value" @overlay-click="overlayClick" :class="overlayClass">
				<div class="rounded-md relative flex flex-col" :class="[computedClassStageOne, wrapperClasses]">
					<header class="w-full flex p-6">
						<h2 class="text-lg font-medium">{{ props?.title }}</h2>
						<slot name="header" />
						<FormButton class="ml-auto" v-if="!props.hideClose" @click="close" type="link" isIcon size="sm">
							<icon-base fill="transparent">
								<icon-cross />
							</icon-base>
						</FormButton>
					</header>
					<main class="h-full">
						<slot />
					</main>
					<footer>
						<slot name="footer" />
					</footer>
				</div>
			</overlay>
		</Transition>
	</Teleport>
</template>

<style scoped>
.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-block,
.modal-leave-to .modal-block {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}


.modal-block {
	@apply transition-all duration-300 shadow-md
}

.modal-right-enter-from {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.modal-right-enter-to {
	@apply translate-y-0 md:translate-x-0
}

.modal-right-leave-from {
	@apply translate-y-0 md:translate-x-0
}

.modal-right-leave-active {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.modal-right-leave-to:not(.modal-right-leave-active) {
	@apply !hidden
}

.modal-right-leave-active .stage-two {
	@apply translate-y-0 md:translate-x-0
}

.modal-right-leave-from .stage-two {
	@apply translate-y-0 md:translate-x-0
}

.modal-right-leave-to .stage-two {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.modal-right-stage-two-enter-from {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.modal-right-stage-two-enter-to {
	@apply translate-y-0 md:translate-x-0
}

.modal-right-stage-two-leave-from {
	@apply translate-y-0 md:translate-x-0
}

.modal-right-stage-two-leave-active {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.modal-right-stage-two-leave-to:not(.modal-right-stage-two-leave-active) {
	@apply !hidden
}

.modal-right-leave-active .modal-right-stage-two {
	@apply !hidden
}

.modal-left-enter-from {
	@apply md:-translate-x-full
}

.modal-left-enter-to {
	@apply md:translate-x-0
}

.modal-left-leave-from {
	@apply md:translate-x-0
}

.modal-left-leave-active {
	@apply md:-translate-x-full
}

.modal-left-leave-to:not(.modal-left-leave-active) {
	@apply !hidden
}
</style>
<style>
body.modalbox-open {
	@apply overflow-y-hidden;
	padding-right: 6px;
}
</style>
