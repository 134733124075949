<script setup>
import { computed, getCurrentInstance, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import IconBase from '../icon/IconBase.vue';
import IconBin from '../icon/IconBin.vue';
import FormButton from '../Atoms/FormButton.vue';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import StreamService from '../../services/StreamService';
import { useRouter } from 'vue-router';
import { useNotification } from "@kyvg/vue3-notification";
import Tooltip from '@/components/Atoms/Tooltip.vue';

const { notify }  = useNotification()

const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const router = useRouter()

const playout = computed(() => store.getters['Streams/tvPlayout'])
const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const allInOneStreams = computed(() => store.getters['Streams/allInOneStreams'])

const hasDestination = computed(() => {
  return !!playoutConfig.value?.destination
})

const toggleLabel = computed(() => playout.value?.enabled ? 'Disable' : 'Enable')

const toggleStatus = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  root.$emit('bv::show::modal', 'modalToggleStream' + playout.value._id, '#btnShow')
}

let deleteMessage = ref("Are you sure you want to delete your TV Playout?");

const onDeletePlayout = async () => {
  root.$emit("bv::hide::modal", 'delete-playout-' + playout.value._id);
  try {
    await StreamService.deleteStream(
      playout.value._id
    );
    notify({ group: 'info', text: 'TV Playout Deleted' })
    router.push({ name: 'TVPlayoutDashboard' })
  } catch (err) {
    console.log(err);
  }
}

const showDeleteModal = async () => {
  root.$emit("bv::show::modal", 'delete-playout-' + playout.value._id);
}

const deleteTooltip = computed(() => {
  const destinationStream = allInOneStreams.value?.find(stream => 
    stream._id === playoutConfig.value?.destination
  )
  const linkedStreamName = destinationStream?.name
  return `This livestream cannot be deleted because it is being used as a source in ${linkedStreamName} livestream. To delete this livestream, first remove it as a source from ${linkedStreamName} livestream.`
})
</script>

<template>
  <div>
    <div class="flex items-center gap-x-2">
      <FormButton type="secondary" @click="toggleStatus">
        {{ toggleLabel }}
      </FormButton>
      <div class="relative group">
        <Tooltip v-if="hasDestination" :content="deleteTooltip">
          <FormButton type="danger" isIcon @click="showDeleteModal" :disabled="hasDestination">
            <icon-base class="" fill="none">
              <icon-bin />
            </icon-base>
          </FormButton>
        </Tooltip>
        <FormButton type="danger" isIcon @click="showDeleteModal" v-else>
          <icon-base class="" fill="none">
            <icon-bin />
          </icon-base>
        </FormButton>
      </div>
    </div>
    <toggle-stream-modal :stream="playout" @modal-toggle="toggleStatus" />
    <confirm-modal 
      :modalId="'delete-playout-' + playout?._id" 
      :message="deleteMessage"
      modal-type="danger" 
      @modal-confirm="onDeletePlayout" 
    />
  </div>
</template>

<style scoped></style>
