<script setup>
import { computed, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import StreamService from '@/services/StreamService';
import CountDown from '../CountDown.vue'
import FormButton from '../../Atoms/FormButton.vue'
import WebcamPlayer from '../WebcamPlayer.vue'
import StreamPlayer from '../StreamPlayer.vue'
import StreamPlayerHls from '../StreamPlayer.hls.vue'
import { useStore } from 'vuex'
import Tooltip from '../../Atoms/Tooltip.vue'

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  mediaPulse: {
    type: Object,
    default() { return {}; }
  },
  streamPreviewSpecs: {
    type: Object,
    default() { return {}; }
  },
  streamAlive: {
    type: Boolean
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  cannotToggleStream: {
    type: Boolean
  },
  transcoderView: {
    type: Boolean
  },
  streamCountdownSecLeft: {
    type: Object
  },
  countdownDate: {
    type: Object
  }
})

const store = useStore()

const webcamDevices = ref(null)
const countdownDate = ref(null)
const streamCountdownSecLeft = ref(null)

const userEmail = computed(() => store.state.User.email)

onMounted(async () => {
  webcamDevices.value = await navigator.mediaDevices?.enumerateDevices();
})

const emit = defineEmits(['show-stream-toggle-modal'])

const showStreamToggleModal = (event) => { 
  emit('show-stream-toggle-modal')
}

</script>
<template>
  <div class="video-wrapper">
    <webcam-player v-if="streamSourceType === sourceTypes.Webcam" :stream="stream" :audioDevice="audioDevice"
      :videoDevice="videoDevice" class="video-thumb" @stream-stopped="onWebcamStopped" @stream-started="onWebcamStarted"
      @stream-authorized="onWebcamAuthorized" />
    <div v-else-if="!stream.enabled" class="video-thumb placeholder text-center">
      <p class="text-center text-xl text-surface-7 font-medium mb-1">
        Disabled Stream
      </p>
      <p class="text-sm text-surface-5">Please enable</p>
      <Tooltip placement="top" :active="cannotToggleStream">
        <FormButton @click="showStreamToggleModal" size="sm" class="mt-4">
          Enable Stream
        </FormButton>
        <template #content>
          <span class="text-surface-8 text-small">
            You don’t have permission. Contact the team owner at <a class="text-surface-8 text-small"
              :href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
        </template>
      </Tooltip>
    </div>
    <div v-else-if="!streamAlive" class="video-thumb placeholder">
      <p v-if="streamType === 'ipcam'">No Camera Source</p>
      <p v-else-if="countdownDate && streamCountdownSecLeft"
        class="d-flex justify-content-center align-items-center flex-column">
        Stream will go live in <br>
        <CountDown :endDate="countdownDate" />
      </p>
      <p class="m-0" v-else>Waiting for stream</p>
    </div>

    <stream-player v-else-if="streamPreviewSpecs.type === 'mse'" :stream="stream" :stream-url="streamPreviewSpecs.url"
      :media-pulse="mediaPulse" class="video-thumb" />

    <stream-player-hls v-else-if="streamPreviewSpecs.type === 'hls'" :stream="stream"
      :stream-url="streamPreviewSpecs.url" :autoplay="true" :frameless="true" class="video-thumb" />
  </div>
</template>