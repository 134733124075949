<script>
export default {
	compatConfig: {
		MODE: 3,
		COMPONENT_V_MODEL: false,
	}
}
</script>
<script setup>
import { ref, computed } from 'vue'
import _ from 'lodash';
import { useNotification } from "@kyvg/vue3-notification";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ModalBox from './ModalBox.vue';
import { date } from '@/filtersNew'
import moment from "moment"

const store = useStore()
const { notify } = useNotification()
const router = useRouter()

const props = defineProps({
	item: {
		type: Object,
		default: () => { }
	},
})

const endTime = ref(null)

const isVisible = defineModel('isVisible')

const durationChanged = (value) => {
	store.dispatch('Playout/changeItemEndTime', { item: props.item, value })
	isVisible.value = false
	endTime.value = null
}

const getDate = (value) => {
	endTime.value = moment(value).valueOf()
	return date(value, 'MMM DD, YYYY HH:mm:ss', true)
}

const minTime = computed(() => {
	const isInPast = endTime.value < props.item?.startTime + 120000;
	const hours = parseInt(date(props.item?.startTime, 'HH', true))
	const minutes = parseInt(date(props.item?.startTime, 'mm', true)) + 2
	const seconds = parseInt(date(props.item?.startTime, 'ss', true))
	return isInPast ? { hours, minutes, seconds } : null
})
</script>

<template>
	<ModalBox v-model="isVisible">
		<template #header>
			<h4 class="text-surface-7 text-tiny">Starts at: <span class="text-surface-9 text-base"> {{ date(item.startTime,
				'MMM DD, YYYY HH:mm:ss ', true) }}</span></h4>
		</template>
		<section class="!px-6">
			<VueDatePicker :min-date="item.startTime" :min-time="minTime" class="mx-auto !block duration-change" inline
				text-input utc enable-seconds time-picker-inline placeholder="Select Time" calendarClassName=""
				menuClassName="!bg-surface-2 !border-none font-[Inter]"
				calendarCellClassName="text-surface-8 hover:text-white hover:bg-main" :modelValue="item.endTime"
				@update:modelValue="durationChanged">
				<template #action-preview="{ value }">
					<h4 class="text-surface-7 text-tiny !mb-1">Ends at: <span class="text-surface-9 text-base">{{ getDate(value)
							}}</span></h4>
				</template>
			</VueDatePicker>
		</section>
	</ModalBox>
</template>

<style>
.duration-change .dp__cell_offset {
	@apply text-surface-6
}

.duration-change .dp__calendar_header {
	@apply text-surface-7 !font-normal text-sm
}

.duration-change .dp--year-select, .dp__month_year_select {
	@apply text-surface-8 hover:text-white hover:bg-transparent
}

.duration-change .dp__time_display {
	@apply text-surface-8 hover:!text-white hover:!bg-transparent
}

.duration-change .dp__instance_calendar {
	@apply grid grid-cols-1 md:grid-cols-2 w-fit mx-auto gap-4
}

.duration-change .dp--tp-wrap {
	@apply size-full flex items-center justify-center mx-auto
}

.duration-change .dp__time_display_inline {
	@apply font-[Inter] text-lg md:text-3xl hover:bg-transparent
}

.duration-change .dp__time_input {
	@apply text-surface-8
}

.duration-change .dp__time_input .dp--time-overlay-btn {
	@apply relative flex flex-col after:block after:text-tiny !my-2
}

.duration-change .dp__time_input .dp__time_col:nth-of-type(1) .dp--time-overlay-btn {
	@apply after:content-['HRS']
}

.duration-change .dp__time_input .dp__time_col:nth-of-type(3) .dp--time-overlay-btn {
	@apply after:content-['MIN']
}

.duration-change .dp__time_input .dp__time_col:nth-of-type(5) .dp--time-overlay-btn {
	@apply after:content-['SEC']
}

.duration-change .dp__tp_inline_btn_bottom {
	@apply w-8
}

.duration-change .dp__tp_inline_btn_top {
	@apply w-8
}

.duration-change .dp__tp_inline_btn_bottom .dp__tp_btn_in_l {
	@apply scale-125 -translate-y-1 rotate-12 bg-surface-6 rounded
}

.duration-change .dp__tp_inline_btn_bottom .dp__tp_btn_in_r {
	@apply scale-125 -translate-y-1 -rotate-12 bg-surface-6 rounded
}

.duration-change .dp__tp_inline_btn_top .dp__tp_btn_in_l {
	@apply scale-125 -translate-y-1 -rotate-12 bg-surface-6 rounded
}

.duration-change .dp__tp_inline_btn_top .dp__tp_btn_in_r {
	@apply scale-125 -translate-y-1 rotate-12 bg-surface-6 rounded
}

.dp__active_date {
	@apply bg-main
}

.duration-change .dp__tp_inline_btn_top:hover .dp__tp_btn_in_r, .duration-change .dp__tp_inline_btn_top:hover .dp__tp_btn_in_l, .duration-change .dp__tp_inline_btn_bottom:hover .dp__tp_btn_in_r, .duration-change .dp__tp_inline_btn_bottom:hover .dp__tp_btn_in_l {
	@apply bg-white
}
.duration-change .dp__cell_inner {
	@apply !size-8
}
</style>