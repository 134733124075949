<script setup>
import { computed, ref, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import _ from 'lodash';
import DynamicService from '@/services/DynamicService';
import AlertModal from '@/components/modals/AlertModal.vue';
import PaywallService from '@/services/PaywallService'
import FormButton from '../../components/Atoms/FormButton.vue';
import FormUpload from '../../components/Atoms/FormUpload.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import Alert from '../../components/Atoms/Alert.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()

// Refs
const watermarkPositionDropdown = ref(null)

const features = ref({
	embedPoster: {
		error: false,
		enabled: false,
		value: null,
		valueType: 'string'
	},
	embedWatermark: {
		error: false,
		enabled: false,
		value: null,
		valueType: 'string'
	},
	embedWatermarkPosition: {
		error: false,
		enabled: true,
		value: 'tr',
		title: 'Top Right',
		valueType: 'string'
	},
})

const featureProcessing = ref({
	embedPoster: false,
	embedWatermark: false,
	embedWatermarkPosition: false,
})

const maxPosterUploadMB = ref(2)
const embedWatermarkTemp = ref(null)
const embedPosterTemp = ref(null)

// Computed
const player = computed(() => store.getters['DynamicPlayers/dynamicPlayer'])

const canSaveWatermarkPosition = computed(() => {
	return (
		features.value.embedWatermark.value &&
		features.value.embedWatermarkPosition.value !==
		player.value.metadata.embedWatermarkPosition
	);
})

const posterUrl = computed(() => {
	if (embedPosterTemp.value) {
		return embedPosterTemp.value
	}
	if (features.value.embedPoster.value) {
		return features.value.embedPoster.value
	}
	const imageId = player.value?.metadata?.embedPoster;
	return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
})

const watermarkUrl = computed(() => {
	if (embedWatermarkTemp.value) return embedWatermarkTemp.value;
	const imageId = player.value.metadata?.embedWatermark;
	return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
})

// Methods
const onEmbedPosterPreview = (file, name) => {
	const selector = name === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	const imageInput = document.querySelector(selector);
	const imageFile = imageInput.files[0];

	if (!imageFile) return;

	if (imageFile.type.indexOf('image/') === -1) {
		root.$emit('bv::show::modal', 'embed-upload-format');
		imageInput.value = null;
		return;
	}

	let maxSize = name === 'watermark' ? 1 : 2;
	maxPosterUploadMB.value = maxSize;

	maxSize *= 1024 * 1024;

	if (imageFile.size > maxSize) {
		root.$emit('bv::show::modal', 'embed-image-size-exceed');
		imageInput.value = null;
		return;
	}

	const validImageTypes = name === 'watermark' ? ['png', 'jpeg', 'gif', 'avif'] : null;
	const uploadedImageFileType = imageFile.type.replace('image/', '');
	// if (validImageType && imageFile.type.indexOf(validImageType) === -1) {
	if (
		validImageTypes &&
		validImageTypes.indexOf(uploadedImageFileType) === -1
	) {
		root.$emit('bv::show::modal', 'embed-image-mime-type');
		imageInput.value = null;
		return;
	}

	imageReader(imageFile, base64 => {
		if (name === 'watermark') {
			embedWatermarkTemp.value = base64;
		} else {
			embedPosterTemp.value = base64;
		}
	})
}

const saveEmbedPoster = async (type) => {
	const featureProp = type === 'watermark' ? 'embedWatermark' : 'embedPoster';
	features.value[featureProp].error = false;
	const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	const imageInput = document.querySelector(selector);
	const imageFile = imageInput.files[0];
	if (!imageInput.value || !imageFile) {
		features.value[featureProp].error = 'please pick an image file';
		return;
	}
	// this.featureProcessing.embedPoster = true;
	featureProcessing.value[featureProp] = true;
	// -- upload reuqest --
	const fdata = new FormData();
	fdata.append('file', imageFile);
	const res = await DynamicService.uploadPlayerPoster(
		player.value._id,
		fdata,
		type === 'watermark'
	);
	if (res.success) {
		if (type === 'watermark') {
			await saveWatermarkPosition();
			embedWatermarkTemp.value = null;
		} else {
			const poster = `https://assets.castr.io/embedImages/${res.uploadId}`;
			PaywallService.updateStreamPaywall(player.value._id, { wallpaper_url: poster }).catch(err => {
				console.error('update paywall stream poster failed: ', err)
			});
			embedPosterTemp.value = null;
		}
		features.value[featureProp].value = res.uploadId;
		await store.dispatch('DynamicPlayers/getPlayer', route.params?.playerId);
		notify({
			group: 'success',
			text: `${type === 'watermark' ? 'Watermark' : 'Poster'} uploaded with success`
		});
		imageInput.value = ''
	} else {
		features.value[featureProp].error = 'could not handle image upload. Please try again later';
	}

	featureProcessing.value[featureProp] = false;
}

const cancelUpload = async (type) => {
	const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
	const previewFlag = type === 'watermark' ? embedWatermarkTemp.value : embedPosterTemp.value;
	if (previewFlag) {
		if (type === 'watermark') {
			embedWatermarkTemp.value = null;
		} else {
			embedPosterTemp.value = null;
			features.value.embedPoster.error = false;
		}
		document.querySelector(selector).value = null;
		return;
	}
	removePoster(type);
}

const removePoster = async (type) => {
	try {
		const settingsProp =
			type === 'watermark' ? 'embedWatermark' : 'embedPoster';
		await saveSetting(settingsProp, null);
		features.value[settingsProp].value = null;
		await store.dispatch('DynamicPlayers/getPlayer', route.params?.playerId);
	} catch (e) {
		console.log(e);
	}
}

const saveWatermarkPosition = async () => {
	const newpos = features.value.embedWatermarkPosition.value;
	await saveSetting('embedWatermarkPosition', newpos);
	await store.dispatch('DynamicPlayers/getPlayer', route.params?.playerId);
}

const saveSetting = async (key, value, successToast = false) => {
	featureProcessing.value[key] = true;
	try {
		await DynamicService.savePlayerMetadata(player.value._id, key, value);
		// clear stream meta cache in embed player
		DynamicService.clearPlayerMetadataCache(player.value._id)
		if (successToast) {
			notify({ group: 'success', text: 'Setting saved' })
		}
	} catch (e) {
		notify({ group: 'error', text: 'could not save changes' });
	}
	featureProcessing.value[key] = false;
}

function imageReader(file, cb) {
	if (!file) {
		return;
	}

	var reader = new FileReader();
	reader.onload = function () {
		if (cb) {
			cb(reader.result);
		}
	};

	reader.readAsDataURL(file);
}

const setWatermarkPosition = (value, title) => {
	features.value.embedWatermarkPosition.value = value
	features.value.embedWatermarkPosition.title = title
	watermarkPositionDropdown.value.toggleDropdown()
}

</script>

<template>
	<div class="divide-y divide-surface-2">
		<section class="flex gap-4 flex-wrap md:flex-nowrap justify-between items-center !py-3">
			<div class="basis-full md:basis-auto">
				<h4 class="text-sm font-medium mb-2">Custom Embed Poster</h4>
				<img v-if="posterUrl" width="100px" :src="posterUrl" class="w-full size-full md:w-[100px]" />
				<p v-else class="text-tiny text-surface-6">No file choosen</p>
			</div>
			<div class="flex justify-end items-center gap-x-2">
				<FormUpload size="sm" name="poster" id="embed-poster-input" placeholder="No file chosen" type="secondary"
					:label="embedPosterTemp || player.metadata?.embedPoster ? 'Change' : 'Select Poster'" :on-file-change="onEmbedPosterPreview" />
				<FormButton size="sm" v-if="embedPosterTemp" :disabled="featureProcessing.embedPoster" @click="saveEmbedPoster">
					{{ featureProcessing.embedPoster ? 'Saving' : 'Save Poster' }}
				</FormButton>
				<FormButton size="sm" v-if="embedPosterTemp || features.embedPoster.value || player.metadata?.embedPoster" :disabled="featureProcessing.embedPoster"
					type="secondary" @click="cancelUpload">
					<span v-if="(embedPosterTemp && features.embedPoster.value)">Restore</span>
					<span v-else-if="embedPosterTemp">Cancel</span>
					<span v-else>Remove</span>
				</FormButton>
			</div>
			<Alert v-show="features.embedPoster.error" :text="features.embedPoster.error" />
		</section>
		<section class="flex gap-4 flex-wrap md:flex-nowrap justify-between items-center !py-3">
			<div class="basis-full md:basis-auto">
				<h4 class="text-sm font-medium mb-2">Embed Watermark</h4>
				<img v-if="watermarkUrl" width="100px" :src="watermarkUrl" class="w-full size-full md:w-[100px]" />
				<p v-else class="text-tiny text-surface-6">No file choosen</p>
			</div>
			<div class="flex justify-end items-center gap-x-2">
				<span class="text-tiny text-surface-8">Position</span>
				<DropDownMenu type="secondary" ref="watermarkPositionDropdown">
					<template #toggle-button>
						{{ features.embedWatermarkPosition.title }}
						<icon-base class="!ml-1">
							<icon-chevron-up-down />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('tr', 'Top Right')">
							Top Right
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('tl', 'Top Left')">
							Top Left
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('cc', 'Center')">
							Center
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('bl', 'Bottom Left')">
							Bottom Left
						</DropDownMenuItem>
						<DropDownMenuItem class="uppercase" @click="setWatermarkPosition('br', 'Bottom Right')">
							Bottom Right
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<FormUpload name="watermark" id="embed-watermark-input" placeholder="No file chosen" type="secondary"
					:label="embedPosterTemp ? 'Change' : 'Select Watermark'" :on-file-change="onEmbedPosterPreview" />
				<FormButton v-show="canSaveWatermarkPosition" :disabled="featureProcessing.embedWatermarkPosition"
					@click="saveWatermarkPosition">
					{{ featureProcessing.embedWatermarkPosition ? 'Saving' : 'Save' }}
				</FormButton>
				<FormButton v-if="embedWatermarkTemp" :disabled="featureProcessing.embedWatermark"
					@click="saveEmbedPoster('watermark')">
					{{ featureProcessing.embedWatermark ? 'Saving' : 'Save' }}
				</FormButton>
				<FormButton v-if="embedWatermarkTemp || features.embedWatermark.value || player.metadata?.embedWatermark"
					:disabled="featureProcessing.embedWatermark" type="secondary" @click="cancelUpload('watermark')">
					<span v-if="embedWatermarkTemp && features.embedWatermark.value">Restore</span>
					<span v-else-if="embedWatermarkTemp">Cancel</span>
					<span v-else>Remove</span>
				</FormButton>
			</div>
			<Alert v-show="features.embedWatermark.error" class="alert alert-danger">
				{{ features.embedWatermark.error }}
			</Alert>
		</section>
	</div>
	<alert-modal :message="'Uploaded image too large. Image must not exceed ' + maxPosterUploadMB + 'MB'"
			modal-id="embed-image-size-exceed" />
</template>