<script setup>
import { computed, watch, ref } from 'vue'

import { useStore } from 'vuex'

import StreamPlayer from '../ui/StreamPlayer.vue';
import CountDown from '../ui/CountDown.vue';
import Spinner from '../ui/Spinner.vue';
import _ from 'lodash';

const props = defineProps({
  mediaPulse: {
    type: Object,
  },
})

const store = useStore()

const streamPreviewSpecs = ref({})
const playout = computed(() => store.getters['Streams/tvPlayout'])
const playoutStartTime = computed(() => store.getters['Playout/playoutStartTime'])
const isPlayoutExist = computed(() => store.getters['Playout/isPlayoutExist'])
const shouldShowCounter = computed(() => store.getters['Playout/shouldShowCounter'])

watch(() => props.mediaPulse, () => {
  if (props.mediaPulse.isWowza === true) {
    streamPreviewSpecs.value.type = 'hls';
    streamPreviewSpecs.value.url = `https://wowza-stage.castr.io/static/${playout.value.key}/playlist_sfm4s.m3u8`;
  }

  if (_.isEmpty(streamPreviewSpecs.value)) {
    streamPreviewSpecs.value.type = 'mse';
    streamPreviewSpecs.value.url = `wss://wowza-stage.castr.io/${playout.value.key}/mse_ld?tracks=v1a1`;
  }
})
const countdownSecsLeft = computed(() => {
  const value = playoutStartTime.value - Date.now();
  return Math.max(value, 0);
})
</script>

<template>
  <div v-if="playout?.enabled">
    <stream-player v-if="props.mediaPulse?.alive" :stream="playout" :stream-url="streamPreviewSpecs.url"
      :media-pulse="mediaPulse" class="mt-4 video-placeholder bg-black position-relative" />
    <div v-else
      class="video-placeholder test-s-l text-500 text-dark-4 d-flex align-items-center justify-content-center flex-wrap bg-black mt-4">
      <p v-if="!shouldShowCounter" class="m-0">Waiting for stream</p>
      <div class="text-center" v-else-if="countdownSecsLeft > 0 && mediaPulse">
        TV Playout will go Live in <br>
        <CountDown class="text-tiny" :endDate="playoutStartTime">
        </CountDown>
      </div>
      <div v-else
        class="video-placeholder test-s-l text-500 text-dark-4 d-flex align-items-center justify-content-center flex-wrap bg-black">
        <Spinner text="Retrieving data..." classes="text-dark-4" spinner-color="var(--c-dark-8)" spinner-size="15px" />
      </div>
    </div>
  </div>
  <figure v-else class="aspect-w-16 aspect-h-9">
    <figcaption class="flex flex-col items-center justify-center flex-wrap bg-black rounded-md h-full w-full">
      <p class="text-center text-sm text-dark-7 font-medium w-full">
        Disabled Stream
      </p>
      <p class="text-center text-tiny text-dark-5 w-full">Please enable</p>
    </figcaption>
  </figure>
</template>
<style scoped></style>
