<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import _ from 'lodash'
import { useStore } from 'vuex';
import DestinationService from "@/services/DestinationService"
import IconBase from '@/components/icon/IconBase.vue'
import FormButton from '../Atoms/FormButton.vue';
import Badge from '../Badge.vue';
import IconCross from '../icon/IconCross.vue';
import { useNotification } from "@kyvg/vue3-notification"

const store = useStore()
const { notify } = useNotification()

const destinations = computed(() => store.getters['Destination/destinations'])

const props = defineProps({
	stream: {
		type: Object,
	},
	size: {
		type: String,
		default: 'md'
	},
	fullWidth: {
		type: Boolean,
		default: false,
	},
	platformUsage: {
		type: Object,
	}
})
const emit = defineEmits({
  'destinations-changed': (value) => {return value}
})

const root = ref(null)

const isVisible = ref(false)

const toggleVisible = () => {
	isVisible.value = !isVisible.value
}
const forceClose = (event) => {
  if (!root.value?.contains(event.target)) {
    isVisible.value = false
  }
}

onMounted(async () => {
	await store.dispatch('Destination/getDestinations')
  window.addEventListener('click', forceClose)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', forceClose)
})

const changeDestinationStatus = async (destination) => {
	isCheched(destination)
		? (
			await DestinationService.removeDestinationFromStream(destination._id, props.stream._id),
			notify({ group: 'success', text: 'Destination removed' })
		)
		: (
			await DestinationService.addDestinationToStream(destination._id, props.stream._id),
			notify({ group: 'success', text: 'Destination added' })
		)
	await store.dispatch('Destination/getDestinations')
	emit('destinations-changed')
}
const isCheched = (destination) => {
	return destination?.linkedStreams?.find(item => item._id === props.stream._id) ? true : false
}
const isDisabled = (isChecked) => {
	return !isChecked && props.platformUsage?.usedDestination >= props.platformUsage?.totalDestination ? true : false
}
</script>

<template>
	<div ref="root" :class="fullWidth ? 'block' : 'block md:inline-block'" class="relative">
		<FormButton label="Saved Platforms" type="secondary" @click="toggleVisible" />
		<div class="fixed md:absolute w-full lg:w-auto max-w-[100vw] md:max-w-[480px] bottom-0 md:bottom-auto md:top-8 left-0 rounded-md bg-surface-4 p-2"
			:class="isVisible ? '!block z-[99999999999] shadow-lg' : '!hidden'">
			<div class="flex items-center w-full -mt-2" v-if="destinations.length > 0">
				<h4 class="text-sm text-white !py-4">You have used <span
						:class="{ 'text-error': platformUsage.usedDestination >= platformUsage.totalDestination }">{{
							platformUsage.usedDestination }}/{{ platformUsage.totalDestination }}</span>
					destinations</h4>
				<FormButton isIcon type="link" class="ml-auto" size="md" @click="toggleVisible">
					<icon-base class="" fill="none">
						<icon-cross />
					</icon-base>
				</FormButton>
			</div>
			<h4 v-else class="text-sm m-0 text-white p-4">You have no Destinations created.</h4>

			<ul class="list-unstyled mb-0 max-h-[300px] min-w-[230px] overflow-y-auto overflow-x-none">
				<li class="bg-surface-2 px-3 py-2 flex" :class="{ 'opacity-40': isDisabled(isCheched(destination)) }"
					v-for="destination in destinations" :key="destination._id">
					<div>
						<input type="checkbox" :disabled="isDisabled(isCheched(destination))" :checked="isCheched(destination)"
							class="mr-2" @change="changeDestinationStatus(destination)" />
					</div>
					<div class="truncate">
						<h4 class="text-base text-sm text-white">{{ destination.name }}</h4>
						<p class="m-0 text-tiny text-surface-8 truncate pr-2">{{ destination.server }}</p>
					</div>
					<Badge class="ml-auto self-center" :title="destination.template" />
				</li>
				<li v-if="destinations.length === 0" class="bg-dark-2 px-3 py-2">
					<div class="text-center">
						<FormButton type="primary" to="/destination" class="mx-auto">
							Go to Destinations
						</FormButton>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<style scoped>
.list-wrapper {
	position: absolute;
	top: 40px;
	left: 0;
	z-index: 1010;
	background-color: var(--c-dark-4);
	border-radius: .5rem;
	border: 1px solid var(--c-neutral-n3);
	min-width: 300px;
}

.destination-list {
	max-height: 300px;
	min-width: 230px;
	overflow-y: auto;
	overflow-x: hidden;
}

.destination-list>li+li {
	border-top: 2px solid var(--c-dark-1)
}

.destination-list>li:first-child {
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
}

.destination-list>li:last-child {
	border-bottom-right-radius: .5rem;
	border-bottom-left-radius: .5rem;
}

.opacity-40 {
	opacity: .4;
}

@media (max-width: 767px) {
	.destination-list {
		max-height: 150px;
	}
}
</style>
