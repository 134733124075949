import _ from "lodash";
import PlayoutService from "@/services/PlayoutService";
import moment from "moment";

const defaultState = () => ({
  playoutConfig: null,
  startTime: null,
  isExist: false,
});

const state = defaultState();

const mutations = {
  GET_PLAYOUT_CONFIG(state, playoutConfig) {
    state.playoutConfig = playoutConfig;
    if (
      state.playoutConfig?.playlist[0]?.startTime &&
      state.playoutConfig?.status !== "ended"
    ) {
      state.startTime = state.playoutConfig?.playlist[0]?.startTime;
      state.isExist = true;
    } else if (
      state.playoutConfig?.playlist[0]?.startTime &&
      state.playoutConfig?.status === "ended"
    ) {
      state.startTime = moment().add(3, "minutes").valueOf();
      const timeDiff =
        state.startTime - state.playoutConfig?.playlist[0]?.startTime;
      state.playoutConfig?.playlist.map((element) => {
        element.startTime = element.startTime + timeDiff;
        element.endTime = element.endTime + timeDiff;
      });
      state.isExist = true;
    } else {
      state.startTime = moment().add(3, "minutes").valueOf();
    }
  },
  ADD_ITEM(state, item) {
    const index = state.playoutConfig.playlist?.length;
    const LIVESTREAM_DEFAULT_DURATION = 600000; // 10 min
		const BREAK_DEFAULT_DURATION = 120000; // 2 min
    const playlistElement = {};
    const latestElement = state.playoutConfig.playlist
      .sort((a, b) => {
        new Date(b.endTime) - new Date(a.endTime);
      })
      .slice(-1)
      .pop();

    playlistElement.startTime =
      state.playoutConfig.playlist?.length > 0
        ? latestElement.endTime + 1000
        : (playlistElement.startTime = state.startTime);

    if (item) {
      playlistElement.endTime =
        item.type === "live"
          ? playlistElement.startTime + LIVESTREAM_DEFAULT_DURATION
          : moment(playlistElement.startTime)
              .add(item.mediaInfo.duration, "seconds")
              .valueOf();

      playlistElement.sourceName =
        item.type === "live" ? item.name : item.fileName;
      playlistElement.sourceLocation =
        item.type === "live" ? item.key : item._id;
      playlistElement.sourceType = item.type === "live" ? "live" : "vod";
      playlistElement.index =
        item.type === "live" ? item.key : item.id + "-" + index;
    } else {
			playlistElement.endTime = moment(playlistElement.startTime).add(BREAK_DEFAULT_DURATION, "milliseconds").valueOf();
			playlistElement.sourceName = 'Break'
			playlistElement.sourceLocation = 'break'
      playlistElement.sourceType = 'break'
      playlistElement.index = 'break' + index;
		}

    state.playoutConfig.playlist.push(playlistElement);
  },
  DELETE_ITEM(state, item) {
    const itemIndex = state.playoutConfig.playlist.indexOf(item);
    const timeDiff = item.endTime - item.startTime + 1000;
    state.playoutConfig.playlist.splice(itemIndex, 1);
    state.playoutConfig.playlist.map((element) => {
      const currentIndex = state.playoutConfig.playlist.indexOf(element);
      if (currentIndex >= itemIndex) {
        element.startTime = element.startTime - timeDiff;
        element.endTime = element.endTime - timeDiff;
      }
    });
  },
  CHANGE_START_TIME(state, item) {
    if (state.playoutConfig?.playlist[0]?.startTime) {
      const timeDiff = item - state.playoutConfig?.playlist[0]?.startTime;
      state.playoutConfig?.playlist.map((element) => {
        element.startTime = element.startTime + timeDiff;
        element.endTime = element.endTime + timeDiff;
      });
    } else {
      state.startTime = moment(item).valueOf();
    }
  },
  CHANGE_ITEM_DURATION(state, data) {
    const { item, value } = data;
    const itemIndex = state.playoutConfig.playlist.indexOf(item);
    const duration = moment.duration(value).asMilliseconds();
    const oldEndTime = state.playoutConfig.playlist[itemIndex].endTime;
    const newEndTime =
      state.playoutConfig.playlist[itemIndex].startTime + duration;
    const timeDiff = newEndTime - oldEndTime;
    item.endTime = item.endTime + timeDiff;
    state.playoutConfig.playlist.map((element) => {
      const currentIndex = state.playoutConfig.playlist.indexOf(element);
      if (currentIndex > itemIndex) {
        element.startTime = element.startTime + timeDiff;
        element.endTime = element.endTime + timeDiff;
      }
    });
  },
  CHANGE_ITEM_END_TIME(state, data) {
    const { item, value } = data;
    const itemIndex = state.playoutConfig.playlist.indexOf(item);
    const oldEndTime = state.playoutConfig.playlist[itemIndex].endTime;
    const newEndTime = moment(value).valueOf()
    console.log(newEndTime);
    const timeDiff = newEndTime - oldEndTime;
    item.endTime = newEndTime
    state.playoutConfig.playlist.map((element) => {
      const currentIndex = state.playoutConfig.playlist.indexOf(element);
      if (currentIndex > itemIndex) {
        element.startTime = element.startTime + timeDiff;
        element.endTime = element.endTime + timeDiff;
      }
    });
  },
  SET_DESTINATION(state, id) {
    state.playoutConfig.destination = id;
  },
  SET_MODE(state, mode) {
    state.playoutConfig.mode = mode;
    state.playoutConfig.playlist = [];
  },
  SET_LOOP_MODE(state, endDate) {
    endDate
      ? ((state.playoutConfig.settings.loopMode = true),
        (state.playoutConfig.settings.loopEndDate = moment(endDate).valueOf()))
      : ((state.playoutConfig.settings.loopMode = false),
        (state.playoutConfig.settings.loopEndDate = null));
  },
  TOGGLE_DESTINATION(state, status) {
    !state.playoutConfig.settings?.destinationEnabled
      ? (state.playoutConfig.settings.destinationEnabled = true)
      : (state.playoutConfig.settings.destinationEnabled = false);

    if (_.isBoolean(status)) state.playoutConfig.settings.destinationEnabled = status;
  },
  TOGGLE_RETAIN_CONTENTS(state) {
    !state.playoutConfig.settings?.retainContents
      ? (state.playoutConfig.settings.retainContents = true)
      : (state.playoutConfig.settings.retainContents = false);
  },
  async SET_SCHEDULE(state, playoutId) {
    const response = await PlayoutService.savePlayoutConfig(
      playoutId,
      state.playoutConfig
    );
    if (response?.playlist[0]?.startTime) state.isExist = true;
  },
  SET_ADVANCED_SETTINGS(state, { fps, resolution }) {
    if (fps) state.playoutConfig.settings.fps = fps;
    if (resolution) state.playoutConfig.settings.resolution = resolution;
  },
  SET_NEW_ORDER(state, { oldIndex, newIndex }) {
    const playlistSorted = state.playoutConfig?.playlist?.sort((a, b) => {
      new Date(b.startTime) - new Date(a.startTime);
    });
    const playoutStart = playlistSorted[0].startTime;
    const draggableItem = playlistSorted[oldIndex];
    const draggableItemDuration =
      playlistSorted[oldIndex].endTime - playlistSorted[oldIndex].startTime;
    const staticItem = playlistSorted[newIndex];

    draggableItem.startTime = staticItem.startTime;
    draggableItem.endTime = staticItem.startTime + draggableItemDuration;

    playlistSorted.splice(oldIndex, 1);
    playlistSorted.splice(newIndex, 0, draggableItem);

    playlistSorted.map((element, index) => {
      const duration = element.endTime - element.startTime;
      element.startTime =
        index === 0
          ? (element.startTime = playoutStart)
          : playlistSorted[index - 1].endTime + 1000;
      element.endTime = element.startTime + duration;
    });
    state.playoutConfig.playlist = playlistSorted;
  },
  CLEAR(state) {
    state.playoutConfig = null;
    state.startTime = null;
    state.isExist = false;
  },
};

const actions = {
  async getPlayoutConfig({ commit }, id) {
    const playoutConfig = await PlayoutService.getPlayoutConfig(id);
    commit("GET_PLAYOUT_CONFIG", playoutConfig);
  },
  addItem({ commit }, item) {
    commit("ADD_ITEM", item);
  },
  deleteItem({ commit }, item) {
    commit("DELETE_ITEM", item);
  },
  changeStartTime({ commit }, item) {
    commit("CHANGE_START_TIME", item);
  },
  changeItemDuration({ commit }, data) {
    commit("CHANGE_ITEM_DURATION", data);
  },
  changeItemEndTime({ commit }, data) {
    commit("CHANGE_ITEM_END_TIME", data);
  },
  setDestination({ commit }, id) {
    commit("SET_DESTINATION", id);
  },
  setMode({ commit }, mode) {
    commit("SET_MODE", mode);
    if (mode === "schedule") commit("SET_LOOP_MODE");
  },
  toggleDestination({ commit }, status) {
    commit("TOGGLE_DESTINATION", status);
  },
  toggleRetainContents({ commit }) {
    commit("TOGGLE_RETAIN_CONTENTS");
  },
  setSchedule({ commit }, playoutId) {
    commit("SET_SCHEDULE", playoutId);
  },
  setAdvancedSettings({ commit }, { fps, resolution }) {
    commit("SET_ADVANCED_SETTINGS", { fps, resolution });
  },
  setNewOrder({ commit }, { oldIndex, newIndex }) {
    commit("SET_NEW_ORDER", { oldIndex, newIndex });
  },
  setLoopMode({ commit }, endDate) {
    commit("SET_LOOP_MODE", endDate);
  },
  clear({ commit }) {
    commit("CLEAR");
  },
};

const getters = {
  playoutConfig: (state) => state.playoutConfig,
  playlist: (state) =>
    state.playoutConfig?.playlist?.sort((a, b) => {
      new Date(b.startTime) - new Date(a.startTime);
    }),
  playoutStartTime: (state, getters) =>
    getters.playlist &&
    getters.playlist[0]?.startTime &&
    state.playoutConfig?.status !== "ended"
      ? getters?.playlist[0]?.startTime
      : state.startTime,
  playoutDuration: (state) => {
    let duration = 0;
    state.playoutConfig?.playlist.map((item) => {
      const itemDuration = item.endTime - item.startTime;
      duration += itemDuration;
    });
    return duration;
  },
  isPlayoutExist: (state) => state.isExist,
  shouldShowCounter: (state) => {
    return state.isExist && state.playoutConfig?.status !== 'ended'
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
