<script setup>
import Spinner from "@/components/ui/Spinner.vue";
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import { computed, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AddDynamicPlayerModal from "@/components/modals/AddDynamicPlayerModal.vue"
import FormButton from "../../components/Atoms/FormButton.vue"
import IconBase from "../../components/icon/IconBase.vue";
import IconPlayer from "../../components/icon/IconPlayer.vue";
import IconPlus from "../../components/icon/IconPlus.vue";
import IconPlay from "../../components/icon/IconPlay.vue";
import IconBookOpen from "../../components/icon/IconBookOpen.vue";

const store = useStore()
const router = useRouter()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

// Computed
const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const dynamicPlayerFeatures = computed(() => store.getters['User/dynamicPlayerFeatures'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const hasVodSubscription = computed(() => store.getters['User/hasVodSubscription'])

// Hooks
onMounted(async () => {
  store.dispatch('Ui/setLoadingStatus', true);
  await store.dispatch('DynamicPlayers/getPlayers');
  await store.dispatch('User/loadUser');
  if (store.state.DynamicPlayers.players.length > 0) {
    store.dispatch('DynamicPlayers/setCurrentPlayer', store.state.DynamicPlayers.players.at(-1)._id);
    router.push({ path: '/dynamic/' + store.state.DynamicPlayers.players.at(-1)._id })
  }
  store.dispatch('Ui/setLoadingStatus', false);
})

// Methods
const onNewPlayer = async () => {
  await store.dispatch('DynamicPlayers/getPlayers');
  router.push({ path: '/dynamic/' + store.state.DynamicPlayers.players.at(-1)._id })
}

const showTutorialModal = () => root.$emit('bv::show::modal', 'modal_tutorial')
const showDemoModal = () => root.$emit('bv::show::modal', 'modal-demo-video')
const showAddDynamicPlayerModal = () => root.$emit('bv::show::modal', 'modal-add-dynamic-player')

</script>

<template>
  <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-surface-8 mt-24"
    spinner-color="var(--c-surface-8)" spinner-size="15px" />
  <div
    v-else-if="dynamicPlayerFeatures && (hasLiveSubscription && hasLiveSubscription.enabled || hasVodSubscription && hasVodSubscription.enabled)"
    class="container page-title-area">
    <header class="flex justify-between items-center">
      <h1 class="page-title mb-3 mt-5">Dynamic Player</h1>
    </header>
    <section class="text-center mt-48">
      <h2 class="text-surface-8 text-lg font-medium">Multiple sources, one player</h2>
      <p class="text-sm text-surface-6 mb-2">Learn more about how it works <a target="_blank" class="underline"
          href="https://docs.castr.com/en/articles/7785953-how-to-create-a-dynamic-player">here</a>.</p>
      <div class="flex flex-col max-w-[225px] mx-auto">
        <FormButton class="mb-2 justify-center gap-x-2" type="secondary" @click="showDemoModal">
          <icon-base>
            <IconPlayer />
          </icon-base>
          Dynamic Players Demo
        </FormButton>
        <FormButton class="justify-center gap-x-2" @click="showAddDynamicPlayerModal">
          <icon-base>
            <IconPlus />
          </icon-base>
          New Player
        </FormButton>
      </div>
    </section>
    <add-dynamic-player-modal @player-added="onNewPlayer" />
    <demo-video-modal videoid="9UJ1aZUoaRqiJ86YfFYKG" />
  </div>
  <div class="container page-title-area" v-else>
    <header class="flex justify-between items-center">
      <h1 class="page-title mb-3 mt-5">Dynamic Player</h1>
    </header>
    <section class="text-center mt-48 text-surface-6 flex flex-col items-center">
      <h2 class="text-surface-8 text-base font-medium">Dynamic Player can play both livestreams and VOD content</h2>
      <p class="text-sm text-surface-6 mb-4">Simply switch the source and your content will be played.</p>
      <FormButton type="link" target="_blank"
        href="https://docs.castr.com/en/articles/7785953-how-to-create-a-dynamic-player"
        class="text-sm flex justify-center !text-surface-7 hover:!text-white gap-x-2">
        <icon-base>
          <IconBookOpen />
        </icon-base>
        How Dynamic Player works.
      </FormButton>
      <FormButton type="link"  @click="showTutorialModal"
        class="text-sm flex justify-center !text-surface-7 hover:!text-white gap-x-2 mb-6">
        <icon-base>
          <IconPlay />
        </icon-base>
        Watch how Dynamic Player works.
      </FormButton>
      <FormButton to="/subscribe">
        Upgrade Plan
      </FormButton>
      <b-modal modal-class="modal_tutorial" id="modal_tutorial" centered>
        <iframe src="https://www.youtube.com/embed/ouEzsml7whU" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </b-modal>
    </section>
  </div>
</template>
