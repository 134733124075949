<template>
    <div>
        <b-modal id="modal-analytics-csv-export" ref="modalCsvExport" size="md" hide-header hide-footer centered
			@shown="onDialogShown">

            <div>Please specify which parameters you would like to export.</div>

            <b-col class="my-2 mx-2">

                <b-checkbox-group
                    @change="onSelectParams"
                >
                    <b-row
                        v-for="param in parameters"
                        :key="param"
                    >
                        <b-checkbox
                            :disabled="false"
                            :value="param"
                            >{{ paramMap[param] }}
                        </b-checkbox>
                    </b-row>
                </b-checkbox-group>

            </b-col>

            <b-row>
				<b-col>
					<div class="text-right w-100 py-3 sw_modal_action">
						<b-button variant="outline-secondary" class="mr-2" size="md" @click="dismiss">
							Cancel
						</b-button>
						<b-button variant="primary" :disabled="!selectedParams.length"
							@click="onExportCsv" size="md">
							Export
						</b-button>
					</div>
				</b-col>
			</b-row>

        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'CsvDialog',
        data() {
            return {
                selectedParams: [],
                paramMap: {
                    play: "Plays",
                    playAttempts: "Play Attempts",
                    uniqueUsers: "Unique Users",
                    concurrentViewers: "Concurrent Viewers",
                    totalPageLoads: "Total Page Loads",
                    totalHoursWatched: "Total Hours Watched",
                    viewTime: "View Time",
                },
            }
        },
        computed: {
            parameters() {
                return Object.keys(this.paramMap);
            }
        },
        methods: {
            onSelectParams(params) {
                this.selectedParams = params;
            },
            onDialogShown() {
            },
            dismiss() {
                this.$refs.modalCsvExport.hide();
            },
            onExportCsv() {
                this.$emit('on-export-csv', this.selectedParams);
                this.dismiss();
            },
        },
    }
</script>

<style scoped>

</style>