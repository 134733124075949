<script setup>
import { computed, watch, ref, onMounted, getCurrentInstance } from 'vue'
import PaywallService from "@/services/PaywallService";
import IconBanknote from "../../components/icon/IconBanknote.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconBin from "../../components/icon/IconBin.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import IconElipsisVertical from '@/components/icon/IconElipsisVertical.vue'
import { useRoute, useRouter } from 'vue-router'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import Title from '../ui/Title.vue'
import Alert from '../Atoms/Alert.vue';

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify } = useNotification()

const userPaywall = computed(() => store.getters['User/userPaywall'])
const paywalls = computed(() => store.getters['Paywalls/paywalls'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])

const props = defineProps({
  onStripeConnectClick: {
    type: Function,
  },
  onStripeConnectCancelClick: {
    type: Function
  },
  stripeConnectProcessing: {
    type: Boolean,
  },
  stripeOauthLink: {
    type: String
  },
  stripeConnectLinkError: {
    type: Boolean
  },
  paywallActive: {
    type: Boolean
  },
  currentPaywall: {
    type: Object
  }
})

const emit = defineEmits({
  'paywall-toggle': (value) => { return value }
})

const changeName = async (newName) => {
  if (props.currentPaywall.title === newName) {
    return
  }
  try {
    await PaywallService.updateStreamPaywallById(props.currentPaywall.id, { title: newName })
    store.dispatch('Paywalls/setPaywallProp', { id: props.currentPaywall.id, prop: 'title', value: newName })
    notify({
      group: "success",
      text: "Paywall source updated",
    });
  } catch (err) {
    notify({
      group: 'error',
      text: err.message || "Paywall name change failed",
    })
  }
}

const togglePaywall = async () => {
  try {
    await PaywallService.toggleStreamPaywall(props.currentPaywall.id)

    store.dispatch('Paywalls/setPaywallToggle', props.currentPaywall.id)

    emit('paywall-toggle')

    notify({
      group: "success",
      text: "Paywall updated",
    });
  } catch (err) {
    notify({
      group: "error",
      text: err.message || "Paywall toggle failed",
    });
  }
}
const showDeleteModal = () => root.$emit('bv::show::modal', 'delete-paywall')
const togglePaywallWarning = async () => {
  emit('paywall-toggle-warning')
}

</script>

<template>
  <header v-if="currentPaywall" class="flex flex-wrap items-center mb-6 md:mb-0 md:p-6 gap-x-3">
    <Title class="!hidden md:!block" titleClasses="text-xl font-medium w-full max-w-full break-all md:w-auto" :editable="true" :title="currentPaywall?.title"
      @changed="changeName" />

    <FormButton class="ml-auto" @click="togglePaywallWarning" type="light"
      :label="currentPaywall.enabled ? '' : ''">
      <icon-base>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.45234 4.81148C5.00855 1.82355 5.78665 0.32959 7 0.32959C8.21334 0.32959 8.99144 1.82355 10.5476 4.81148L10.5477 4.81149L12.2876 8.15228C13.6496 10.7673 14.3306 12.0748 13.7466 13.0374C13.1626 14 11.6884 14 8.73996 14L5.25999 14H5.25998C2.31159 14 0.837399 14 0.253401 13.0373C-0.330596 12.0747 0.350385 10.7672 1.71235 8.15226L3.45234 4.81148ZM7.99995 10.6666C7.99995 11.2189 7.55224 11.6666 6.99995 11.6666C6.44767 11.6666 5.99995 11.2189 5.99995 10.6666C5.99995 10.1143 6.44767 9.66663 6.99995 9.66663C7.55224 9.66663 7.99995 10.1143 7.99995 10.6666ZM6.99996 8.33329C7.4602 8.33329 7.83329 4.9602 7.83329 4.49996C7.83329 4.03972 7.4602 3.66663 6.99996 3.66663C6.53972 3.66663 6.16663 4.03972 6.16663 4.49996C6.16663 4.9602 6.53972 8.33329 6.99996 8.33329Z" fill="#AEBAD6"/>
        </svg>
      </icon-base>
      <span class="ml-1">
        Notice
      </span>
    </FormButton>
    <FormButton class="" @click="togglePaywall" type="secondary"
      :label="currentPaywall.enabled ? 'Disable' : 'Enable'" />
    <FormButton @click="showDeleteModal" type="danger" isIcon>
      <icon-base>
        <icon-bin />
      </icon-base>
    </FormButton>
    <p class="!order-first md:!order-none md:w-full text-surface-6 text-tiny mb-0">
      <a class="inline-flex items-center"
      href="https://docs.castr.com/en/articles/8925810-how-to-create-a-paywall" target="_blank">
      <icon-base class="mr-1">
        <icon-book-open />
      </icon-base>
      How Paywall works
    </a>
    </p>
  </header>
  <Alert v-if="!hasLiveSubscription || isLiveSubscriptionExpired" type="error" class="mx-6">
    Your plan has ended. Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a>
    to
    continue.
  </Alert>
</template>

<style scoped></style>
