<script setup>
import { computed, ref } from 'vue'
import IconBase from '../icon/IconBase.vue';
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"


const props = defineProps({
  content: {
    type: String,
  },
  wrapperClass: {
    type: String,
    default: 'inline'
  },
  interactive: {
    type: Boolean,
    default: false,
  },
  hide: {
    type: Number,
    default: 300,
  },
  active: {
    type: Boolean,
    default: true
  }
})

</script>
<template>
  <div :class="wrapperClass">
    <VTooltip popperClass="max-w-[200px] text-sm" v-if="props.active" :delay="{
      hide: props.hide
    }">
      <slot />

      <template #popper>
        {{ props.content }}
        <slot name="content" />
      </template>
    </VTooltip>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<style scoped></style>