<script setup>
import { computed, } from 'vue'
import Spinner from '../Spinner.vue';
import FormButton from '../../Atoms/FormButton.vue'
import Tooltip from '../../Atoms/Tooltip.vue'

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  disabled: {
    type: Boolean,
    default: false
  },
  streamSourceTypeProcessing: {
    type: Boolean
  }
})

const emit = defineEmits({
  'request-url': (url) => { return { url } },
  'set-stream-pull-url': (value) => value 
})

const tooltipMessage = computed(() => {
  return !props.isRtmpPullEnabled ? "This feature is not available for your plan. Please upgrade to use it." : "This can only be used when stream is live"
})

const requestUrl = (url) => emit('request-url', url);
const setStreamPullUrl = () => emit('set-stream-pull-url');

</script>
<template>
  <div class="flex items-center justify-center mt-3 gap-x-2">
    <FormButton size="sm" v-if="streamSourceType === sourceTypes?.Pull"
      :disabled="disabled || streamSourceTypeProcessing" @click="setStreamPullUrl">
      <Spinner v-if="streamSourceTypeProcessing" text="Retrieving data..." classes="text-dark-8"
        spinner-color="var(--c-surface-8)" spinner-size="12px" />
      <span v-else>Save</span>
    </FormButton>
    <Tooltip :active="!streamAlive || !isRtmpPullEnabled">
      <FormButton v-show="stream.type === 'restream' || stream.type === 'live'" :disabled="!streamAlive || !isRtmpPullEnabled" size="sm" @click="requestUrl('RTMP')">
        <span v-if="rmptPullUrlProcessing">
          <Spinner text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
            spinner-size="15px" />
        </span>
        <span v-else>Get RTMP Pull</span>
      </FormButton>
      <template #content>
        <span v-if="!streamAlive">{{ tooltipMessage }}</span>
        <span v-else class="text-surface-8 test-tiny">Click to get your RTMP pull URL. Need static URL? <a
            target="blank" class="text-reset text-underline"
            href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s
            how</a>.</span>
      </template>
    </Tooltip>

    <!-- srt pull -->
    <Tooltip :active="!streamAlive || !isRtmpPullEnabled" :content="tooltipMessage">
      <FormButton :disabled="!streamAlive || !isRtmpPullEnabled" size="sm"
        v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestUrl('SRT')">
        <Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-surface-8 my-3"
          spinner-color="var(--c-dark-8)" spinner-size="15px" />
        <span v-else>Get SRT Pull</span>
      </FormButton>
    </Tooltip>
  </div>
</template>