<template>
	<div class="w-100">
		<b-button :disabled="selectorDisabled" variant="dark-3" class="justify-content-start text-left w-100" @click="toggleVisible" :size="size"
			:block="fullWidth">
			<div class="flex items-center" v-if="stream && stream.uiRegion">
				<img :src="getCountryFlag(stream.uiRegion)" width="20" class="region-flag mr-2">
				<span class="region-name">{{ stream.uiRegion.name }}</span>
			</div>
			<div v-else class="font-weight-normal text-left w-100">
				Choose your region
			</div>
			<svg class="ml-auto mr-0" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M4.60944 0.544448C4.83413 0.351853 5.16569 0.351853 5.39039 0.544448L8.89039 3.54445C9.14198 3.7601 9.17112 4.13888 8.95547 4.39048C8.73981 4.64207 8.36103 4.67121 8.10944 4.45556L4.99991 1.79025L1.89039 4.45556C1.63879 4.67121 1.26001 4.64207 1.04436 4.39048C0.828706 4.13888 0.857843 3.7601 1.10944 3.54445L4.60944 0.544448ZM1.04436 7.60953C1.26001 7.35793 1.63879 7.32879 1.89039 7.54445L4.99991 10.2098L8.10944 7.54445C8.36103 7.32879 8.73981 7.35793 8.95547 7.60953C9.17112 7.86112 9.14198 8.2399 8.89039 8.45556L5.39039 11.4556C5.16569 11.6481 4.83413 11.6481 4.60944 11.4556L1.10944 8.45556C0.857843 8.2399 0.828706 7.86112 1.04436 7.60953Z"
					fill="currentColor" />
			</svg>
		</b-button>
		<div v-if="visible" class="regions-wrapper">
			<div class="d-flex py-2 align-items-center">
				<h4 class="text-dark-7 ml-3 text-s-s m-0">Change your region</h4>
				<b-button variant="icon" size="sm" class="ml-auto p-0 mr-2" @click="close">
					<img src="@/assets/images/menu-close.svg" alt="Menu close">
				</b-button>
			</div>
			<b-tabs pills vertical content-class="bg-dark-5 py-3 mb-3 mr-3 rounded-r" class="no-gutters"
				nav-wrapper-class="mb-3">
				<b-tab v-for="(area, index) in areas" :key="index" :title="area" title-link-class="region-tab-link"
					:active="area === activeArea" @click="setActiveArea(area)">
					<ul class="list-unstyled text-500 region-list max-h-[320px] overflow-y-auto overflow-x-hidden">
						<li v-for="(region, index) in regionsFiltered" :key="index" class="">
							<b-button variant="dark-5" class="w-100 text-left justify-start text-sm"
								@click="setRegion(region)">
								<img width="24" class="mr-2" :src="getCountryFlag(region)" :alt="region.name + ' flag'"> {{ region.name }}
								<svg v-if="stream.uiRegion && (region._id === stream.uiRegion._id)" class="ml-auto mr-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM11.0303 5.53033C11.3232 5.23744 11.3232 4.76256 11.0303 4.46967C10.7374 4.17678 10.2626 4.17678 9.96967 4.46967L6 8.43934L4.03033 6.46967C3.73744 6.17678 3.26256 6.17678 2.96967 6.46967C2.67678 6.76256 2.67678 7.23744 2.96967 7.53033L5.46967 10.0303C5.76256 10.3232 6.23744 10.3232 6.53033 10.0303L11.0303 5.53033Z" fill="white"/>
								</svg>
							</b-button>
						</li>
					</ul>
				</b-tab>
			</b-tabs>
		</div>
		<confirm-modal modalId="confirm-region-change" message="Change region"
			body="Changing the region will alter your RTMP/SRT URL. Please ensure to update the encoder accordingly."
			ok-text="Confirm" cancel-text="Cancel" @modal-cancel="closeModal" @modal-confirm="confirmRegionChange" />
	</div>
</template>

<script>
import _ from 'lodash'
import StreamService from '@/services/StreamService'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

export default {
	name: 'StreamRegion',
	components: {
		ConfirmModal,
	},
	props: {
		stream: {
			type: Object,
		},
		size: {
			type: String,
			default: 'md'
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		showAlert: {
			type: Boolean,
			default: false,
		},
		selectorDisabled: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			visible: false,
			regions: [],
			activeArea: '',
			newRegion: null,
		};
	},
	computed: {
		areas() {
			const areaList = [...new Set(this.regions.map(item => item.area && item.area))].filter(item => item !== undefined)
			return areaList
		},
		regionsFiltered() {
			let regionsList = this.regions.filter(item => item.area === this.activeArea && item.platforms.find(item => item === 'restream'))

			// remove dubai region from this user, it has dubai region prefix in old format(ae)
			if (this.stream.user === '5ba39c5e688503562c8097b4') {
				regionsList = regionsList.filter( item => item.identifier !== 'dubai')
			}
			return _.sortBy(regionsList, (item) => parseFloat(item.sortOrder))
		}
	},
	async mounted() {
		this.regions = await StreamService.getAvailableRegions('list')
		this.activeArea = this.areas[0]
	},
	destroyed() {

	},
	methods: {
		toggleVisible() {
			this.visible = !this.visible
		},
		getCountryFlag(region) {
			const identifier = _.get(region || {}, 'identifier', '');
			return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
		},
		close() {
			this.visible = false
		},
		setActiveArea(area) {
			this.activeArea = area
		},
		setRegion(region) {
			this.newRegion = region
			if(this.showAlert) {
				this.$root.$emit("bv::show::modal", 'confirm-region-change')
			} else {
				this.$emit('switch-stream-region', this.newRegion)
				this.visible = false
				this.newRegion = null
			}
		},
		confirmRegionChange() {
			this.$emit('switch-stream-region', this.newRegion)
			this.visible = false
			this.newRegion = null
		},
		closeModal() {
			this.$root.$emit("bv::hide::modal", 'confirm-region-change')
			this.visible = false
			this.newRegion = null
		}
	}
};
</script>

<style scoped>
.regions-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1010;
	background-color: var(--c-dark-3);
	border-radius: .5rem;
	border: 1px solid var(--c-neutral-n3);
	min-width: 100%;
}

:deep(.nav-pills .region-tab-link),
:deep(.nav-pills .show>.region-tab-link) {
	font-size: var(--font-s-xs);
	font-weight: 500;
	color: var(--c-dark-9);
	padding: .5rem;
	margin-left: 1rem;
	border-radius: .5rem 0 0 .5rem;
}

:deep(.nav-pills .region-tab-link.active),
:deep(.nav-pills .show>.region-tab-link) {
	color: #fff;
	background-color: var(--c-dark-5);
}

:deep(.rounded-r) {
	border-radius: 0 .5rem .5rem 0;
}
@media (max-width: 767px) {
	.region-list {
		max-height: 210px;
		overflow-y: auto;	
	}
}
</style>
