<script setup>
import { computed, ref, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification"
import utils from '@/utils';
import StreamService from '@/services/StreamService';
import IconBase from '../../icon/IconBase.vue';
import FormInput from '../../Atoms/FormInput.vue';
import GetRtmpSrtButtons from './GetRtmpSrtButtons.vue';

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  transcoderView: {
    type: Boolean,
    default: false
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  selectedLinkedSource: {
    type: Object,
    default: null
  },
  sourceSet: {
    type: Boolean,
    default: false
  },
  getStreamPushUrl: {
    type: Function
  },
  getStreamUrlAndKey: {
    type: String,
  },
  streamKey: {
    type: String
  },
  isPasswordEnabled: {
    type: Boolean,
    default: false
  },
  isAnyBackupStreamLive: {
    type: Boolean,
    default: false
  },
  streamKeySrt: {
    type: Object,
    default: null
  },
  srtRegionIngestDestination: {
    type: String,
    default: null
  },
})

const store = useStore()
const { notify } = useNotification()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const emit = defineEmits({
  'request-url': (url) => { return url },
  'set-linked-source': (val) => { return val },
  'init-selected-source': (source) => { return source },
  'selected-source': (source) => { return source },
})

const streamPullUrl = ref(null)
const streamPullError = ref(false)
const isPullSourceValid = ref(true)
const isLoading = ref(false)

const isTrialSubscription = computed(() => store.getters['User/isTrialSubscription'])

onMounted(async () => {
  streamPullUrl.value = props.stream?.pullUrl
})

const pullSourceValidation = () => {
  // Clean the input string by removing all whitespace characters
  const pullSource = streamPullUrl.value?.replace(/\s/g, "");
  streamPullUrl.value = pullSource;
  if (pullSource?.length > 0) {
    isPullSourceValid.value = utils.validateSrtUrl(pullSource)
  } else {
    isPullSourceValid.value = true
  }
}

const onPullUrlChange = () => {
  pullSourceValidation();
  streamPullError.value = false;
}

const isDisabled = computed(() => {
  if (streamPullUrl.value?.length === 0) return true
  if (streamPullUrl.value === props.stream?.pullUrl) return true
  if (!isPullSourceValid.value) return true
  return false
})

const isMixerPullAuthorized = () => {
  const exlcudedRegions = ['br'];
  const curRegion = props.stream.region.identifier;

  let bypassed = true;
  for (let i = 0; i < exlcudedRegions.length; i++) {
    if (curRegion === exlcudedRegions[i]) {
      bypassed = false;
      break;
    }
  }
  return bypassed;
}

const parseURL = (srcURL) => {
  let url;
  try {
    url = new URL(srcURL);
  } catch (_) {
    return false;
  }
  return url
}

const setStreamPullUrl = async () => {
  const pullSource = streamPullUrl.value.replace(/\s/g, '');
  if (utils.isMixerFTLSource(pullSource) && !isMixerPullAuthorized()) {
    root.$emit('bv::show::modal', 'alert-mixer-pull');
    return;
  }
  if (isTrialSubscription.value) {
    const url = parseURL(pullSource)
    if (url.protocol === "http:" || url.protocol === "https:") {
      root.$emit('bv::show::modal', 'modal-pull-hls-premium');
      return;
    }
  }

  try {
    isLoading.value = true
    await StreamService.setStreamPullUrl(props.stream._id, pullSource);
    isLoading.value = false
    emit('stream-updated', { pullUrl: pullSource });
    
    notify({ group: 'success', text: 'stream pull url saved' });
  } catch (e) {
    notify({
      group: 'error',
      text: 'could not save stream pull url'
    });
  }
}

const requestUrl = (url) => emit('request-url', url);
</script>
<template>
  <!-- Publish source tabs -->
  <div class="flex flex-wrap w-full mt-3">
    <div class="flex relative w-full">
      <FormInput label="Pull Source" v-model="streamPullUrl" @blur="pullSourceValidation" @keypress="onPullUrlChange()"
        :isError="!isPullSourceValid" placeholder="Specify source url" />
      <code v-if="stream.enabled && stream.pullUrl" class="ml-auto text-tiny absolute right-0"
        :class="streamAlive ? 'text-primary' : 'text-warning'">{{ streamAlive ? 'Connected' : 'Connecting' }}</code>
    </div>
    <p class="flex gap-x-2 text-sm mt-2" :class="isPullSourceValid ? 'text-surface-7' : 'text-danger'">
      <icon-base classIcon="mt-1">
        <icon-alert-triangle />
      </icon-base>
      <span>Please make sure the Pull URL follows our <a class="underline" target="_blank"
          href="https://docs.castr.com/en/articles/5268042-supported-protocols-for-pull-mode">supported
          formats</a></span>
    </p>

    <GetRtmpSrtButtons v-if="!transcoderView" @request-url="requestUrl" :stream="stream" :stream-alive="streamAlive"
      :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
      :stream-source-type="streamSourceType" :source-types="sourceTypes" :disabled="isDisabled" class="!justify-start"
      @set-stream-pull-url="setStreamPullUrl" :stream-source-type-processing="isLoading" />
  </div>
</template>