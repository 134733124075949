<script setup>
import { computed, ref, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import _ from 'lodash';
import DynamicService from '@/services/DynamicService'
import FormSwitch from '@/components/Atoms/FormSwitch.vue';
import FormButton from '@/components/Atoms/FormButton.vue';
import FormInput from '@/components/Atoms/FormInput.vue';
import Spinner from "@/components/ui/Spinner.vue";
import MSEPlayer from '@flussonic/flussonic-mse-player';
import Badge from '../Badge.vue';
import IconBase from '../icon/IconBase.vue';
import IconPlayerPauseOn from '../icon/IconPlayerPauseOn.vue';
import IconPlayerPauseOff from '../icon/IconPlayerPauseOff.vue';
import IconPlayerFullScreen from '../icon/IconPlayerFullScreen.vue';
import IconPlayerVolumeOff from '../icon/IconPlayerVolumeOff.vue';
import IconPlayerVolumeOn from '../icon/IconPlayerVolumeOn.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	mediaPulse: {
		type: Object,
		default() { return {}; }
	},
	streamUrl: {
		type: String,
		required: true
	}
})

// Refs
const bufferWaitTimeout = ref(null)
const playing = ref(false)
const playback = ref(false)
const playbackUrl = ref(null)
const playbackPaused = ref(false)
const videoPauseImgUrl = ref(null)
const buffered = ref(true)
const controls = ref({
	volume: false,
	fullscreen: false
})
const scopeAlive = ref(true)
const flashDisabled = ref(false)
const videoDOM = ref(null)
const videoPlayer = ref(null)

// Hooks
onMounted(() => {
	window.FlussonicMsePlayer = MSEPlayer;
	playbackUrl.value = props.streamUrl;

	const hostId = props.mediaPulse?.hostId;
	if (hostId) {
		const staticPrefix = props.mediaPulse.staticPrefix;
		const anc = document.createElement('a');
		anc.href = playbackUrl.value;

		if (/\w+-\d+/gi.test(hostId) || props.stream.pullUrl) {
			anc.hostname = `${hostId}.castr.io`;
			anc.pathname = anc.pathname.replace(props.stream.key, staticPrefix ? `static/${props.mediaPulse.name}` : props.mediaPulse.name);
		}
		playbackUrl.value = anc.href;
	}
	initPlaybackSetup();
})

onBeforeUnmount(() => {
	scopeAlive.value = false;
	stopPlayback();
})

// Methods

const initPlaybackSetup = () => {
	if (!scopeAlive.value) return;

	playbackPaused.value = false;

	const element = document.getElementById('player');

	if (!element) return;

	videoDOM.value = element;
	videoDOM.value.volume = 0;

	const playerConfig = {
		bufferMode: 'sequence',
		onProgress: () => {
			playing.value = true;
		},
		onMediaInfo: () => {
			playback.value = true;
		},
		onError: () => {
			if (!scopeAlive.value) return;

			stopPlayback(() => {
				if (!scopeAlive.value) return;

				window.player.play();
				if (window.player.setBufferMode) { window.player.setBufferMode('segments'); }
			});
		}
	};

	console.log('prop:streamUrl', playbackUrl.value);
	// eslint-disable-next-line no-undef
	const playerInstance = new FlussonicMsePlayer(
		element,
		playbackUrl.value,
		playerConfig
	);

	buffered.value = false;
	playerInstance.play();

	const bufferWaitTimeout = setTimeout(() => {
		console.log('bufferr timedout, retrying playback');
		stopPlayback(() => {
			initPlaybackSetup();
		});
	}, 25 * 1000);

	window.player = videoPlayer.value = playerInstance;

	// DOM events
	if (element) {
		element.onplaying = () => {
			buffered.value = true;
			clearTimeout(bufferWaitTimeout);
		};
	}
}


const stopPlayback = (cb) => {
	// const { player } = window;
	const player = videoPlayer.value;
	playing.value = false;
	if (controls.value.volume) {
		toggleVolume();
	}
	const regex = /(\/mse_).*/s;
	const str = playbackUrl.value;
	const subst = ``;
	const result = str.replace(regex, subst);
	videoPauseImgUrl.value = 'https' + result.substring(3) + '/preview.mp4';
	try {
		player.pause();
	} catch (e) { }

	setTimeout(async () => {
		player.stop();
		playbackPaused.value = true;

		if (player.stopPromise) {
			await player.stopPromise;
		}
		if (cb) {
			cb();
		}
	}, 500);
}

const toggleVolume = () => {
	const oldVolume = videoDOM.value.volume;
	videoDOM.value.volume = oldVolume === 1 ? 0 : 1;
	controls.value.volume = videoDOM.value.volume === 1;
}

const toggleFullscreen = () => {
	const elem = videoDOM.value;
	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) {
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) {
		elem.webkitRequestFullscreen();
	}
}

</script>

<template>
	<div class="">
		<video class="player h-full w-full" v-if="playbackPaused" :src="videoPauseImgUrl"></video>
		<div v-if="!buffered" class="player-loading-indicator w-full flex justify-center absolute">
			<Spinner text="" classes="text-surface-8 my-3 mx-auto" spinner-color="var(--c-surface-8)" spinner-size="15px" />
		</div>
		<div v-if="playback" class="player-controls-wrapper flex">
			<Badge class="inline-flex absolute top-2 left-2" type="online">Live</Badge>

			<div :class="{ 'playing': playing }" class="absolute bottom-0 w-full left-0 z-2">
				<div class="flex mt-5 justify-center gap-x-2 !p-2">
					<FormButton v-if="!playbackPaused"
						customClasses="px-0 size-10 bg-light-3 rounded-full flex items-center justify-center ml-auto"
						@click="stopPlayback()">
						<icon-base width="20" height="20" widthViewBox="20" heightViewBox="20">
							<IconPlayerPauseOn />
						</icon-base>
					</FormButton>
					<FormButton v-else customClasses="px-0 size-10 bg-light-3 rounded-full flex items-center justify-center ml-auto"
						@click="initPlaybackSetup()">
						<icon-base width="20" height="20" widthViewBox="20" heightViewBox="20">
							<IconPlayerPauseOff />
						</icon-base>
					</FormButton>
					<FormButton v-if="!controls.volume"
						customClasses="px-0 size-10 bg-error rounded-full flex items-center justify-center  -mr-12" @click="toggleVolume">
						<icon-base width="20" height="20" widthViewBox="20" heightViewBox="20">
							<IconPlayerVolumeOff />
						</icon-base>
					</FormButton>
					<FormButton v-else customClasses="px-0 size-10 bg-error rounded-full flex items-center justify-center"
						@click="toggleVolume">
						<icon-base width="20" height="20" widthViewBox="20" heightViewBox="20">
							<IconPlayerVolumeOn />
						</icon-base>
					</FormButton>
					<FormButton customClasses="px-0 size-10 bg-light-3 rounded-full flex items-center justify-center ml-auto mr-0"
						@click="toggleFullscreen">
						<icon-base width="20" height="20" widthViewBox="20" heightViewBox="20">
							<IconPlayerFullScreen />
						</icon-base>
					</FormButton>
				</div>
			</div>

		</div>
		<video id="player" class="rounded-0" />
	</div>
</template>

<style scoped></style>
