<script setup>
import { computed, ref, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification"
import StreamService from '@/services/StreamService';
import FormButton from '../../Atoms/FormButton.vue'
import IconBase from '../../icon/IconBase.vue';
import IconCopy from '../../icon/IconCopy.vue';
import FormInput from '../../Atoms/FormInput.vue';
import IconChevronUpDown from '../../icon/IconChevronUpDown.vue';
import IconEye from '../../icon/IconEye.vue';
import IconEyeCrossed from '../../icon/IconEyeCrossed.vue';
import TabGroup from '../../Atoms/TabGroup.vue';
import TabItem from '../../Atoms/TabItem.vue';
import DropDownMenu from '../../Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../Atoms/DropDownMenuItem.vue';
import SelectLinkedSource from '../SelectLinkedSource.vue';
import GetRtmpSrtButtons from './GetRtmpSrtButtons.vue';
import Tooltip from '../../Atoms/Tooltip.vue'
import IconHelp from '../../icon/IconHelp.vue';
import FormCheckbox from '../../Atoms/FormCheckbox.vue';
import FormSwitch from '../../Atoms/FormSwitch.vue';
import IconChevronDown from '../../icon/IconChevronDown.vue';

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  transcoderView: {
    type: Boolean,
    default: false
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  selectedLinkedSource: {
    type: Object,
    default: null
  },
  sourceSet: {
    type: Boolean,
    default: false
  },
  getStreamPushUrl: {
    type: Function
  },
  getStreamUrlAndKey: {
    type: String,
  },
  streamKey: {
    type: String
  },
  isPasswordEnabled: {
    type: Boolean,
    default: false
  },
  isAnyBackupStreamLive: {
    type: Boolean,
    default: false
  },
  streamKeySrt: {
    type: Object,
    default: null
  },
  srtRegionIngestDestination: {
    type: String,
    default: null
  },
})

const store = useStore()
const { notify } = useNotification()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const hasFailoverIngestFeature = computed(() => store.getters['User/hasFailoverIngestFeature'])

const emit = defineEmits({
  'request-url': (url) => { return url },
  'set-linked-source': (val) => { return val },
  'init-selected-source': (source) => { return source },
  'selected-source': (source) => { return source },
  'update-password-enabled': (value) => { return value },
})

const streamKeyVisible = ref(false)
const streamKeyVisibleTimeout = ref(0)
const streamKeyVisibleTimeoutCtrl = ref(null)
const showSRT = ref(false)
onMounted(async () => {

})

const activeTab = ref('rtmp')

const setActivetab = (tabName) => activeTab.value = tabName

const clipboardCopy = async (value) => {
  await navigator.clipboard.writeText(value);
  notify({ group: 'info', text: 'Copied to clipboard' });
}

const toggleStreamKeyVisibility = () => {
  window.clearInterval(streamKeyVisibleTimeoutCtrl.value);
  const newState = !streamKeyVisible.value;
  streamKeyVisible.value = newState;

  if (newState) {
    const timeout = 1000;
    streamKeyVisibleTimeout.value = 9000;
    streamKeyVisibleTimeoutCtrl.value = setInterval(() => {
      streamKeyVisibleTimeout.value -= timeout;
      if (!streamKeyVisibleTimeout.value)
        toggleStreamKeyVisibility();
    }, timeout);

    // track event
    window.trackEvent(
      `Viewed Stream key in stream ${props.stream.name}`
    );
  }
}

const SRTHostname = computed(() => {
  let streamHost = ` srt://${props.srtRegionIngestDestination}.castr.io`;
  return streamHost;
})

const SRTPort = computed(() => {
  let streamPort = `9998`;
  return streamPort;
})

const SRTID = computed(() => {
  let streamIDSrt = `#!::r=${props.stream.key},m=publish`;
  if (props.stream.config && props.stream.config.password && props.isPasswordEnabled) {
    streamIDSrt = `#!::r=${props.stream.key},password=${props.stream.config.password},m=publish`
  }
  return streamIDSrt
})

const streamKeyWHIP = computed(() => {
  let hostname = _.get(props.stream, 'uiRegion.config.failover.backupIngest');
  if (!hostname) {
    hostname = _.get(props.stream, 'uiRegion.config.failover.primaryIngest');
  }
  if (!hostname) {
    hostname = props.stream.uiRegion.hostname
  }
  let streamURL = `https://${hostname}/${props.stream.key}/whip`;
  if (props.stream.config && props.stream.config.password && props.isPasswordEnabled) {
    streamURL = `https://${hostname}/${props.stream.key}/whip?password=${props.stream.config.password}`
  }

  return streamURL;
})

const requestUrl = (url) => emit('request-url', url);

const isPasswordEnabledComputed = computed({
  get() {
    return props.isPasswordEnabled
  },
  set(value) {
    console.log(value);
    emit('update-password-enabled', value);
  }
})

const resetStreamPassword = () => {
  root.$emit('bv::show::modal', 'modal-reset-stream-password');
}

</script>
<template>
  <!-- Publish source tabs -->
  <div class="flex flex-wrap w-full mt-3">
    <TabGroup type="lines" class="w-full">
      <TabItem type="line" label="RTMP" :isActive="activeTab === 'rtmp'" @click="setActivetab('rtmp')" />
      <TabItem type="line" label="SRT" :isActive="activeTab === 'srt'" @click="setActivetab('srt')" />
      <TabItem type="line" label="WHIP" :isActive="activeTab === 'whip'" @click="setActivetab('whip')" />
    </TabGroup>
    <div class="w-full mt-3" v-if="activeTab === 'rtmp'">
      <label v-if="failoverIgestActive && hasFailoverIngestFeature"><strong>Primary
          RTMP</strong></label>
      <div class="flex relative w-full">
        <FormInput customClasses="!pr-8" :modelValue="getStreamPushUrl" @click="clipboardCopy(getStreamPushUrl)"
          readonly />
        <FormButton class="absolute right-0 top-px z-10" isLink isIcon type="link" size="sm"
          @click="clipboardCopy(getStreamPushUrl)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
      <div class="flex flex-wrap mt-3">
        <label class="mr-auto">Streaming Key</label>
        <Tooltip v-if="!transcoderView" :active="streamAlive" content="Password cannot be reset as stream is live now">
          <FormButton customClasses="text-surface-8 hover:text-surface-9 text-sm disabled:text-surface-7"
            :disabled="streamAlive" size="sm" type="link" @click="resetStreamPassword()">
            Reset Password
          </FormButton>
        </Tooltip>
      </div>
      <div class="flex items-center justify-between gap-x-2">
        <div class="flex relative w-full">
          <FormInput customClasses="!pr-16" v-if="streamKeyVisible" class="w-[1%] flex-grow" :modelValue="streamKey"
            @click="clipboardCopy(streamKey)" readonly />
          <FormInput customClasses="!pr-12" v-if="!streamKeyVisible" class="w-[1%] flex-grow"
            :modelValue="'xxxxxxxxxxxxxxxxx'" readonly />
          <FormButton class="absolute right-7 top-px z-10" isLink isIcon type="link" size="sm"
            @click="toggleStreamKeyVisibility">
            <span v-if="streamKeyVisible" class="show-counter absolute text-c-dark-7 text-xs">({{
              streamKeyVisibleTimeout / 1000 }})</span>
            <icon-base class="" fill="none">
              <Icon-eye v-if="!streamKeyVisible" />
              <Icon-eye-crossed v-else />
            </icon-base>
          </FormButton>
          <FormButton class="absolute right-0 top-px z-10" isLink isIcon type="link" size="sm"
            @click="clipboardCopy(streamKey)">
            <icon-base fill="none">
              <icon-copy />
            </icon-base>
          </FormButton>
        </div>
        <Tooltip v-if="stream.type === 'live' && !transcoderView" wrapperClass="block" :content="isAnyBackupStreamLive && hasFailoverIngestFeature ? 'Turn off the stream to change this' : `If your encoder doesn't support passing query parameters, the password option can be disabled
              here.`">
          <FormSwitch class="password-toggle !block" v-model="isPasswordEnabledComputed"
            :disabled="isAnyBackupStreamLive" />
        </Tooltip>
      </div>
      <FormButton @click="clipboardCopy(getStreamUrlAndKey)" type="secondary" size="md"
        class="mt-3 w-full justify-center sm:hidden">
        Copy Stream URL & Key as One URL
      </FormButton>
    </div>
    <div class="w-full mt-3" v-if="activeTab === 'srt'">
      <div class="flex items-center justify-between w-full gap-x-2">
        <div class="flex relative w-full">
          <FormInput customClasses="!pr-8" :modelValue="streamKeySrt" @click="clipboardCopy(streamKeySrt)" readonly />
          <FormButton class="absolute right-0 top-px z-10" isLink isIcon type="link" size="sm"
            @click="clipboardCopy(streamKeySrt)">
            <icon-base class="" fill="none">
              <icon-copy />
            </icon-base>
          </FormButton>
        </div>
        <Tooltip v-if="stream.type === 'live' && !transcoderView" wrapperClass="block" :content="isAnyBackupStreamLive && hasFailoverIngestFeature ? 'Turn off the stream to change this' : `If your encoder doesn't support passing query parameters, the password option can be disabled
              here.`">
          <FormSwitch class="password-toggle !block" v-model="isPasswordEnabledComputed"
            :disabled="isAnyBackupStreamLive" />
        </Tooltip>
      </div>
    </div>
    <div class="w-full mt-3" v-if="activeTab === 'whip'">
      <div class="flex items-center justify-between gap-x-2">
        <div class="flex relative w-full">
          <FormInput customClasses="!pr-16" :modelValue="streamKeyWHIP" @click="clipboardCopy(streamKeyWHIP)"
            readonly />
          <FormButton class="absolute right-0 top-px z-10" isLink isIcon type="link" size="sm"
            @click="clipboardCopy(streamKeyWHIP)">
            <icon-base fill="none">
              <icon-copy />
            </icon-base>
          </FormButton>
        </div>
        <Tooltip v-if="stream.type === 'live' && !transcoderView" wrapperClass="block" :content="isAnyBackupStreamLive && hasFailoverIngestFeature ? 'Turn off the stream to change this' : `If your encoder doesn't support passing query parameters, the password option can be disabled
              here.`">
          <FormSwitch class="password-toggle !block" v-model="isPasswordEnabledComputed"
            :disabled="isAnyBackupStreamLive" />
        </Tooltip>
      </div>
    </div>
    <GetRtmpSrtButtons v-if="!transcoderView" @request-url="requestUrl" :stream="stream" :stream-alive="streamAlive"
      :is-rtmp-pull-enabled="isRtmpPullEnabled" :rmpt-pull-url-processing="rmptPullUrlProcessing"
      :stream-source-type="streamSourceType" :source-types="sourceTypes" class="!justify-start" />

    <div class="w-full mt-2 flex flex-col gap-y-3" v-if="showSRT && activeTab === 'srt'">
      <div class="flex relative w-full">
        <FormInput label="Hostname" customClasses="!pr-8" :modelValue="SRTHostname" @click="clipboardCopy(SRTHostname)"
          readonly />
        <FormButton class="absolute right-0 bottom-px z-10" isLink isIcon type="link" size="sm"
          @click="clipboardCopy(SRTHostname)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
      <div class="flex relative w-full">
        <FormInput label="Port" customClasses="!pr-8" :modelValue="SRTPort" @click="clipboardCopy(SRTPort)" readonly />
        <FormButton class="absolute right-0 bottom-px z-10" isLink isIcon type="link" size="sm"
          @click="clipboardCopy(SRTPort)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
      <div class="flex relative w-full">
        <FormInput label="Stream ID" customClasses="!pr-8" :modelValue="SRTID" @click="clipboardCopy(SRTID)" readonly />
        <FormButton class="absolute right-0 bottom-px z-10" isLink isIcon type="link" size="sm"
          @click="clipboardCopy(SRTID)">
          <icon-base class="" fill="none">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
    </div>
    <FormButton v-if="activeTab === 'srt'"
      customClasses="grid grid-cols-[1fr_auto_1fr] text-sm text-surface-8 hover:text-white w-full place-items-center gap-x-3 mt-3"
      @click="showSRT = !showSRT">
      <hr class="w-full">
      <span class="flex items-center gap-x-2">
        {{ showSRT ? 'Hide ' : 'Show ' }} Separate Fields
        <icon-base :class="showSRT ? 'rotate-180' : 'rotate-0'">
          <Icon-chevron-down />
        </icon-base>
      </span>
      <hr class="w-full">
    </FormButton>
    <FormButton v-if="stream.type !== 'subsecond'" full-width
      class="gap-x-2 !text-surface-7 hover:!text-white justify-center mt-2"
      href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders" target="_blank" type="link">
      <icon-base fill="none">
        <icon-help />
      </icon-base>
      Recommended settings
    </FormButton>
  </div>
</template>