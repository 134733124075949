<script setup>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'
import _ from 'lodash'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconSearch from '../icon/IconSearch.vue';
import IconCross from '../icon/IconCross.vue';
import IconChevronRightFilled from '../icon/IconChevronRightFilled.vue';
import IconPlus from '../icon/IconPlus.vue';
import IconFilm from '../icon/IconFilm.vue';
import Badge from '../Badge.vue';
import FormInput from '../Atoms/FormInput.vue';
import Spinner from './Spinner.vue';
import Tooltip from '../Atoms/Tooltip.vue';

const props = defineProps({
	source: {
		type: String,
		default: 'playout'
	}
})

const store = useStore()

const emit = defineEmits({
	'add-vod': (value) => { return value }
})

// Refs
const loadFiles = ref(false)
const activeFolder = ref(null)
const search = ref('')


// Computed
const hasVodFiles = computed(() => store.getters['Streams/hasVodFiles'])
const vodStreams = computed(() => store.getters['Streams/vodStreams'])
const vodFiles = computed(() => store.getters['Streams/vodFiles'])

const vodFilesFiltered = computed(() => {
	const vodFolders = _.cloneDeep(vodStreams.value.filter(stream => stream.name.toLowerCase().includes(search.value.toLowerCase()) && stream.totalFiles > 0))
	return vodFolders.sort(function (a, b) {
		return new Date(b.creationTime) - new Date(a.creationTime)
	})
})

const shouldShowAddVodFileButon = computed(() => (props.source === 'playout') || (props.source === 'dynamic'))

// Methods
const clearSearch = () => search.value = ''

const getFiles = async (folder) => {
	if (folder?._id != activeFolder.value?._id) {
		loadFiles.value = true
		activeFolder.value = folder
		await store.dispatch('Streams/getVodFilesByVodId', folder._id)
		loadFiles.value = false
	} else {
		activeFolder.value = null
	}
}
const shouldShowFilesInFolder = (folder) => folder?._id === activeFolder.value?._id

const addFile = (file, folder) => {
	if (props.source === 'dynamic') emit('add-vod', { file, folder })
	emit('add-vod', file)
}

const disabledMessage = (vodFile, vodFolder) => {
	const isAllowedCodecPlayout = (props.source === 'playout' && vodFile.mediaInfo?.codecs?.find(element => element.codec === 'AVC')) || vodFolder?.liveParent
	const isExceedFileSizePlayout = props.source === 'playout' && (vodFile.bytes > 10737418240)
	if (!isAllowedCodecPlayout) return 'This video cannot be added because it is not encoded in the H.264 codec. Please re-encode the video using the H.264 format and upload it again to Video Hosting.'
	if (isExceedFileSizePlayout) return 'This file exceeds 10 GB and cannot be added as content in TV Playout.'
	return false
}

</script>

<template>
	<section v-if="!hasVodFiles" class="min-h-28">
		<h3 class="text-sm text-surface-8 font-medium text-center mt-5">You have no videos uploaded at Video Hosting</h3>
		<p class="text-center my-3">
			<FormButton to="/vods">
				Open Video Hosting
			</FormButton>
		</p>
	</section>
	<section v-else>
		<div class="flex items-center mb-3 relative">
			<icon-base class="absolute z-10 left-2 text-surface-7">
				<icon-search />
			</icon-base>
			<FormInput customClasses="!px-8" v-model="search" />
			<FormButton class="absolute z-10 right-0" isIcon type="link" v-if="search" @click="clearSearch">
				<icon-base>
					<icon-cross />
				</icon-base>
			</FormButton>
		</div>
		<slot />
		<ul class="flex flex-col divide-y divide-surface-3 max-h-[400px] overflow-y-auto overflow-x-hidden">
			<li v-for="folder in vodFilesFiltered" :key="folder._id" :title="folder.name" class="py-1 pr-2">
				<div class="flex gap-x-3">
					<FormButton customClasses="h-8 text-sm flex items-center gap-x-2 text-surface-8 hover:text-white w-full"
						@click="getFiles(folder)">
						<icon-base class="transition-transform shrink-0" :class="shouldShowFilesInFolder(folder) ? 'rotate-90' : 'rotate-0'">
							<icon-chevron-right-filled />
						</icon-base>
						<badge v-if="folder.liveParent" class="text-nowrap" title="Live-to-VOD" />
						<span class="grow-0 truncate shrink">{{ folder.name }}</span>
						<span class="ml-auto mr-0 text-tiny">{{ folder.totalFiles }}</span>
					</FormButton>
					<FormButton v-if="props.source === 'paywall'" size="sm" isIcon class="shrink-0 ml-auto"
						@click="addFile(folder)">
						<icon-base>
							<icon-plus />
						</icon-base>
					</FormButton>
				</div>
				<Spinner class="my-2" v-if="loadFiles && shouldShowFilesInFolder(folder)" />
				<ul v-if="!loadFiles && shouldShowFilesInFolder(folder)">
					<li v-for="file in vodFiles" :key="file._id" :title="file.fileName" class="hover:bg-surface-3">
						<Tooltip wrapperClass="w-full" :active="disabledMessage(file, folder)"
							:content="disabledMessage(file)">
							<div class="flex items-center py-1 gap-x-2 px-2">
								<icon-base class="shrink-0">
									<icon-film />
								</icon-base>
								<span class="text-sm grow-0 truncate">{{ file.fileName }} {{ file.bytes }}</span>
								<FormButton v-if="shouldShowAddVodFileButon" size="sm" isIcon class="shrink-0 ml-auto"
									@click="addFile(file, folder)" :disabled="disabledMessage(file, folder)">
									<icon-base>
										<icon-plus />
									</icon-base>
								</FormButton>
							</div>
						</Tooltip>
					</li>
				</ul>
			</li>
		</ul>
	</section>
</template>

<style scoped></style>