<script setup>
import _ from 'lodash'
import { computed, ref, onMounted, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AlertModal from '@/components/modals/AlertModal.vue'
import AddVodModal from "@/components/modals/AddVodModal.vue"
import SharePlaylistModal from "@/components/modals/SharePlaylistModal.vue"
import RenameVODFolderModal from "@/components/modals/RenameVODFolderModal.vue"
import VODUploadWidgetLight from "@/components/ui/VODUploadWidgetLight.vue"
import VODUploadWidgetDrop from "@/components/ui/VODUploadWidgetDrop.vue"
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import MetricsService from '@/services/MetricsService'
import UserService from '@/services/UserService'
import IconBase from '../icon/IconBase.vue'
import IconFolderFilled from '../icon/IconFolderFilled.vue'
import IconFilm from '../icon/IconFilm.vue'
import IconHandleVertical from '../icon/IconHandleVertical.vue'
import IconBin from '../icon/IconBin.vue'
import IconBanknote from '../icon/IconBanknote.vue'
import IconGear from '../icon/IconGear.vue'
import IconAdvertising from '../icon/IconAdvertising.vue'
import IconInfo from '../icon/IconInfo.vue'
import IconEdit from '../icon/IconEdit.vue'
import IconChevronUpDown from '../icon/IconChevronUpDown.vue'
import IconFolderPlus from '../icon/IconFolderPlus.vue'
import IconFolder from '../icon/IconFolder.vue'
import IconFile from '../icon/IconFile.vue'
import IconHosting from '../icon/IconHosting.vue'
import IconBandwidth from '../icon/IconBandwidth.vue'
import IconChevronRight from '../icon/IconChevronRight.vue'
import IconSearch from '../icon/IconSearch.vue'
import IconSorting from '../icon/IconSorting.vue'
import IconCross from '../icon/IconCross.vue'
import IconTag from '../icon/IconTag.vue'
import IconCheckmark from '../icon/IconCheckmark.vue'
import Badge from '../Badge.vue'
import FormInput from '../Atoms/FormInput.vue'
import FormButton from '../Atoms/FormButton.vue'
import StreamService from '../../services/StreamService'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
	isVodTrial: {
		type: Boolean,
		default() { return false }
	},
	vodSubExpiredDays: {
		type: Number,
		default() { return 1 }
	},
	aioStorageLimit: {
		type: Object,
		default() { return {}; }
	},
})

const emit = defineEmits({
	'route-change': (id) => { return id },
	'video-added': (videoItem) => { return videoItem },
	'stream-updated': (value) => { return value },
})

const sortDropdown = ref(null)
const foldersDropdown = ref(null)
const vodMenuDropdown = ref(null)
const sortTagsDropdown = ref(null)
const uploadWidget = ref(null)
const cloudImp = ref(false)
const showOptions = ref(false)
const isDropdownUploadVisible = ref(false)
const isDropdownDetailsVisible = ref(false)
const searchQuery = ref('')
const searchFoldersQuery = ref('')
const searchTagQuery = ref('')
const filteredTags = ref([])
const bandwidthUsage = ref(0)
const searchActive = ref(false)

const sortBy = computed(() => store.getters['Streams/sortBy'])
const vodStreams = computed(() => store.getters['Streams/vodStreams'])
const vodFiles = computed(() => store.getters['Streams/vodFiles'])
const totalVodFiles = computed(() => store.getters['Streams/totalVodFiles'])
const totalVodBytes = computed(() => store.getters['Streams/totalVodBytes'])
const maxVodFolders = computed(() => store.getters['User/maxVodFolders'])
const maxVodBandwidth = computed(() => store.getters['User/maxVodBandwidth'])
const maxVodStorage = computed(() => store.getters['User/maxVodStorage'])
const generalTags = computed(() => store.getters['Ui/generalTags'])
const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])

const currentFile = computed(() => {
	return vodFiles.value?.find(file => file.id === route.params?.videoId)
})

const vodFoldersFiltered = computed(() => {
	const searchResult = vodStreams.value?.filter(folder => folder?.name?.toLowerCase().includes(searchFoldersQuery.value.toLowerCase()))
	return searchResult
})

const tagsFiltered = computed(() => {
	const searchResult = generalTags.value?.filter(tag => tag?.title?.toLowerCase().includes(searchTagQuery.value.toLowerCase()))
	return searchResult
})

const sortedBy = (sort) => sort === sortBy.value

const vodFilesFiltered = computed(() => {
	const searchResult = vodFiles.value?.filter(file => _.lowerCase(file?.fileName)?.includes(_.lowerCase(searchQuery.value)))
	if (filteredTags.value.length > 0) {
		searchResult.filter(item => {
			return item.tags?.some(tag => filteredTags.value.includes(tag))
		})
	}
	
	return filteredTags.value.length > 0 
		? searchResult.filter(item => {
			return item.tags?.some(tag => filteredTags.value.includes(tag))
		})
		: searchResult
})

const streamKey = computed(() => {
	const array = _.cloneDeep(vodFiles.value)
	// in live-to-vod, all the keys should be equal
	const v = array.find(x => x.archive?.key);
	return v?.archive?.key;
})

const canEncodeVideos = computed(() => {
	const encodingUnits = _.get(
		store.state.User.baseSub,
		"package.definition.encodingUnits"
	);
	return encodingUnits > 0;
})

const hasEncodingEnabled = computed(() => {
	return (
		store.state.User.aggregatedDefinition &&
		store.state.User.aggregatedDefinition.vod &&
		store.state.User.aggregatedDefinition.vod.transcoding
	);
})

const streamId = computed(() => route.params.streamId)

const vodStream = computed(() => {
	return vodStreams.value.find(stream => stream._id === streamId.value)
})

watch(
	() => addonSubscriptions,
	async () => {
		const addonSub = await addonSubscriptions.value?.find(subscription => subscription.category === 'live')
		const userId = await UserService.getUserId()
		const bandwidth = await MetricsService.getSubscriptionBandwidth(userId, addonSub?.package._id)
		bandwidthUsage.value = bandwidth
	}
)

onMounted(async () => {
	store.dispatch('Ui/getAllTags')
	await store.dispatch('Streams/getStreams');
	const userId = await UserService.getUserId()
	const addonSub = await addonSubscriptions.value?.find(subscription => subscription.category === 'live')
	const bandwidth = await MetricsService.getSubscriptionBandwidth(userId, addonSub?.package._id)
	bandwidthUsage.value = bandwidth

	
	root.$on('bv::dropdown::show', bvEvent => {
		if (bvEvent.componentId === 'dropdown-upload') {
			isDropdownUploadVisible.value = true;
		}
		if (bvEvent.componentId === 'dropdown-details') {
			isDropdownDetailsVisible.value = true;
		}
	})
	root.$on('bv::dropdown::hide', bvEvent => {
		if (bvEvent.componentId === 'dropdown-upload') {
			isDropdownUploadVisible.value = false;
		}
		if (bvEvent.componentId === 'dropdown-details') {
			isDropdownDetailsVisible.value = false;
		}
		if (isDropdownUploadVisible.value) {
			bvEvent.preventDefault()
		}
		if (isDropdownDetailsVisible.value) {
			bvEvent.preventDefault()
		}
	})
})

const activeRoute = (id) => {
	return (route.params?.streamId === id || route.params?.videoId === id)
}

const changeRoute = async (streamId) => {
	foldersDropdown.value.toggleDropdown()
	if (streamId != route.params.streamId) {
		store.dispatch('Ui/setLoadingStatus', true);
		await router.push({
			name: "VODFolderManage",
			params: {
				streamId
			},
		});
		await store.dispatch('Streams/getFilesInVODFolder');
	} else return
}

const showVodAddModal = () => {
	root.$emit('bv::show::modal', 'modal-add-vod');
}

const showRenameFolderModal = () => {
	root.$emit('bv::show::modal', 'modal-rename-vod-folder');
}

const onNewStream = async () => {
	await store.dispatch('Streams/getStreams');
	notify({ group: "success", text: "Folder created successfully" });
}

const onVideoAdded = (videoItem) => {
	emit('video-added', videoItem);
	setTimeout(async () => {
		await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, videoItem.id, {
			author: store?.state?.User?.activeTeam ? store?.state?.User?.teamMemberUser?.email : store?.state?.User?.email,
		});
	})
	cloudImp.value = false;
	showOptions.value = false;
}

const dragover = (event) => {
	event.preventDefault()

	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('drop-zone-hidden_dragover')) {
		event.currentTarget.classList.remove('drop-zone-hidden_dragleave');
		event.currentTarget.classList.add('drop-zone-hidden_dragover');
	}
	if (event.currentTarget.classList.contains('w-100')) {
		event.currentTarget.classList.add('z-10');
	}
}

const dragleave = (event) => {
	// Clean up
	event.currentTarget.classList.remove('drop-zone-hidden_dragover');
	if (event.currentTarget.classList.contains('w-100')) {
		event.currentTarget.classList.remove('z-10');
	}
}

const drop = (event) => {
	event.preventDefault();
	uploadWidget.initVideoUpload()
	// Clean up
	event.currentTarget.classList.add('drop-zone-hidden_dragleave');
	event.currentTarget.classList.remove('drop-zone-hidden_dragover');
}

const onRenamed = async () => {
	await store.dispatch('Streams/getStreams');
}

const vodDelete = () => {
	root.$emit("bv::show::modal", 'delete-vod-stream');
}

const getBytes = (size) => {
	if (size > 0) {
		let totalBytes = size;
		var i = Math.floor(Math.log(totalBytes) / Math.log(1000)),
			sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		return (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + " " + sizes[i];
	} else {
		return 0;
	}
}

const toggleSearchTag = (selectedTag) => {
	filteredTags.value.find(tag => tag === selectedTag)
		? filteredTags.value = filteredTags.value.filter(tag => tag !== selectedTag)
		: filteredTags.value.push(selectedTag)
}

const tagChecked = (selectedTag) => {
	return filteredTags.value.find(tag => tag === selectedTag)
}

const changeSortBy = (value) => {
	store.dispatch('Streams/setSortOption', value)
	sortDropdown.value.toggleDropdown()
}

const changeSortTagsBy = (value) => {
	store.dispatch('Ui/setSortTags', value)
	sortTagsDropdown.value.toggleDropdown()
}


	const activeTeam = computed(() => store.state.User.activatedTeam);
	const memberPermission = computed(() => store.state.User.memberPermission);

	const canDeleteVideoFolder = computed(() => !activeTeam.value || (activeTeam.value && memberPermission.value && memberPermission.value.hostingDelete))

	const ownerEmail = computed(() => store.state.User.email)

	const noDeletePermissionTooltip = computed(() => {
			return `<span class="text-dark-8 text-small">
						You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
						:href="'mailto:${ownerEmail.value}'">${ownerEmail.value}</a> to gain access.
					</span>`
	})


</script>

<template>
	<aside class="aside-secondary mt-12 lg:mt-0">
		<div class="flex items-center text-surface-7 md:justify-between !pr-4 md:!pr-0">
			<DropDownMenu type="light" ref="foldersDropdown" maxHeight="[300px]"
				wrapperClass="translate-x-2 md:translate-x-0" class="max-w-[calc(100%-1rem)]">
				<template #toggle-button>
					<icon-base class="!mr-2 shrink-0" fill="none">
						<icon-folder-filled />
					</icon-base>
					<span class="truncate">{{ vodStream?.name }}</span>
					<icon-base class="!ml-1 shrink-0">
						<icon-chevron-up-down />
					</icon-base>
				</template>
				<template #menu-items>
					<DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
						<div class="flex relative items-center transition-all w-full">
							<icon-base class="text-surface-8 absolute left-3 shrink-0 z-10" fill="none">
								<icon-search />
							</icon-base>
							<FormInput placeholder="Search" customClasses="!px-8" v-model="searchFoldersQuery" />
							<FormButton type="icon" size="sm" @click="searchFoldersQuery = ''" :class="searchFoldersQuery
								? 'opacity-100 absolute right-2 shrink-0 z-10'
								: 'opacity-0 absolute right-2 shrink-0 z-10'">
								<icon-base class="shrink-0" fill="none">
									<icon-cross />
								</icon-base>
							</FormButton>
						</div>
					</DropDownMenuItem>
					<DropDownMenuItem v-for="(folder, index) in vodFoldersFiltered" :key="index" @click="changeRoute(folder._id)">
						<Badge v-if="folder.liveParent" variant="dark-4" class="text-s-xs mr-1 mb-n1">L-V</Badge>
						{{ folder.name }}
					</DropDownMenuItem>
				</template>
			</DropDownMenu>
			<span v-if="vodFilesFiltered.length > 0" class="lg:hidden">/</span>
			<DropDownMenu position="mobile-right" class="max-w-[150px] lg:hidden" v-if="vodFilesFiltered.length > 0"
				type="light" ref="filesDropdown" maxHeight="[300px]">
				<template #toggle-button>
					<div class="flex items-center max-w-full">
						<span class="truncate block max-w-[150px]">{{ currentFile && currentFile.fileName }}</span>
						<icon-base class="!ml-1 shrink-0">
							<icon-chevron-up-down />
						</icon-base>
					</div>
				</template>
				<template #menu-items>
					<DropDownMenuItem v-for="(item, index) in vodFilesFiltered" :key="index" :active="activeRoute(item.id)"
						:to="'../' + item.id + '/video'"
						customClasses="h-8 p-2 hover:bg-surface-3 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full max-w-[50vw] truncate">
						<icon-base class="!mr-1 shrink-0" fill="none">
							<icon-film />
						</icon-base>
						<span class="shrink grow-0 truncate">{{ item.fileName }}</span>
					</DropDownMenuItem>
				</template>
			</DropDownMenu>
			<DropDownMenu class="ml-auto"
				customClasses="h-7 w-7 rounded-md flex items-center justify-center text-surface-1 bg-surface-9 hover:bg-white hover:text-black"
				position="mobile-right" ref="vodMenuDropdown" listClasses="!overflow-y-visible">
				<template #toggle-button>
					<icon-base>
						<icon-handle-vertical />
					</icon-base>
				</template>
				<template #menu-items>
					<DropDownMenuItem
						customClasses="h-8 p-2 hover:bg-surface-3 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full"
						v-if="!vodStream?.liveParent">
						<VODUploadWidgetLight :stream="vodStream" :canEncodeVideos="canEncodeVideos"
							:encoding-module="hasEncodingEnabled" :is-vod-trial="isVodTrial" :vod-sub-expired-days="vodSubExpiredDays"
							@video-added="onVideoAdded" :aioStorageLimit="aioStorageLimit" ref="uploadWidget" class=" w-100" />
					</DropDownMenuItem>
					<DropDownMenuItem @click="showVodAddModal">
						<icon-base class="!mr-2 shrink-0">
							<icon-folder-plus />
						</icon-base>
						New Folder
					</DropDownMenuItem>
					<DropDownMenuItem @click="showRenameFolderModal">
						<icon-base class="mr-2 shrink-0">
							<icon-edit />
						</icon-base>
						Rename folder
					</DropDownMenuItem>
					<DropDownMenuItem isCustom>
						<DropDownMenu
							customClasses="h-8 !px-2 hover:bg-surface-3 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full"
							ref="vodMenuDropdown" submenu position="mobile-right" listClasses=" !overflow-y-visible bg-surface-2 rounded-md min-w-[250px]">
							<template #toggle-button>
								<icon-base class="mr-2 shrink-0" fill="none">
									<icon-info />
								</icon-base>
								Hosting Details
								<icon-base class="ml-2 shrink-0" fill="none">
									<icon-chevron-right />
								</icon-base>
							</template>
							<template #menu-items>
								<DropDownMenuItem isCustom
									class="text-surface-9 grid grid-cols-[40%_60%] gap-y-2 !p-2 items-center text-tiny w-full">
									<span class="flex items-center text-surface-8">
										<icon-base class="mr-2 shrink-0" fill="none">
											<icon-folder />
										</icon-base>
										Folders
									</span>
									<span class="text-right font-medium">
										{{ vodStreams.length }} / {{ maxVodFolders }}
									</span>
									<span class="flex items-center text-surface-8">
										<icon-base class="mr-2 shrink-0" fill="none">
											<icon-file />
										</icon-base>
										Files
									</span>
									<span class="text-right font-medium">
										{{ totalVodFiles }}
									</span>
									<span class="flex items-center text-surface-8">
										<icon-base class="mr-2 shrink-0" fill="none">
											<icon-hosting />
										</icon-base>
										Storage
									</span>
									<span class="text-right font-medium">
										{{ getBytes(totalVodBytes * 1000 * 1000 * 1000) }} / {{ getBytes(maxVodStorage * 1000 * 1000 * 1000)
										}}
									</span>
									<span class="flex items-center text-surface-8">
										<icon-base class="mr-2 shrink-0" fill="none">
											<icon-bandwidth />
										</icon-base>
										Bandwidth
									</span>
									<span class="text-right font-medium">
										{{ getBytes(bandwidthUsage.bytes) }} / {{ getBytes(maxVodBandwidth * 1000 * 1000 * 1000) }}
									</span>
								</DropDownMenuItem>
							</template>
						</DropDownMenu>
					</DropDownMenuItem>
					<hr />
					<DropDownMenuItem class="text-tiny flex items-center" :to="{ name: 'VODBucketSettings' }">
						<icon-base class="mr-2 shrink-0" fill="none">
							<icon-gear />
						</icon-base>
						Settings
					</DropDownMenuItem>
					<DropDownMenuItem class="text-tiny flex items-center" :to="{ name: 'StreamsManageVodAdvertising' }">
						<icon-base class="mr-2 shrink-0" fill="none">
							<icon-advertising />
						</icon-base>
						Advertising
					</DropDownMenuItem>
					<DropDownMenuItem class="text-tiny flex items-center" :to="{ name: 'VODstreamManagePaywall' }">
						<icon-base class="mr-2 shrink-0" fill="none">
							<icon-banknote />
						</icon-base>
						Paywall
					</DropDownMenuItem>
					<hr />
					<DropDownMenuItem @click="vodDelete" v-if="canDeleteVideoFolder">
						<icon-base class="mr-2 shrink-0" fill="none">
							<icon-bin />
						</icon-base>
						Delete
					</DropDownMenuItem>
					<ejs-tooltip v-else :content="noDeletePermissionTooltip">
						<DropDownMenuItem @click="vodDelete" :disabled="true">
							<icon-base class="mr-2 shrink-0" fill="none">
								<icon-bin />
							</icon-base>
							Delete
						</DropDownMenuItem>
					</ejs-tooltip>
				</template>
			</DropDownMenu>
		</div>
		<div class="!hidden md:!flex !my-4 !px-4 md:!px-0">
			<div class="flex relative items-center transition-all" :class="searchActive ? 'w-full' : 'w-9/12'">
				<icon-base class="text-surface-8 absolute left-3 shrink-0 z-10" fill="none">
					<icon-search />
				</icon-base>
				<FormInput placeholder="Search" customClasses="!px-8" @click="searchActive = true" @blur="searchActive = false"
					v-model="searchQuery" />
				<FormButton type="link" size="sm" @click="searchQuery = ''" :class="searchQuery
					? 'opacity-100 absolute right-2 shrink-0 z-10'
					: 'opacity-0 absolute right-2 shrink-0 z-10'">
					<icon-base class="shrink-0" fill="none">
						<icon-cross />
					</icon-base>
				</FormButton>
			</div>
			<div class="ml-auto" :class="searchActive ? '!hidden' : 'flex'">
				<DropDownMenu position="mobile-right" type="secondary" isIcon ref="sortDropdown">
					<template #toggle-button>
						<icon-base class="shrink-0 text-surface-8" fill="none">
							<icon-sorting />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem :active="sortBy === 'Sort by Oldest'" @click="changeSortBy('Sort by Oldest')">
							Sort by Oldest
							<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('Sort by Oldest')" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
						<DropDownMenuItem active="sortBy === 'Sort by Newest'" @click="changeSortBy('Sort by Newest')">
							Sort by Newest
							<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('Sort by Newest')" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
						<DropDownMenuItem :active="sortBy === 'A to Z'" @click="changeSortBy('A to Z')">
							A to Z
							<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('A to Z')" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
						<DropDownMenuItem :active="sortBy === 'Sort by Oldest'" @click="changeSortBy('Z to A')">
							Z to A
							<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('Z to A')" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<DropDownMenu type="secondary" position="mobile-right" isIcon ref="tagsDropdown" maxHeight="[300px]">
					<template #toggle-button>
						<icon-base class="shrink-0 text-surface-8" fill="none">
							<icon-tag />
						</icon-base>
						<div v-if="filteredTags.length > 0" class="size-2 bg-error rounded-full absolute right-2 top-4"></div>
					</template>
					<template #menu-items>
						<DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
							<div class="flex relative items-center transition-all w-full">
								<icon-base class="text-surface-8 absolute left-3 shrink-0 z-10" fill="none">
									<icon-search />
								</icon-base>
								<FormInput size="sm" placeholder="Search" customClasses="!px-8" v-model="searchTagQuery" />
								<FormButton type="link" isIcon size="sm" @click="searchTagQuery = ''" :class="searchTagQuery
									? 'opacity-100 absolute right-10 shrink-0 z-10'
									: 'opacity-0 absolute right-2 shrink-0 z-10'">
									<icon-base class="shrink-0" fill="none">
										<icon-cross />
									</icon-base>
								</FormButton>
								<DropDownMenu class="ml-2 relative z-10" position="right" type="secondary" isIcon ref="sortTagsDropdown">
									<template #toggle-button>
										<icon-base class="shrink-0 text-surface-8" fill="none">
											<icon-sorting />
										</icon-base>
									</template>
									<template #menu-items>
										<DropDownMenuItem :active="sortBy === 'A to Z'" @click="changeSortTagsBy('A to Z')">
											A to Z
											<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('A to Z')" fill="none">
												<icon-checkmark />
											</icon-base>
										</DropDownMenuItem>
										<DropDownMenuItem :active="sortBy === 'Sort by Oldest'" @click="changeSortTagsBy('Z to A')">
											Z to A
											<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('Z to A')" fill="none">
												<icon-checkmark />
											</icon-base>
										</DropDownMenuItem>
									</template>
								</DropDownMenu>
							</div>
						</DropDownMenuItem>
						<DropDownMenuItem class="!p-2 hover:!bg-transarent min-w-48 text-tiny" isCustom v-if="tagsFiltered.length === 0">
							No tags were found
						</DropDownMenuItem>
						<DropDownMenuItem class="min-w-48" v-else v-for="tag in tagsFiltered" :key="tag.id"
							@click="toggleSearchTag(tag.id)">
							<Badge tag :type="tag.color" class="mr-1">{{ tag.title }}</Badge>
							<icon-base class="shrink-0 ml-auto text-surface-7" v-if="tagChecked(tag.id)" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
			</div>
		</div>
		<ul class="!hidden md:!flex flex-col mb-6 !px-4 md:!px-0 max-h-[50vh] overflow-x-hidden overflow-y-auto ">
			<li v-for="(item, index) in vodFilesFiltered" :key="index">
				<router-link :title="item.fileName"
					class="text-sm text-surface-8 hover:text-white hover:bg-surface-3 flex items-center !px-3 !py-2 hover:no-underline rounded"
					:active="activeRoute(item.id)"
					:to="'/vods/' + streamId + '/' + item.id + '/video'">
					<icon-base class="mr-2 shrink-0" fill="none" :iconName="item.fileName">
						<icon-film />
					</icon-base>
					<span class="flex-shrink-1 flex-grow-0 truncate max-w-[calc(100%-2rem)]">{{ item.fileName }}</span>
				</router-link>
			</li>
		</ul>
		<VODUploadWidgetDrop v-if="vodStream" :stream="vodStream" :canEncodeVideos="canEncodeVideos"
			:encoding-module="hasEncodingEnabled" :is-vod-trial="isVodTrial" :vod-sub-expired-days="vodSubExpiredDays"
			@video-added="onVideoAdded" :aioStorageLimit="aioStorageLimit" ref="uploadWidgetDrop"
			class="w-100 d-none d-lg-flex" />
		<add-vod-modal @new-channel="onNewStream" />
		<rename-VOD-folder-modal v-if="vodStream?._id" modalId="modal-rename-vod-folder" message="Rename Folder"
			:streamId="vodStream?._id" :streamName="vodStream?.name" ok-text="Update Name" cancel-text="Cancel"
			@modal-confirm="onRenamed" />
		<alert-modal modal-id="alert-video-encoding"
			message="Only MP4 Videos are allowed. Please upload video with appropriate encoding/format"
			ok-text="Got it, thanks" />
		<share-playlist-modal :stream="vodStream" :streamKey="streamKey" button-alignment="text-right" />
	</aside>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.router-link-active {
	@apply bg-surface-3 text-white
}
</style>
