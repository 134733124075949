import moment from "moment";

export const bytes = (num, breakSegments, decimalPlaces = 3, mini = false) => {
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  const neg = num < 0;
  const units = [
    mini ? "B" : "Bytes",
    "kB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ];

  if (neg) num = -num;
  if (num < 1) {
    const value = (neg ? "-" : "") + num;
    return breakSegments ? { value, unit: units[0] } : value + " Bytes";
  }

  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  const unit = units[exponent].toLowerCase();

  num = (num / Math.pow(1000, exponent)).toFixed(decimalPlaces) * 1;

  const value = (neg ? "-" : "") + num;
  return breakSegments ? { value: Number(value), unit } : value + " " + unit;
};

export const shortString = (value, maxLen) => {
  value = value || "";
  return value.length <= maxLen ? value : value.substr(0, maxLen) + " ..";
};

const DefaultDateFormat = "hh:mm DD/MM/YYYY";

export const date = (value, format = DefaultDateFormat, isUnix = false) => {
  if (value && isUnix) {
    return moment(value).format(format);
  } else if (value) {
    return moment(new Date(String(value))).format(format);
  }
};
export const newDate = (value) => {
  return new Date(value);
};

export const duration = (value, format = "hh:mm:ss", trim = false) => {
  if (value > 86399000) {
    return moment.duration(value, "milliseconds").format("dd [days], hh:mm:ss", {
      trim: trim,
    });
  }
  return moment.duration(value, "milliseconds").format(format, {
    trim: trim,
  });
};
export const timeAgo = (value) => {
  if (value) {
    return moment(new Date(String(value))).fromNow();
  }
};

export const numberFormat = (value) => {
  if (!value) return value;

  const chunkSize = 3;
  const str = parseInt(value).toString();
  if (str.length <= chunkSize) return str;

  let fmt = "";
  for (let c = 0, i = str.length - 1; i >= 0; c++, i--) {
    if (c && c % chunkSize === 0) fmt = "," + fmt;

    fmt = str[i] + fmt;
  }

  return fmt;
};

export const normalizedFPS = (value) => {
  return Number.isFinite(value) ? Math.ceil(value) : value;
};
