<script setup>
import { computed, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import StreamService from '@/services/StreamService';
import FormButton from '../../Atoms/FormButton.vue'
import IconBase from '../../icon/IconBase.vue';
import IconInfo from '../../icon/IconInfo.vue';
import SelectLinkedSource from '../SelectLinkedSource.vue';
import GetRtmpSrtButtons from './GetRtmpSrtButtons.vue';
import { useStore } from 'vuex'
import Tooltip from '../../Atoms/Tooltip.vue'

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
  isRtmpPullEnabled: {
    type: Boolean,
    default: false
  },
  rmptPullUrlProcessing: {
    type: Boolean,
    default: false
  },
  transcoderView: {
    type: Boolean,
    default: false
  },
  streamSourceType: {
    type: String
  },
  sourceTypes: {
    type: Array
  },
  selectedLinkedSource: {
    type: Object,
    default: null
  },
  sourceSet: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits({
  'request-url': (url) => { return url },
  'set-linked-source': (val) => { return val },
  'init-selected-source': (source) => { return source },
  'selected-source': (source) => { return source },
})

const requestUrl = (url) => emit('request-url', url);
const setLinkedSource = () => emit('set-linked-source')
const initSelectedSource =(source) => emit('init-selected-source', source);
const selectedSource = (source) => emit('selected-source', source);

</script>
<template>
  <!-- Dropdown for Linked Source -->
    <div class="w-full relative">
      <div v-show="stream.type === 'live' || stream.type === 'restream'" class="mb-3 region">
        <div class="flex items-center mb-2 mt-3 w-full">
          <label class="mb-0" for="sourceSelect">Select Source</label>
          <Tooltip v-if="selectedLinkedSource && !selectedLinkedSource.enabled" content="Source is disabled">
            <icon-base class="mx-2 text-warning" fill="none">
              <icon-info />
            </icon-base>
          </Tooltip>
        </div>
        <SelectLinkedSource
          :class="{ 'border border-warning rounded-md': selectedLinkedSource && !selectedLinkedSource.enabled }"
          :stream="stream" @selected-source="selectedSource" @init-linked-source="initSelectedSource" fullWidth
          show-alert />

          <GetRtmpSrtButtons 
          v-if="!transcoderView"
          @request-url="requestUrl"
          :stream="stream" 
          :stream-alive="streamAlive" 
          :is-rtmp-pull-enabled="isRtmpPullEnabled"
          :rmpt-pull-url-processing="rmptPullUrlProcessing"
          :stream-source-type="streamSourceType" :source-types="sourceTypes"
          class="!justify-start"
        />
        <FormButton class="mt-3" size="sm" @click="setLinkedSource"
          :disabled="sourceSet">Set as Source</FormButton>
      </div>
    </div>
</template>