<script setup>
import { ref, computed, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import StreamService from '@/services/StreamService'
import StreamStatsService from '@/services/StreamStatsService'
// import { subscribeMediaPulse } from '../../Utils/mediaPulse'
import { useNotification } from "@kyvg/vue3-notification"
import Badge from '../Badge.vue'
import FormButton from '../Atoms/FormButton.vue'
import IconBase from '../icon/IconBase.vue'
import IconBin from '../icon/IconBin.vue'
import IconCamera from '../icon/IconCamera.vue'
import StreamThumbnail from '../ui/StreamThumbnail.vue'
import Tooltip from '@/components/Atoms/Tooltip.vue'

const { notify } = useNotification()

const store = useStore()

const instance = getCurrentInstance();
const root = instance.proxy.$root;

const scopeAlive = ref(true),
	mediaPulse = ref(null),
	mediaPulseTimeoutCtrl = ref(null)

const props = defineProps({
	playout: {
		type: Object,
		default() { return {}; }
	},
	viewMode: {
		type: String,
		default: 'grid',
	}
})

const subscribeMediaPulse = async () => {
	(async function loop() {
		if (!scopeAlive.value) return;

		let timeoutInterval = 5000;
		let shouldUpdatePulse = props.playout.enabled;
		if (document.hidden) {
			shouldUpdatePulse = false;
			timeoutInterval = 6000;
		}

		if (shouldUpdatePulse) {
			if (!document.hasFocus()) {
				timeoutInterval = 6000;
			}
		}
		if (shouldUpdatePulse) {
			try {
				const streamKey = props.playout?.key;
				const pulseObject = await StreamStatsService.getStreamMediaPulse(
					streamKey
				);
				onMediaPulse(pulseObject);
			} catch (e) {
				console.log(e);
			}
		}
		// reschedule
		mediaPulseTimeoutCtrl.value = setTimeout(loop, timeoutInterval);
	})();
}

const onMediaPulse = (pulseObject = {}) => {
	if (mediaPulse.value?.withoutPushStats) {
		const oldPushStats = mediaPulse.value.push_stats;
		pulseObject.push_stats = oldPushStats;
	}
	mediaPulse.value = pulseObject;
	// streamAlive.value = props.playout.enabled && mediaPulse.value?.alive;
	// streamFps.value = mediaPulse.value?.fps;
}

const unsubscribeMediaPulse = () => {
	clearTimeout(mediaPulseTimeoutCtrl.value);
}
onMounted(async () => {
	subscribeMediaPulse()
})
onUnmounted(() => {
	unsubscribeMediaPulse()
	scopeAlive.value = false
})

const toggleStatus = (ev) => {
	ev.preventDefault();
	ev.stopPropagation();
	root.$emit('bv::show::modal', 'modalToggleStream' + props.playout._id, '#btnShow')
}
const onDeletePlayout = async () => {
	root.$emit("bv::hide::modal", 'delete-playout-' + props.playout._id);
	try {
		await StreamService.deleteStream(
			props.playout._id
		);
		await store.dispatch('Streams/getStreams')
		notify({ group: 'info', text: 'TV Playout Deleted' })
	} catch (err) {
		this.error = err
	}
}

const showDeleteModal = (ev) => {
	ev.preventDefault();
	ev.stopPropagation();
	root.$emit("bv::show::modal", 'delete-playout-' + props.playout._id);
}

const hasDestination = computed(() => !!props.playout?.destination)
const getTooltipText = computed(() => {
	if (hasDestination.value) {
		const linkedStreamName = props.playout.destination;
		return `This livestream cannot be deleted because it is being used as a source in ${linkedStreamName} livestream. To delete this livestream, first remove it as a source from ${linkedStreamName} livestream.`
	}
	return '';
})

</script>
<template>
	<div class="flex relative"
		:class="props.viewMode === 'grid' ? 'flex-col bg-surface-2 rounded-lg h-full' : 'flex-row items-center'">
		<router-link class="text-reset hover:no-underline" :class="{'grid gap-3 w-full grid-cols-[1fr_2fr] md:grid-cols-[5rem_2fr_1fr_110px] !py-2 md:!py-0': props.viewMode !== 'grid'}" :to="{ name: 'PlayoutView', params: { tvId: playout._id } }">
			<StreamThumbnail :stream="playout" :media-pulse="mediaPulse"
				:compact-mode="props.viewMode === 'grid' ? false : true" :class="{ 'self-center': props.viewMode !== 'grid' }" />
			<div :class="props.viewMode === 'grid' ? 'pt-2 px-3' : 'py-2 w-2/3 md:w-9/12'">
				<h2 class="text-bace font-medium mb-1">
					{{ playout.name
					}}
				</h2>
				<p class="text-tiny mb-0 flex items-center" :class="playout.destination ? 'text-white' : 'text-surface-7'">
					<icon-base class="mr-2">
						<icon-camera />
					</icon-base>
					{{ playout.destination ? playout.destination : 'No Destination Selected' }}
				</p>
			</div>
			<Badge :type="mediaPulse?.alive ? 'online' : 'offline'" :title="mediaPulse?.alive ? 'Live' : 'Offline'"
				class="inline-flex self-center" :class="props.viewMode === 'grid' ? 'absolute top-2 left-3 z-10' : '!mr-auto md:!mr-0 md:!ml-auto'" />
			<div class="text-right flex items-center justify-end" :class="{'px-3 pb-3': props.viewMode === 'grid'}">
				<FormButton @click="toggleStatus" v-if="playout.enabled" type="secondary" size="sm" class="mr-2">
					Disable
				</FormButton>
				<FormButton @click="toggleStatus" v-else size="sm" class="mr-2">
					Enable
				</FormButton>
				<Tooltip v-if="hasDestination" :content="getTooltipText">
					<FormButton @click="showDeleteModal" type="danger" size="sm" isIcon :disabled="hasDestination">
						<icon-base>
							<icon-bin />
						</icon-base>
					</FormButton>
				</Tooltip>
				<FormButton 
					v-else @click="showDeleteModal" type="danger" size="sm" isIcon>
					<icon-base>
						<icon-bin />
					</icon-base>
				</FormButton>
			</div>
		</router-link>
		<toggle-stream-modal :stream="playout" @modal-toggle="toggleStatus" />
		<confirm-modal :modalId="'delete-playout-' + playout._id" message="Are you sure you want to delete your TV Playout?"
			modal-type="danger" @modal-confirm="onDeletePlayout" />
	</div>
</template>

<style scoped></style>