<template>
  <b-container id="resizible-wrapper" class="highlight-container mx-auto px-0 ml-0 position-relative">
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <main v-else>
      <div class="highlight_content mt-5">
        <div class="highlight--top d-flex align-items-center justify-content-between">
          <div>
            <div class="d-flex align-items-center justify-content-start">
              <a href="/app/highlight" class="btn pl-0 btn-link-7 d-none d-md-block mt-0"><svg class="mr-1"
                  data-v-216aa6dc="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path data-v-216aa6dc="" fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.42417 1.07573C6.18985 0.841415 5.80995 0.841415 5.57564 1.07573L1.07564 5.57573C0.841324 5.81004 0.841324 6.18994 1.07564 6.42426L5.57564 10.9243C5.80995 11.1586 6.18985 11.1586 6.42417 10.9243C6.65848 10.6899 6.65848 10.31 6.42417 10.0757L2.34843 5.99999L6.42417 1.92426C6.65848 1.68994 6.65848 1.31004 6.42417 1.07573Z"
                    fill="currentColor"></path>
                </svg></a>
              <textarea v-model="highlightName" ref="highlightTitle" rows="1" autocomplete="off" autocorrect="off"
                autocapitalize="off" spellcheck="false" title="" style="background:transparent;border:0px"
                class="text-s-xxl text-500 mb-0 highlight-name pl-1 overflow-hidden form-control form-control-sm"
                @keydown.enter.prevent="preventEnter" @change="onHighlightChange" @focus="resizeTextarea"
                @keyup="resizeTextarea"></textarea>
            </div>
            <div class="d-flex align-items-center justify-content-start mb-3 pl-4">
              <svg style="filter: brightness(0) invert(1)" class="ml-1 mr-1" xmlns="http://www.w3.org/2000/svg"
                width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.5758 3.42426C11.4294 2.27786 9.57076 2.27787 8.42436 3.42426L7.92436 3.92426C7.69005 4.15858 7.31015 4.15858 7.07583 3.92426C6.84152 3.68995 6.84152 3.31005 7.07583 3.07573L7.57583 2.57574C9.19086 0.960709 11.8093 0.960705 13.4244 2.57573C15.0394 4.19076 15.0394 6.80923 13.4244 8.42426L12.4244 9.42426C10.8093 11.0393 8.19086 11.0393 6.57583 9.42426C6.34152 9.18995 6.34152 8.81005 6.57583 8.57573C6.81015 8.34142 7.19005 8.34142 7.42436 8.57573C8.57076 9.72213 10.4294 9.72213 11.5758 8.57573L12.5758 7.57573C13.7222 6.42934 13.7222 4.57066 12.5758 3.42426ZM8.57583 7.42426C7.42944 6.27786 5.57076 6.27786 4.42436 7.42426L3.42436 8.42426C2.27796 9.57066 2.27796 11.4293 3.42436 12.5757C4.57076 13.7221 6.42944 13.7221 7.57584 12.5757L8.07583 12.0757C8.31015 11.8414 8.69005 11.8414 8.92436 12.0757C9.15868 12.31 9.15868 12.6899 8.92436 12.9243L8.42436 13.4243C6.80934 15.0393 4.19086 15.0393 2.57583 13.4243C0.960806 11.8092 0.960807 9.19076 2.57583 7.57573L3.57583 6.57574C5.19086 4.96071 7.80933 4.9607 9.42436 6.57573C9.65868 6.81005 9.65868 7.18995 9.42436 7.42426C9.19005 7.65858 8.81015 7.65858 8.57583 7.42426Z"
                  fill="#AEBAD6" />
              </svg>
              <p class="mb-0 tex-dark-9 text-s-ss text-normal">{{ this.highlight && this.highlight.stream?.name }}</p>
            </div>
          </div>

          <div class=" d-md-flex align-items-center justify-content-start mt-2">
            <a class="text-s-s text-dark-6 inline-flex items-center mr-2" target="_blank"
              href="https://docs.castr.com/en/articles/8856289-highlights">
              <svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                <path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              </svg>How Live Clipping works</a>
            <b-button class="text-s-s text-dark-6 px-0 mr-2 py-0 font-weight-normal" variant="link"
              v-b-modal.modal-demo-video>
              <svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Live Clipping Demo
            </b-button>
            <b-dropdown menu-class="dropdown-menu_md" right no-caret variant="icon" size="sm" toggle-class="p-0"
              class="">
              <template #button-content>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4 8C4 8.69036 3.44036 9.25 2.75 9.25C2.05964 9.25 1.5 8.69036 1.5 8C1.5 7.30964 2.05964 6.75 2.75 6.75C3.44036 6.75 4 7.30964 4 8ZM9.25 8C9.25 8.69036 8.69036 9.25 8 9.25C7.30964 9.25 6.75 8.69036 6.75 8C6.75 7.30964 7.30964 6.75 8 6.75C8.69036 6.75 9.25 7.30964 9.25 8ZM13.25 9.25C13.9404 9.25 14.5 8.69036 14.5 8C14.5 7.30964 13.9404 6.75 13.25 6.75C12.5596 6.75 12 7.30964 12 8C12 8.69036 12.5596 9.25 13.25 9.25Z"
                    fill="#8794B5" />
                </svg>
              </template>
              <b-dropdown-item variant="disable" class="p-0" @click="highlightStatus()">
                <span>{{ this.isDisabled ? 'Enable' : 'Disable' }}</span>
              </b-dropdown-item>
              <b-dropdown-item variant="delete" class="p-0" @click="highlightDelete()">
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="flex items-center clip-size-warning-text">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00002 2.09999C4.74154 2.09999 2.10002 4.74151 2.10002 7.99999C2.10002 11.2585 4.74154 13.9 8.00002 13.9C11.2585 13.9 13.9 11.2585 13.9 7.99999C13.9 4.74151 11.2585 2.09999 8.00002 2.09999ZM0.900024 7.99999C0.900024 4.07877 4.0788 0.899994 8.00002 0.899994C11.9212 0.899994 15.1 4.07877 15.1 7.99999C15.1 11.9212 11.9212 15.1 8.00002 15.1C4.0788 15.1 0.900024 11.9212 0.900024 7.99999ZM9 5.5C9 6.05228 8.55228 6.5 8 6.5C7.44772 6.5 7 6.05228 7 5.5C7 4.94772 7.44772 4.5 8 4.5C8.55228 4.5 9 4.94772 9 5.5ZM8.6 8.5C8.6 8.16863 8.33137 7.9 8 7.9C7.66863 7.9 7.4 8.16863 7.4 8.5V11C7.4 11.3314 7.66863 11.6 8 11.6C8.33137 11.6 8.6 11.3314 8.6 11V8.5Z" fill="#AEBAD6"/>
          </svg>

          <p>You can clip maximum of {{ durationMins }} minutes. If you want to clip more minutes, <span class="text-underline cursor-pointer" @click="openIntercom">contact sales</span>.</p>
        </div>
        <div class="rmp-wrap">
          <div id="rmp" style="height: 80vh"></div>
        </div>
      </div>
    </main>
    <confirm-modal modalId="limit-exceed" :message="`Clip exceeds ${maxHighlightDuration}-minute duration limit.`"
      body="Please contact sales if they want to increase the duration limit" ok-text="Contact sales"
      cancel-text="Cancel" @modal-confirm="redirect" />
    <demo-video-modal videoid="cm5nmbgpk0810m5nfqcj34fpb"/>
  </b-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from 'vuex';
import AddHighlightModal from "@/components/modals/AddHighlightModal.vue";
import HighlightSubchannelModal from "@/components/modals/HighlightSubchannelModal.vue";
import CustomMetadataModal from "@/components/modals/CustomMetadataModal.vue"
import Spinner from "@/components/ui/Spinner.vue";
import { v4 } from 'uuid';
import AlertModal from "@/components/modals/AlertModal.vue";
import moment from 'moment';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import HighlightCardView from '@/components/ui/HighlightCardView.vue';
import IntegrationService from '@/services/IntegrationService';
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';
import HighlightService from '@/services/HighlightService';
import Vue from 'vue';
import { isProxy, toRaw } from 'vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import DvrPlayer from '@flussonic/flussonic-dvr-player';
import '@flussonic/flussonic-dvr-player/dist/fonts/font.css';

export default {
  name: "StreamManageHighlights",
  components: {
    Spinner,
    ConfirmModal,
    HighlightSubchannelModal,
    CustomMetadataModal,
    AddHighlightModal,
    HighlightCardView,
    AlertModal,
    DemoVideoModal,
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      subscriptions: "User/subscriptions",
    }),
    subscription() {
      return this.$store.state.User.subscriptions;
    },
    isTrialSubscription() {
      const subs = this.subscription.map(sub => _.assign({ category: 'live' }, sub))
      const baseSub = _.find(subs, { category: 'live' }) || {};
      console.log(subs);
      console.log(baseSub);
      let basePackages = baseSub.package;
      if (isProxy(basePackages)) {
        basePackages = toRaw(basePackages)
      }
      return false;
    },
    // streamAlive() {
    // 	return this.streamStatus && this.mediaPulse && this.mediaPulse.alive;
    // },
    user() {
      return this.$store.state.User;
    },
    clipbtnStatus() {
      return this.isDisabled || (this.duration == 0) || (this.clipName.length == 0) || this.clipped || this.isTrialSubscription;
    },
    userId() {
      return this.highlight?.stream?.user || this.$store.state.User._id;
    },
    maxHighlightDuration() {
      let sub = null
      this.subscriptions.some((item) => {
        if (item.enabled && item.category === 'live') sub = item
      })
      let isValidPlanOverrideDefinition = null
      let isValidPlanDefinition = false
      if (sub) {
        if (sub?.package?.definition?.maxHighlightDuration) isValidPlanDefinition = sub.package.definition.maxHighlightDuration
        if (sub?.definitionOverride?.maxHighlightDuration) isValidPlanOverrideDefinition = sub.definitionOverride.maxHighlightDuration
      }
      if (isValidPlanOverrideDefinition) return isValidPlanOverrideDefinition
      return isValidPlanDefinition
    }
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true)
    await this.$store.dispatch("User/loadUser");
    await this.$store.dispatch('Highlight/getHighlight');
    this.highlightId = this.$route.params.highlightId;
    await this.setupHighlight();

    await this.$store.dispatch('Ui/setLoadingStatus', false)

    this.setupPlayer();
  },
  created() {
  },
  beforeDestroy() {
    clearInterval(this.pollId);
  },
  watch: {
  },
  methods: {
    openIntercom() {
      window.Intercom('show');
    },
    metaChanged() {
      this.unsavedMeta = true;
    },
    saveasMeta() {
      if (this.highlight.platforms.length > 0) {
        this.highlight.platforms.map(async (p) => {
          let updatedPlatformdata = { ...p };
          updatedPlatformdata.meta.title = this.metaTitle;
          updatedPlatformdata.meta.description = this.metaDescription;
          try {
            const updatePlatform = await HighlightService.updatePlatformmeta(
              p.highlight,
              p._id,
              updatedPlatformdata
            );
          } catch (err) {
            console.log(err);
          }
        });
        this.$notify({ group: 'success', text: 'Platform Metadata Updated' });
        this.unsavedMeta = false;
      } else {
        this.$notify({
          group: 'error',
          title: "No Platform Available"
        });

      }
    },
    reversedItems(items) {
      return [...items].reverse();
    },
    normalizePlatform(platforms) {
      platforms.map(async (p, index) => {
        let isTokenValid = await this.checkValidity(p);
        let type = p.type;
        this.isLinkvalid[type] = isTokenValid;
      });
    },
    nameChanged() {
      this.clipped = false;
    },
    setupPlayer() {
      console.log('recreating');
      
      const createPlayer = () => {
        const elementID = 'rmp';
        const settings = {
          name: `highlights/${this.userId}/${this.highlight.stream.key}`,
          streamer_http: 'https://castr-highlights.castr.com',
          // streamer_rtmp: "rtmp://central-storage-1.castr.io" + ":1935/static/",
          auth_token_name: "token",
          statsSendEnable: false,
          statsSendTime: 60,
          query: 'dvr=true',
          dvr: true,
          proto: 'hls',
          debug: false,
          export_limit: (Number(this.maxHighlightDuration) * 60 ) || 60 * 15,
        };
        this.durationMins = Number(this.maxHighlightDuration) ?? 15;
        this.rmp = null;        
        let rmp = DvrPlayer.load(settings, document.getElementById(elementID));
        this.rmp = rmp;
      };

      createPlayer();
    },
    async checkValidity(platform) {
      if (platform.type == 'facebook') {
        try {
          const authStatus = await IntegrationService.isFacebookTokenValid(platform.linkedService, true);
          return authStatus;
        } catch (error) {
          console.log(error);
        }
      }
      if (platform.type == 'youtube') {
        try {
          const authStatus = await IntegrationService.isYoutubeTokenValid(platform.linkedService, platform.meta.subChannel, true);
          return authStatus.isTokenValid;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async highlightStatus() {
      this.rmp?.destroy();
      this.rmp = null;
      console.log('nulling1');
      let status = await HighlightService.checkDisabled(this.highlight._id, !this.isDisabled);
      this.isDisabled = !this.isDisabled;
      let msg = this.isDisabled ? 'Disabled' : 'Enabled';
      this.setupHighlight();
      this.$notify({
        group: 'success',
        title: 'The Live Clipping is ' + msg,
      });
    },
    async getClipstatus(cid) {
      try {
        let status = await HighlightService.checkStatus(this.highlight._id, cid);
        return status.status;
      } catch (err) {
        console.log('integration-conflict-check-error', err);
      }
    },
    onTimeLineRangeChanged(args) {
      this.clipped = false;
      this.rangeStart = args[0];
      this.rangeEnd = args[1];
      this.duration = this.rangeEnd - this.rangeStart;
      this.isLiveMode = false;
      clearInterval(this.pollId);
      this.setupRange();
    },
    async shareClip() {
      let clips = localStorage.getItem('clips');
      clips = JSON.parse(clips);
      let publishedPlatform = [];
      if (this.selectedPlatform && this.selectedPlatform.length && clips && (clips.length > 0)) {
        this.selectedPlatform.map(p => {
          let singlePlatform = {
            "title": p.meta.title,
            "description": p.meta.description,
            "type": p.type,
            "platform": p._id
          }
          if(p.meta.privacyStatus) singlePlatform['privacyStatus'] = p.meta.privacyStatus;
          publishedPlatform.push(singlePlatform);
        });
        let shredHighlight = await HighlightService.shareHighlight(this.highlight._id, clips[0].hname, Math.round(clips[0].rstart * 0.001), Math.round(clips[0].rend * 0.001), Math.round(clips[0].rdur * 0.001), this.highlight.stream._id, publishedPlatform);

        let clipStatus = await this.getClipstatus(shredHighlight._id);
        this.highlight.clips.push(shredHighlight);

        this.rangeStart = 0,
          this.rangeEnd = 0,
          this.duration = 0,
          this.clipName = '',
          localStorage.setItem("clips", []);
      } else {
        this.$notify({
          group: 'error',
          title: "No clip selected"
        });
      }
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
    },
    sortPlatforms(platforms) {
      return platforms.sort(function (a, b) {
        var textA = a.type.toUpperCase();
        var textB = b.type.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    async listenAckEventResponse(key, callback) {
      const ackRes = await IntegrationService.getAckByKey(key);
      if (ackRes && ackRes.success) {
        callback(ackRes.data);
        this.oauthProcessing = false;
        this.serviceResponseRecieved = false;
      } else {
        setTimeout(() => {
          this.listenAckEventResponse(key, callback);
        }, 4000);
      }
    },
    async onServiceAuthResponse(response) {
      if (this.serviceResponseRecieved) return;
      this.serviceResponseRecieved = true;
      if (!response || response.error) {
        this.serviceLinkError = true;
        return;
      }
      const oauthMetaId = response.linkedMeta;
      // acknowledge UI, authorization was successful
      this.linkedServiceCreds = oauthMetaId;
      // check for linking conflicts
      let linkResult;
      try {
        linkResult = await IntegrationService.checkIntegrationConflict(this.highlight.stream._id, oauthMetaId);
      } catch (err) {
        console.log('integration-conflict-check-error', err);
        window.alert('could not perform integration duplication check');
      }

      if (!linkResult || linkResult.linked) {
        // conflicts are there
        this.serviceLinkError = true;
        this.serviceLinkConflict = true;
        this.linkedServiceCreds = null;
        return;
      }
      if (!this.reconnecting) {
        this.setSubChannels(oauthMetaId);
      } else {
        await this.normalizePlatform(this.highlight.platforms);
        this.reconnecting = false;
        this.linkedServiceCreds = null;
      }

    },
    async onHighlightChange() {
      if (!this.highlightName) this.highlightName = this.highlight.name;

      if (this.highlight.name === this.highlightName) return;

      // try changing stream name
      try {
        await HighlightService.setHighlightName(this.highlight._id, this.highlightName);

        // track event
        window.trackEvent(
          `Updated stream name ${this.highlight.name} -> ${this.highlightName}`
        );
      } catch (err) {
        this.highlightName = this.highlight.name;
        this.$notify({
          group: 'error',
          title: "Couldn't change Live Clipping name",
          text: err.message
        });
      }
    },
    resizeTextarea: function (e) {
      let area = e.target;
      area.style.height = area.scrollHeight + 'px';
    },
    async retry(cid) {
      try {
        let retryShare = await HighlightService.retryClipshare(this.highlight._id, cid);
        await this.$store.dispatch('Highlight/getHighlight')
      } catch (err) {
        console.log('retryShare', err);
        // window.alert('could not addPlatform');
      }
    },
    preventEnter(e) {
      e.preventDefault();
    },
    async requestDelete(p) {
      try {
        let deletePlatform = await HighlightService.deletePlatform(this.highlight._id, p._id);
        this.selectedPlatform = [];
      } catch (err) {
        console.log('deletePlatform', err);
        // window.alert('could not addPlatform');
      }
      this.$notify({
        group: 'success',
        title: `${p.name}'s account is deleted.`,
      });
      try {
        await this.$store.dispatch('Highlight/getHighlight')
        const highlight = await HighlightService.getHighlightbyid(this.highlightId);
        this.highlight = highlight;
        // this.serviceResponseRecieved = false;
      } catch (err) {
        console.log('checkpf', err);
        // window.alert('could not addPlatform');
      }
    },
    async highlightDelete(p) {
      try {
        let deleteHighlight = await HighlightService.deleteHighlight(this.highlight._id);
        if (deleteHighlight.success) {
          this.$router.push({ name: 'Highlight' })
          this.$notify({
            group: 'success',
            title: "The Live Clipping is Deleted",
          });
        }
      } catch (err) {
        console.log('deletePlatform', err);
        // window.alert('could not addPlatform');
      }
    },
    redirect() {
      if (window.Intercom) {
        window.Intercom('show');
      }
    },
    async onSavePlatform(subChannelItem) {
      let metaOverrides = {};
      const ingestConfParams = {};
      const subchannelSlug = subChannelItem.type ? ((subChannelItem.type || '-') + '@' + subChannelItem.id) : subChannelItem.id;
      metaOverrides = {
        'title': this.metaTitle,
        'description': this.metaDescription,
        "subChannel": subchannelSlug,
        "subChannelName": subChannelItem.name
      };

      try {
        let addPlatform = await HighlightService.addPlatform(this.highlight._id, subChannelItem.name, this.oauthFactoryName, this.linkedServiceCreds, this.highlight.stream._id, metaOverrides);
        let newPlatforms = addPlatform.platforms;
        this.highlight.platforms = newPlatforms;
        // this.normalizePlatform(this.highlight.platforms);
        // await this.$store.dispatch('Highlight/getHighlight');
        this.serviceResponseRecieved = false;
      } catch (err) {
        console.log('addPlatform', err);
      }
    },
    async addMetadata(platform) {
      this.updatePlatform = platform;
      this.$bvModal.show('modal-custom-metadata');
    },
    async setSubChannels(oauthMetaId) {
      this.$bvModal.show('modal-highlight-subchannel');
      let subChannelList = await IntegrationService.getServiceSubChannels(oauthMetaId);
      this.subChannelLists = subChannelList;

    },
    getServiceOAuthUrl(oauthFactoryName, reconnect = false) {
      this.reconnecting = reconnect;
      this.oauthFactoryName = oauthFactoryName;
      const pathname = `integrations/${oauthFactoryName}/oauth`;
      const connectId = v4();
      this.oauthConnectId = connectId;

      const searchParams = `redirect=1&user=${this.user._id || this.user.id}&stream=${this.highlight._id}&connectId=${this.oauthConnectId}`;

      let routeUrl = `${process.env.VUE_APP_APP_API_BASE}/${pathname}?${searchParams}`;
      window.open(routeUrl, '_blank');
      this.listenAckEventResponse(connectId, this.onServiceAuthResponse);
    },
    async setupHighlight() {
      try {
        if(!this.highlightId) return;
        const highlight = await HighlightService.getHighlightbyid(this.highlightId);
        this.highlight = highlight;
        this.highlightName = this.highlight.name;
        this.isDisabled = this.highlight.isDisabled;
        if (this.highlight.platforms.length > 0) {
          this.metaTitle = this.highlight.platforms[0].meta.title;
          this.metaDescription = this.highlight.platforms[0].meta.description;
        } else {
          this.metaTitle = this.highlight.name;
        }
      } catch (err) {
        this.$notify({
          group: 'error',
          title: err.error,
          text: err.message
        });
      }
    },
    getDate(ts) {
      return moment(ts).format('LL');
    },
    filteredPlatform(platforms) {
      let filtered = platforms.filter(p => {
        return p && p.hasOwnProperty('status');
      });
      return filtered;
    },
    async onExportCustomRange() {

      if (this.rangeEnd === 0) {
        this.rangeEnd = this.duration
      }
      if (this.duration < (this.maxHighlightDuration * 60 * 1000)) {
        let clip = { hid: this.highlight._id, hname: this.clipName, rstart: this.rangeStart, rend: this.rangeEnd, rdur: this.duration, streamid: this.highlight.stream._id }
        this.clips = [clip].concat(this.clips)
        localStorage.setItem("clips", JSON.stringify(this.clips));
        this.metaTitle = this.clipName;
        this.clipped = true;
      } else {
        this.$bvModal.show("limit-exceed");
      }
    },
    humanTimeRange(duration) {
      return moment.utc(moment.duration(duration, "ms").asMilliseconds()).format("HH:mm:ss")
    },
    humanTime(time) {
      return moment(time)
        .format("HH:mm:ss");
    },
    updateStatus() {
      this.serviceResponseRecieved = false;
    }
  },
  data() {
    return {
      timeline: {
        start: 0,
        end: 0,
      },
      durationLimit: 120,
      countdownSec: 0,
      playlistIndex: 0,
      hasHighlight: false,
      highlightId: null,
      highlight: null,
      oauthConnectId: null,
      updatePlatform: null,
      fbCheck: null,
      ytCheck: null,
      highlightName: null,
      hlsUrl: null,
      rangeStart: 0,
      rangeEnd: 0,
      duration: 0,
      dvrDuration: 3600,
      dvrFrom: 0,
      clipName: '',
      serviceResponseRecieved: false,
      isDisabled: true,
      serviceLinkError: false,
      linkedServiceCreds: null,
      serviceLinkConflict: false,
      clips: [],
      selectedPlatform: [],
      subChannelLists: [],
      metaDescription: "",
      metaTitle: '',
      oauthFactoryName: '',
      clipped: false,
      reconnecting: false,
      isLinkvalid: {
        'facebook': true,
        'youtube': true
      },
      isLiveMode: true,
      unsavedMeta: false,
      streamOffline: false,
      mediaPulse: null,
      durationMins: 15,
    };
  },
};
</script>
<style>
#dvr-body {
    width: 100vw;
    height: 100dvh;
    max-width: 100vw;
    max-height: 100dvh;
    overflow-x: hidden
}

#dvr-player {
    height: 100%;
    width: 100%;
    overflow-x: hidden
}

div.dvrp-right-controls > div.dropDownWrapper {
  display: none;
}

.firstRow .item:not(:first-of-type) {
    display: none;
}

.firstRow a.item {
    display: none;
}

#rmp > div > div.playersContainer > div.seekContainer > .buttonGroup {
  display: none;
}
</style>
<style scoped>
.clip-size-warning-text {
  color: var(--c-dark-8);
  font-size: var(--font-s-xs);
  margin-bottom: 8px;
}

.embed_highlight {
  width: 550px;
  height: 320px;
}

.video-range-bar .video-range-bar-left {
  border-radius: 100%;
}

.clipping-control {
  border-radius: 0px 0px 8px 8px;
  background: #232D4D;
  padding: 8px 12px 16px 12px;
}

.back-live {
  background: #232D4D;
  padding: 8px 5px 0px 5px;
}

.clipping-info {
  border-radius: 6px;
  background: rgba(167, 181, 226, 0.08);
  padding: 6px 12px;
}

.highlight-stream {
  background: #232D4D;
  min-height: 293px;
}

.clip-name {
  min-width: 177px;
}

.clipping-control .cbox>div {
  border-radius: 6px;
  background: var(--c-dark-3);
  height: 32px;
  padding: 0px 10px;
}

.clipping-control .cbox input {
  border-radius: 6px;
  background: var(--c-dark-3);
  height: 32px;
  padding: 0px;
}

.cbox p {
  min-height: 19.5px;
}

.trimmed--placeholder {
  padding-left: 24px;
}

.h-input {
  border-radius: 6px;
  background: var(--neutral-3, rgba(167, 181, 226, 0.08));
  border: 0;
  box-shadow: none;
  outline: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 10px;
}

.highlight_bottom--right {
  width: 100%;
}

.platform--box {
  padding: 0px 16px;
  margin: 15px 0px;
}

.clip-list {
  border-radius: 8px;
  background: var(--neutral-dark-2, #232D4D);
  margin-bottom: 8px;
  padding: 12px 16px;
}

.highlight_thumb {
  width: 73px;
  height: 44px;
  border-radius: 4px;
  background: #000;
}

.clip-right {
  min-width: 100px;
  text-align: right;
}

.clip-middle {
  /* width: 100px; */
  text-align: center;
}

.clip-left {
  min-width: 39%;
}

.clip-middle a {
  padding: 0px 6px;
}

/*.posted-clips:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 112px;
  left: 0;
  background: #fff;
  bottom: -15px;
}*/

.clip-status {
  display: flex;
  align-items: center;
}

.status_failed .failed-clip {
  display: inline-block;
}

.failed-clip {
  display: none;
}

.status_failed {
  position: relative;
  display: inline-block;
  pointer-events: none;
  opacity: .6;
}

.status_failed .failed-clip {
  position: absolute;
  right: 5px;
  top: calc(50% + 6px);
}

.failed_msg {
  margin-left: 82px;
  display: none;
}

.failed_clip .failed_msg {
  display: block;
}

.highlight-name:focus {
  background: var(--c-dark-3) !important;
}

/* .clipping-control:after {
    content: "";
    position: absolute;
    width: calc(100% - 24px);
    background: #343E61;
    height: 1px;
    top: 16px;
    left: 12px;
} */
a.status_processing {
  pointer-events: none;
  opacity: .7;
}

.single_status {
  display: none;
}

.type_facebook.status_failed~.single_status--facebook,
.type_youtube.status_failed~.single_status--youtube {
  display: block;
}

.failed_clip .single_status {
  display: none !important;
}

.single_status {
  position: absolute;
  left: 97px;
  bottom: 5px;
}


.status_failed {
  height: 55px;
}

.failed_clip .status_failed {
  height: unset;
}

.highlight_platform_action {
  position: absolute;
  right: -10px;
  top: 12px;
  z-index: 9;
  cursor: pointer;
}

.waiting-time {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiting-time code {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .highlight-stream {
    min-height: 200px;
  }

  .timeline-wrap {
    margin: 0px 5px;
  }
}
</style>
