<template>
  <div class>
    <b-modal size="md" hide-header hide-footer centered id="add-paywall-modal" ref="addPaywallModal"
      :modal-class="['modal-center teaminvite-modal']">
      <b-row>
        <b-col>
          <h4 class="text-s-l mb-1 text-500">Create Paywall</h4>
          <p class="text-s-m text-dark-8 font-normal mb-4">Set up your pricing to enable Paywall for this
            content. Your audiences will have access to watch this content after they make payment.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="">
          <label class="text-s-s text-dark-8 font-normal mb-2">
            Paywall Name
          </label>
          <b-input v-model="name" size="md" placeholder="Enter the name of the paywall" />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" md="12" class="">
          <label class="text-s-s text-dark-8 font-normal mb-2">
            Payment Type
          </label>
          <b-form-group id="input-group-3" label-class="label-md" class="mb-4">
            <b-form-select v-model="selectedPaymentType" id="selectPayment" :options="paymentTypes" size="md"
              required></b-form-select>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="">
        <b-col cols="12" md="12" class="">
          <div class="d-flex  align-items-center justify-content-between">
            <div class="d-flex flex-column" style="width: 47%">
              <label class="text-s-s text-dark-8 font-normal mb-2">
                Currency
              </label>
              <country-currency-dropdown :is-disabled="false" @on-currency-selected="onCurrencySelected"
                :selectedCurrency="selectedCurrency" />
            </div>

            <div class="d-flex flex-column" style="width: 47%">
              <label class="text-s-s text-dark-8 font-normal mb-2">
                Amount
              </label>
              <b-input v-model="selectedPrice" size="md" placeholder="0" />
            </div>

          </div>
        </b-col>

      </b-row>

      <b-row class="mt-3">
        <b-col cols="12" md="12" class="">
          <label class="text-s-s text-dark-8 font-normal mb-2">
            Source
          </label>
          <stream-source-dropdown @on-source-selected="onSourceStreamSelected" :dropRight="true"
            :selectedStream="selectedSource" :showPlaylist="true" :hide-notification="true" :close-after-select="true" />
        </b-col>
      </b-row>

      <hr class="my-4">

      <b-row class="">
        <b-col class="text-right">
          <b-button variant="outline-secondary" size="md" @click="unselectForm" class="text-center mr-2">
            Cancel
          </b-button>

          <b-button variant="primary" size="md" class="" @click="onConfirm()"
            :disabled="createDisabled || paywallLimitReached">
            <span id="tooltip-limit-reached">{{ processing ? 'Creating ...' : 'Create Paywall' }}</span>
          </b-button>

          <b-tooltip v-if="paywallLimitReached" target="tooltip-limit-reached" placement="top">
            You've reached the soft limit for the number of paywalls allowed. This limit can be increased for free. To increase your limit, please contact our <span class="test-surface-6 underline cursor-pointer" onclick="Intercom('show')">customer support team.</span></b-tooltip>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import CountryCurrencyDropdown from "@/components/ui/CountryCurrencyDropdown.vue"
import StreamSourceDropdown from "@/components/ui/StreamSourceDropdown.vue"
import PaywallService from "@/services/PaywallService"
import { mapGetters } from 'vuex'

export default {
  name: 'AddPaywallModal',
  components: {
    CountryCurrencyDropdown,
    StreamSourceDropdown
  },
  data() {
    return {
      processing: false,
      name: '',
      selectedSource: null,
      selectedPrice: 0,
      selectedCurrency: 'usd',
      selectedPaymentType: 'payperview',
      paymentTypes: [
        {
          text: "Pay per view",
          value: "payperview",
        },
      ],
    }
  },
  props: {

  },
  computed: {
    ...mapGetters({
      maxPaywalls: "User/maxPaywalls",
      paywalls: "Paywalls/paywalls",
    }),

    createDisabled() {
      if (this.name === '') {
        return true
      }

      if (this.selectedSource === null) {
        return true
      }

      if (Number(this.selectedPrice) < 0) {
        return true
      }

      return false
    },
    paywallLimitReached() {
      if (this.paywalls.length >= this.maxPaywalls) {
        return true
      }

      return false
    },
  },
  async mounted() {
    this.$refs.addPaywallModal.$on('hide', this.onDismiss);
    this.$refs.addPaywallModal.$on('shown', this.onInit);
  },
  methods: {
    onSourceStreamSelected(source) {
      this.selectedSource = source
    },
    onCurrencySelected(currency) {
      this.selectedCurrency = currency.code.toLowerCase()
    },
    async onInit() {
    },
    async onConfirm() {
      this.processing = true
      try {
        const rand = Math.random().toString(36).slice(-7)
        const payload = {
          url: `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${this.selectedSource.key}?paywall=1&rand=${rand}`,
          title: this.name,
          recurring: 0,                           // pay per view
          currency: this.selectedCurrency,
          price: this.selectedPrice,
          period: 'year',
        }

        if (this.selectedSource.type === 'vod') {
          payload.url = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/playlist/${this.selectedSource._id}?paywall=1&rand=${rand}`;
        }

        if (this.selectedSource.streams) {
          // should be playlist
          payload.url = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/playlist/${this.selectedSource._id}?paywall=1&rand=${rand}`;
        }

        const pwObj = await PaywallService.activateStreamPaywall(
          this.selectedSource._id,
          payload
        );

        this.$emit('paywall-created', pwObj);
        this.dismiss()

      } catch (err) {
        this.$notify({
          group: "error",
          text: err.message || "Create paywall failed",
        });
      }

      this.processing = false
    },
    unselectForm() {
      this.resetForm();
      this.dismiss();
    },
    onDismiss() {
      this.resetForm();
      // lazy clear
      setTimeout(() => {
        this.$parent.processing = false;
      }, 1000);
    },
    dismiss() {
      this.processing = false;
      this.onDismiss();
      this.$refs.addPaywallModal.hide();
    },
    resetForm() {
      this.selectedSource = null
      this.selectedPrice = 0
      this.selectedCurrency = 'usd'
      this.selectedPaymentType = 'payperview'
    },
  },
  watch: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  border-top-color: var(--c-dark-4);
}

.cursor-pointer {
  cursor: pointer;
}

.edit-pm:hover {
  opacity: .8;
  transition: all .3s ease;
}

@media (max-width: 991px) {
  .sticky-header {
    background-color: var(--c-dark-2);
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

@media (max-width: 767px) {
  .label-v {
    display: flex;
    align-items: flex-end;
  }
}
</style>
