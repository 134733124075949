<script setup>
import { computed, ref, onMounted, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import StreamService from '@/services/StreamService';
import IntegrationService from '@/services/IntegrationService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import Spinner from '../../components/ui/Spinner.vue';

const { notify } = useNotification()
const store = useStore()
const router = useRouter()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	}
})

const streamId = ref(null)
const streamMeta = ref(null)
const countryBlockSelection = ref([])
const countryWhitelistSelection = ref([])
const countryList = ref([])
const countryListLoaded = ref(false)
const whitelistedDomains = ref([])
const whitelistedDomainsLoaded = ref(false)
const features = ref({
	embedPassword: {
		enabled: false,
		value: null,
		valueType: 'string'
	},
	countryBlock: {
		enabled: false,
		value: [],
		valueType: 'array'
	},
	countryWhitelists: {
		enabled: false,
		value: [],
		valueType: 'array'
	},
	domainWhitelists: {
		enabled: false,
		value: [],
		valueType: 'array'
	}
})
const featureProcessing = ref({
	embedPassword: false,
	countryBlock: false,
	countryWhitelists: false,
	domainWhitelists: false
})

const productsFeatures = computed(() => {
	return store.state.User.aggregatedDefinition;
})

onMounted(async () => {
	{
		streamId.value = props.stream._id;

		const meta = await StreamService.getStreamMetadata(streamId.value);
		streamMeta.value = meta;

		_.forIn(meta, (value, key) => {
			if (value !== undefined && value !== null && key in features.value) {
				let enabled = value !== false;
				if (Array.isArray(value)) {
					enabled = value.length > 0;
				}

				features.value[key].enabled = enabled;
				if (value.constructor !== Boolean) {
					features.value[key].value = value;
				}
			}
		});

		// fetch country list
		countryList.value = await IntegrationService.getCountryList();
		countryListLoaded.value = true;

		countryBlockSelection.value = _.map(
			features.value.countryBlock.value,
			code => _.find(countryList.value, { code })
		);
		countryWhitelistSelection.value = _.map(
			features.value.countryWhitelists.value,
			code => _.find(countryList.value, { code })
		);

		whitelistedDomains.value = _.join(
			_.filter(features.value.domainWhitelists.value, item => item !== 'https://castr.com'),
			'\r\n',
		);
		whitelistedDomainsLoaded.value = true;

		// event tracking
		window.trackEvent(
			props.stream.name + ' - Live Stream Security Settings Page',
			props.stream
		);
	}
})

const navigateToPayments = (category = 'vod') => {
	// Redirect to correct live or vod package
	if (category !== 'live' && category !== 'vod') {
		category = 'vod'
	}
	if (category === 'vod') {
		category = 'live'
	}
	router.push({ name: 'Payments', query: { category } });
}

const toggleFeature = async (featureName) => {
	if (featureProcessing.value[featureName]) return;

	const feature = features.value[featureName];
	if (!feature) return;

	let mappedSecurityFeatName = featureName;
	switch (featureName) {
		case 'embedPassword':
			mappedSecurityFeatName = 'securityFeaturesPasswordAuth';
			break;
		case 'countryBlock':
			mappedSecurityFeatName = 'securityFeaturesGeoBlock';
			break;
		case 'countryWhitelists':
			mappedSecurityFeatName = 'securityFeaturesGeoWhitelist';
			break;
		case 'domainWhitelists':
			mappedSecurityFeatName = 'securityFeaturesDomainWhitelist';
			break;
	}

	// Disable geo-blocking/whitelisting on those packages
	const data = _.filter(store.state.User.subscriptions, (item) => item.category === 'live' && item.enabled)[0];
	const disabledGeoFeaturePackages = [
		'5c6e7405b408c1b41dd32f46', // 1TB Bandwidth
		'5c6e7405b408c1b41dd32f47', // 1TB Bandwidth - Yearly
		'5c6e7406b408c1b41dd32f48', // 2TB Bandwidth
		'5c6e7406b408c1b41dd32f49', // 2TB Bandwidth - Yearly
		'5ccd92f8152b7f25111c39cf', // Live 5GB Bandwidth - 7 Day Trial
		'6362114709950a6a642540c3', // New All in One - 7 Days Trial
		'6010063d03afd67aa9831cd4', // All-In-One Entry Monthly
		'6361ecd609950a6a642540b7', // New All-In-One Entry Monthly
		'6018dbb703afd67aa9831ce3', // All-In-One Entry Yearly
		'6361ecd609950a6a642540b8', // New All-In-One Entry Yearly
		'636dcc7ced167e4759926fd9', // BF22 - All in One - Entry Yearly
		'6018dc7403afd67aa9831ce4', // All-In-One Plus Monthly
		'6361ecd609950a6a642540b9', // New All-In-One Plus Monthly
		'602cc7a503afd67aa9831ced', // All-In-One Plus Yearly
		'6361ecd609950a6a642540ba', // New All-In-One Plus Yearly
		'602cc91703afd67aa9831cf2', // All-In-One Premium Monthly
		'6361ecd609950a6a642540bf', // New All-In-One Premium Monthly
		'602cc95903afd67aa9831cf3', // All-In-One Premium Yearly
		'6361ecd609950a6a642540c0', // New All-In-One Premium Yearly
		'6434ffbd40771d569f1e98e6', // Starter 2023
		'6435032f40771d569f1e98f2', // Starter Yearly 2023
		'6435001640771d569f1e98e8', // Standard 2023
		'6435035f40771d569f1e98f4', // Standard Yearly 2023
		'6435007440771d569f1e98ea', // Professional 2023
		'643503a640771d569f1e98f6', // Professional Yearly 2023
	]

	if ((props.stream.type === 'live' && data && disabledGeoFeaturePackages.indexOf(data.package._id) !== -1
		&& (['countryBlock'].indexOf(featureName) !== -1)
		&& features.value.countryBlock.enabled) || (props.stream.type === 'live' && data && disabledGeoFeaturePackages.indexOf(data.package._id) !== -1
			&& (['countryWhitelists'].indexOf(featureName) !== -1)
			&& !features.value.countryBlock.enabled)
	) {
		root.$emit('bv::show::modal', 'feature-upgrade');
		setTimeout(() => {
			features.value[featureName].enabled = !features.value[featureName].enabled;
		}, 500);
		return;
	}

	const trialAndAIOEntryPackages = [
		'5ccd92f8152b7f25111c39cf', // Live 5GB Bandwidth - 7 Day Trial
		'6362114709950a6a642540c3', // New All in One - 7 Days Trial
		'6010063d03afd67aa9831cd4', // All-In-One Entry Monthly
		'6361ecd609950a6a642540b7', // New All-In-One Entry Monthly
		'6018dbb703afd67aa9831ce3', // All-In-One Entry Yearly
		'6361ecd609950a6a642540b8', // New All-In-One Entry Yearly
		'636dcc7ced167e4759926fd9', // BF22 - All in One - Entry Yearly
		'6434ffbd40771d569f1e98e6', // Starter 2023
		'6435032f40771d569f1e98f2', // Starter Yearly 2023
		'6435001640771d569f1e98e8', // Standard 2023
		'6435035f40771d569f1e98f4', // Standard Yearly 2023
	]
	if (props.stream.type === 'live' && data && trialAndAIOEntryPackages.indexOf(data.package._id) !== -1
		&& (['domainWhitelists'].indexOf(featureName) !== -1)
		&& !features.value.domainWhitelists.enabled
	) {
		root.$emit('bv::show::modal', 'feature-upgrade');
		setTimeout(() => {
			features.value[featureName].enabled = !features.value[featureName].enabled;
		}, 500);
		return;
	}

	if (!productsFeatures.value || productsFeatures.value[props.stream.type][mappedSecurityFeatName]) {
		root.$emit('bv::show::modal', 'feature-upgrade');
		setTimeout(() => {
			features.value[featureName].enabled = !features.value[featureName].enabled;
		}, 500);
		return;
	}

	let nstate = feature.enabled;
	feature.enabled = nstate;

	if (feature.valueType === 'bool' || !nstate) {
		if (feature.valueType === 'array' && !nstate) {
			nstate = [];
		}

		await saveSetting(featureName, nstate);
	}
}

const saveGeoBlocking = async () => {
	const updatedSettings = countryBlockSelection.value;
	await saveSetting('countryBlock', _.map(updatedSettings, 'code'));
}

const saveGeoWhitelisting = async () => {
	const updatedSettings = countryWhitelistSelection.value;
	await saveSetting(
		'countryWhitelists',
		_.map(updatedSettings, 'code')
	);
}

const setStreamPassword = async () => {
	const password = features.value.embedPassword.value;
	if (!password) return;
	await saveSetting('embedPassword', password);
}

const saveWhitelistedDomains = async () => {
	let domains = _.split(whitelistedDomains.value, '\n')
		.map(d => d.trim())
		.filter(Boolean);
	const castrExist = domains.find((item) => item === 'https://castr.com');
	if (_.isEmpty(castrExist)) {
		domains = [...domains, 'https://castr.com'];
	}
	if (!domains.length) return;

	await saveSetting('domainWhitelists', domains);
}

const saveSetting = async (key, value) => {
	featureProcessing.value[key] = true;
	try {
		await StreamService.saveStreamMetadata(
			streamId.value,
			key,
			value
		);
		// clear stream meta cache in embed player
		StreamService.clearStreamMetadataCache(streamId.value);
	} catch (e) {
		notify({ group: 'error', text: 'could not save changes' });
	}
	featureProcessing.value[key] = false;
}
</script>

<template>
	<div class="divide-y divide-surface-2">
		<section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
			<h4 class="text-base">Enable Password Protection</h4>
			<FormSwitch class="justify-self-end" @change="toggleFeature('embedPassword')"
				v-model="features.embedPassword.enabled" :disabled="featureProcessing.embedPassword" />
			<div class="col-span-2 flex items-center gap-x-2 !mt-1" v-if="features.embedPassword.enabled">
				<label class="m-0">Password</label>
				<FormInput class="!w-1/3" v-model="features.embedPassword.value" placeholder="Set Password" />
				<FormButton :disabled="!features.embedPassword.value || featureProcessing.embedPassword" @click="setStreamPassword">
					{{ featureProcessing.embedPassword ? 'Saving' : 'Save' }}
				</FormButton>
			</div>
		</section>
		<section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
			<div>
				<h4 class="text-base">Geo/Country Blocking</h4>
				<p class="text-tiny text-surface-7">Prevent (ban) viewers from specific country(ies).</p>
			</div>
			<FormSwitch class="justify-self-end" v-model="features.countryBlock.enabled"
				:disabled="featureProcessing.countryBlock" @change="toggleFeature('countryBlock')" />
			<div class="col-span-2 flex items-center gap-x-2 !mt-1" v-if="features.countryBlock.enabled">
				<template v-if="countryListLoaded">
					<multiselect v-model="countryBlockSelection" :options="countryList" :multiple="true" :taggable="true"
						tag-placeholder="Unsupported region" placeholder="Search for a country" label="name" track-by="code"
						class="h-8 min-h-[2rem]" />
					<FormButton :disabled="featureProcessing.countryBlock" @click="saveGeoBlocking">
						{{ featureProcessing.countryBlock ? 'Saving' : 'Save' }}
					</FormButton>
				</template>
				<template v-else>
					<Spinner />
				</template>
			</div>
		</section>
		<section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
			<div>
				<h4 class="text-base">Geo/Country Whitelisting</h4>
				<p class="text-tiny text-surface-7">Allow viewers only from specific country(ies).</p>
			</div>
			<FormSwitch class="justify-self-end" v-model="features.countryWhitelists.enabled"
				:disabled="featureProcessing.countryWhitelists" @change="toggleFeature('countryWhitelists')" />
			<div class="col-span-2 flex items-center gap-x-2 !mt-1" v-if="features.countryWhitelists.enabled">
				<template v-if="countryListLoaded">
					<multiselect v-model="countryWhitelistSelection" :options="countryList" :multiple="true" :taggable="true"
						tag-placeholder="Unsupported region" placeholder="Search for a country" label="name" track-by="code"
						class="h-8 min-h-[2rem]" />
					<FormButton :disabled="featureProcessing.countryWhitelists" @click="saveGeoWhitelisting">
						{{ featureProcessing.countryWhitelists ? 'Saving' : 'Save' }}
					</FormButton>
				</template>
				<template v-else>
					<Spinner />
				</template>
			</div>
		</section>
		<section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
			<div>
				<h4 class="text-base">Enable Domain Whitelisting</h4>
				<p class="text-tiny text-surface-7">Allow the embedded player to be watched only on specific domains.</p>
			</div>
			<FormSwitch class="justify-self-end" v-model="features.domainWhitelists.enabled"
				:disabled="featureProcessing.domainWhitelists" @change="toggleFeature('domainWhitelists')" />
			<div class="col-span-2 items-center gap-x-2 !mt-1" v-if="features.domainWhitelists.enabled">
				<template v-if="whitelistedDomainsLoaded">
					<div class="flex items-start gap-x-2">
						<textarea v-model="whitelistedDomains" class="bg-surface-3 rounded w-full !p-2"
							placeholder="one domain per line" autocomplete="off" autocorrect="off" autocapitalize="off"
							spellcheck="false" rows="4" />
						<FormButton :disabled="!features.domainWhitelists.value || featureProcessing.domainWhitelists"
							@click="saveWhitelistedDomains">
							{{ featureProcessing.domainWhitelists ? 'Saving' : 'Save' }}
						</FormButton>
					</div>
					<p class="mt-3">
						Whitelisted Domains
						<small class="ml-1">(one domain per line)</small>
					</p>
					<p class="text-tiny">
						example:
						<br>
						<code>https://example.com</code>
						<br>
						<code>https://mysite.com</code>
						<br>
						<code>https://analytics-site.net</code>
					</p>
				</template>
				<template v-else>
					<Spinner />
				</template>
			</div>
		</section>
	</div>
	<div>
		<confirm-modal modal-id="feature-upgrade" ok-text="Upgrade Now" cancel-text="Later"
			@modal-confirm="navigateToPayments(stream.type)">
			<p class="mb-0 text-s-m"><a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to
				access this feature. Check <a target="_blank" class="text-reset text-underline text-nowrap"
					href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.</p>
		</confirm-modal>
	</div>
</template>

<style>
.multiselect__tags {
	@apply h-8 min-h-[2rem] !px-2 !py-1 bg-surface-3 border-none
}

.multiselect__select {
	@apply h-8 !p-1
}

.multiselect__option {
	@apply h-8 min-h-[2rem] !px-2 !py-1 text-tiny flex items-center
}

.multiselect__option::after {
	@apply h-8 min-h-[2rem] leading-8 text-surface-7
}

.multiselect__input, .multiselect__single {
	@apply h-8 min-h-[2rem] !px-2 text-tiny -mt-1 text-surface-8 placeholder:text-surface-7
}

.multiselect__input, .multiselect__single {
	@apply h-8 min-h-[2rem] text-tiny -mt-1
}

.multiselect__placeholder {
	@apply h-8 min-h-[2rem] !px-2 text-tiny text-surface-8
}

.multiselect__tag-icon {
	@apply flex items-center justify-center
}

.multiselect__tag-icon::after {
	@apply text-tiny leading-none
}
</style>