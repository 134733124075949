import DynamicService from "@/services/DynamicService";
import router from "@/router";

const defaultState = () => ({
  players: [],
  player: {},
  currentPlayerId: "",
  playerLoaded: false
});

const state = defaultState();

const mutations = {
  GET_PLAYERS(state, players) {
    state.players = players;
  },
  GET_PLAYER(state, player) {
    state.player = player;
    state.playerLoaded = true;
  },
  SET_CURRENT_PLAYER(state, id) {
    state.currentPlayerId = id;
  },
};

const actions = {
  async getPlayers({ commit }) {
    const players = await DynamicService.getPlayers();
    commit("GET_PLAYERS", players);
  },
  async getPlayer({ commit }, id) {
    const player = await DynamicService.getPlayer(id);
    commit("GET_PLAYER", player);
  },
  setCurrentPlayer({ commit }, id) {
    commit("SET_CURRENT_PLAYER", id);
  },
};

const getters = {
  dynamicPlayers: (state) => state.players,
  dynamicPlayer: (state) => state.player,
  currentPlayer: (state) => {
    if (state.currentPlayerId === '') {
      return state.players.find(
        (player) =>
          player._id ===
          (router.currentRoute.params && router.currentRoute.params.playerId)
      );
    } else {
      return state.players.find((player) => player._id === state.currentPlayerId);
    }
  },
  dynamicPlayerLoaded: (state) => state.playerLoaded,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
