<script setup>
import { onMounted } from 'vue'

import PlatformList from '@/components/ui/PlatformList.vue';

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	mediaPulse: {
		type: Object,
		default() { return {}; }
	}
})

onMounted(async () => {
	await window.trackEvent(props.stream.name + ' - Stream Dashboard Page', props.stream);
})

const emit = defineEmits({
	'refresh-stream': (cb) => { return cb },
	'stream-updated': (platforms) => { return platforms },
})

const requestStreamRefresh = (cb) => {
	emit('refresh-stream', cb);
}
const onPlatformListUpdated = (platformList) => {
	emit('stream-updated', { platforms: platformList });
}
</script>

<template>
	<div>
		<div class="content-container">
			<platform-list :stream="stream" :media-pulse="mediaPulse" @platform-list="onPlatformListUpdated"
				@refresh-stream="requestStreamRefresh" />
		</div>
	</div>
</template>
