<script setup>
</script>

<template>
  <span class="relative flex h-2 w-2">
    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-success opacity-75"></span>
    <span class="relative inline-flex rounded-full h-2 w-2 bg-success"></span>
  </span>
</template>
<style>
</style>
