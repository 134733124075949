<template>
  <path
    d="M4.66663 5.33333C4.66663 3.49238 6.15901 2 7.99996 2C9.84091 2 11.3333 3.49238 11.3333 5.33333V7.33333C11.3333 9.17428 9.84091 10.6667 7.99996 10.6667C6.15901 10.6667 4.66663 9.17428 4.66663 7.33333V5.33333Z"
    stroke="currentColor" stroke-width="1.22" />
  <path d="M7.33329 5.33333H8.66663" stroke="currentColor" stroke-width="1.22" stroke-linecap="round" />
  <path d="M6.66671 7.33334L9.33337 7.33334" stroke="currentColor" stroke-width="1.22" stroke-linecap="round" />
  <path
    d="M13.3333 6.66667V7.33334C13.3333 10.2789 10.9455 12.6667 7.99996 12.6667C5.05444 12.6667 2.66663 10.2789 2.66663 7.33334V6.66667"
    stroke="currentColor" stroke-width="1.22" stroke-linecap="round" />
  <path d="M8 12.6667V14.6667" stroke="currentColor" stroke-width="1.22" stroke-linecap="round" />
</template>