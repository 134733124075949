<script setup>
import { computed, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from "lodash";
import Spinner from "@/components/ui/Spinner.vue";
import StreamStatsService from '@/services/StreamStatsService';
import StreamService from '@/services/StreamService';
import SubSecondStats from '@/components/SubSecondStream/SubSecondStats.vue';
import StreamSidebar from '../../components/ui/StreamSidebar.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import Title from '../../components/ui/Title.vue';
import PaywallService from '@/services/PaywallService';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue'
import FormButton from '../../components/Atoms/FormButton.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronLeft from '../../components/icon/IconChevronLeft.vue';
import IconPlay from '../../components/icon/IconPlay.vue';
import IconBin from '../../components/icon/IconBin.vue';
import IconBookOpen from '../../components/icon/IconBookOpen.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import Tooltip from '../../components/Atoms/Tooltip.vue';
import TabGroup from '../../components/Atoms/TabGroup.vue';
import TabItem from '../../components/Atoms/TabItem.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const sssDropdown = ref(null)
const notAllowed = ref(false)
const isVideoListLoading = ref(false)
const scopeAlive = ref(true)
const statusProcessing = ref(false)
const trialSubscription = ref(null)
const processing = ref(true)
const processingMessage = ref(null)
const stream = ref(null)
const streamAlive = ref(false)
const streamName = ref(null)
const streamFps = ref(null)
const mediaPulse = ref(null)
const backupStreamAlive = ref(false)
const mediaPulseTimeoutCtrl = ref(null)
const videoFiles = ref([])
const scheduledAlert = ref(true)
const isRtmpPullEnabled = ref(null)
const streamDvrEnabled = ref(null)
const childViewList = ref([
	{
		streamType: 'subsecond',
		name: 'destinations',
		viewName: 'SubSecondManageDashboard'
	},
	{
		streamType: 'subsecond',
		name: 'Analytics',
		viewName: 'SubSecondManageAnalyticsLiveInputHealthCharts'
	},
	{
		streamType: 'subsecond',
		name: 'Settings',
		viewName: 'SubSecondSecuritySettings'
	},
])
const doc = ref({
	subsecond: {
		text: 'Guide Link',
		link: 'https://docs.castr.com/en/articles/8693777-sub-second-streaming',
		iframe: 'https://www.youtube.com/embed/Txy2k4IAIWI'
	},
})

const emit = defineEmits({
	'updateTitle': (streamName) => { return streamName },
})


const toggleProcessing = ref(false)

const userEmail = computed(() => store.state.User.email)

const baseSubscription = computed(() => store.getters["User/baseSubscription"])

const activeTeam = computed(() => store.state.User.activatedTeam)

const memberPermission = computed(() => activeTeam.value && store.state.User?.memberPermission)

onMounted(async () => {
	await store.dispatch('User/getactiveTeam', null, { root: true })
	await store.dispatch('Streams/getStreams')

	// get stream details
	await setupStream();
	await subscribeMediaPulse()
	if (!stream.value) return;
	if (localStorage.getItem('notAllowed' + stream.value.type)) {
		notAllowed.value = (localStorage.getItem('notAllowed' + stream.value.type) == 'true');
	}

	// Get storage usage

	streamDvrEnabled.value = stream.value.metadata?.dvrArchive;

	// we can't just update region to new one due HLS binded to region
	// we do hack with multiple regions: region & uiRegion assigned for single stream
	if (!stream.value.uiRegion) {
		stream.value.uiRegion = stream.value.region;
	}

	// event tracking
	window.trackEvent(stream.value.name + ' - Stream Page', stream.value);

	// update win title
	emit(
		'updateTitle',
		_.toUpper(stream.value.name) + ' - Castr Restreams'
	);

	if (baseSubscription.value) {
		trialSubscription.value = /trial/gi.test(baseSubscription.value.package.name);
	}

	processing.value = false;

})

onUnmounted(() => {
	scopeAlive.value = false
	unsubscribeMediaPulse()
})

const checkDelPermission = () => {
	let permission = memberPermission.value && !memberPermission.value.liveStreamDelete
	return permission;
}

const checkTogglePermission = () => {
	let permission = memberPermission.value && !memberPermission.value.liveStreamToggle
	return permission;
}

const openCreatestream = () => {
	root.$emit('bv::show::modal', 'modal-add-stream');
}

const onStreamUpdates = (updates) => {
	stream.value = _.assign({}, stream.value, updates);
}

const setupStream = async () => {
	// get stream details
	try {
		const streamDetails = await StreamService.getStream(route.params.streamId);
		const metadata = await StreamService.getStreamMetadata(route.params.streamId);
		stream.value = streamDetails
		stream.value.metadata = metadata;
	} catch (err) {
		// redirect to stream list
		// router.push({ name: 'ChannelList' });
		notify({
			group: 'error',
			title: err.error,
			text: err.message
		});
	}
}

const onStreamNameChange = async (newName) => {
	if (!newName) return

	if (stream.value.name === newName) return;

	// try changing stream name
	try {
		await StreamService.setStreamName(stream.value._id, newName);
		PaywallService.updateStreamPaywall(stream.value._id, { title: newName }).catch(err => {
			console.error('update paywall stream name failed: ', err)
		});

		// track event
		window.trackEvent(
			`Updated stream name ${stream.value.name} -> ${streamName.value}`
		);
	} catch (err) {
		notify({
			group: 'error',
			title: "Couldn't change stream name",
			text: err.message
		});
	}
}

const subscribeMediaPulse = async () => {
	(async function loop() {
		if (!scopeAlive.value) return;

		let timeoutInterval = 5000;
		let shouldUpdatePulse = stream.value?.enabled;
		if (document.hidden) {
			shouldUpdatePulse = false;
			timeoutInterval = 6000;
		}

		if (shouldUpdatePulse) {
			if (!document.hasFocus()) {
				timeoutInterval = 6000;
			}
		}
		if (shouldUpdatePulse) {
			try {
				const streamKey = stream.value?.key;
				const pulseObject = await StreamStatsService.getStreamMediaPulse(
					streamKey
				);
				onMediaPulse(pulseObject);
			} catch (e) {
				console.log(e);
			}
		}
		// reschedule
		mediaPulseTimeoutCtrl.value = setTimeout(loop, timeoutInterval);
	})();
}

const onMediaPulse = (pulseObject = {}) => {
	if (mediaPulse.value?.withoutPushStats) {
		const oldPushStats = mediaPulse.value.push_stats;
		pulseObject.push_stats = oldPushStats;
	}
	mediaPulse.value = pulseObject;
	streamAlive.value = stream.value.enabled && mediaPulse.value?.alive;
	streamFps.value = mediaPulse.value?.fps;
}

const unsubscribeMediaPulse = () => {
	clearTimeout(mediaPulseTimeoutCtrl.value);
}

const toggleStatus = async (ev, newStatus) => {
	ev && ev.preventDefault();
	ev && ev.stopPropagation();

	const oldStatus = stream.value.enabled;
	newStatus = newStatus === undefined ? !oldStatus : newStatus;

	// this.statusProcessing = true;
	stream.value.enabled = newStatus;
	if (!newStatus) {
		scheduledAlert.value = true
	}
	try {
		await StreamService.toggleStream(stream.value._id);
		// this.stream.enabled = newStatus;

		window.trackEvent(
			`${newStatus ? 'Enabled' : 'Disabled'} stream: ${stream.value.name
			}`,
			stream.value
		);
	} catch (err) {
		stream.value.enabled = oldStatus;

		notify({
			group: 'error',
			title: "Couldn't toggle stream status",
			text: err.message
		});

		if (err.message && err.message.indexOf('upgrade') > -1) {
			root.$emit('bv::show::modal', 'billing-prompt');
		}
	}

	statusProcessing.value = false;
}

const onToggleProcessing = (status) => {
	toggleProcessing.value = status
}

const onToggleDone = () => {
	fetchStreamDetails();
}

const requestStreamDelete = () => {
	if (activeTeam.value && checkDelPermission()) return;
	if (!activeTeam || !(((stream.value.type === 'live') && memberPermission.value && !memberPermission.value.liveStreamDelete))) {
		root.$emit('bv::show::modal', 'modal-confirm');
	}
	sssDropdown.value.toggleDropdown()
}

const onStreamDeleteConfirm = async () => {
	processing.value = true;
	processingMessage.value = 'Deleting stream';
	// try deleting stream
	try {
		await StreamService.deleteStream(stream.value._id);
		// track event
		window.trackEvent(
			`Deleted stream ${stream.value.name}`,
			stream.value
		);
		router.push({ name: 'ChannelList' });


	} catch (err) {
		// redirect to stream list
		notify({
			group: 'error',
			title: err.error,
			text: err.message
		});
	}

	processing.value = false;
}

const showStreamToggleModal = (ev) => {
	ev.preventDefault();
	ev.stopPropagation();
	if (notAllowed.value + stream.value.type && !stream.value.enabled) {
		root.$emit('bv::show::modal', 'increase-limit');
	}
	if (activeTeam.value && checkTogglePermission()) return;
	if (!notAllowed.value || (notAllowed.value && stream.value.enabled)) {
		root.$emit('bv::show::modal', 'modalToggleStream' + stream.value._id, '#btnShow');
	}
	sssDropdown.value.toggleDropdown()
}

const isTabActive = (viewItem) => {
	return route.name === viewItem.viewName
}

const changeRouteQuery = (viewItem) => {
  if (route.name != viewItem.viewName) {
    router.push({ name: viewItem.viewName })
  } else return
}
</script>

<template>
	<div class="container">
		<Spinner v-if="processing" text="Retrieving data..." classes="text-surface-8 mt-[30vh]"
			spinner-color="var(--c-surface-8)" spinner-size="15px" />
		<div class="md:grid grid-cols-[auto_420px] gap-4 mt-6 grid-rows-[auto_1fr]" v-else>
			<div class="">
				<div class="flex items-start w-full">
					<FormButton isIcon type="link" to="/sub-second" size="sm">
						<icon-base class="" fill="none">
							<icon-chevron-left />
						</icon-base>
					</FormButton>
					<Title :editable="true" :title="stream?.name" @changed="onStreamNameChange" titleClasses="text-lg font-medium rounded-md block" />
					<div class="ml-auto" :class="stream.type + '_doc'">
						<a target="_blank" class="inline-flex items-center text-sm text-surface-7" :href="doc[stream.type].link">
							<icon-base class="mr-1">
								<icon-book-open />
							</icon-base>
							{{ doc[stream.type].text }}
						</a>
						<a class="inline-flex items-center text-sm text-surface-7 md:ml-4" v-b-modal.modal_tutorial>
							<icon-base class="mr-1">
								<icon-play />
							</icon-base>
							Demo Link
						</a>
					</div>
					<drop-down-menu v-if="activeTeam && checkDelPermission()" ref="sssDropdown" size="sm" isIcon no-border
						type="secondary" class="inline-flex" position="right">
						<template #toggle-button>
							<icon-base class="ml-auto">
								<icon-elipsis />
							</icon-base>
						</template>
						<template #menu-items>
							<drop-down-menu-item class="w-full" @click="showStreamToggleModal">
								{{ stream.enabled ? 'Disable' : 'Enable' }}
							</drop-down-menu-item>
							<drop-down-menu-item class="w-full" @click="requestStreamDelete">
								Delete
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
				</div>
			</div>
			<aside class="row-span-2">
				<stream-sidebar v-if="streamType !== 'scheduled'" :stream="stream" ref="sidebarStreamPreviewPane"
					:stream-alive="streamAlive" :backup-stream-alive="backupStreamAlive" :primary-stream-alive="primaryStreamAlive" :media-pulse="mediaPulse"
					:isRtmpPullEnabled="isRtmpPullEnabled" @stream-updated="onStreamUpdates" class="mb-4" />
				<div class="">
					<div v-if="statusProcessing">
						<i class="fas fa-spinner fa-spin" />
					</div>
					<div v-else>
						<div class="!hidden md:!flex justify-end">
							<Tooltip :active="memberPermission">
								<FormButton v-if="stream.enabled" :disabled="toggleProcessing || memberPermission" type="secondary"
									class="mr-2" 
									@click="showStreamToggleModal">
									{{stream.enabled ? 'Disable' : 'Enable'}}
								</FormButton>
								<template #content>
									<span class="text-surface-8 text-small">
										You don’t have permission. Contact the team owner at <a class="text-surface-8 text-small"
											:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
								</template>
							</Tooltip>
							<Tooltip :active="memberPermission">
								<FormButton :disabled="memberPermission" type="danger"
									@click="requestStreamDelete">
									<icon-base>
										<icon-bin />
									</icon-base>
								</FormButton>
								<template #content>
									<span class="text-surface-8 text-small">
										You don’t have permission. Contact the team owner at <a class="text-surface-8 text-small"
											:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
								</template>
							</Tooltip>
						</div>
					</div>
				</div>
			</aside>
			<main class="">
				<sub-second-stats :stream="stream" :media-pulse="mediaPulse" :subscription="baseSubscription"
					:video-files="videoFiles" />
					<TabGroup class="w-full mt-6" type="lines">
						<TabItem v-for="(viewItem, index) in childViewList" :key="index" type="line" @click="changeRouteQuery(viewItem)" :label="viewItem.name"
							:isActive="isTabActive(viewItem, true)" />
					</TabGroup>
				<div class="mt-6">
					<router-view v-slot="{ Component }">
							<component :is="Component" :stream="stream" :stream-alive="streamAlive" :media-pulse="mediaPulse"
								:video-files="videFilesForCurrentPage" :trial-subscription="trialSubscription"
								@stream-updated="onStreamUpdates" @refresh-stream="fetchStreamDetails" @video-update="onVideoFileUpdate"
								@video-removed="onVideoFileRemoved" />
						</router-view>
						<Spinner v-if="isVideoListLoading" text="Retrieving data..." classes="text-dark-8 my-3"
							spinner-color="var(--c-dark-8)" spinner-size="15px" />
				</div>
			</main>
		</div>
		<confirm-modal message="Would you like to delete this stream and all of its content?" modal-type="danger"
			@modal-confirm="onStreamDeleteConfirm" />

		<confirm-modal
			message="Attention: Multistream has been discontinued. Set up a new All-In-One Stream today for uninterrupted streaming with enhanced features, at no extra cost."
			modal-id="create-aio" ok-text="Create All in One" cancel-text="Cancel" @modal-confirm="openCreatestream" />
		<confirm-modal modalId="delete-vod-stream" message="Permanently delete all files in this folder?"
			modal-type="danger" body="Yours files in VOD section inside the stream will also be deleted."
			@modal-confirm="onStreamDeleteConfirm" />
		<confirm-modal modalId="delete-vod-stream-showcase" message="Permanently delete all files in this folder?"
			modal-type="danger"
			body="This folder contains video(s) which are selected in the showcase. Deleting the folder will remove videos from the showcase as well."
			@modal-confirm="onStreamDeleteConfirm" />

		<confirm-modal modal-size="sm" modal-id="scheduled-status-warning" modal-type="danger"
			message="Stream is already scheduled" ok-text="Disable anyway" cancel-text="Cancel"
			body="If you disable the stream, it won’t start at the scheduled time."
			@modal-confirm="(scheduledAlert = false); toggleStatus()" />

		<alert-modal modal-id="alert-chat-down"
			message="We have encountered unusual behaviour in our chat app and would appreciate your patience while our team get things back online."
			ok-text="I Understand" class="text-center" />

		<demo-video-modal modalId="modal_tutorial" videoid="48ofjE3WZ064-SuIElpIr" />
		<toggle-stream-modal v-if="stream" :stream="stream" @modal-toggle="toggleStatus"
			@toggle-processing="onToggleProcessing" @toggle-done="onToggleDone" />
	</div>
</template>

<style scoped></style>
